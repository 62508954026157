import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import MasterDataEmptyScreen from 'components/MasterDataEmptyScreen'
import SearchIcon from '@mui/icons-material/Search'
import Drawer from '@mui/material/Drawer'
import React, { useEffect, useState } from 'react'
import Table from 'components/Mui/TableComponent'
import { useNavigate } from 'react-router-dom'
import { EditButton } from 'components/IconButtons'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import { Close } from '@mui/icons-material'
import HeadMastersForm from './HeadMastersForm'
import moment from 'moment'
import { http } from 'services/http'
import { useQuery, useQueryClient } from 'react-query'
import { UploadModal } from 'components/UploadModal/UploadModal'
import HeadMastersEmptyScreen from './HeadMastersEmptyScreen'

const HeadMasters = () => {
  const userType = localStorage.getItem('user_type')

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState()

  const [headMasters, setHeadMasters] = useState()
  const [open, setOpen] = React.useState(false)
  const [formData, setFormData] = useState([])
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()

  useEffect(() => {
    modalOpen == false && queryClient.invalidateQueries('headMasters')
  }, [modalOpen])

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  async function getHeadMasters() {
    setSearchLoading(true)
    try {
      http
        .get(`/statistics/master-data/head-masters?search=${search}`)
        .then((res) => {
          console.log(res)
          setPage(1)
          setCount(res?.data?.headMasters?.length)
          setHeadMasters(res?.data?.headMasters?.slice(0, 10))
          setSearchLoading(false)
        })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getHeadMasters()
  }, [search])
  const handleSearch = (e) => {
    e?.preventDefault()
    getHeadMasters()
  }

  const { data, isLoading } = useQuery(
    ['headMasters'],
    () =>
      http.get(`/statistics/master-data/head-masters`).then((res) => {
        console.log(res)
        return res?.data?.headMasters
      }),
    {
      onSuccess: (data) => {
        setCount(data?.length)
        setHeadMasters(data?.slice(0, 10))
      },
    },
  )

  const columns = [
    {
      title: 'Head Master ID',

      render: (_, row) => {
        return row?.employeeCode ? row?.employeeCode : 'NA'
      },
    },
    {
      title: 'Head Master Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'School Name',
      dataIndex: 'school',
      key: 'school',
      render: (_, row) => {
        return row?.school ? row?.school?.name : 'NA'
      },
    },

    {
      title: 'Added on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (colData, row) => (
        <EditButton
          onClick={() => {
            handleEditClick(row)
          }}
        />
      ),
    },
  ]

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/statistics/master-data/head-masters?limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&search=${search}`,
      )
      console.log(res)
      setHeadMasters(res.data?.headMasters)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  if (isLoading || loading || !headMasters) {
    return <CircularProgress />
  }

  return (
    <div>
      {modalOpen && (
        <UploadModal
          setOpen={setModalOpen}
          img={'/images/Rectangle 11247.png'}
          title={'Head Masters'}
          section={'head-masters'}
        />
      )}{' '}
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <form className="mb-4" onSubmit={handleSearch}>
            <TextField
              label="Search by Head Master Name"
              sx={{ width: '494px', marginBottom: '1.5rem' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <Box sx={{ display: 'flex' }} gap={'18px'}>
            {userType == 'Admin' && (
              <Button
                variant="outlined"
                sx={{
                  borderRadius: '10px',
                  borderColor: 'black !important',
                  background: 'transparent !important',
                }}
                onClick={() => setModalOpen(true)}
              >
                {' '}
                <Typography
                  sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
                >
                  + Import XLS
                </Typography>
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                borderRadius: '10px',
                borderColor: 'black !important',
                background: 'transparent !important',
              }}
              onClick={() => {
                setFormData(null)
                toggleDrawer(true)
              }}
            >
              <Typography
                sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
              >
                + Add Head Master
              </Typography>
            </Button>
          </Box>
        </Box>
        {headMasters?.length == 0 ? (
          userType == 'Admin' ? (
            <MasterDataEmptyScreen
              title="Head Masters"
              section={'head-masters'}
            />
          ) : (
            <HeadMastersEmptyScreen
              title="Head Masters"
              section={'head-masters'}
            />
          )
        ) : (
          <>
            <Table
              key={'Head Masters'}
              dataSource={headMasters}
              rowKey={'id'}
              columns={columns}
              loading={isLoading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />{' '}
            </Box>
          </>
        )}
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            {formData ? 'Edit Head Master' : 'Add Head Master'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <HeadMastersForm data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  )
}

export default HeadMasters
