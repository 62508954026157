import { Box, IconButton, TextField, Typography } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/Mui/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { ViewButton } from 'components/IconButtons';
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen';
import Table from 'components/Mui/TableComponent';
import { useQuery } from 'react-query';
import { http } from 'services/http';
import moment from 'moment';
function ViewItemTable() {
    let { id } = useParams();
    const navigate = useNavigate()
    const [search, setSearch] = useState('');

    const { data, isLoading } = useQuery(["stocks",search], () =>
    http.get(`/stocks?schoolId=${id}&search=${search}`).then((res) => res.data)
  );

  console.log(data,"data");


    const columns = [
        {
          title: 'Stock Updated On',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
        },
        {
          title: 'Stock Item',
          dataIndex: 'stockMaterial.itemName',
          key: 'stockMaterial.itemName',
        },
        {
          title: 'Stock Usage Quantity',
          dataIndex: 'currentQuantity',
          key: 'currentQuantity',
        },
        {
          title: 'Price',
          dataIndex: 'lowStockIndication',
          key: 'lowStockIndication',
          render: (_,row) => (
            <Typography>{row.stockMaterial.rate * row.currentQuantity + "/-"}</Typography>
          )
        },
   
        {
          title: 'Actions',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <Box>
              <ViewButton
                onClick={() => {
                  navigate(
                    `/gcc/stockitem-table/${id}/viewitemtable`,
                  )
                }}
              />
            </Box>
          ),
        },
      ]


  return (
    <div>
          <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'GCC Stock', to: '/gcc' },
              {
                name: 'Stock Usage',
                to: null,
              },
            ]}
          />
        }
      />
      <TextField
        label="Search by School name, School ID"
        sx={{ width: '290px',mb:2 }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={search}
        onChange={({ target }) => {
          setSearch(target.value)
        }}
      />
        {false ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'schools'}
            dataSource={data?.result}
            rowKey={'id'}
            columns={columns}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '} */}
          </Box>
        </>
      )}
    </div>
  )
}

export default ViewItemTable



