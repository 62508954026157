import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DividerHeading from 'components/DividerHeading'
import Table from 'components/Mui/TableComponent'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { http } from 'services/http'

const TeacherAndStaffAttendanceDetailsInnerScreen = () => {
  const navigate = useNavigate()
  const [stats, setStats] = useState()
  const [teachers, setTeachers] = useState()
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const userType = localStorage.getItem('user_type')
  const DATE = localStorage.getItem('date')
  const [date, setDate] = useState(new Date(DATE ? new Date(DATE) : new Date()))
  const [loading, setLoading] = useState()
  const [paginationLoading, setPaginationLoading] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [searchParams] = useSearchParams()
  const schoolID = searchParams.get('id')
  const type = searchParams.get('type')

  useEffect(() => {
    setLoading(true)
    let fromDate = moment(new Date(date)).startOf('month').format('YYYY-MM-DD')
    let toDate = moment(new Date(date)).endOf('month').format('YYYY-MM-DD')

    type == 'Staff'
      ? http
          .get(
            `/statistics/staff?fromDate=${fromDate}&toDate=${toDate}&schoolId=${schoolID}`,
          )
          .then((res) => {
            console.log(res)
            setCount(res?.data?.count)
            setTeachers(res?.data?.data?.slice(0, 10))
            setLoading(false)
          })
      : http
          .get(
            `/attendance-activities/teacher?type=${type}&date=${date}&schoolId=${schoolID}`,
          )
          .then((res) => {
            console.log(res)
            setStats(res?.data?.regularTeacherSubject)
            setCount(res?.data?.stats?.teacher?.count)
            setTeachers(res?.data?.stats?.teacher?.result?.slice(0, 10))
            setLoading(false)
          })
  }, [date])

  const columns = [
    {
      title: 'School ID',
      dataIndex: 'schoolId',
      key: 'schoolId',
    },
    {
      title: 'School Name',
      dataIndex: 'school.name',
      key: 'school.name',
    },
    {
      title: type == 'Staff' ? 'Staff Member Name' : 'Teacher Name',
      dataIndex: type == 'Staff' ? 'staff.name' : 'teacher.name',
      key: type == 'Staff' ? 'staff.name' : 'teacher.name',
    },
    {
      title: 'Mobile Number',
      dataIndex:
        type == 'Staff' ? 'staff.mobileNumber' : 'teacher.mobileNumber',
      key: type == 'Staff' ? 'staff.mobileNumber' : 'teacher.mobileNumber',
    },
    {
      title: 'Gender',
      dataIndex: type == 'Staff' ? 'staff.gender' : 'teacher.gender',
      key: type == 'Staff' ? 'staff.gender' : 'teacher.gender',
    },
    type != 'Staff' && {
      title: 'Subject',
      dataIndex: 'teacher.subject.name',
      key: 'teacher.subject.name',
    },
    {
      title: 'Designation',
      dataIndex:
        type == 'Staff' ? 'staff.designation.name' : 'teacher.designation',
      key: type == 'Staff' ? 'staff.designation.name' : 'teacher.designation',
    },
    {
      title: 'Attendance Status',
      dataIndex: 'status',
      key: 'status',
      render: (rowData) =>
        rowData ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Present
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Absent
          </Box>
        ),
    },
  ]

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/attendance-activities/teacher?type=${type}&date=${date}&limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&schoolId=${schoolID}`,
      )
      console.log(res)
      setTeachers(res?.data?.stats?.teacher?.result)
      setPaginationLoading(false)
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
      setPaginationLoading(false)
    }
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div>
      {' '}
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: '180px', height: '50px' }}>
            <DatePicker
              value={date}
              onChange={(newValue) => {
                setDate(newValue)
              }}
              inputFormat="DD MMM, YYYY"
              maxDate={new Date()}
              renderInput={(params) => <TextField {...params} />}
            />{' '}
          </Box>
        </LocalizationProvider>{' '}
      </Box>
      <Box sx={{ marginTop: '-40px' }}>
        <DividerHeading title={'Attendance Details'} />
      </Box>{' '}
      {paginationLoading ? (
        <CircularProgress />
      ) : count == 0 ? (
        <SchoolAnalyticsEmptyScreen />
      ) : (
        <>
          <Table
            key={'regularTeachers'}
            dataSource={teachers}
            rowKey={'id'}
            columns={columns}
            loading={loading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
    </div>
  )
}

export default TeacherAndStaffAttendanceDetailsInnerScreen
