import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const HospitalisedDetails = ({
  data,
  item,
  setItem,
  search,
  setSearch,
  loading,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [hospitalized, setHospitalized] = useState(data)

  useEffect(() => {
    data?.length !== 0 && setHospitalized(data)
  }, [data])

  const StatusBox = styled(Box)(({ status }) => {
    return {
      backgroundColor:
        status === 'ADMITTED'
          ? '#FFFAEF'
          : status === 'DISCHARGED' && '#E8F6EE',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '8px 8px',
    }
  })

  const changeSelected = (item) => {
    setItem(item)
  }
  return (
    <div>
      <Box>
        <TextField
          label="Search by Student's Name"
          sx={{ mb: 2, width: '100%' }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {data?.length === 0 ? (
          <Box>
            <SchoolAnalyticsEmptyScreen />
          </Box>
        ) : loading ? (
          <Loader />
        ) : (
          <Box overflow="auto" maxHeight="700px" className="scrollBox">
            {hospitalized?.map((obj, index) => {
              let desc =
                obj?.description?.length > 500
                  ? obj?.description?.slice(0, 500) + '...'
                  : obj?.description

              return (
                <Box
                  p={2}
                  mb={1.5}
                  border={
                    obj?.id == item?.id
                      ? `1px solid black`
                      : '1px solid rgba(34, 34, 34, 0.08)'
                  }
                  borderRadius="5px"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => changeSelected(obj)}
                >
                  <Grid container sx={{ width: '100%' }}>
                    <Grid item xs={6}>
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Admitted Date
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {obj?.admittedDate
                            ? moment(obj?.admittedDate).format('DD-MM-YYYY')
                            : 'NA'}
                        </Typography>
                      </Box>
                      <Box pt={2.5}>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Student's Name
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {obj?.studentName ?? 'NA'}
                        </Typography>
                      </Box>
                      <Box pt={2.5}>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Hospital Name
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {obj?.hospitalName ?? 'NA'}
                        </Typography>
                      </Box>
                      <Box pt={2.5}>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Description
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {desc}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Box>
                        <StatusBox status={obj?.status}>
                          <Typography
                            sx={{
                              color:
                                obj?.status === 'ADMITTED'
                                  ? '#FFCF64'
                                  : obj?.status === 'DISCHARGED' && '#1AAA55',
                            }}
                          >
                            {obj?.status}
                          </Typography>
                        </StatusBox>
                        <Box
                          pt={2.5}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Typography
                            sx={{
                              fontFamily: 'poppins_medium',
                              fontSize: '14px',
                              opacity: '0.5',
                            }}
                          >
                            Class
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: 'poppins_semibold',
                              fontSize: '14px',
                            }}
                          >
                            {obj?.studentClass ?? 'NA'}
                          </Typography>
                        </Box>
                        <Box
                          pt={2.5}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Typography
                            sx={{
                              fontFamily: 'poppins_medium',
                              fontSize: '14px',
                              opacity: '0.5',
                            }}
                          >
                            Priority
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: 'poppins_semibold',
                              fontSize: '14px',
                            }}
                          >
                            {obj?.priority}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            })}
          </Box>
        )}
      </Box>
    </div>
  )
}

export default HospitalisedDetails
