import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import PageHeader from 'components/Mui/PageHeader'
import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { http } from 'services/http'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useSnackbar } from 'notistack'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import ImagePopup from 'components/ImagePopup/ImagePopup'
import InfiniteScroll from 'react-infinite-scroll-component'

const AcademicActivityDetails = () => {
  const districtID = localStorage.getItem('districtId')
  const atdoPointID = localStorage.getItem('atdoPointId')
  const DATE = localStorage.getItem('date')
  const [params] = useSearchParams()
  const type = params?.get('type')
  const [activityType, setActivityType] = useState(type)
  const userType = localStorage.getItem('user_type')
  const [search, setSearch] = useState()
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [mandals, setMandals] = useState()
  const [districtId, setDistrictId] = useState(districtID ?? '')
  const [atdoPointId, setAtdoPointId] = useState(atdoPointID ?? '')
  const [mandalId, setMandalId] = useState('')
  const [date, setDate] = useState(DATE ? new Date(DATE) : new Date())
  const [schools, setSchools] = useState([])
  const [loading, setLoading] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)
  const [schoolDetails, setSchoolDetails] = useState()

  useEffect(() => {
    setLoading(true)
    http
      .get(
        `/statistics/activity/type?date=${date}&type=${activityType}&districtId=${districtId}&atdoPointId=${atdoPointId}`,
      )
      .then((res) => {
        setCount(res?.data?.count)
        setSchools(res?.data?.data?.slice(0, 11))
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }, [date, activityType])

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  const selectType =
    type == 'Physical'
      ? 'Morning Physical Activity'
      : type == 'MorningStudyHour'
      ? 'Morning Study Hour'
      : type == 'Prayer'
      ? 'Prayer'
      : type == 'NightStudyHour'
      ? 'Night Study Hour'
      : type == 'Sports'
      ? 'Sports Activity'
      : type == 'Special' && 'Special Class'


  const academicActivities = [
    { name: 'Morning Physical Activity', value: 'Physical' },
    { name: 'Morning Study Hour', value: 'MorningStudyHour' },
    { name: 'Prayer', value: 'Prayer' },
    { name: 'Night Study Hour', value: 'NightStudyHour' },
    { name: 'Sports Activity', value: 'Sports' },
    { name: 'Special Class', value: 'Special' },
  ]

  const handleSubmit = (e) => {
    e?.preventDefault()
    setLoading(true)
    http
      .get(
        `/statistics/activity/type?date=${date}&type=${activityType}&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}`,
      )
      .then((res) => {
        setCount(res?.data?.count)
        setSchools(res?.data?.data?.slice(0, 11))
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }

  async function handlePagination(e, v) {
    try {
      setPage(page + 1)
      http
        .get(
          `/statistics/activity/type?date=${date}&type=${activityType}&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&limit=11&offset=${
            page * 11
          }`,
        )
        .then((res) => {
          // setCount(res?.data?.count)
          setSchools(schools?.concat(res?.data?.data))
          // setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      {modalOpen && (
        <ImagePopup setOpen={setModalOpen} schoolDetails={schoolDetails} />
      )}{' '}
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Activity Dashboard', to: '/activity-dashboard' },
              {
                name: 'Activity Details',
                to: null,
              },
            ]}
          />
        }
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'15px'}>
          <Box
            sx={{
              padding: '30px',
              boxShadow: '0px 3px 16px #2A32710F',
              borderRadius: '10px',
              width: '150px',
            }}
          >
            <Typography
              sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
            >
              {count}
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                fontFamily: 'poppins_medium',
                opacity: '0.7',
              }}
            >
              Marked Schools
            </Typography>
          </Box>
          <FormControl sx={{ width: '150px' }}>
            <InputLabel id="type">Activity Type</InputLabel>
            <Select
              labelId="type"
              id="type"
              label="Activity Type"
              defaultValue={type}
              value={activityType}
              onChange={(e) => setActivityType(e.target.value)}
            >
              {academicActivities?.map((item) => {
                return <MenuItem value={item?.value}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          {}
          <Box>
            {userType == 'Admin' || userType == 'DD' ? (
              <form onSubmit={(e) => handleSubmit(e)}>
                <Stack
                  gap={3}
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <FormControl sx={{ width: '170px' }}>
                    <InputLabel id="districtId">Select District</InputLabel>
                    <Select
                      labelId="districtId"
                      id="districtId"
                      label="Select District"
                      value={districtId}
                      onChange={(e) => {
                        setAtdoPointId('')
                        setDistrictId(e?.target?.value)
                      }}
                    >
                      {districts?.map((item) => {
                        return (
                          <MenuItem value={item?.id}>{item?.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: '170px' }}>
                    <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                    <Select
                      labelId="atdoPointId"
                      id="atdoPointId"
                      label="Select ATDO Point"
                      value={atdoPointId}
                      onChange={(e) => setAtdoPointId(e?.target?.value)}
                    >
                      {atdoPoints?.map((item) => {
                        return (
                          <MenuItem value={item?.id}>{item?.name}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: '145px', height: '50px' }}>
                      <DatePicker
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue)
                        }}
                        inputFormat="DD MMM, YYYY"
                        maxDate={new Date()}
                        renderInput={(params) => <TextField {...params} />}
                      />{' '}
                    </Box>
                  </LocalizationProvider>{' '}
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'black !important',
                      height: '50px',
                      width: '130px',
                      borderRadius: '10px',
                    }}
                    type="submit"
                  >
                    <Typography
                      sx={{
                        fontFamily: 'poppins_semibold',
                        color: 'white',
                        fontSize: '14px',
                      }}
                    >
                      Apply
                    </Typography>
                  </Button>
                </Stack>
              </form>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ width: '145px', height: '50px' }}>
                    <DatePicker
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue)
                      }}
                      inputFormat="DD MMM, YYYY"
                      maxDate={new Date()}
                      renderInput={(params) => <TextField {...params} />}
                    />{' '}
                  </Box>
                </LocalizationProvider>{' '}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : schools?.length == 0 ? (
        <Box sx={{ marginTop: '80px' }}>
          <SchoolAnalyticsEmptyScreen />
        </Box>
      ) : (
        <>
          <InfiniteScroll
            dataLength={schools?.length}
            next={handlePagination}
            hasMore={schools?.length < count}
            loader={<CircularProgress />}
            style={{ overflow: 'unset' }}
          >
            <Grid container spacing={2} sx={{ marginTop: '25px' }}>
              {schools?.map((item) => {
                return (
                  <Grid
                    item
                    xl={2.25}
                    lg={4}
                    md={4}
                    onClick={() => {
                      setModalOpen(true)
                      setSchoolDetails(item)
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Images
                      image={
                        item?.media[0] ??
                        'https://jss-giri.s3.ap-south-1.amazonaws.com/blank_image.png'
                      }
                      name={item?.school?.name}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </InfiniteScroll>

          {/* <Box
            sx={{
              marginTop: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />
          </Box> */}
        </>
      )}
    </div>
  )
}

export default AcademicActivityDetails

const Images = ({ image, name }) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 3px 13px #2A32711A',
        borderRadius: '10px',
      }}
    >
      <Box>
        <img
          src={image}
          style={{
            height: '190px',
            width: '100%',
            objectFit: 'cover',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
          }}
        />
      </Box>
      <Box sx={{ padding: '18px' }}>
        <Typography sx={{ fontFamily: 'poppins_semibold', fontSize: '13px' }}>
          {name}
        </Typography>
      </Box>
    </Box>
  )
}
