import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { http } from 'services/http'
import { Link, useOutletContext } from 'react-router-dom'
import moment from 'moment'

const AttendanceActivities = () => {
  const type = 'Attendance'
  localStorage.setItem('schoolActivityType', type)
  const [schoolActivities, setSchoolActivities] = useState()
  const [date] = useOutletContext()
  const [loading, setLoading] = useState(false)
  const id = localStorage.getItem('schoolId')
  console.log(date)
  useEffect(() => {
    setLoading(true)
    http
      .get(`/statistics/school/${id}?type=${type}&date=${date}`)
      .then((res) => {
        console.log(res)
        setSchoolActivities(res?.data)
        setLoading(false)
      })
  }, [date])
  const cards = [
    {
      title: 'Student Attendance',
      presentCount: `${schoolActivities?.student?.present}`,
      presentContent: 'Students Present',
      sickCount: `${schoolActivities?.student?.sick ?? 'NA'}`,
      sickContent: 'Students Sick',
      absentCount: `${schoolActivities?.student?.absent}`,
      absentContent: 'Students Absent',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.student?.percentage)
          ? schoolActivities?.student?.percentage
          : schoolActivities?.student?.percentage?.toFixed(1)
      }%`,
      link: '/schools/attendance-details',
    },
    {
      title: 'Regular Teacher Attendance',
      presentCount: `${schoolActivities?.regular?.present}`,
      presentContent: 'Teachers Present',
      absentCount: `${schoolActivities?.regular?.absent}`,
      absentContent: 'Teachers Absent',
      leaves: schoolActivities?.regular?.teacherLeave,
      presentPercentage: `${
        Number.isInteger(schoolActivities?.regular?.percentage)
          ? schoolActivities?.regular?.percentage
          : schoolActivities?.regular?.percentage?.toFixed(1)
      }%`,
      link: '/schools/regular-teachers-details',
    },
    {
      title: 'CRT Teacher Attendance',
      presentCount: `${schoolActivities?.crt?.present}`,
      presentContent: 'Teachers Present',
      absentCount: `${schoolActivities?.crt?.absent}`,
      absentContent: 'Teachers Absent',
      leaves: schoolActivities?.crt?.crtLeave,
      presentPercentage: `${
        Number.isInteger(schoolActivities?.crt?.percentage)
          ? schoolActivities?.crt?.percentage
          : schoolActivities?.crt?.percentage?.toFixed(1)
      }%`,
      link: '/schools/crt-teachers-details',
    },
    {
      title: 'Night Closing Attendance',
      presentCount: `${schoolActivities?.night?.present}`,
      presentContent: 'Students Present',
      sickCount: `${schoolActivities?.night?.sick ?? 'NA'}`,
      sickContent: 'Students Sick',
      absentCount: `${schoolActivities?.night?.absent}`,
      absentContent: 'Students Absent',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.night?.percentage)
          ? schoolActivities?.night?.percentage
          : schoolActivities?.night?.percentage?.toFixed(1)
      }%`,
      link: '/schools/night-attendance',
    },
    {
      title: 'Staff Attendance',
      presentCount: `${schoolActivities?.staff?.present}`,
      presentContent: 'Staff Members Present',
      leaves: schoolActivities?.staff?.staffLeave,
      absentCount: `${schoolActivities?.staff?.absent}`,
      absentContent: 'Staff Members Absent',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.staff?.percentage)
          ? schoolActivities?.staff?.percentage
          : schoolActivities?.staff?.percentage?.toFixed(1)
      }%`,
      link: '/schools/staff-attendance',
    },
  ]

  if (loading) {
    return <CircularProgress />
  }
  return (
    <>
      <Box sx={{ marginTop: '30px' }}>
        <DividerHeading title={'Student Attendance'} />
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-35px' }}>
        <Grid item xl={4.5} lg={4.5} md={4.5}>
          <Card
            link={cards[0]?.link}
            title={cards[0]?.title}
            presentCount={cards[0]?.presentCount}
            presentContent={cards[0]?.presentContent}
            sickCount={cards[0]?.sickCount}
            sickContent={cards[0]?.sickContent}
            absentCount={cards[0]?.absentCount}
            absentContent={cards[0]?.absentContent}
            presentPercentage={cards[0]?.presentPercentage}
          />
        </Grid>
        <Grid item xl={4.5} lg={4.5} md={4.5}>
          <Card
            link={cards[3]?.link}
            title={cards[3]?.title}
            presentCount={cards[3]?.presentCount}
            presentContent={cards[3]?.presentContent}
            sickCount={cards[3]?.sickCount}
            sickContent={cards[3]?.sickContent}
            absentCount={cards[3]?.absentCount}
            absentContent={cards[3]?.absentContent}
            presentPercentage={cards[3]?.presentPercentage}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '30px' }}>
        <DividerHeading title={'Teacher Attendance'} />
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-35px' }}>
        <Grid item xl={4.5} lg={5} md={5}>
          <TeacherCard
            link={cards[1]?.link}
            title={cards[1]?.title}
            presentCount={cards[1]?.presentCount}
            presentContent={cards[1]?.presentContent}
            leaves={cards[1]?.leaves}
            absentCount={cards[1]?.absentCount}
            absentContent={cards[1]?.absentContent}
            presentPercentage={cards[1]?.presentPercentage}
          />
        </Grid>
        <Grid item xl={4.5} lg={5} md={5}>
          <TeacherCard
            link={cards[2]?.link}
            title={cards[2]?.title}
            presentCount={cards[2]?.presentCount}
            presentContent={cards[2]?.presentContent}
            leaves={cards[2]?.leaves}
            absentCount={cards[2]?.absentCount}
            absentContent={cards[2]?.absentContent}
            presentPercentage={cards[2]?.presentPercentage}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '30px' }}>
        <DividerHeading title={'Staff Attendance'} />
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-35px' }}>
        <Grid item xl={4.5} lg={5} md={5}>
          <TeacherCard
            link={cards[4]?.link}
            title={cards[4]?.title}
            presentCount={cards[4]?.presentCount}
            presentContent={cards[4]?.presentContent}
            leaves={cards[4]?.leaves}
            absentCount={cards[4]?.absentCount}
            absentContent={cards[4]?.absentContent}
            presentPercentage={cards[4]?.presentPercentage}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AttendanceActivities

function DividerHeading({ title }) {
  return (
    <StyledContainer title={title}>
      <Typography sx={{ fontSize: '14px', fontFamily: 'poppins_medium' }}>
        {title}
      </Typography>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)(({ theme, title }) => ({
  margin: '20px 0',
  borderBottom: theme.borders.grayLight,
}))

const Card = ({
  title,
  presentCount,
  presentContent,
  sickCount,
  sickContent,
  absentCount,
  absentContent,
  link = '',
  presentPercentage,
}) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 6px 16px #2A32711A',
          margin: '29px 29px 0px 0px',
        }}
      >
        <Box>
          <Box sx={{ borderBottom: '1px solid #E7E7E7' }}>
            <Box sx={{ padding: '23px' }}>
              <Typography
                sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: '23px' }}>
          <Box
            sx={{
              padding: '23px 0px',
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E7E7E7',
            }}
            gap={'66px'}
          >
            <Box>
              <Typography
                sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
              >
                {presentCount}
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
              >
                {presentContent}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
              >
                {sickCount}
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
              >
                {sickContent}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
              >
                {absentCount}
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
              >
                {absentContent}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '22px',
            }}
          >
            <Typography sx={{ fontSize: '13px', fontFamily: 'poppins_medium' }}>
              Present Percentage
            </Typography>
            <Typography
              sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
            >
              {presentPercentage}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

const TeacherCard = ({
  title,
  presentCount,
  presentContent,
  leaves,
  absentCount,
  absentContent,
  link = '',
  presentPercentage,
}) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 6px 16px #2A32711A',
          margin: '29px 29px 0px 0px',
        }}
      >
        <Box>
          <Box sx={{ borderBottom: '1px solid #E7E7E7' }}>
            <Box sx={{ padding: '23px' }}>
              <Typography
                sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: '23px' }}>
          <Box
            sx={{
              padding: '23px 0px',
              display: 'flex',
            }}
            gap={'66px'}
          >
            <Box>
              <Typography
                sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
              >
                {presentCount}
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
              >
                {presentContent}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
              >
                {absentCount}
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
              >
                {absentContent}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: '23px 0px',
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E7E7E7',
            }}
            gap={'66px'}
          >
            {leaves?.length == 0 || !leaves ? (
              <Box>
                <Typography
                  sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
                >
                  NA
                </Typography>
                <Typography
                  sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
                >
                  NA
                </Typography>
              </Box>
            ) : (
              leaves?.map((item) => (
                <Box>
                  <Typography
                    sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
                  >
                    {item?.count}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
                  >
                    {item?.leave_type}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '22px',
            }}
          >
            <Typography sx={{ fontSize: '13px', fontFamily: 'poppins_medium' }}>
              Present Percentage
            </Typography>
            <Typography
              sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
            >
              {presentPercentage}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}
