import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import PageHeader from 'components/Mui/PageHeader'
import Filters from './Filters'
import { useEffect, useState } from 'react'
import { Box, Button, Pagination, Typography } from '@mui/material'
import { http } from 'services/http'
import { useQuery } from 'react-query'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import Table from 'components/Mui/TableComponent'
import * as moment from 'moment'
import AlternateScreen from './AlternateScreen'

const OverallStockUsage = () => {
  const [districtId, setDistrictId] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [atdoPointId, setAtdoPointId] = useState('')
  const [schoolId, setSchoolId] = useState('')
  const [date, setDate] = useState(new Date())

  const fetchStockUsage = async (
    districtId,
    atdoPointId,
    schoolId,
    date,
    search,
    page,
  ) => {
    console.log(date)
    const dateFilter = moment(date).toISOString()
    const res = await http.get(
      `/stock-usages?districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}&date=${dateFilter}&search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`,
    )
    return res?.data[0]?.individualStockUsages
  }

  const { data, isLoading } = useQuery(
    [
      'schoolsStockUsages',
      districtId,
      atdoPointId,
      schoolId,
      date,
      search,
      page,
    ],
    () =>
      fetchStockUsage(districtId, atdoPointId, schoolId, date, search, page),
    {
      onError: () => {
        alert('Network error')
      },
    },
  )

  useEffect(() => {
    const dateFilter = moment(date).toISOString()

    http
      .get(
        `/stock-usages?districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}&date=${dateFilter}&search=${search}`,
      )
      .then((res) => {
        setCount(res?.data?.length)
      })
  }, [districtId, atdoPointId, schoolId, date, search])

  const columns = [
    {
      title: 'Stock Item Name',
      dataIndex: 'stockMaterial.itemName',
      key: 'stockMaterial.itemName',
    },
    {
      title: 'Stock Type',
      dataIndex: 'stockMaterial.itemType',
      key: 'stockMaterial.itemType',
      render: (rowData) =>
        rowData == 'nonPerishable' ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Non-Perishable
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Perishable
          </Box>
        ),
    },
    {
      title: 'Total Stock Used Per Day',
      dataIndex: 'changeQuantity',
      key: 'changeQuantity',
    },

    {
      title: 'Amount Used Per Day',
      dataIndex: 'price',
      key: 'price',
    },
  ]

  function handlePagination(e, v) {
    setPage(v)
  }

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'GCC Stock', to: '/gcc' },
              { name: 'View Stock Usage', to: null },
            ]}
          />
        }
      />
      <Filters
        search={search}
        setSearch={setSearch}
        districtId={districtId}
        setDistrictId={setDistrictId}
        atdoPointId={atdoPointId}
        setAtdoPointId={setAtdoPointId}
        schoolId={schoolId}
        setSchoolId={setSchoolId}
        date={date}
        setDate={setDate}
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'black !important',
            height: '50px',
            width: '130px',
            borderRadius: '10px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'poppins_semibold',
              color: 'white',
              fontSize: '14px',
            }}
          >
            Export Report
          </Typography> 
        </Button>
      </Box>
      {schoolId?.length === 0 ? (
        <AlternateScreen/>
      ) : (
      isLoading || !data ? (
        <Loader />
      ) : data?.length == 0 ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'schoolsStockUsages'}
            dataSource={data}
            rowKey={'id'}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      ))}
    </div>
  )
}

export default OverallStockUsage
