import { Box, IconButton, Pagination, TextField, Typography } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PageHeader from 'components/Mui/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { ViewButton } from 'components/IconButtons';
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen';
import Table from 'components/Mui/TableComponent';
import { useQuery } from 'react-query';
import { http } from 'services/http';
import moment from 'moment';
function StockItemTable() {
    let { id } = useParams();
    const navigate = useNavigate()
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0)

    const { data, isLoading } = useQuery(["stocks",search,page], () =>
    http.get(`/stocks?search=${search}&schoolId=${id}&limit=10&offset=${page}`).then((res) => res.data)
  );


  const handlePagination = (e, value) => {
    setPage(value-1 * 10)
  };


    const columns = [
        {
          title: 'Stock Item',
          dataIndex: 'stockMaterial.itemName',
          key: 'stockMaterial.itemName',
        },
        {
          title: 'Current Stock Balance',
          dataIndex: 'currentQuantity',
          key: 'currentQuantity',
        },
        {
          title: 'StockType',
          dataIndex: 'stockMaterial.itemType',
          key: 'stockMaterial.itemType',
          render: (_,row) => (
            <Box >
                <Typography sx={{bgcolor: row.stockMaterial.itemType == "perishable" ? "#159947" :"#CC2323",p:"4px 8px",width: row.stockMaterial.itemType == "perishable" ? "80px" : "120px",borderRadius:"10px",color:"#FFF",m:0,alignItems:"center"}}>
                {row.stockMaterial.itemType}
            </Typography>

            </Box>
            
          )
        },
        {
          title: 'Low Stock Limit',
          dataIndex: 'lowStockIndication',
          key: 'lowStockIndication',
        },
        {
            title:"Stock Status",
            dataIndex: 'action',
            key: 'action',
            render:(_,row) => (
                <Typography sx={{textAlign:"center",bgcolor:row.currentQuantity > row.lowStockIndication ? "#EFF9F3": "#feeaeb",color: row.currentQuantity > row.lowStockIndication ?"#68C689":"#F2353C", width:"100px",p:"4px 8px",borderRadius:"10px"}}>
                    {Math.round(row.currentQuantity - row.lowStockIndication)}
                </Typography>             
            )
        },
        {
            title:"Last Updated On",
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render:(_,row) => (
                <Typography>{moment(row.updatedAt).format("DD-MM-YYYY HH:MM a")}</Typography>
            )
        },
        {
          title: 'Actions',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <Box>
              <ViewButton
                onClick={() => {
                  navigate(
                    `/gcc/stockitem-table/${id}/viewitemtable`,
                  )
                }}
              />
            </Box>
          ),
        },
      ]


  return (
    <div>
          <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'GCC Stock', to: '/gcc' },
              {
                name: 'Stock Usage',
                to: null,
              },
            ]}
          />
        }
      />
      <TextField
        label="Search by School name, School ID"
        sx={{ width: '290px',mb:2 }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={search}
        onChange={({ target }) => {
          setSearch(target.value)
        }}
      />
        {false ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'schools'}
            dataSource={data?.result}
            rowKey={'id'}
            columns={columns}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {
              data?.count > 10 &&      <Pagination
              count={data?.count > 10 ? Math.ceil(data?.count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />
            }
       
          </Box>
        </>
      )}
    </div>
  )
}

export default StockItemTable



