import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { ContentCards } from 'components/ContentCards'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { http } from 'services/http'

const DashboardAcademicActivities = () => {
  const [date, districtId, atdoPointId, mandalId, schoolId, hitApi, setHitApi] =
    useOutletContext()
  const [loading, setLoading] = useState(false)
  const [activities, setActivities] = useState()

  const type = 'Academic'
  console.log(date)
  useEffect(() => {
    setLoading(true)
    http
      .get(
        `/statistics/activity?date=${date}&type=${type}&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      .then((res) => {
        console.log(res)
        setActivities(res?.data)
        setLoading(false)
        setHitApi(false)
      })
  }, [date, hitApi])

  const handleNavigation = (title) => {
    localStorage.setItem('heading', title)
  }

  useEffect(() => {
    localStorage.setItem('districtId', districtId)
    localStorage.setItem('atdoPointId', atdoPointId)
    localStorage.setItem('date', date)
  }, [districtId, atdoPointId, date])

  console.log(activities)
  const physicalActivityCards = [
    {
      count: activities?.physicalActivity?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/academicDetails?type=Physical',
    },
    {
      count: activities?.physicalActivity?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Physical',
    },
    {
      percentage: `${
        Number.isInteger(activities?.physicalActivity?.percentage)
          ? activities?.physicalActivity?.percentage
          : activities?.physicalActivity?.percentage?.toFixed(1)
      }% `,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const StudyHourCards = [
    {
      count: activities?.morningStudyHour?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/academicDetails?type=MorningStudyHour',
    },
    {
      count: activities?.morningStudyHour?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=MorningStudyHour',
    },
    {
      percentage:
        `${
          Number.isInteger(activities?.morningStudyHour?.percentage)
            ? activities?.morningStudyHour?.percentage
            : activities?.morningStudyHour?.percentage?.toFixed(1)
        }%` ?? 'NA',
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const PrayerCards = [
    {
      count: activities?.prayer?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/academicDetails?type=Prayer',
    },
    {
      count: activities?.prayer?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Prayer',
    },
    {
      percentage: `${
        Number.isInteger(activities?.prayer?.percentage)
          ? activities?.prayer?.percentage
          : activities?.prayer?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const NightCards = [
    {
      count: activities?.nightStudyHour?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/academicDetails?type=NightStudyHour',
    },
    {
      count: activities?.nightStudyHour?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=NightStudyHour',
    },
    {
      percentage: `${
        Number.isInteger(activities?.nightStudyHour?.percentage)
          ? activities?.nightStudyHour?.percentage
          : activities?.nightStudyHour?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const SportsCards = [
    {
      count: activities?.sportsActivity?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/academicDetails?type=Sports',
    },
    {
      count: activities?.sportsActivity?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Sports',
    },
    {
      percentage: `${
        Number.isInteger(activities?.sportsActivity?.percentage)
          ? activities?.sportsActivity?.percentage
          : activities?.sportsActivity?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const SpecialCards = [
    {
      count: activities?.specialClass?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/academicDetails?type=Special',
    },
    {
      count: activities?.specialClass?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Special',
    },
    {
      percentage: `${
        Number.isInteger(activities?.specialClass?.percentage)
          ? activities?.specialClass?.percentage
          : activities?.specialClass?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  if (loading) {
    return <CircularProgress />
  }
  return (
    <div>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Morning Physical Activity{' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {physicalActivityCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Morning Physical Activity')}
            >
              <ContentCards
                count={item?.count ?? 'NA'}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage ?? 'NA'}
                width={'480px'}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Morning Study Hour{' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {StudyHourCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Morning Study Hour')}
            >
              <ContentCards
                count={item?.count ?? 'NA'}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage ?? 'NA'}
                width={'480px'}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Prayer
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {PrayerCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Prayer')}
            >
              <ContentCards
                count={item?.count ?? 'NA'}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage ?? 'NA'}
                width={'480px'}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Night Study Hour{' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {NightCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Night Study Hour')}
            >
              <ContentCards
                count={item?.count ?? 'NA'}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage ?? 'NA'}
                width={'480px'}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Sports Activity
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {SportsCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Sports Activity')}
            >
              <ContentCards
                count={item?.count ?? 'NA'}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage ?? 'NA'}
                width={'480px'}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Special Class (Bridge/SSC Courses){' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {SpecialCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() =>
                handleNavigation('Special Class (Bridge/SSC Courses)')
              }
            >
              <ContentCards
                count={item?.count ?? 'NA'}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage ?? 'NA'}
                width={'480px'}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>{' '}
    </div>
  )
}

export default DashboardAcademicActivities
