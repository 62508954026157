import { Box, Grid, MenuItem, TextField } from '@mui/material'
import Loader from 'components/Loader'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { getTests, getUserTypeClass } from 'services/api/aksharaJyothi'

const SideBar = ({ setFilterData, filterData }) => {
  const { data: tests, isLoading: testLoading } = useQuery(['tests'], getTests)

  const { data, isLoading } = useQuery(['class'], getUserTypeClass)

  useEffect(() => {
    setFilterData({
      ...filterData,
      classId: data?.data?.[0]?.id,
      subjectId: data?.data[0]?.subjects[0]?.id,
      testId: tests?.data[0]?.id,
      value: 'LOW',
    })
  }, [data?.data, tests?.data])

  const handleChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    })
  }

  const handleChangeClass = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
      subjectId: null,
    })
  }
  return (
    <Box
      p={1}
      sx={{
        borderRadius: '10px 10px 10px 10px',
        boxShadow: '0px 3px 13px #2A32711A',
        height: '280px',
        width: '80vw',
        '@media (min-width: 1000px)': {
          position: 'fixed',
          width: '230px',
          height: '76vh',
        },
        '@media (min-width: 1200px)': {
          position: 'fixed',
          width: '250px',
          height: '76vh',
        },
        '@media (min-width: 1400px)': {
          position: 'fixed',
          width: '300px',
          height: '76vh',
        },
      }}
    >
      {testLoading || isLoading ? (
        <Box>
          <Loader />
        </Box>
      ) : (
        <Box p={2}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  required
                  fullWidth
                  sx={{ width: '100%' }}
                  name="classId"
                  label="Select Class"
                  onChange={handleChangeClass}
                  value={filterData?.classId || ''}
                >
                  {data?.data?.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      class {item?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  name="subjectId"
                  label="Select Subject"
                  onChange={handleChange}
                  value={filterData?.subjectId || ''}
                >
                  {data?.data
                    ?.filter((item) => item?.id === filterData?.classId)[0]
                    ?.subjects?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  name="testId"
                  label="Select Test"
                  onChange={handleChange}
                  value={filterData?.testId || ''}
                >
                  {tests?.data?.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  name="value"
                  label="Select Performance"
                  onChange={handleChange}
                  value={filterData?.value || ''}
                >
                  <MenuItem value="LOW">Low</MenuItem>
                  <MenuItem value="HIGH">High</MenuItem>
                </TextField>
              </Grid>
              {/* <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ p: 3 }}
                >
                  Apply
                </Button>
              </Grid> */}
            </Grid>
          </form>
        </Box>
      )}
    </Box>
  )
}

export default SideBar
