import { Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { UploadModal } from './UploadModal/UploadModal'

const MasterDataEmptyScreen = ({ title, section, type }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const buttonSX = {
    '&:hover': {
      borderColor: '#24272C',
      color: '#24272C',
      background: 'none',
    },
    marginTop: '28px',
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    borderColor: '#24272C',
    color: '#24272C',
  }
  return (
    <div>
      {modalOpen && (
        <UploadModal
          setOpen={setModalOpen}
          img={'/images/Rectangle 11247.png'}
          title={title}
          section={section}
          type={type}
        />
      )}{' '}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10rem',
        }}
      >
        <img src="/images/Group 20286.png" style={{ height: '300px' }} />
        <Box>
          <Typography
            sx={{
              fontSize: '22px',
              fontFamily: 'poppins_bold',
              marginTop: '23px',
              marginBottom: '10px',
            }}
          >
            <center>{title}</center>
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_medium',
              opacity: '0.85',
            }}
          >
            <center>{`There are no ${title} available. Tap on import xls to import the`}</center>
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_medium',
              opacity: '0.85',
            }}
          >
            <center>{`excel sheet of all the ${title} available.`}</center>
          </Typography>
          <center>
            <Button
              sx={buttonSX}
              variant="outlined"
              onClick={() => {
                setModalOpen(true)
              }}
            >
              + Import XLS
            </Button>
          </center>
        </Box>
      </Box>
    </div>
  )
}

export default MasterDataEmptyScreen
