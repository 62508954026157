import { Box, Pagination } from '@mui/material'
import { ViewButton } from 'components/IconButtons'
import Table from 'components/Mui/TableComponent'
import moment from 'moment'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const EngTable = ({ data, count, loading, page, setPage }) => {
  const navigate = useNavigate()
  const handleViewClick = (id) => {
    localStorage.setItem('TicketID', id)
    navigate(`/engineering/tickets`)
  }
  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'ticketName',
      key: 'ticketName',
      render: (value) => {
        return value ? value : 'NA'
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (colData) => {
        return moment(colData).format('YYYY-MM-DD')
      },
    },
    {
      title: 'School',
      dataIndex: 'school.name',
      key: 'school.name',
    },
    {
      title: 'AE Name',
      dataIndex: 'user.fullName',
      key: 'user.fullName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (rowData) =>
        rowData === 'WORK IN PROGRESS' ? (
          <Box
            sx={{
              backgroundColor: '#FEF2E6',
              padding: '8px 8px',
              borderRadius: '10px',
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#F27F0C',
            }}
          >
            WORK IN PROGRESS
          </Box>
        ) : rowData === 'ESCALATED' ? (
          <Box
            sx={{
              backgroundColor: '#E9F6FA',
              padding: '8px 8px',
              borderRadius: '10px',
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#25A4CF',
            }}
          >
            ESCALATED
          </Box>
        ) : rowData === 'COMPLETED' ? (
          <Box
            sx={{
              backgroundColor: '#00d9a626',
              padding: '8px 8px',
              borderRadius: '10px',
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#00D9A6',
            }}
          >
            COMPLETED
          </Box>
        ) : rowData === 'PENDING' ? (
          <Box
            sx={{
              backgroundColor: '#FFFAEF',
              padding: '8px 8px',
              borderRadius: '10px',
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#FFCF64',
            }}
          >
            PENDING
          </Box>
        ) : (
          rowData === 'ASSIGNED' && (
            <Box
              sx={{
                backgroundColor: '#0a11601a',
                padding: '8px 8px',
                borderRadius: '10px',
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#0A1160',
              }}
            >
              ASSIGNED
            </Box>
          )
        ),
    },
    {
      title: 'Ticket Duration',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (rowData) => {
        const currentDate = moment(new Date())
        const givenDate = moment(new Date(rowData))
        const diffInDays = currentDate.diff(givenDate, 'days')
        return `${diffInDays} days`
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => (
        <Box display="flex" gap="10px">
          <ViewButton onClick={() => handleViewClick(row?.id)} />
        </Box>
      ),
    },
  ]

  function handlePagination(e, v) {
    setPage(v)
  }

  return (
    <div style={{ marginTop: '30px' }}>
      <Table
        key={'engData'}
        dataSource={data}
        rowKey={'id'}
        columns={columns}
        loading={loading}
      />
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Pagination
          count={count > 10 ? Math.ceil(count / 10) : 1}
          variant="outlined"
          shape="rounded"
          onChange={handlePagination}
          page={page}
        />{' '}
      </Box>
    </div>
  )
}

export default EngTable
