import { Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Cell, Legend, Pie, PieChart } from 'recharts'

const PieChartCard = ({ data, colors }) => {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1500px)').matches,
  )

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${value}`}
      </text>
    )
  }

  useEffect(() => {
    window
      .matchMedia('(min-width: 1500px)')
      .addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <>
        {matches && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '450px',
              marginTop: '-20px',
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  {payload.map((entry, index) => (
                    <Grid item xs={12} key={index}>
                      <span
                        style={{
                          padding: '18px 0px',
                          // paddingLeft: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        key={`item-${index}`}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            width: '12px',
                            marginRight: '6px',
                            height: '12px',
                            backgroundColor: entry.color,
                            borderRadius: '3px',
                          }}
                        ></div>
                        <Typography>{entry.value} TICKETS</Typography>
                      </span>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        {!matches && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '-20px',
              marginLeft: '340px',
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  {payload.map((entry, index) => (
                    <Grid item xs={12} key={index}>
                      <span
                        style={{
                          padding: '18px 50px',

                          display: 'flex',
                          alignItems: 'center',
                        }}
                        key={`item-${index}`}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            width: '12px',
                            marginRight: '6px',
                            height: '12px',
                            borderRadius: '3px',
                            backgroundColor: entry.color,
                          }}
                        ></div>
                        <Typography>{entry.value} TICKETS</Typography>
                      </span>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    )
  }
  return (
    <PieChart
      width={700}
      height={matches ? 285 : 285}
      style={{ fontFamily: 'poppins_regular' }}
    >
      <Pie
        data={data}
        cx={220}
        cy={135}
        outerRadius={110}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
        stroke="none"
        labelLine={false}
        label={renderCustomizedLabel}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              entry?.name === 'PENDING'
                ? colors[0]
                : entry?.name === 'WORK IN PROGRESS'
                ? colors[3]
                : entry?.name === 'ESCALATED'
                ? colors[1]
                : entry?.name === 'COMPLETED'
                ? colors[4]
                : entry?.name === 'ASSIGNED' && colors[2]
            }
          />
        ))}
      </Pie>

      <Legend
        content={renderLegend}
        iconType={'square'}
        align={'right'}
        verticalAlign={'middle'}
      />
    </PieChart>
  )
}

export default PieChartCard
