import { Box, Grid, styled, Typography } from '@mui/material'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { http } from 'services/http'

const EmergencyDetailsCard = ({ details }) => {
  const [status, setStatus] = useState('')
  useEffect(() => {
    details?.status && setStatus(details?.status)
  }, [details])

  const StatusBox = styled(Box)(() => {
    return {
      backgroundColor:
        details?.status === 'PENDING'
          ? '#FFFAEF'
          : details?.status === 'ASSIGNED'
          ? '#EAEBF1'
          : details?.status === 'ACKNOWLEDGED'
          ? '#F7EFEE'
          : details?.status === 'HOSPITALIZED'
          ? '#E8F1FA'
          : '#E8F6EE',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '8px 8px',
    }
  })

  let desc =
    details?.description?.length > 500
      ? details?.description?.slice(0, 500) + '...'
      : details?.description

  const emergencyId = details?.id

  const fetchImages = async (id) => {
    const res = await http.get(`/medical-emergencies/images/${id}`)
    return res?.data
  }

  const { data: images, isLoading } = useQuery(
    ['emergencyImages', emergencyId],
    () => fetchImages(emergencyId),
    {
      enabled: !!emergencyId,
    },
  )
  // const images = details?.media?.filter((item) => details?.subtype === 'create')

  return (
    <Box
      style={{ padding: '17px' }}
      overflow="auto"
      maxHeight="700px"
      className="scrollBox"
    >
      {' '}
      <Box
        // key={index}
        p={2}
        mb={1.5}
        border={'1px solid rgba(34, 34, 34, 0.08)'}
        borderRadius="5px"
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={6}>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Indent ID
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {`GRV${details?.id?.toString()?.padStart(3, '0')}`}
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Date
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {moment(details?.createdAt).format('DD-MM-YYYY')}
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                School ID
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {details?.schoolId ?? 'NA'}
              </Typography>
            </Box>
            {/* <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Description
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {desc}
              </Typography>
            </Box> */}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Box>
              <StatusBox status={details?.status}>
                <Typography
                  sx={{
                    color:
                      details?.status === 'PENDING'
                        ? '#FFCF64'
                        : details?.status === 'ASSIGNED'
                        ? '#0A1160'
                        : details?.status === 'ACKNOWLEDGED'
                        ? '#AD6359'
                        : details?.status === 'HOSPITALIZED'
                        ? '#1F78D1'
                        : '#1AAA55',
                  }}
                >
                  {details?.status}
                </Typography>
              </StatusBox>
              <Box
                pt={2.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '14px',
                    opacity: '0.5',
                  }}
                >
                  School Name
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    fontSize: '14px',
                  }}
                >
                  {details?.school?.name}
                </Typography>
              </Box>
              <Box
                pt={2.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '14px',
                    opacity: '0.5',
                  }}
                >
                  Total Price
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    fontSize: '14px',
                  }}
                >
                  {details?.totalIndentPrice}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2.5}>
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              fontSize: '14px',
              opacity: '0.5',
            }}
          >
            Uploaded Images
          </Typography>
          {images?.length == 0 ? (
            <SchoolAnalyticsEmptyScreen height={'190px'} />
          ) : (
            images?.map((item, index) => {
              return (
                <Box
                  p={2}
                  mt={1.25}
                  sx={{
                    border: '1px solid rgba(34, 34, 34, 0.08)',
                    borderRadius: '5px',
                  }}
                >
                  <img
                    style={{ width: '100%', borderRadius: '5px' }}
                    src={item}
                  />
                </Box>
              )
            })
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EmergencyDetailsCard
