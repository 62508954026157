import { Box, CircularProgress, Grid } from '@mui/material'
import Card from 'components/Mui/Card'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { http } from 'services/http'
const NewProgramme = () => {
  const type = 'NewProgram'
  const [schoolActivities, setSchoolActivities] = useState()
  const [date] = useOutletContext()
  const id = localStorage.getItem('schoolId')
  const [loading, setLoading] = useState(false)
  console.log(id, date)
  useEffect(() => {
    setLoading(true)
    http
      .get(`/statistics/school/${id}?type=${type}&date=${date}`)
      .then((res) => {
        console.log(res)
        setSchoolActivities(res?.data)
        setLoading(false)
      })
  }, [date])
  const cards = [
    {
      title: `${schoolActivities?.schoolProgram?.title}`,
      presentCount: `${schoolActivities?.schoolProgram?.completed}`,
      presentContent: 'Number of students participating',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.schoolProgram?.percentage)
          ? schoolActivities?.schoolProgram?.percentage
          : schoolActivities?.schoolProgram?.percentage?.toFixed(1)
      }%`,
    },
  ]
  return (
    <Grid container spacing={1}>
      {cards?.map((item) => {
        if (loading) {
          return <CircularProgress />
        }
        return (
          <Grid item xl={3} lg={4} md={4}>
            <Card
              link={`/schools/new-programme-details?programme=${
                item?.title ? item?.title : 'Not Available'
              }`}
              title={item?.title ? item?.title : 'Not Available'}
              presentCount={item?.presentCount}
              presentContent={item?.presentContent}
              absentCount={item?.absentCount}
              presentPercentage={item?.presentPercentage}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default NewProgramme
