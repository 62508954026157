import { Box, Pagination, Typography } from '@mui/material'

import Table from 'components/Mui/TableComponent'
import { useEffect, useState } from 'react'

import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Loader from 'components/Loader'
import PageHeader from 'components/Mui/PageHeader'
import moment from 'moment'
import { useQuery } from 'react-query'
import { http } from 'services/http'

const AmbulanceServices = () => {
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  const fetchData = async (page) => {
    const res = await http.get(
      `/ambulance?limit=10&offset=${page > 0 ? (page - 1) * 10 : page * 10}`,
    )
    console.log(res?.data)
    return res?.data
  }

  const { data, isLoading } = useQuery(['ambulances', page], () =>
    fetchData(page),
  )

  useEffect(() => {
    http.get(`/ambulance`).then((res) => {
      setCount(res?.data?.length)
    })
  }, [])

  const columns = [
    {
      title: 'Requested Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (colData) => {
        return moment(colData).format('DD-MM-YYYY')
      },
    },
    {
      title: 'School Name',
      dataIndex: 'school.name',
      key: 'school.name',
    },
    {
      title: 'Head master name',
      dataIndex: 'user.fullName',
      key: 'user.fullName',
    },
    {
      title: 'ATDO Point',
      dataIndex: 'school.atdoPoint.name',
      key: 'school.atdoPoint.name',
    },
    {
      title: 'District',
      dataIndex: 'school.district.name',
      key: 'school.district.name',
    },
  ]

  function handlePagination(e, v) {
    setPage(v)
  }

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Medical', to: '/Medical' },
              { name: 'Ambulance services', to: null },
            ]}
          />
        }
      />
      {isLoading ? (
        <Loader />
      ) : data?.length === 0 ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'ambulanceServices'}
            dataSource={data}
            rowKey={'id'}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
    </div>
  )
}

export default AmbulanceServices
