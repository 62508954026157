import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const AssetsForm = ({ data, toggleDrawer }) => {
  const [categories, setCategories] = useState()
  const queryClient = useQueryClient()
  const [id, setId] = useState()
  const [edit, setEdit] = useState(false)
  const schoolId = localStorage?.getItem('schoolId')

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [deployedDate, setDeployedDate] = useState(
    data?.deployedAt
      ? moment(new Date(data?.deployedAt))?.format('YYYY-MM-DD')
      : moment(new Date())?.format('YYYY-MM-DD'),
  )
  const [updatedDate, setUpdatedDate] = useState(
    data?.updatedDate
      ? moment(new Date(data?.updatedDate))?.format('YYYY-MM-DD')
      : moment(new Date())?.format('YYYY-MM-DD'),
  )
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  console.log(data)

  useEffect(() => {
    http.get(`/assets-category`).then((res) => {
      console.log(res)
      setCategories(res?.data?.result)
    })
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: data?.name,
      brand: data?.brand,
      quantity: data?.quantity,
      deployedAt: data?.deployedAt,
      assetCategoryId: data?.assetCategory?.id,
    },
  })

  const onSubmit = (data) => {
    if (edit) {
      console.log(id)
      const formData = {
        ...data,
        deployedAt: moment(deployedDate)?.format('YYYY-MM-DD'),
      }
      http
        .patch(`/school-assets/${id}`, formData)
        .then((res) => {
          enqueueSnackbar('The Asset has been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('schoolAssets')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      const formData = {
        ...data,
        deployedAt: moment(deployedDate)?.format('YYYY-MM-DD'),
        schoolId: schoolId,
      }
      http
        .post(`/school-assets`, formData)
        .then((res) => {
          enqueueSnackbar('The Asset Category has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('schoolAssets')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={'text'}
            {...register('name', {
              required: true,
              pattern: /^[A-Za-z.\- ()]+$/,
            })}
            label={'Asset Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.name?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.name?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="categoryId">Category *</InputLabel>
            <Select
              labelId="categoryId"
              id="categoryId"
              label="Category *"
              {...register('assetCategoryId', { required: true })}
              defaultValue={data?.assetCategory?.id}
              required={true}
            >
              {categories?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.assetCategoryId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('quantity', {
                required: true,
              })}
              label={'Quantity'}
              required={true}
            />
          </FormControl>
          {errors?.quantity && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl
            component="fieldset"
            sx={{ marginTop: '24px' }}
            fullWidth={true}
          >
            {/* <Controller
              render={({ field }) => ( */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <DatePicker
                  label="Deployed date"
                  value={deployedDate}
                  {...register('deployedDate', {
                    required: true,
                  })}
                  maxDate={moment(new Date())}
                  inputFormat="YYYY-MM-DD"
                  // maxDate={dayjs('2022-01-01')}
                  onChange={(newValue) => {
                    setDeployedDate(moment(new Date(newValue)).toISOString())
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      fullWidth={true}
                      required={true}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </FormControl>
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {data ? 'Edit Asset' : 'Add Asset'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default AssetsForm
