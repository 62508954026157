import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import { Box, Divider, Grid, Typography } from '@mui/material'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
// import TicketDetailsCard from 'components/TicketDetailsCard'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import PageHeader from 'components/Mui/PageHeader'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { http } from 'services/http'
import EmergencyDetails from './EmergencyDetails'
import EmergencyDetailsCard from './EmergencyDetailsCard'
// import { TicketDetails } from './TicketDetails'

const MedicalEmergencies = () => {
  const navigate = useNavigate()
  const [emergency, setEmergency] = useState({})
  const [count, setCount] = useState(0)
  const [images, setImages] = useState([])
  const [completedImages, setCompletedImages] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [timeline, setTimeline] = useState([])
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const emergencyStatuses = [
    'PENDING',
    'ASSIGNED',
    'ACKNOWLEDGED',
    'HOSPITALIZED',
    'COMPLETED',
  ]

  const fetchData = async (statuses, search) => {
    const res = await http.get(
      `/medical-emergencies?statuses=${statuses}&issueName=${search}`,
    )
    setCount(res?.data?.length)
    return res?.data
  }

  const { data: emergencies, isLoading } = useQuery(
    ['emergencies', emergencyStatuses, search],
    () => fetchData(emergencyStatuses, search),
  )

  useEffect(() => {
    console.log(emergencies)
    emergencies &&
      emergencies?.length != 0 &&
      !emergency.id &&
      setEmergency({ ...emergencies[0] })
  }, [emergencies])

  useEffect(() => {
    search?.length !== 0 && emergencies && setEmergency({ ...emergencies[0] })
  }, [search, emergencies])

  useEffect(() => {
    setTimeline(
      emergencies?.filter((item) => item?.id == emergency?.id)[0]
        ?.medicalEmergencyStatuses,
    )
  }, [emergency, emergencies])

  if (isLoading && search?.length === 0) {
    return <Loader />
  }
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Medical', to: '/Medical' },
              { name: 'Medical Emergencies', to: null },
            ]}
          />
        }
      />
      {/* {emergencies?.length == 0 ? (
        <Box sx={{ marginTop: '230px' }}>
          <SchoolAnalyticsEmptyScreen />
        </Box>
      ) : ( */}
      <Grid container spacing={4} sx={{ marginTop: '5px' }}>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Box
            sx={{
              boxShadow: '0px 5px 15px #22222214;',
              paddingTop: '20px',
              borderRadius: '10px',
            }}
          >
            <Typography
              pb={2}
              px={2}
              sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}
            >
              Medical Emergencies
            </Typography>
            <Divider />

            <Box p={2} overflow="auto" maxHeight="700px" className="scrollBox">
              <EmergencyDetails
                data={emergencies}
                emergency={emergency}
                setEmergency={setEmergency}
                search={search}
                setSearch={setSearch}
                loading={isLoading}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Box
            sx={{
              boxShadow: '0px 5px 15px #22222214;',
              paddingTop: '20px',
              borderRadius: '10px',
            }}
          >
            <Typography
              pb={2}
              px={2}
              sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}
            >
              Medical Emergency Details
            </Typography>
            <Divider />
            {emergencies?.length === 0 ? (
              <Box mt={'17px'}>
                <SchoolAnalyticsEmptyScreen />
              </Box>
            ) : (
              <EmergencyDetailsCard
                details={emergency}
                images={images}
                completedImages={completedImages}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Box
            sx={{
              boxShadow: '0px 5px 15px #22222214;',
              paddingTop: '20px',
              borderRadius: '10px',
              // height: '774px',
            }}
          >
            <Typography
              pb={2}
              px={2}
              sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}
            >
              Timeline
            </Typography>
            <Divider />
            <Box px={1} overflow="auto" maxHeight="700px" className="scrollBox">
              {timeline?.length === 0 || emergencies?.length === 0 ? (
                <Box sx={{ marginTop: '30px' }}>
                  <SchoolAnalyticsEmptyScreen />
                </Box>
              ) : (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {emergencyStatuses?.map((step, index) => {
                    console.log(timeline)
                    console.log(step)
                    const contains = timeline?.some(
                      (item) => item?.status === step,
                    )
                    const item = timeline?.filter(
                      (item) => item?.status === step,
                    )[0]
                    if (step === 'PENDING' && !contains) {
                      return
                    }
                    return (
                      <TimelineItem key={index}>
                        <TimelineSeparator>
                          <TimelineDot
                            sx={{
                              border: 'none',
                              backgroundColor: 'white',
                              boxShadow: 'none',
                            }}
                          >
                            <Box
                              sx={{
                                width: '35px',
                                height: '35px',
                                borderRadius: '999px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={'/images/TickMark.png'}
                                style={{
                                  opacity: !contains && '0.3',
                                }}
                              />
                            </Box>
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box
                            sx={{
                              boxShadow: '0px 5px 15px #22222214',
                              borderRadius: '5px',
                              marginBottom: '40px',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '20px',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: 'poppins_medium',
                                  fontSize: '15px',
                                  color: 'black',
                                  opacity: !contains && '0.45',
                                }}
                              >
                                {step}
                              </Typography>
                              {contains && (
                                <i>
                                  <Typography
                                    sx={{
                                      fontFamily: 'poppins_medium',
                                      fontSize: '12px',
                                      opacity: '0.5',
                                    }}
                                  >
                                    {moment
                                      .utc(item?.createdAt)
                                      ?.format('DD MMM, YYYY hh:mm A')}
                                  </Typography>
                                </i>
                              )}
                            </Box>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  })}
                </Timeline>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* )} */}
    </div>
  )
}

export default MedicalEmergencies
