import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { URLPattern } from 'urlpattern-polyfill'
import '../App.css'
import LayoutDrawer from './Drawer'
import Routing from './Routes'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import EngineeringIcon from '@mui/icons-material/Engineering'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import InventoryIcon from '@mui/icons-material/Inventory'

function Layout() {
  return (
    <div style={{ display: 'flex' }}>
      <AppBar sx={{ backgroundColor: 'white' }}>
        <Toolbar sx={{ paddingLeft: '0px !important' }}>
          <HeaderLogo />
        </Toolbar>
      </AppBar>
      <LayoutDrawer />
      <main
        style={{
          flexGrow: 1,
          minHeight: '100vh',
          padding: '24px',
        }}
      >
        <Toolbar />
        <Routing />
      </main>
    </div>
  )
}

export default Layout

const HeaderLogo = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const urlPath = window.location.href
  const userType = localStorage.getItem('user_type')
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl1, setAnchorEl1] = useState(null)

  const open = Boolean(anchorEl)
  const open1 = Boolean(anchorEl1)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget)
  }
  const handleClose1 = () => {
    setAnchorEl1(null)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/login')
  }
  const schoolPattern = new URLPattern({
    pathname: '/school*',
    protocol: '(^https?)',
  })

  const activityDashboardPattern = new URLPattern({
    pathname: '/activity-dashboard*',
    protocol: '(^https?)',
  })

  const medicalPattern = new URLPattern({
    pathname: '/Medical*',
    protocol: '(^https?)',
  })

  const masterDataPattern = new URLPattern({
    pathname: '/master-data*',
    protocol: '(^https?)',
  })

  const academicMasterDataPattern = new URLPattern({
    pathname: '/academic-master-data*',
    protocol: '(^https?)',
  })

  const usersMasterDataPattern = new URLPattern({
    pathname: '/users-master-data*',
    protocol: '(^https?)',
  })

  const engineeringMasterDataPattern = new URLPattern({
    pathname: '/engineering-master-data*',
    protocol: '(^https?)',
  })

  const medicalMasterDataPattern = new URLPattern({
    pathname: '/medical-master-data*',
    protocol: '(^https?)',
  })

  const stockMasterDataPattern = new URLPattern({
    pathname: '/stock-master-data*',
    protocol: '(^https?)',
  })

  const foodPattern = new URLPattern({
    pathname: '/food*',
    protocol: '(^https?)',
  })

  const gccPattern = new URLPattern({
    pathname: '/gcc*',
    protocol: '(^https?)',
  })

  const adminUsersPattern = new URLPattern({
    pathname: '/admin-users*',
    protocol: '(^https?)',
  })

  const ticketsPattern = new URLPattern({
    pathname: '/engineering/tickets*',
    protocol: '(^https?)',
  })

  const url = location.pathname
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        flex={1}
        style={{
          display: 'flex',
          gap: '2.5rem',
          // marginLeft: '1rem',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '160px',
            borderRight: '1px solid #12121229',
          }}
        >
          <img
            src={'/images/GIRI Veekshanam Final Logo.png'}
            style={{ width: '70px', height: '56px', padding: '9px 43px' }}
          />
        </Box>
        <Typography
          style={{
            color: 'black',
            fontSize: '18px',
            fontFamily: 'poppins_semibold',
          }}
        >
          {url === '/dashboard'
            ? 'Dashboard'
            : activityDashboardPattern.test(urlPath)
            ? 'Dashboard'
            : schoolPattern.test(urlPath)
            ? 'Schools'
            : url === '/regular-teachers'
            ? 'Regular Teachers'
            : url === '/crt-teachers'
            ? 'CRT Teachers'
            : url === '/staff-attendance'
            ? 'Staff Attendance'
            : gccPattern.test(urlPath)
            ? 'GCC Stock'
            : medicalPattern.test(urlPath)
            ? 'Medical'
            : masterDataPattern.test(urlPath)
            ? 'Master Data'
            : academicMasterDataPattern.test(urlPath)
            ? 'Academic Master Data'
            : usersMasterDataPattern.test(urlPath)
            ? 'Users Master Data'
            : engineeringMasterDataPattern.test(urlPath)
            ? 'Engineering Master Data'
            : medicalMasterDataPattern.test(urlPath)
            ? 'Medical Master Data'
            : stockMasterDataPattern.test(urlPath)
            ? 'Stock Master Data'
            : foodPattern.test(urlPath)
            ? 'Master Data'
            : ticketsPattern.test(urlPath)
            ? 'Tickets'
            : adminUsersPattern.test(urlPath)
            ? userType == 'Admin'
              ? 'Admin Users'
              : 'Dashboard'
            : 'Dashboard'}
        </Typography>
      </Box>
      {/* {userType == 'Admin' && ( */}
      <>
        <Box
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ background: 'none', cursor: 'pointer' }}
        >
          <img src={'/images/setting.png'} />
        </Box>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          // sx={{ width: '100vw' }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              width: '200px',
              padding: '5px 15px',
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={() => navigate('/master-data')}
            sx={{ margin: '0px -15px 0px -15px' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
              <img
                style={{ width: '22px', height: '22px' }}
                src="/images/Master Data.png"
              />
              <Typography
                sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
              >
                Master Data
              </Typography>
            </Box>
          </MenuItem>
          {userType == 'Admin' && (
            <MenuItem
              onClick={() => navigate('/academic-master-data')}
              sx={{ margin: '0px -15px 0px -15px' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
                <MenuBookOutlinedIcon sx={{ width: '22px', height: '22px' }} />
                <Typography
                  sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
                >
                  Academic Master Data
                </Typography>
              </Box>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => navigate('/users-master-data')}
            sx={{ margin: '0px -15px 0px -15px' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
              <PersonOutlineOutlinedIcon
                sx={{ width: '22px', height: '22px' }}
              />
              <Typography
                sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
              >
                Users Master Data
              </Typography>
            </Box>
          </MenuItem>
          {userType == 'Admin' && (
            <MenuItem
              onClick={() => navigate('/engineering-master-data')}
              sx={{ margin: '0px -15px 0px -15px' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
                <EngineeringIcon />
                <Typography
                  sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
                >
                  Engineering Master Data
                </Typography>
              </Box>
            </MenuItem>
          )}
          {userType == 'Admin' && (
            <MenuItem
              onClick={() => navigate('/medical-master-data')}
              sx={{ margin: '0px -15px 0px -15px' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
                <LocalHospitalIcon />
                <Typography
                  sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
                >
                  Medical Master Data
                </Typography>
              </Box>
            </MenuItem>
          )}
          {userType == 'Admin' && (
            <MenuItem
              onClick={() => navigate('/stock-master-data')}
              sx={{ margin: '0px -15px 0px -15px' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
                <InventoryIcon />
                <Typography
                  sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
                >
                  Stock Master Data
                </Typography>
              </Box>
            </MenuItem>
          )}
          {userType == 'Admin' && (
            <MenuItem
              onClick={() => navigate('/admin-users')}
              sx={{ margin: '0px -15px 0px -15px' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
                <img
                  style={{ width: '22px', height: '22px' }}
                  src="/images/Admin Users.png"
                />
                <Typography
                  sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
                >
                  Admin Users
                </Typography>
              </Box>
            </MenuItem>
          )}
          {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu>
      </>
      {/* )} */}
      <Box
        id="basic-button"
        aria-controls={open1 ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open1 ? 'true' : undefined}
        onClick={handleClick1}
        sx={{ background: 'none', cursor: 'pointer', marginLeft: '23px' }}
      >
        <img src="/images/userIcon.png" />
      </Box>
      <Menu
        id="account-menu"
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: '200px',
            padding: '5px 15px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogout} sx={{ margin: '0px -15px 0px -15px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'12px'}>
            <img
              style={{ width: '22px', height: '22px' }}
              src="/images/exit.png"
            />
            <Typography
              sx={{ fontSize: '12px', fontFamily: 'poppins_semibold' }}
            >
              Logout
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  )
}
