import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import { Box, Divider, Grid, Typography } from '@mui/material'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import TicketDetailsCard from 'pages/Engineering/TicketDetailsCard'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { http } from 'services/http'
import { TicketDetails } from './TicketDetails'

const Tickets = () => {
  const navigate = useNavigate()
  const [tickets, setTickets] = useState([])
  const [ticket, setTicket] = useState({})
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [count, setCount] = useState(0)
  const [images, setImages] = useState([])
  const [completedImages, setCompletedImages] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [timeline, setTimeline] = useState([])
  const location = useLocation()

  const ticketID = localStorage.getItem('TicketID')
  const districtID = localStorage.getItem('districtID')
  const atdoPointID = localStorage.getItem('atdoPointID')

  const fetchData = async (search) => {
    const res = await http.get(
      `/tickets?ticketName=${search}&districtId=${districtID}&atdoPointId=${atdoPointID}`,
    )
    setTickets(res?.data)
    setCount(res?.data?.length)
  }

  const { data, isLoading } = useQuery(['ticketDetails', search], () =>
    fetchData(search),
  )

  useEffect(() => {
    if (!ticketID) {
      console.log(tickets)
      tickets?.length > 1 && !ticketID && setTicket({ ...tickets[0] })
    }
  }, [tickets])

  useEffect(() => {
    setTimeline(
      tickets
        ?.filter((item) => item?.id == ticket?.id)[0]
        ?.ticketStatuses.reverse(),
    )
  }, [ticket, tickets])

  useEffect(() => {
    console.log(tickets)
    search?.length !== 0 && setTicket({ ...tickets[0] })
  }, [search, tickets])

  console.log(tickets)
  console.log(timeline)

  if (isLoading && search?.length === 0) {
    return <Loader />
  }

  console.log(timeline)
  return (
    <div>
      {' '}
      <Grid container spacing={4} sx={{ marginTop: '5px' }}>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Box
            sx={{
              boxShadow: '0px 5px 15px #22222214;',
              paddingTop: '20px',
              borderRadius: '10px',
            }}
          >
            <Typography
              pb={2}
              px={2}
              sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}
            >
              Tickets
            </Typography>
            <Divider />

            <Box p={2} overflow="auto" maxHeight="700px" className="scrollBox">
              <TicketDetails
                data={tickets}
                ticket={ticket}
                setTicket={setTicket}
                search={search}
                setSearch={setSearch}
                loading={isLoading}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Box
            sx={{
              boxShadow: '0px 5px 15px #22222214;',
              paddingTop: '20px',
              borderRadius: '10px',
            }}
          >
            <Typography
              pb={2}
              px={2}
              sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}
            >
              Ticket Details
            </Typography>
            <Divider />
            {tickets?.length === 0 ? (
              <Box sx={{ marginTop: '17px' }}>
                <SchoolAnalyticsEmptyScreen />
              </Box>
            ) : (
              <TicketDetailsCard
                details={ticket}
                images={images}
                completedImages={completedImages}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Box
            sx={{
              boxShadow: '0px 5px 15px #22222214;',
              paddingTop: '20px',
              borderRadius: '10px',
              // height: '774px',
            }}
          >
            <Typography
              pb={2}
              px={2}
              sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}
            >
              Timeline
            </Typography>
            <Divider />
            <Box px={1} overflow="auto" maxHeight="700px" className="scrollBox">
              {timeline?.length === 0 || tickets?.length === 0 ? (
                <Box sx={{ marginTop: '30px' }}>
                  <SchoolAnalyticsEmptyScreen />
                </Box>
              ) : (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {timeline?.map((step, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            border: 'none',
                            backgroundColor: 'white',
                            boxShadow: 'none',
                          }}
                        >
                          <Box
                            sx={{
                              width: '35px',
                              height: '35px',
                              borderRadius: '999px',
                              border: '0.5px solid #0C1361',
                              color: '#0C1361',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'poppins_medium',
                                fontSize: '20px',
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </Box>
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box
                          sx={{
                            boxShadow: '0px 5px 15px #22222214',
                            borderRadius: '5px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: '20px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'poppins_medium',
                                fontSize: '15px',
                              }}
                            >
                              {step.status}
                            </Typography>
                            <i>
                              <Typography
                                sx={{
                                  fontFamily: 'poppins_medium',
                                  fontSize: '12px',
                                  opacity: '0.5',
                                }}
                              >
                                {moment
                                  .utc(step?.createdAt)
                                  ?.format('DD MMM, YYYY hh:mm A')}
                              </Typography>
                            </i>
                          </Box>

                          <Divider />
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '20px',
                            }}
                            gap={'7px'}
                          >
                            <AccountCircleIcon sx={{ color: '#BDBDBD' }} />
                            <Typography
                              sx={{
                                fontFamily: 'poppins_medium',
                                fontSize: '12px',
                              }}
                            >
                              {step?.user?.fullName ?? 'NA'}
                            </Typography>
                          </Box>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* )} */}
    </div>
  )
}

export default Tickets
