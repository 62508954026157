import { Box, Pagination, Typography } from '@mui/material'

import Table from 'components/Mui/TableComponent'
import { useEffect, useState } from 'react'

import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Loader from 'components/Loader'
import PageHeader from 'components/Mui/PageHeader'
import moment from 'moment'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import { TextModal } from './TextModal'

const HealthCheckups = () => {
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  const [modalOpen, setModalOpen] = useState(false)
  const [details, setDetails] = useState({})

  const fetchData = async (page) => {
    const res = await http.get(
      `/health-checkups/all?limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`,
    )
    setCount(res?.data?.count)
    return res?.data?.healthCheckups
  }

  const { data: healthCheckups, isLoading } = useQuery(
    ['health-checkups', page],
    () => fetchData(page),
  )

  const handleViewClick = (row) => {
    setModalOpen(true)
    setDetails(row)
  }

  const columns = [
    {
      title: 'Health Checkup Number',
      dataIndex: 'id',
      key: 'id',
      render: (row) => {
        return row ? `HC${row?.toString().padStart(4, '0')}` : 'NA'
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (colData) => {
        return moment(colData).format('DD-MM-YYYY')
      },
    },
    {
      title: 'School Name',
      dataIndex: 'school.name',
      key: 'school.name',
    },
    {
      title: 'Head master name',
      dataIndex: 'headMaster.fullName',
      key: 'headMaster.fullName',
    },
    {
      title: 'ATDO Point',
      dataIndex: 'school.atdoPoint.name',
      key: 'school.atdoPoint.name',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        return (
          <Box onClick={() => handleViewClick(row)} sx={{ cursor: 'pointer' }}>
            <Typography>View More</Typography>
          </Box>
        )
      },
    },
  ]

  function handlePagination(e, v) {
    setPage(v)
  }

  return (
    <div>
      {modalOpen && <TextModal setOpen={setModalOpen} row={details} />}{' '}
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Medical', to: '/Medical' },
              { name: 'Health Checkups', to: null },
            ]}
          />
        }
      />
      {isLoading ? (
        <Loader />
      ) : healthCheckups?.length === 0 ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'healthCheckups'}
            dataSource={healthCheckups}
            rowKey={'id'}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
    </div>
  )
}

export default HealthCheckups
