import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import Schools from 'pages/AksharaJyothiDashboard/Schools'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  getSchoolClassWithSubjects,
  getTestDetails,
} from 'services/api/aksharaJyothi'
import TestDetails from './TestDetails'

const AksharaJyothyTests = () => {
  const navigate = useNavigate()
  const [filterData, setFilterData] = useState({
    id: null,
    schoolId: null,
    classId: null,
    subjectId: null,
  })

  const { data: testDetails, isLoading: testLoading } = useQuery(
    [
      'test-details',
      {
        schoolId: filterData?.id,
        classId: filterData?.classId,
        subjectId: filterData?.subjectId,
      },
    ],
    getTestDetails,
    {
      enabled:
        filterData?.schoolId && filterData?.classId && filterData?.subjectId
          ? true
          : false,
    },
  )

  const { data, isLoading } = useQuery(
    ['class', filterData?.schoolId],
    getSchoolClassWithSubjects,
    {
      enabled: filterData?.schoolId ? true : false,
    },
  )

  useEffect(() => {
    setFilterData({
      ...filterData,
      classId: data?.data?.[0]?.id,
      subjectId: data?.data[0]?.subjects[0]?.id,
    })
  }, [data?.data])

  const handleChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value })
  }

  return (
    <Box p={2} pt={3}>
      <Button
        onClick={() => navigate('/akshara-jyothi-dashboard')}
        variant="text"
        sx={{ color: 'black' }}
        startIcon={<ArrowBack />}
      >
        Akshara Jyothi Tests
      </Button>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item sx={12} md={3.5} lg={3.3} xl={3}>
            <Box>
              <Schools filterData={filterData} setFilterData={setFilterData} />
            </Box>
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <Grid mt={2} item sx={12} md={8.5} lg={8.7} xl={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    sx={{ width: 200 }}
                    select
                    name="classId"
                    label="Select Class"
                    onChange={handleChange}
                    value={filterData?.classId || ''}
                  >
                    {data?.data?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        class {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    sx={{ width: 200 }}
                    name="subjectId"
                    label="Select Subject"
                    onChange={handleChange}
                    value={filterData?.subjectId || ''}
                  >
                    {data?.data
                      ?.filter((item) => item?.id === filterData?.classId)[0]
                      ?.subjects?.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Box>
                {testLoading ? (
                  <Box>
                    <Loader />
                  </Box>
                ) : testDetails?.data?.result?.length > 0 ? (
                  testDetails?.data?.result?.map((item) => (
                    <TestDetails dataVal={item} />
                  ))
                ) : (
                  <Box textAlign="center" ml={'7px'} mt={3}>
                    <SchoolAnalyticsEmptyScreen />
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default AksharaJyothyTests
