import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const EmergencyDetails = ({
  data,
  emergency,
  setEmergency,
  search,
  setSearch,
  loading,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [emergencies, setEmergencies] = useState(data)

  useEffect(() => {
    data?.length !== 0 && setEmergencies(data)
  }, [data])

  const StatusBox = styled(Box)(({ status }) => {
    return {
      backgroundColor:
        status === 'PENDING'
          ? '#FFFAEF'
          : status === 'ASSIGNED'
          ? '#EAEBF1'
          : status === 'ACKNOWLEDGED'
          ? '#F7EFEE'
          : status === 'HOSPITALIZED'
          ? '#E8F1FA'
          : '#E8F6EE',
      //   color: theme.colors.warning.main,
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '8px 8px',
    }
  })

  const changeSelected = (item) => {
    setEmergency(item)
  }

  return (
    <Box>
      <TextField
        label="Search by Medical Issue Name"
        sx={{ mb: 2, width: '100%' }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {data?.length === 0 ? (
        <Box>
          <SchoolAnalyticsEmptyScreen />
        </Box>
      ) : loading ? (
        <Loader />
      ) : (
        <Box overflow="auto" maxHeight="700px" className="scrollBox">
          {emergencies?.map((item, index) => {
            let desc =
              item?.description?.length > 500
                ? item?.description?.slice(0, 500) + '...'
                : item?.description

            return (
              <Box
                // key={index}
                p={2}
                mb={1.5}
                border={
                  item?.id == emergency?.id
                    ? `1px solid black`
                    : '1px solid rgba(34, 34, 34, 0.08)'
                }
                borderRadius="5px"
                sx={{ cursor: 'pointer' }}
                onClick={() => changeSelected(item)}
              >
                <Grid container sx={{ width: '100%' }}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Emergency ID
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {`GRV${item?.id.toString().padStart(3, '0')}`}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Date
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {moment(item?.createdAt).format('DD-MM-YYYY')}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Medical Issue
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {item?.medicalIssue?.name ?? 'NA'}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Description
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {desc}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Box>
                      <StatusBox status={item?.status}>
                        <Typography
                          sx={{
                            color:
                              item?.status === 'PENDING'
                                ? '#FFCF64'
                                : item?.status === 'ASSIGNED'
                                ? '#0A1160'
                                : item?.status === 'ACKNOWLEDGED'
                                ? '#AD6359'
                                : item?.status === 'HOSPITALIZED'
                                ? '#1F78D1'
                                : '#1AAA55',
                          }}
                        >
                          {item?.status}
                        </Typography>
                      </StatusBox>
                      <Box
                        pt={2.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          School Name
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {item?.school?.name}
                        </Typography>
                      </Box>
                      <Box
                        pt={2.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Priority
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {item?.priority}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export const EmergencyDetailsGCC = ({
  data,
  emergency,
  setEmergency,
  search,
  setSearch,
  loading,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [emergencies, setEmergencies] = useState(data)

  console.log(emergency.updatedAt    ,"emergency");

  useEffect(() => {
    data?.length !== 0 && setEmergencies(data)
  }, [data])

  const StatusBox = styled(Box)(({ status }) => {
    return {
      backgroundColor:
        status === 'PENDING'
          ? '#FFFAEF'
          : status === 'ASSIGNED'
          ? '#EAEBF1'
          : status === 'ACKNOWLEDGED'
          ? '#F7EFEE'
          : status === 'HOSPITALIZED'
          ? '#E8F1FA'
          : '#E8F6EE',
      //   color: theme.colors.warning.main,
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '8px 8px',
    }
  })

  const getAge = (date) => {
    var date1 = new Date(date);
    var date2 = new Date();

    var Difference_In_Time = date2.getTime() - date1.getTime();

    return  Math.round(Difference_In_Time / (1000 * 3600 * 24));

  }

  const changeSelected = (item) => {
    setEmergency(item)
  }

  return (
    <Box>
      <TextField
        label="Search by Indent ID"
        sx={{ mb: 2, width: '100%' }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {data?.length === 0 ? (
        <Box>
          <SchoolAnalyticsEmptyScreen />
        </Box>
      ) : loading ? (
        <Loader />
      ) : (
        <Box overflow="auto" maxHeight="700px" className="scrollBox">
          {emergencies?.map((item, index) => {
            let desc =
              item?.description?.length > 500
                ? item?.description?.slice(0, 500) + '...'
                : item?.description

            return (
              <Box
                // key={index}
                p={2}
                mb={1.5}
                border={
                  item?.id == emergency?.id
                    ? `1px solid black`
                    : '1px solid rgba(34, 34, 34, 0.08)'
                }
                borderRadius="5px"
                sx={{ cursor: 'pointer' }}
                onClick={() => changeSelected(item)}
              >
                <Grid container sx={{ width: '100%' }}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Indent ID
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {`GRV${item?.id.toString().padStart(3, '0')}`}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Date
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {moment(item?.createdAt).format('DD-MM-YYYY')}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        School Id
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {item?.schoolId ?? 'NA'}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        indent age
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {getAge(emergency.updatedAt)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Box>
                      <StatusBox status={item?.status}>
                        <Typography
                          sx={{
                            color:
                              item?.status === 'PENDING'
                                ? '#FFCF64'
                                : item?.status === 'ASSIGNED'
                                ? '#0A1160'
                                : item?.status === 'ACKNOWLEDGED'
                                ? '#AD6359'
                                : item?.status === 'HOSPITALIZED'
                                ? '#1F78D1'
                                : '#1AAA55',
                          }}
                        >
                          {item?.status}
                        </Typography>
                      </StatusBox>
                      <Box
                        pt={2.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          School Name
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {item?.school?.name}
                        </Typography>
                      </Box>
                      <Box
                        pt={2.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Total Price
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {item?.totalIndentPrice}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default EmergencyDetails
