import AcademicAndExtraCurricularsScreen from 'components/AcademicAndExtraCurricularsScreen'
import FoodMenuImplementationDetails from 'components/FoodMenuImplementationDetails'
import NewProgrammeDetails from 'components/NewProgrammeDetails'
import AcademicActivityDetails from 'pages/ActivityDashboard/AcademicActivityDetails'
import ActivityDashboard from 'pages/ActivityDashboard/ActivityDashboard'
import ActivityFeed from 'pages/ActivityDashboard/ActivityFeed'
import AttendanceDetailsScreen from 'pages/ActivityDashboard/AttendanceDetailsScreen'
import DashboardAcademicActivities from 'pages/ActivityDashboard/DashboardAcademicActivities'
import DashboardAttendanceActivities from 'pages/ActivityDashboard/DashboardAttendanceActivities'
import DashboardFoodMenuActivities from 'pages/ActivityDashboard/DashboardFoodMenuActivities'
import FeedAcademic from 'pages/ActivityDashboard/FeedAcademic'
import FeedAttendance from 'pages/ActivityDashboard/FeedAttendance'
import FeedFoodMenu from 'pages/ActivityDashboard/FeedFoodMenu'
import FoodMenuImpDetails from 'pages/ActivityDashboard/FoodMenuImpDetails'
import TeacherAndStaffAttendanceDetailsInnerScreen from 'pages/ActivityDashboard/TeacherAndStaffAttendanceDetailsInnerScreen'
import TeacherAndStaffAttendanceDetailsScreen from 'pages/ActivityDashboard/TeacherAndStaffAttendanceDetailsScreen'
import UnmarkedSchools from 'pages/ActivityDashboard/UnmarkedSchools'
import AdminUsers from 'pages/Admin Users/AdminUsers'
import AdminUsersDetails from 'pages/Admin Users/AdminUsersDetails'
import AksharaJyothiDashboard from 'pages/AksharaJyothiDashboard'
import AksharaTestComparison from 'pages/AksharaJyothiDashboard/performanceComparison'
import AksharaPerformanceReport from 'pages/AksharaJyothiDashboard/performanceReport'
import AksharaJyothiTests from 'pages/AksharaJyothiDashboard/tests'
import CRTTeacherAttDetails from 'pages/CRTTeachers/CRTTeacherAttDetails'
import CRTTeachers from 'pages/CRTTeachers/CRTTeachers'
import Dashboard from 'pages/Dashboard/Dashboard'
import Engineering from 'pages/Engineering'
import Tickets from 'pages/Engineering/Tickets'
import GCC from 'pages/GCC'
import GCCDashboard from 'pages/GCC/Dashboard'
import OverallStockUsage from 'pages/GCC/Dashboard/OverallStockUsage'
import StockIndent from 'pages/GCC/StockIndents/StockIndent'
import StockInventoryBalance from 'pages/GCC/StockInventoryBalance'
import StockItemTable from 'pages/GCC/StockInventoryBalance/StockItemTable'
import ViewItemTable from 'pages/GCC/StockInventoryBalance/ViewItemTable'
import StockUsage from 'pages/GCC/StockUsage'
import StockUsageAnalytics from 'pages/GCC/StockUsage/StockUsageAnalytics'
import StockUsageTable from 'pages/GCC/StockUsage/StockUsageTable'
import ViewStockUsageTable from 'pages/GCC/StockUsage/ViewStockUsageTable'
import AcademicMasterData from 'pages/MasterData/AcademicMasterData'
import AssetCategories from 'pages/MasterData/AssetCategories'
import ATDOPoints from 'pages/MasterData/ATDOPoints'
import Categories from 'pages/MasterData/Categories'
import Classrooms from 'pages/MasterData/Classrooms'
import CRTteachers from 'pages/MasterData/CRTteachers'
import Districts from 'pages/MasterData/Districts'
import EngineeringMasterData from 'pages/MasterData/EngineeringMasterData'
import FoodMenu from 'pages/MasterData/FoodMenu'
import FoodMenuInnerPage from 'pages/MasterData/FoodMenuInnerPage'
import HeadMasters from 'pages/MasterData/HeadMasters'
import IssueTypes from 'pages/MasterData/IssueTypes'
import Mandals from 'pages/MasterData/Mandals'
import MasterData from 'pages/MasterData/MasterData'
import MedicalMasterData from 'pages/MasterData/MedicalMasterData'
import MedicalIssues from 'pages/MasterData/MedicalMasterData/MedicalIssues'
import RegTeachers from 'pages/MasterData/RegTeachers'
import SchoolStaff from 'pages/MasterData/SchoolStaff'
import StockMasterData from 'pages/MasterData/StockMasterData'
import Subjects from 'pages/MasterData/Subjects'
import Tests from 'pages/MasterData/Tests'
import TestSubjects from 'pages/MasterData/TestSubjects'
import UsersMasterData from 'pages/MasterData/UsersMasterData'
import Medical from 'pages/Medical'
import AmbulanceServices from 'pages/Medical/AmbulanceServices'
import MedicalDashboard from 'pages/Medical/Dashboard'
import AllEmergencies from 'pages/Medical/Dashboard/AllEmergencies'
import MedicalEmergencies from 'pages/Medical/Emergencies'
import HealthCheckups from 'pages/Medical/HealthCheckups'
import HospitalisedStudents from 'pages/Medical/HospitalisedStudents'
import RegularTeachers from 'pages/RegularTeachers/RegularTeachers'
import RegularTeachersAttDetails from 'pages/RegularTeachers/RegularTeachersAttDetails'
import AcademicAndExtraCurriculars from 'pages/School Analytics/AcademicAndExtraCurriculars'
import AksharaJyothiSchoolDashboard from 'pages/School Analytics/AksharaJyothiSchoolDashboard'
import Assets from 'pages/School Analytics/Assets'
import AttendanceActivities from 'pages/School Analytics/AttendanceActivities'
import AttendanceDetails from 'pages/School Analytics/AttendanceDetails'
import CRTTeacherAttendanceDetails from 'pages/School Analytics/CRTTeacherAttendanceDetails'
import CRTTeacherDetails from 'pages/School Analytics/CRTTeacherDetails'
import FoodMenuImplementation from 'pages/School Analytics/FoodMenuImplementation'
import NewProgramme from 'pages/School Analytics/NewProgramme'
import NightAttendance from 'pages/School Analytics/NightAttendance'
import RegularTeacherAttendanceDetails from 'pages/School Analytics/RegularTeacherAttendanceDetails'
import RegularTeacherDetails from 'pages/School Analytics/RegularTeacherDetails'
import SchoolAnalytics from 'pages/School Analytics/SchoolAnalytics'
import SchoolStock from 'pages/School Analytics/SchoolStock'
import StaffAttendance from 'pages/School Analytics/StaffAttendance'
import StaffAttendanceDetails from 'pages/School Analytics/StaffAttendanceDetails'
import SchoolDetails from 'pages/SchoolDetails/SchoolDetails'
import Schools from 'pages/Schools/Schools'
import StaffAttDetails from 'pages/StaffAttendance/StaffAttDetails'
import StaffAttendanceDashboard from 'pages/StaffAttendance/StaffAttendanceDashboard'
import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

function Routing() {
  const navigate = useNavigate()

  const userType = localStorage.getItem('user_type')

  useEffect(() => {
    const authenticate = localStorage.getItem('token') ? true : false
    !authenticate && navigate('/login', { replace: true })
  }, [])

  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="activity-dashboard" element={<ActivityDashboard />}>
        <Route path="" element={<DashboardAttendanceActivities />} />
        <Route
          path="academic-extra-curriculars"
          element={<DashboardAcademicActivities />}
        />
        <Route
          path="food-menu-implementation"
          element={<DashboardFoodMenuActivities />}
        />
        {/* <Route path="new-programme" element={<DashboardNewProgram />} /> */}
      </Route>
      <Route path="activity-dashboard/activity-feed" element={<ActivityFeed />}>
        <Route path="" element={<FeedAttendance />} />
        <Route path="academic-extra-curriculars" element={<FeedAcademic />} />
        <Route path="food-menu-implementation" element={<FeedFoodMenu />} />
      </Route>
      <Route
        path="activity-dashboard/academicDetails"
        element={<AcademicActivityDetails />}
      />
      <Route
        path="activity-dashboard/foodMenuDetails"
        element={<FoodMenuImpDetails />}
      />
      <Route
        path="activity-dashboard/overall-attendance"
        element={<AttendanceDetailsScreen />}
      />
      <Route
        path="activity-dashboard/present-attendance"
        element={<AttendanceDetailsScreen attType={'present'} />}
      />
      <Route
        path="activity-dashboard/absent-attendance"
        element={<AttendanceDetailsScreen attType={'absent'} />}
      />
      <Route
        path="activity-dashboard/sick-attendance"
        element={<AttendanceDetailsScreen attType={'sick'} />}
      />

      <Route
        path="activity-dashboard/attendance-details"
        element={<TeacherAndStaffAttendanceDetailsScreen />}
      />
      <Route
        path="activity-dashboard/present-attendance-details"
        element={<TeacherAndStaffAttendanceDetailsScreen attType={'present'} />}
      />
      <Route
        path="activity-dashboard/absent-attendance-details"
        element={<TeacherAndStaffAttendanceDetailsScreen attType={'absent'} />}
      />
      <Route
        path="activity-dashboard/attendance-details-inner-screen"
        element={<TeacherAndStaffAttendanceDetailsInnerScreen />}
      />
      <Route
        path="activity-dashboard/unmarked-schools"
        element={<UnmarkedSchools />}
      />

      <Route path="schools" element={<Schools />} />

      <Route path="/schools/school-details" element={<SchoolDetails />} />
      <Route
        path="/schools/akshara-jyothi-dashboard"
        element={<AksharaJyothiSchoolDashboard />}
      />
      <Route path="/schools/assets" element={<Assets />} />
      <Route path="/schools/stock" element={<SchoolStock />} />

      <Route path="/schools/school-analytics" element={<SchoolAnalytics />}>
        <Route
          path="attendance-activities"
          element={<AttendanceActivities />}
        />
        <Route
          path="academic-extra-curriculars"
          element={<AcademicAndExtraCurriculars />}
        />
        <Route
          path="food-menu-implementation"
          element={<FoodMenuImplementation />}
        />
        <Route path="new-programme" element={<NewProgramme />} />
      </Route>
      <Route
        path="/schools/attendance-details"
        element={<AttendanceDetails />}
      />
      <Route
        path="/schools/regular-teachers-details"
        element={<RegularTeacherDetails />}
      />
      <Route
        path="/schools/regular-teachers/attendance-details"
        element={<RegularTeacherAttendanceDetails />}
      />
      <Route
        path="regular-teachers/attendance-details"
        element={<RegularTeachersAttDetails />}
      />

      <Route
        path="/schools/crt-teachers/attendance-details"
        element={<CRTTeacherAttendanceDetails />}
      />
      <Route
        path="crt-teachers/attendance-details"
        element={<CRTTeacherAttDetails />}
      />
      <Route path="/schools/staff-attendance" element={<StaffAttendance />} />
      <Route
        path="/schools/staff-attendance-details"
        element={<StaffAttendanceDetails />}
      />
      <Route
        path="/schools/academic-and-extra-curriculars-details"
        element={<AcademicAndExtraCurricularsScreen />}
      />
      <Route
        path="/schools/food-menu-implementation-details"
        element={<FoodMenuImplementationDetails />}
      />
      <Route
        path="/schools/new-programme-details"
        element={<NewProgrammeDetails />}
      />

      <Route
        path="/schools/crt-teachers-details"
        element={<CRTTeacherDetails />}
      />
      <Route path="/schools/night-attendance" element={<NightAttendance />} />
      <Route path="regular-teachers" element={<RegularTeachers />} />
      <Route path="crt-teachers" element={<CRTTeachers />} />
      <Route path="akshara-jyothi" element={<AksharaJyothiDashboard />} />
      <Route path="akshara-jyothi/tests" element={<AksharaJyothiTests />} />
      <Route
        path="akshara-jyothi/test-comparison"
        element={<AksharaTestComparison />}
      />
      <Route
        path="akshara-jyothi/performance-report"
        element={<AksharaPerformanceReport />}
      />

      <Route path="staff-attendance" element={<StaffAttendanceDashboard />} />
      <Route path="staff-attendance/details" element={<StaffAttDetails />} />
      <Route path="engineering" element={<Engineering />} />
      <Route path="Medical" element={<Medical />} />
      <Route path="Medical/dashboard" element={<MedicalDashboard />} />
      <Route
        path="Medical/dashboard/all-emergencies"
        element={<AllEmergencies />}
      />

      <Route path="Medical/emergencies" element={<MedicalEmergencies />} />
      <Route path="Medical/health-checkups" element={<HealthCheckups />} />
      <Route
        path="Medical/ambulance-services"
        element={<AmbulanceServices />}
      />
      <Route
        path="Medical/hospitalized-students"
        element={<HospitalisedStudents />}
      />
      <Route path="gcc" element={<GCC />} />
      <Route path="gcc/dashboard" element={<GCCDashboard />} />
      <Route
        path="gcc/dashboard/overall-stock-usage"
        element={<OverallStockUsage />}
      />
      <Route path="gcc/stock-indents" element={<StockIndent/>}/>
      <Route
        path="gcc/stock-inventory-balance"
        element={<StockInventoryBalance />}
      />
      <Route path="gcc/stockitem-table/:id" element={<StockItemTable/>}/>
      <Route path="gcc/stockitem-table/:id/viewitemtable" element={<ViewItemTable/>}/>
      <Route path="gcc/stock-usage" element={<StockUsage/>}/>
      <Route path="gcc/stock-usage-analytics/:id" element={<StockUsageAnalytics/>}/>
      <Route path="gcc/stockusagetable/:id" element={<StockUsageTable/>}/>
      <Route path="gcc/view-stockusagetable/:id" element={<ViewStockUsageTable/>}/>

      <Route path="engineering/tickets" element={<Tickets />} />
      <Route path="master-data" element={<MasterData />}>
        <Route
          path=""
          element={userType == 'Admin' ? <Districts /> : <FoodMenu />}
        />
        <Route
          path="atdo-points"
          element={userType == 'Admin' ? <ATDOPoints /> : <Dashboard />}
        />
        <Route
          path="mandals"
          element={userType == 'Admin' ? <Mandals /> : <Dashboard />}
        />
        <Route
          path="subjects"
          element={userType == 'Admin' ? <Subjects /> : <Dashboard />}
        />
        <Route
          path="classrooms"
          element={userType == 'Admin' ? <Classrooms /> : <Dashboard />}
        />
        <Route
          path="tests"
          element={userType == 'Admin' ? <Tests /> : <Dashboard />}
        />
        <Route path="head-masters" element={<HeadMasters />} />
        <Route path="regular-teachers" element={<RegTeachers />} />
        <Route path="crt-teachers" element={<CRTteachers />} />
        <Route path="school-staff" element={<SchoolStaff />} />
        <Route path="food-menu" element={<FoodMenu />} />
      </Route>
      <Route path="academic-master-data" element={<AcademicMasterData />}>
        <Route path="" element={userType == 'Admin' && <Classrooms />} />
        <Route path="tests" element={userType == 'Admin' && <Tests />} />
        <Route path="subjects" element={userType == 'Admin' && <Subjects />} />
        <Route
          path="test-subjects"
          element={userType == 'Admin' && <TestSubjects />}
        />
      </Route>
      <Route path="users-master-data" element={<UsersMasterData />}>
        <Route path="" element={<HeadMasters />} />
        <Route path="regular-teachers" element={<RegTeachers />} />
        <Route path="crt-teachers" element={<CRTteachers />} />
        <Route path="school-staff" element={<SchoolStaff />} />
      </Route>
      <Route path="engineering-master-data" element={<EngineeringMasterData />}>
        <Route path="" element={<IssueTypes />} />
        <Route path="issue-categories" element={<Categories />} />
        <Route path="asset-categories" element={<AssetCategories />} />
      </Route>
      <Route path="medical-master-data" element={<MedicalMasterData />}>
        <Route path="" element={<MedicalIssues />} />
      </Route>
      <Route path="stock-master-data" element={<StockMasterData />} />

      <Route
        path="/master-data/food-menu-details"
        element={<FoodMenuInnerPage />}
      />
      <Route
        path="/admin-users"
        element={userType == 'Admin' ? <AdminUsers /> : <Dashboard />}
      />
      <Route
        path="/admin-users/details"
        element={userType == 'Admin' ? <AdminUsersDetails /> : <Dashboard />}
      />
    </Routes>
  )
}

export default Routing
