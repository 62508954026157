import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import DividerHeading from 'components/DividerHeading'
import { DeleteButton } from 'components/IconButtons'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { http } from 'services/http'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import TestSubjectsForm from './TestSubjectsForm'

const TestSubjects = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()

  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState()
  const [classroomSubjects, setClassroomSubjects] = useState([])
  const [open, setOpen] = React.useState(false)
  const [formData, setFormData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  useEffect(() => {
    modalOpen == false && queryClient.invalidateQueries('testSubjects')
  }, [modalOpen])

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  // async function getTestSubjects() {
  //   setSearchLoading(true)
  //   try {
  //     http.get(`/subjects?search=${search}`).then((res) => {
  //       console.log(res)
  //       setSubjects(res?.data?.subjects)
  //       setSearchLoading(false)
  //     })
  //   } catch (err) {
  //     console.log(err)
  //     enqueueSnackbar(err?.response?.data?.message, {
  //       variant: 'error',
  //     })
  //   }
  // }
  // useEffect(() => {
  //   getSubjects()
  // }, [search])
  // const handleSearch = (e) => {
  //   e?.preventDefault()
  //   getSubjects()
  // }

  const { data, isLoading } = useQuery(
    ['testSubjects'],
    () =>
      http.get(`/class`).then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        return res?.data?.data
      }),
    {
      onSuccess: (data) => {
        setClassroomSubjects(data?.slice(0, 10))
      },
    },
  )

  const buttonSX = {
    '&:hover': {
      borderColor: '#24272C',
      color: '#24272C',
      background: 'none',
    },
    // marginTop: '28px',
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    borderColor: '#24272C',
    color: '#24272C',
  }

  async function handlePagination(e, v) {
    try {
      setPage(page + 1)
      http.get(`/class?limit=10&offset=${page * 10}`).then((res) => {
        console.log(res)
        // setCount(res?.data?.count)
        setClassroomSubjects(classroomSubjects?.concat(res?.data?.data))
        // setLoading(false)
      })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  if (isLoading || loading || !classroomSubjects) {
    return <CircularProgress />
  }
  return (
    <div>
      <Box>
        {classroomSubjects?.length == 0 ? (
          <CircularProgress />
        ) : (
          classroomSubjects?.map((item, ind) => {
            return (
              <Box
                sx={{
                  boxShadow: '0px 3px 16px #2A32710F',
                  borderRadius: '10px',
                  padding: '28px',
                  marginTop: '28px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    // marginTop: '2.2rem',
                  }}
                >
                  <Button
                    sx={buttonSX}
                    variant="outlined"
                    onClick={() => {
                      setFormData(item)
                      toggleDrawer(true)
                    }}
                  >
                    + Add Subjects
                  </Button>{' '}
                </Box>
                <Box sx={{ marginTop: '-50px' }}>
                  <DividerHeading title={`Classroom - ${item?.name}`} />
                </Box>
                {item?.subjects?.length == 0 ? (
                  <SchoolAnalyticsEmptyScreen />
                ) : (
                  <Grid container spacing={1}>
                    {item?.subjects?.map((obj, ind) => {
                      return (
                        <Grid item xl={3} lg={4} md={4}>
                          <SmallCard
                            img="/images/vuesax-outline-book.png"
                            title={obj?.name}
                            id={obj?.id}
                            subjects={item?.subjects}
                            classroomId={item?.id}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                )}
              </Box>
            )
          })
        )}
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            Add Subjects
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <TestSubjectsForm data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  )
}

export default TestSubjects

const SmallCard = ({ title, img, subjects, id, classroomId }) => {
  const subjectsArr = subjects?.map((item) => item?.id)
  const queryClient = useQueryClient()

  const handleDelete = () => {
    const ind = subjectsArr?.indexOf(id)
    subjectsArr?.splice(ind, 1)
    const formData = {
      name: classroomId,
      subjectIds: subjectsArr,
    }
    http.put(`/class/${classroomId}`, formData).then((res) => {
      queryClient.invalidateQueries('testSubjects')
    })
  }
  return (
    <Box
      sx={{
        // width: '350px',
        height: '105px',
        padding: '4px 20px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 3px 13px #2A32711A',
        margin: '18px 18px 0px 0px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
        <Box>
          <img src={img} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box>
        <DeleteButton onClick={handleDelete} />
      </Box>
    </Box>
  )
}
