import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const FoodMenuForm = ({ data, toggleDrawer, foodCourseId }) => {
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  let weekDays = new Array(7)
  weekDays[0] = 'Monday'
  weekDays[1] = 'Tuesday'
  weekDays[2] = 'Wednesday'
  weekDays[3] = 'Thursday'
  weekDays[4] = 'Friday'
  weekDays[5] = 'Saturday'
  weekDays[6] = 'Sunday'
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])
  console.log(foodCourseId)

  const onSubmit = (data) => {
    console.log(data)
    if (edit) {
      const body = { ...data, foodCourseId: foodCourseId }
      http
        .put(`/food-menu/${id}`, body)
        .then((res) => {
          enqueueSnackbar('Food Menu Item has been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('foodMenuItems')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      const body = { ...data, foodCourseId: foodCourseId }
      http
        .post(`/food-menu`, body)
        .then((res) => {
          enqueueSnackbar('Food Menu Item has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('foodMenuItems')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      foodName: data?.name,
    },
  })

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={'text'}
            {...register('name', {
              required: true,
              pattern: {
                value: /^[A-Za-z ,.]+$/,
              },
            })}
            label={'Food Name'}
            required={true}
            defaultValue={data?.name}
          />
          {errors?.name?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          {errors?.name?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="day">Day *</InputLabel>
            <Select
              labelId="day"
              id="day"
              label="Day *"
              {...register('day', { required: true })}
              defaultValue={data?.day}
              required={true}
            >
              {weekDays?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>
              })}
            </Select>
          </FormControl>
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {data ? 'Edit Food Item' : 'Add Food Item'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default FoodMenuForm
