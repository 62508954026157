import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import PieChartCard from 'components/PieChartComponent'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import * as moment from 'moment'
import { http } from 'services/http'
import { useQuery } from 'react-query'
import Loader from 'components/Loader'
const ATDOWiseStats = ({ districtId, atdoPointId, date }) => {
  const COLORS1 = ['#FFCF64']
  const COLORS2 = ['#25A4CF']

  const emergencyStatuses = [
    'PENDING',
    'ASSIGNED',
    'ACKNOWLEDGED',
    'HOSPITALIZED',
    'COMPLETED',
  ]
  const fetchEmergencies = async (statuses, districtId, atdoPointId, date) => {
    const res = await http.get(
      `/medical-emergencies/atdo-emergencies?statuses=${statuses}&districtId=${districtId}&atdoPointId=${atdoPointId}&date=${date}`,
    )
    return res?.data
  }

  const { data: emergencies, isLoading: emergenciesLoading } = useQuery(
    ['getATDOEmergencies', emergencyStatuses, districtId, atdoPointId, date],
    () => fetchEmergencies(emergencyStatuses, districtId, atdoPointId, date),
  )

  const hospitalizedStatuses = ['ADMITTED', 'DISCHARGED']
  const fetchHospitalized = async (statuses, districtId, atdoPointId, date) => {
    const res = await http.get(
      `/hospitalized-students/atdo-hospitalised?statuses=${statuses}&districtId=${districtId}&atdoPointId=${atdoPointId}&date=${date}`,
    )
    return res?.data
  }

  const { data: hospitalised, isLoading: hospitalizedLoading } = useQuery(
    [
      'getATDOHospitalized',
      hospitalizedStatuses,
      districtId,
      atdoPointId,
      date,
    ],
    () =>
      fetchHospitalized(hospitalizedStatuses, districtId, atdoPointId, date),
  )

  return (
    <div>
      <Grid container spacing={3} sx={{ marginTop: '50px' }}>
        <Grid item xs={6}>
          <Card
            title={'ATDO point wise Emergencies'}
            data={emergencies}
            colors={COLORS1}
            loading={emergenciesLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <Card
            title={'ATDO point wise Hospitalized Students'}
            data={hospitalised}
            colors={COLORS2}
            loading={hospitalizedLoading}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ATDOWiseStats

const Card = ({ title, data, colors, loading }) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 4px 11px #26338B14;',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#E9EBFF',
            padding: '16px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            border: '1px solid #0E0E0E1A',
          }}
        >
          <Typography sx={{ fontSize: '16px', fontFamily: 'poppins_semibold' }}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              padding: '0px 10px',
            }}
          >
            {loading ? (
              <Loader />
            ) : data?.length == 0 ? (
              <Box
                sx={{
                  marginTop: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <SchoolAnalyticsEmptyScreen />
              </Box>
            ) : (
              <PieChartCard data={data} colors={colors} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
