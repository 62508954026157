import SyncAltIcon from '@mui/icons-material/SyncAlt'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import Loader from 'components/Loader'
import { useEffect, useState } from 'react'
import PieChartCard from '../PieChart'

const TestDetails = ({
  filterData,
  handleChange,
  error,
  setFilterData,
  tests,
  testLoading,
  comparisionData,
  comparisionLoading,
}) => {
  const [oneData, setOneData] = useState()
  const [twoData, setTwoData] = useState()

  useEffect(() => {
    if (!comparisionLoading) {
      const testOneData = comparisionData?.testOne?.grades?.map((item) => {
        return {
          name: `grade ${item.grade}`,
          value: item?.percentage,
        }
      })
      const testTwoData = comparisionData?.testTwo?.grades?.map(
        (item, index) => {
          return {
            name: `grade ${item.grade}`,
            value: item?.percentage,
            trendValue: comparisionData?.testTwo?.performance[index]?.value,
            trendPercentage:
              comparisionData?.testTwo?.performance[index]?.percentage,
          }
        },
      )
      setOneData(testOneData)
      setTwoData(testTwoData)
    }
  }, [comparisionData])

  const COLORS = ['#FF3465', '#FFCF64', '#00D9A6', '#25A4CF']

  return (
    <Box>
      <DividerHeading title="Comparison" />
      {testLoading ? (
        <Box>
          <Loader />
        </Box>
      ) : (
        <Box p={2}>
          {comparisionLoading ? (
            <Box>
              <Loader />
            </Box>
          ) : (
            <Grid
              sx={{
                p: 2,
                borderRadius: '10px 10px 10px 10px',
                boxShadow: '0px 3px 13px #2A32711A',
              }}
              container
              spacing={2}
            >
              <Grid
                item
                md={6}
                sx={{
                  '@media (min-width: 900px)': {
                    borderRight: '1px solid #00000033',
                  },
                  '@media (max-width: 899px)': {
                    borderBottom: '1px solid #00000033',
                  },
                  pr: 1,
                  pb: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      sx={{ minWidth: 200 }}
                      select
                      name="testOne"
                      label="Select Test 1"
                      onChange={handleChange}
                      value={filterData?.testOne || ''}
                    >
                      {tests?.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {!error && (
                    <Grid
                      sx={{ display: 'flex', justifyContent: 'center' }}
                      item
                      xs={12}
                      md={12}
                    >
                      {oneData && (
                        <PieChartCard
                          data={oneData}
                          COLORS={COLORS}
                          name={comparisionData?.testOne?.subject?.name}
                        />
                      )}
                    </Grid>
                  )}
                  {!error && (
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        {oneData?.map((item, index) => (
                          <GradeCard
                            title={item?.name}
                            percentage={item?.value.toFixed(1)}
                            color={
                              index === 0
                                ? '#FF3465'
                                : index === 1
                                ? '#FFCF64'
                                : index === 2
                                ? '#00D9A6'
                                : '#25A4CF'
                            }
                          />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      sx={{ minWidth: 200 }}
                      select
                      name="testTwo"
                      label="Select Test 2"
                      onChange={handleChange}
                      value={filterData?.testTwo || ''}
                    >
                      {tests?.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {!error && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      {twoData && (
                        <PieChartCard
                          data={twoData}
                          COLORS={COLORS}
                          name={comparisionData?.testTwo?.subject?.name}
                        />
                      )}
                    </Grid>
                  )}
                  {!error && (
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        {twoData?.map((item, index) => (
                          <GradeCard
                            isComp={true}
                            title={item?.name}
                            trendpercentage={item?.trendPercentage}
                            trendValue={item?.trendValue}
                            percentage={item?.value}
                            color={
                              index === 0
                                ? '#FF3465'
                                : index === 1
                                ? '#FFCF64'
                                : index === 2
                                ? '#00D9A6'
                                : '#25A4CF'
                            }
                          />
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Box mt={3} display="flex" justifyContent="center">
                    <Typography variant="h5" sx={{ color: 'red' }}>
                      {error}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  )
}

export default TestDetails

const GradeCard = ({
  color,
  percentage,
  trendpercentage,
  trendValue,
  title,
  isComp = false,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Box
        p={2}
        sx={{
          borderRadius: '10px 10px 10px 10px',
          boxShadow: '0px 3px 13px #2A32711A',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box p display="flex" alignItems="center">
            <Box
              sx={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: color,
              }}
            ></Box>
            <Typography ml={1} variant="h5">
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5">{percentage} %</Typography>
          </Box>
        </Box>
        {isComp && (
          <Box display="flex" justifyContent="center">
            <Box
              display="flex"
              alignItems="center"
              sx={{
                backgroundColor:
                  trendValue === 'HIGH'
                    ? '#E7F8EE'
                    : trendValue === 'LOW'
                    ? '#FFEEEB'
                    : '#36408D08',
                px: 1,
                borderRadius: '5px',
              }}
            >
              {trendValue === 'HIGH' ? (
                <TrendingUpIcon
                  sx={{
                    color: '#19B956',
                  }}
                />
              ) : trendValue === 'LOW' ? (
                <TrendingDownIcon
                  sx={{
                    color: '#FF5A3A',
                  }}
                />
              ) : (
                <SyncAltIcon
                  sx={{
                    color: '#191919',
                  }}
                />
              )}
              <Typography
                ml={1}
                variant="h5"
                sx={{
                  color:
                    trendValue === 'HIGH'
                      ? '#19B956'
                      : trendValue === 'LOW'
                      ? '#FF5A3A'
                      : '#36408D08',
                }}
              >
                {trendpercentage.toFixed(1)} %
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  )
}
