import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import { useOutletContext } from 'react-router-dom'
import moment from 'moment'

const FeedAttendance = () => {
  const [item] = useOutletContext()
  return (
    <div style={{ marginTop: '15px' }}>
      <Grid container spacing={2} rowGap={2}>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.studentActivity?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Student Attendance
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.studentActivity?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.studentActivity?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.studentActivity?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.regularTeacherActivity?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Regular Teacher Attendance
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.regularTeacherActivity?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.regularTeacherActivity?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.regularTeacherActivity?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.crtTeacherActivity?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              CRT Teacher Attendance
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.crtTeacherActivity?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.crtTeacherActivity?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.crtTeacherActivity?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.nightActivity?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Night Closing Attendance
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.nightActivity?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.nightActivity?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.nightActivity?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.staffActivity?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Staff Attendance
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.staffActivity?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.staffActivity?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.staffActivity?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default FeedAttendance
