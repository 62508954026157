import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { http } from 'services/http'
import { useQueryClient } from 'react-query'

const ClassroomsForm = ({ data, toggleDrawer }) => {
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  const [schools, setSchools] = useState([])

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      console.log(res)
      setSchools(res?.data?.schools)
    })
  }, [])

  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: data?.name,
      schoolId: data?.school,
      noOfStudents: data?.noOfStudents,
      type: data?.type,
    },
  })

  const queryClient = useQueryClient()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const onSubmit = (formData) => {
    if (edit) {
      const submitData = {
        ...data,
        noOfStudents: formData?.noOfStudents,
        schoolId: formData?.schoolId?.id,
      }
      http
        .put(`/classrooms/${id}`, submitData)
        .then((res) => {
          enqueueSnackbar('The classroom has been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('classrooms')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      const submitData = { ...formData, schoolId: formData?.schoolId?.id }
      http
        .post(`/classrooms`, submitData)
        .then((res) => {
          enqueueSnackbar('The classroom has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('classrooms')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }
  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={'text'}
            {...register('name', {
              required: true,
              pattern: { value: /^[A-Za-z0-9 ]+$/ },
            })}
            label={'Classroom'}
            required={true}
            disabled={edit ? true : false}
          />
          {errors?.name?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.name?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}

          <Controller
            control={control}
            name="schoolId"
            render={({ field: { onChange, value } }) => {
              console.log(value)
              return (
                <Autocomplete
                  id="country-select-demo"
                  sx={{ marginTop: '25px' }}
                  options={schools}
                  disabled={edit ? true : false}
                  // autoHighlight
                  defaultValue={value}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required={true}
                      // fullWidth
                      {...params}
                      label="School"
                      inputProps={{
                        ...params.inputProps,
                        // className: params?.classes?.input,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(_, data) => {
                    onChange(data)
                  }}
                  // defaultValue={value}
                />
              )
            }}
          />
          <TextField
            type={'number'}
            {...register('noOfStudents', {
              required: true,
            })}
            inputProps={{ min: 0 }}
            sx={{ marginTop: '25px' }}
            label={'Number of Students'}
            required={true}
          />
          {errors?.noOfStudents?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl
            component="fieldset"
            sx={{ marginTop: '24px' }}
            disabled={edit ? true : false}
          >
            <FormLabel component="legend">Type *</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="type"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="Primary"
                    control={<Radio />}
                    label="Primary"
                  />
                  <FormControlLabel
                    value="Secondary"
                    control={<Radio />}
                    label="Secondary"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {data ? 'Edit Classroom' : 'Add Classroom'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default ClassroomsForm
