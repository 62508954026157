import { KeyboardArrowDown, MoreVert } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuListProps,
  MenuProps,
  styled,
} from '@mui/material'
import { ReactNode, useState } from 'react'

interface Props {
  label?: string
  icon?: {
    icon: ReactNode
    iconProps?: IconButtonProps
    outlined?: boolean
  }
  menu: {
    label: string | ReactNode
    icon?: ReactNode
    onClick: () => any
  }[]
  buttonProps?: ButtonProps
  menuProps?: Omit<MenuProps, 'open'>
  menuListProps?: MenuListProps
}
const defaultIcon = {
  icon: <MoreVert color="primary" />,
  outlined: true,
}

export default function DropDownButton({
  label,
  icon = defaultIcon,
  menu,
  buttonProps,
  menuProps,
  menuListProps,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!label ? (
        <StyledIconButton
          outlined={icon?.outlined ?? false}
          onClick={handleClick}
          {...icon.iconProps}
        >
          {icon.icon}
        </StyledIconButton>
      ) : (
        <StyledDropDownButton
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
          {...buttonProps}
        >
          {label}
        </StyledDropDownButton>
      )}
      <Menu
        // elevation={4}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={menuListProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...menuProps}
      >
        {menu.map((item, index) => (
          <StyledMenuItem
            sx={{ minWidth: '120px', width: '100%' }}
            key={index}
            onClick={() => {
              handleClose()
              item.onClick()
            }}
          >
            {item?.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.label}</ListItemText>
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  )
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'outlined',
})<{ outlined?: boolean }>(({ theme, outlined }) => ({
  ...(outlined && {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
  }),
}))

const StyledMenuItem = styled(MenuItem)(({}) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
  },
  '& .MuiSvgIcon-root': {
    height: '18px',
    width: '18px',
  },
  '& .MuiTypography-root': {
    fontWeight: 600,
  },
}))

const StyledDropDownButton = styled(Button)(({}) => ({
  padding: '0 14px',
}))
