import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Breadcrumbs from 'components/Breadcrumbs'
import PageHeader from 'components/Mui/PageHeader'
import SearchIcon from '@mui/icons-material/Search'
import React, { useEffect, useState } from 'react'
import { http } from 'services/http'
import { Cell, Pie, PieChart } from 'recharts'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { NavLink, Outlet } from 'react-router-dom'
import SchoolStaffEmptyScreen from 'pages/MasterData/SchoolStaffEmptyScreen'
import { useSnackbar } from 'notistack'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import InfiniteScroll from 'react-infinite-scroll-component'

const ActivityFeed = () => {
  const userType = localStorage.getItem('user_type')
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [districtId, setDistrictId] = useState('')
  const [atdoPointId, setAtdoPointId] = useState('')
  const [date, setDate] = useState(new Date())
  const [activities, setActivities] = useState()
  const [loading, setLoading] = useState(false)
  const [itemLoading, setItemLoading] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [percentage, setPercentage] = useState()
  const [isOpen, setIsOpen] = useState({})
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const toggleOpen = (category) => {
    // setItemLoading(true)
    setIsOpen({
      ...isOpen,

      [category]: !isOpen[category],
    })
    // setItemLoading(false)
  }

  const handleClick = (ind) => {
    setItemLoading(true)
    toggleOpen(ind)
    setItemLoading(false)
  }
  let percent
  let completed = 0
  let incompleted = 0

  useEffect(() => {
    setPercentage(percent)
  }, [percent])

  useEffect(() => {
    setLoading(true)
    http
      .get(`/attendance-activities/schools/status?date=${date}`)
      .then((res) => {
        setCount(res?.data?.count)
        let result = res?.data?.data
        result?.forEach((item) => {
          let vals = Object?.values(item)

          for (let i = 0; i < vals?.length; i++) {

            if (vals[i]?.status?.toString() == 'true') {
              completed = completed + 1
            }
            if (vals[i]?.status?.toString() == 'false') {
              incompleted = incompleted + 1
            }
          }
          item['completedCount'] = completed
          item['incompletedCount'] = incompleted
          let percent = (completed / (completed + incompleted)) * 100
          item['percent'] = percent
          item['percentage'] =
            (Number.isInteger(percent) ? percent : percent?.toFixed(1)) + '%'
          completed = 0
          incompleted = 0
          return item
        })
        setActivities(result?.slice(0, 10))

        setLoading(false)
      })
  }, [date])

  const DividerHeading = ({ title, details }) => {
    return (
      <StyledContainer title={title}>
        <Box sx={{ display: 'flex' }} gap={'95px'}>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: 'poppins_medium',
              opacity: '1',
            }}
          >
            {title}
          </Typography>
          {!details ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </Box>
      </StyledContainer>
    )
  }

  const StyledContainer = styled(Box)(({ theme, title }) => ({
    margin: '20px 0',
    borderBottom: theme.borders.grayLight,
    '&::after': {
      borderRadius: '2px',
      content: '""',
      display: 'block',
      marginTop: '5px',
      width: `${title?.length - 2}rem`,
      height: '4px',
      backgroundColor: theme.palette.common.green,
      bottom: '10px',
    },
  }))

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])
  const handleSubmit = (e) => {
    e?.preventDefault()
    setLoading(true)
    http
      .get(
        `/attendance-activities/schools/status?date=${date}&districtId=${districtId}&atdoPointId=${atdoPointId}`,
      )
      .then((res) => {
        setCount(res?.data?.count)
        let result = res?.data?.data
        result?.forEach((item) => {
          let vals = Object?.values(item)

          for (let i = 0; i < vals?.length; i++) {

            if (vals[i]?.status?.toString() == 'true') {
              completed = completed + 1
            }
            if (vals[i]?.status?.toString() == 'false') {
              incompleted = incompleted + 1
            }
          }
          item['completedCount'] = completed
          item['incompletedCount'] = incompleted
          let percent = (completed / (completed + incompleted)) * 100
          item['percent'] = percent
          item['percentage'] =
            (Number.isInteger(percent) ? percent : percent?.toFixed(1)) + '%'
          completed = 0
          incompleted = 0
          return item
        })
        setActivities(result?.slice(0, 10))

        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }

  // const handleReport = () => {
  //   http.get(
  //     `/attendance-activities/schools/status?date=${date}&districtId=${districtId}&atdoPointId=${atdoPointId}&search=${search}`,
  //   )
  // }

  const handleReport = (e) => {
    e?.preventDefault()
    try {
      http
        .get(
          `/attendance-activities/activity/overall-report?date=${date}&districtId=${districtId}&atdoPointId=${atdoPointId}&search=${search}`,
        )
        .then((res) => {
          const arr = new Uint8Array(res.data?.data)
          const blob = new Blob([arr], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const file = window.URL.createObjectURL(blob)
          let link = document.createElement('a')
          link.href = file
          link.download = 'report.xlsx'
          link.click()
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  async function handlePagination(e, v) {
    try {
      setPage(page + 1)
      http
        .get(
          `/attendance-activities/schools/status?date=${date}&districtId=${districtId}&atdoPointId=${atdoPointId}&search=${search}&limit=10&offset=${
            page * 10
          }`,
        )
        .then((res) => {
          let result = res?.data?.data
          result?.forEach((item) => {
            let vals = Object?.values(item)

            for (let i = 0; i < vals?.length; i++) {

              if (vals[i]?.status?.toString() == 'true') {
                completed = completed + 1
              }
              if (vals[i]?.status?.toString() == 'false') {
                incompleted = incompleted + 1
              }
            }
            item['completedCount'] = completed
            item['incompletedCount'] = incompleted
            let percent = (completed / (completed + incompleted)) * 100
            item['percent'] = percent
            item['percentage'] =
              (Number.isInteger(percent) ? percent : percent?.toFixed(1)) + '%'
            completed = 0
            incompleted = 0
            return item
          })
          setActivities(activities?.concat(result))
        })
    } catch (err) {
    }
  }

  const getSchools = () => {
    setSearchLoading(true)
    try {
      http
        .get(
          `/attendance-activities/schools/status?date=${date}&districtId=${districtId}&atdoPointId=${atdoPointId}&search=${search}`,
        )
        .then((res) => {
          setPage(1)
          setCount(res?.data?.count)
          let result = res?.data?.data
          result?.forEach((item) => {
            let vals = Object?.values(item)

            for (let i = 0; i < vals?.length; i++) {

              if (vals[i]?.status?.toString() == 'true') {
                completed = completed + 1
              }
              if (vals[i]?.status?.toString() == 'false') {
                incompleted = incompleted + 1
              }
            }
            item['completedCount'] = completed
            item['incompletedCount'] = incompleted
            let percent = (completed / (completed + incompleted)) * 100
            item['percent'] = percent
            item['percentage'] =
              (Number.isInteger(percent) ? percent : percent?.toFixed(1)) + '%'
            completed = 0
            incompleted = 0
            return item
          })
          setActivities(result?.slice(0, 10))

          setSearchLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }
  useEffect(() => {
    getSchools()
  }, [search])

  const handleSearch = (e) => {
    e?.preventDefault()
    getSchools()
  }

  if (loading) {
    return <CircularProgress />
  }
  return (
    <div>
      {' '}
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Activity Dashboard', to: '/activity-dashboard' },
              {
                name: 'Overall Schools Activity Report',
                to: null,
              },
            ]}
          />
        }
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <form className="mb-4" onSubmit={handleSearch}>
          <TextField
            label="Search by School Name"
            sx={{ width: '300px' }}
            hookForm={false}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={search}
            onChange={({ target }) => {
              setSearch(target.value)
            }}
          />
        </form>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {userType == 'Admin' || userType == 'DD' ? (
            <form onSubmit={(e) => handleSubmit(e)}>
              <Stack
                gap={3}
                direction="row"
                sx={{
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <FormControl sx={{ width: '170px' }}>
                  <InputLabel id="districtId">Select District</InputLabel>
                  <Select
                    labelId="districtId"
                    id="districtId"
                    label="Select District"
                    value={districtId}
                    onChange={(e) => {
                      setAtdoPointId('')
                      setDistrictId(e?.target?.value)
                    }}
                  >
                    {districts?.map((item) => {
                      return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: '170px' }}>
                  <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                  <Select
                    labelId="atdoPointId"
                    id="atdoPointId"
                    label="Select ATDO Point"
                    value={atdoPointId}
                    onChange={(e) => setAtdoPointId(e?.target?.value)}
                  >
                    {atdoPoints?.map((item) => {
                      return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ width: '145px', height: '50px' }}>
                    <DatePicker
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue)
                      }}
                      inputFormat="DD MMM, YYYY"
                      maxDate={new Date()}
                      renderInput={(params) => <TextField {...params} />}
                    />{' '}
                  </Box>
                </LocalizationProvider>{' '}
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'black !important',
                    height: '50px',
                    width: '130px',
                    borderRadius: '10px',
                  }}
                  type="submit"
                >
                  <Typography
                    sx={{
                      fontFamily: 'poppins_semibold',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    Apply
                  </Typography>
                </Button>
              </Stack>
            </form>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '145px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                </Box>
              </LocalizationProvider>{' '}
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {(userType == 'Admin' || userType == 'DD') && (
          <Button
            variant="contained"
            sx={{
              mt: '15px',
              backgroundColor: 'black !important',
              height: '50px',
              width: '160px',
              borderRadius: '10px',
            }}
            onClick={handleReport}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'white',
                fontSize: '14px',
              }}
            >
              Download Report
            </Typography>
          </Button>
        )}
      </Box>
      {searchLoading ? (
        <CircularProgress />
      ) : (
        <InfiniteScroll
          dataLength={activities?.length ? activities?.length : 0}
          next={handlePagination}
          hasMore={activities?.length < count}
          loader={<CircularProgress />}
          style={{ overflow: 'unset' }}
        >
          <Box>
            {activities?.length == 0 ? (
              <SchoolAnalyticsEmptyScreen />
            ) : (
              activities?.map((item, ind) => {
                const data = [
                  { name: 'Group A', value: item?.percent },
                  { name: 'Group B', value: 100 - item?.percent },
                  // { name: 'Group C', value: 300 },
                  // { name: 'Group D', value: 200 },
                ]
                const COLORS = ['#68C689', '#DBDBDB']

                return (
                  <Box
                    sx={{
                      boxShadow: '0px 3px 16px #2A32710F',
                      borderRadius: '10px',
                      padding: '28px',
                      marginTop: '28px',
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={3}
                        sx={{ display: 'flex', alignItems: 'center' }}
                        gap={'18px'}
                      >
                        <Box>
                          <img src={'/images/SchoolWithBackground.png'} />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: 'poppins_semibold',
                              fontSize: '18px',
                            }}
                          >
                            {item?.school?.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: 'poppins_semibold',
                              fontSize: '14px',
                              color: '#68C689',
                              marginTop: '3px',
                            }}
                          >
                            {item?.school?.schoolId}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={1.25}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          ATDO Point
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                            marginTop: '3px',
                          }}
                        >
                          {item?.school?.atdoPoint?.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1.25}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Head master name
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                            marginTop: '3px',
                          }}
                        >
                          {item?.headMaster?.fullName}
                        </Typography>
                      </Grid>{' '}
                      <Grid
                        item
                        xs={1.25}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Mobile number
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                            marginTop: '3px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item?.headMaster?.mobileNumber}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginRight: '8px',
                        }}
                      >
                        <Box
                          sx={{
                            border: '1px solid #01010129',
                            padding: '18px',
                            borderRadius: '10px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'poppins_medium',
                              fontSize: '12px',
                              opacity: '0.5',
                            }}
                          >
                            Activities Incomplete
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: 'poppins_semibold',
                              fontSize: '22px',
                              marginTop: '5px',
                            }}
                          >
                            {item?.incompletedCount}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            border: '1px solid #01010129',
                            padding: '18px',
                            borderRadius: '10px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'poppins_medium',
                              fontSize: '12px',
                              opacity: '0.5',
                            }}
                          >
                            Activities Completed
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: 'poppins_semibold',
                              fontSize: '22px',
                              marginTop: '5px',
                            }}
                          >
                            {item?.completedCount}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '28px',
                            // width: '100%',
                            // flexWrap: 'wrap',
                          }}
                        >
                          <PieChart
                            width={200}
                            height={100}
                            style={{
                              fontSize: '24px',
                              fontFamily: 'poppins_semibold',
                            }}
                          >
                            <Pie
                              data={data}
                              cx={80}
                              cy={80}
                              startAngle={180}
                              endAngle={0}
                              innerRadius={60}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {data.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                            <text
                              x={87}
                              y={80}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              {item?.percentage}
                            </text>
                          </PieChart>
                          <center>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontFamily: 'poppins_medium',
                                marginLeft: '-28px',
                              }}
                            >
                              Activity Percentage
                            </Typography>
                          </center>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      onClick={() => {
                        handleClick(ind)
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <DividerHeading
                        title={'Activity Details'}
                        details={isOpen[ind]}
                      />
                    </Box>
                    {itemLoading ? (
                      <CircularProgress />
                    ) : (
                      isOpen[ind] && (
                        <Box>
                          <Box
                            className="linksContainer"
                            sx={{ display: 'flex' }}
                            gap={'18px'}
                          >
                            <NavLink
                              to={'/activity-dashboard/activity-feed'}
                              end
                            >
                              Attendance Activities
                            </NavLink>
                            <NavLink
                              to={
                                '/activity-dashboard/activity-feed/academic-extra-curriculars'
                              }
                            >
                              Academic & Extra-Curricular Activities
                            </NavLink>
                            <NavLink
                              to={
                                '/activity-dashboard/activity-feed/food-menu-implementation'
                              }
                            >
                              Food Menu Implementation Activities
                            </NavLink>
                          </Box>
                          <Outlet context={[item]} />
                        </Box>
                      )
                    )}
                  </Box>
                )
              })
            )}
          </Box>
        </InfiniteScroll>
      )}
    </div>
  )
}

export default ActivityFeed
