import { AppBar, Box, Typography } from '@mui/material'
import { NavLink, Outlet } from 'react-router-dom'
import '../../../App.css'

const MedicalMasterData = () => {
  return (
    <div>
      <AppBar
        style={{
          // position: 'absolute',
          top: 74,
          left: '162px',
          backgroundColor: '#F4F4F4',
          boxShadow: 'none',
        }}
      >
        <nav
          style={{
            display: 'flex',
            paddingLeft: '38px',
          }}
        >
          {/* {userType == 'Admin' && (
          <> */}
          <NavLink
            className={'masterDataNavLink'}
            to={'/medical-master-data'}
            end
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              Medical Issues
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
        </nav>
      </AppBar>
      <Box sx={{ marginTop: '50px' }}>
        <Outlet />
      </Box>
    </div>
  )
}

export default MedicalMasterData
