import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { http } from 'services/http'

const FoodMenu = () => {
  const [foodMenu, setFoodMenu] = useState()
  const images = {
    Special: '/images/Special Menu.png',
    Snacks: '/images/snacks.png',
    'Breakfast Drink': '/images/RagiMalt  Boost & Milk.png',
    Lunch: '/images/Lunch.png',
    Dinner: '/images/Dinner.png',
    Breakfast: '/images/Breakfast.png',
  }

  const { data, isLoading } = useQuery(
    ['foodMenu'],
    () =>
      http.get(`/food-courses`).then((res) => {
        console.log(res)
        return res?.data?.foodCourses
      }),
    {
      onSuccess: (data) => {
        setFoodMenu(data)
      },
    },
  )
  if (foodMenu) {
    foodMenu.forEach((item) => {
      item.image = images[item.name]
    })
  }
  if (isLoading) {
    return <CircularProgress />
  }
  console.log(foodMenu)
  return (
    <div>
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: '-15px',
        }}
      >
        {foodMenu?.map((item) => {
          return (
            <Grid item xl={4} lg={4} md={4}>
              <Card
                title={item?.name}
                itemsCount={item?.foodMenu?.length}
                img={item?.image}
                id={item?.id}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default FoodMenu

export const Card = ({ title, img, itemsCount, id }) => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        borderRadius: '10px',
        boxShadow: '0px 3px 13px #2A32711A',
        margin: '28px 28px 0px 0px',
      }}
    >
      <Box
        onClick={() => {
          navigate({
            pathname: '/master-data/food-menu-details',
            search: `?id=${id}&course=${title}`,
          })
        }}
        sx={{
          padding: '30px',
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ padding: '10px', marginBottom: '27px' }}>
          <Box>
            <img src={img} />
          </Box>
          <Box sx={{ marginTop: '8px' }}>
            <Typography
              sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: 'poppins_medium',
                marginTop: '8px',
              }}
            >
              Number of items added : {itemsCount}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <IconButton sx={{ color: 'black' }}>
            <EastIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
