import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { DeleteButton, EditButton } from 'components/IconButtons'
import Table from 'components/Mui/TableComponent'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { http } from 'services/http'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import IssueTypesEmptyScreen from './IssueTypesEmptyScreen'
import IssueTypesForm from './IssueTypesForm'

const IssueTypes = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [issueTypes, setIssueTypes] = useState([])
  const [open, setOpen] = React.useState(false)
  const [formData, setFormData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  useEffect(() => {
    modalOpen == false && queryClient.invalidateQueries('issues')
  }, [modalOpen])

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const handleDelete = (id) => {
    http
      .delete(`/issues/${id}`)
      .then((res) => {
        queryClient.invalidateQueries('issues')
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    ;(search?.length != 0 || search == '') && getIssues()
  }, [search])

  async function getIssues() {
    setSearchLoading(true)
    try {
      http.get(`/issues?search=${search}`).then((res) => {
        console.log(res)
        setPage(1)
        setCount(res?.data?.result?.length)
        setIssueTypes(res?.data?.result?.slice(0, 10))
        setSearchLoading(false)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const { data, isLoading } = useQuery(
    ['issues'],
    () =>
      http.get(`/issues`).then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        return res?.data?.result
      }),
    {
      onSuccess: (data) => {
        setIssueTypes(data?.slice(0, 10))
      },
    },
  )

  const columns = [
    {
      title: 'Issue',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Category',
      dataIndex: 'category.name',
      key: 'category.name',
    },

    {
      title: 'Added on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (colData, row) => (
        <Box display="flex" gap="10px">
          <EditButton
            onClick={() => {
              handleEditClick(row)
            }}
          />
          <DeleteButton onClick={() => handleDelete(row?.id)} />
        </Box>
      ),
    },
  ]

  const buttonSX = {
    '&:hover': {
      borderColor: '#24272C',
      color: '#24272C',
      background: 'none',
    },
    // marginTop: '28px',
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    borderColor: '#24272C',
    color: '#24272C',
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/issues?limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&search=${search}`,
      )
      console.log(res)
      setIssueTypes(res.data?.result)
      setLoading(false)
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
      setLoading(false)
    }
  }

  if (isLoading || loading || !issueTypes) {
    return <CircularProgress />
  }
  return (
    <div>
      <Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <form className="mb-4" onSubmit={() => {}}>
              <TextField
                label="Search by Issue Name"
                sx={{ width: '494px', marginBottom: '1.5rem' }}
                hookForm={false}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => {}}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
            </form>
            <Box sx={{ display: 'flex' }} gap={'18px'}>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: '10px',
                  borderColor: 'black !important',
                  background: 'transparent !important',
                }}
                onClick={() => {
                  setFormData(null)
                  toggleDrawer(true)
                }}
              >
                {' '}
                <Typography
                  sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
                >
                  + Add Issue
                </Typography>
              </Button>
            </Box>
          </Box>
          {issueTypes?.length == 0 ? (
            <IssueTypesEmptyScreen title="Issues" section={'issues'} />
          ) : (
            <>
              <Table
                key={'Issues'}
                dataSource={issueTypes}
                rowKey={'id'}
                columns={columns}
                loading={isLoading}
              />
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={count > 10 ? Math.ceil(count / 10) : 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePagination}
                  page={page}
                />
              </Box>
            </>
          )}

          <Drawer
            anchor="right"
            open={open}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
          >
            <StyledDrawerHeader>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {formData ? 'Edit Issue' : 'Add Issue'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  opacity: '0.5',
                  cursor: 'pointer',
                }}
                onClick={() => toggleDrawer(false)}
              >
                Cancel
              </Typography>
            </StyledDrawerHeader>
            <StyledDrawerContent>
              <Box sx={{ width: '550px' }}>
                <IssueTypesForm data={formData} toggleDrawer={toggleDrawer} />
              </Box>
            </StyledDrawerContent>
          </Drawer>
        </Box>
      </Box>
    </div>
  )
}

export default IssueTypes
