import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Paper,
  Typography,
} from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import DividerHeading from 'components/DividerHeading'
import { DeleteButton, EditButton } from 'components/IconButtons'
import PageHeader from 'components/Mui/PageHeader'
import Table from 'components/Mui/TableComponent'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { http } from 'services/http'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import FoodMenuEmptyScreen from './FoodMenuEmptyScreen'
import FoodMenuForm from './FoodMenuForm'

const FoodMenuInnerPage = () => {
  const [params] = useSearchParams()
  const [title, setTitle] = useState()
  const [open, setOpen] = React.useState(false)
  const userType = localStorage.getItem('user_type')

  const [menuItems, setMenuItems] = useState()
  const foodId = params.get('id')
  const [formData, setFormData] = useState([])
  const name = params.get('course')
  const queryClient = useQueryClient()

  console.log(name)
  useEffect(() => {
    setTitle(name)
  }, [name])

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const { data, isLoading } = useQuery(
    ['foodMenuItems'],
    () =>
      http.get(`/food-menu?foodCourseId=${foodId}`).then((res) => {
        console.log(res)
        return res?.data?.foodMenus
      }),
    {
      onSuccess: (data) => {
        console.log(data)
        setMenuItems(data)
      },
    },
  )

  const handleDelete = (id) => {
    http
      .delete(`/food-menu/${id}`)
      .then((res) => {
        queryClient.invalidateQueries('foodMenuItems')
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message)
      })
  }

  const buttonSX = {
    '&:hover': {
      borderColor: '#24272C',
      color: '#24272C',
      background: 'none',
    },
    marginTop: '28px',
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    borderColor: '#24272C',
    color: '#24272C',
  }
  const images = {
    Special: '/images/Special Menu.png',
    Snacks: '/images/snacks.png',
    'Breakfast Drink': '/images/RagiMalt  Boost & Milk.png',
    Lunch: '/images/Lunch.png',
    Dinner: '/images/Dinner.png',
    Breakfast: '/images/Breakfast.png',
  }

  const columns = [
    {
      title: 'Menu Items',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      render: (row) => {
        return row ? row : 'NA'
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (colData, row) => (
        <Box display="flex" gap="10px">
          <EditButton
            onClick={() => {
              handleEditClick(row)
            }}
          />
          <DeleteButton
            onClick={() => {
              handleDelete(row?.id)
            }}
          />
        </Box>
      ),
    },
  ]

  if (isLoading) {
    return <CircularProgress />
  }
  console.log(title)
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              {
                name: 'Food Menu',
                to:
                  userType == 'Admin'
                    ? '/master-data/food-menu'
                    : '/master-data',
              },
              { name: title, to: null },
            ]}
          />
        }
      />
      <Box>
        <Box>
          <Card title={title} img={images[title]} />
        </Box>
        {menuItems?.length != 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              // marginTop: '2.2rem',
            }}
          >
            <Button
              sx={buttonSX}
              variant="outlined"
              onClick={() => {
                setFormData(null)
                toggleDrawer(true)
              }}
            >
              + Add Food Item
            </Button>{' '}
          </Box>
        )}
        <Box sx={{ marginTop: menuItems?.length != 0 ? '-2.2rem' : '' }}>
          <DividerHeading title={`${title} Menu Items`} />
          {menuItems?.length != 0 ? (
            <Table
              key={'Menu Items'}
              dataSource={menuItems}
              rowKey={'id'}
              columns={columns}
              loading={isLoading}
            />
          ) : (
            <FoodMenuEmptyScreen title={title} foodCourseId={foodId} />
          )}
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            {formData ? 'Edit Food Item' : 'Add Food Item'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <FoodMenuForm
              data={formData}
              toggleDrawer={toggleDrawer}
              foodCourseId={foodId}
            />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  )
}

export default FoodMenuInnerPage

const Card = ({ title, img }) => {
  return (
    <Box
      sx={{
        borderRadius: '10px',
        width: '400px',
        boxShadow: '0px 3px 13px #2A32711A',
      }}
    >
      <Box
        sx={{ padding: '31px 18px', display: 'flex', alignItems: 'center' }}
        gap={'13px'}
      >
        <Box>
          <img src={img} />
        </Box>
        <Box>
          <Typography sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
