import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const GCC = () => {
  const navigate = useNavigate()

  return (
    <Box p={2} pt={0}>
      <Box>
        <Box>
          <Grid container spacing={3} mt={3}>
            {tabData?.map((item, index) => (
              <Grid key={index} item xs={12} md={3}>
                <Box
                  width="100%"
                  height="100%"
                  sx={{
                    boxShadow: '0px 3px 13px #2A32711A',
                    cursor: 'pointer',
                    borderRadius: '10px',
                  }}
                  onClick={() => navigate(item.path)}
                >
                  <Box p={2}>
                    <img
                      src={item?.img}
                      style={{ width: '65px', height: '65px' }}
                    />
                    <Box
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box p={1}>
                        <Typography
                          sx={{
                            fontSize: '15px',
                            fontFamily: 'poppins_medium',
                            opacity: '0.7',
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box p={1}>
                        <ArrowForwardIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default GCC

const tabData = [
  {
    title: 'Dashboard',
    img: '/images/GCC-dashboard.png',
    path: '/gcc/dashboard',
  },
  {
    title: 'Stock Inventory Balance',
    img: '/images/stock-inventory-balance.png',
    path: '/gcc/stock-inventory-balance',
  },
  {
    title: 'Stock Usage',
    img: '/images/GCC-stock-usage.png',
    path: '/gcc/stock-usage',
  },
  {
    title: 'Stock Indents',
    img: '/images/stockIndents.png',
    path: '/gcc/stock-indents',
  },
]
