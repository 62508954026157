import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import DividerHeading from 'components/DividerHeading'
import DetailsBanner from 'components/Mui/DetailsBanner'
import PageHeader from 'components/Mui/PageHeader'
import { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { http } from 'services/http'
import { useQuery } from 'react-query'

const SchoolAnalytics = () => {
  const { pathname } = useLocation()
  const [schoolInfo, setSchoolInfo] = useState()
  const [type, setType] = useState()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [date, setDate] = useState(new Date())
  const url = location.pathname
  const id = localStorage.getItem('schoolId')
  // setId(searchParams.get('id'))
  // if (url == '/schools/school-analytics/attendance-activities') {
  //   setType('Attendance')
  // }
  //  else if (url == '/schools/school-analytics/academic-extra-curriculars') {
  // setType('Academic')
  // } else if (url == '/schools/school-analytics/food-menu-implementation') {
  // setType('FoodMenu')
  // }
  // if (url == '/schools/school-analytics/new-programme') {
  //   setType('NewProgram')
  // }
  const { data, isLoading } = useQuery(
    ['schoolInfo'],
    () =>
      http.get(`/statistics/school/${id}/info`).then((res) => {
        console.log(res)
        return res?.data?.school
      }),
    {
      onSuccess: (data) => {
        setSchoolInfo(data)
      },
    },
  )

  useEffect(() => {
    localStorage.setItem('selectedDate', date)
  }, [date])

  console.log(moment(new Date(date)).format('YYYY-MM-DD'))
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Schools', to: '/schools' },
              { name: 'School Analytics', to: null },
            ]}
          />
        }
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ marginTop: '20px' }}>
            <DetailsBanner
              items={[
                { name: 'School ID', value: schoolInfo?.schoolId },
                { name: 'School Name', value: schoolInfo?.name },
                { name: 'ATDO Point', value: schoolInfo?.atdoPoint?.name },
                { name: 'School Type', value: schoolInfo?.schoolType },
                { name: 'Mandal', value: schoolInfo?.mandal?.name },
                { name: 'District', value: schoolInfo?.district?.name },
              ]}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box
                sx={{
                  marginTop: '30px',
                  padding: '30px',
                  borderRadius: '10px',
                  boxShadow: '0px 3px 13px #2A32711A',
                  // width: '600px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    gap={'18px'}
                  >
                    <img src={'/images/schoolAssets.png'} />
                    <Typography
                      sx={{ fontFamily: 'poppins_semibold', fontSize: '14px' }}
                    >
                      School Assets
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate('/schools/assets')
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: 'poppins_semibold', fontSize: '13px' }}
                    >
                      Click Here
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  marginTop: '30px',
                  padding: '30px',
                  borderRadius: '10px',
                  boxShadow: '0px 3px 13px #2A32711A',
                  // width: '600px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    gap={'18px'}
                  >
                    <img src={'/images/schoolStock.png'} />
                    <Typography
                      sx={{ fontFamily: 'poppins_semibold', fontSize: '14px' }}
                    >
                      School Stock
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate('/schools/stock')
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: 'poppins_semibold', fontSize: '13px' }}
                    >
                      Click Here
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '30px',
          alignItems: 'center',
        }}
        gap={2}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: '190px', height: '50px' }}>
            <DatePicker
              value={moment(new Date(date)).format('YYYY-MM-DD')}
              onChange={(newValue) => {
                setDate(moment(new Date(newValue)).format('YYYY-MM-DD'))
              }}
              inputFormat="DD MMM, YYYY"
              maxDate={new Date()}
              renderInput={(params) => <TextField {...params} />}
            />{' '}
          </Box>
        </LocalizationProvider>{' '}
        {pathname &&
          pathname == '/schools/school-analytics/attendance-activities' && (
            <Link
              to={`/schools/school-details?id=${id}`}
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                sx={{ height: '50px', backgroundColor: 'black !important' }}
              >
                School Information
              </Button>
            </Link>
          )}
      </Box>
      <Box sx={{ marginTop: '-2.2rem' }}>
        <DividerHeading title={'School Analytics'} />
        <Box className="linksContainer" sx={{ display: 'flex' }} gap={'18px'}>
          <NavLink to={'/schools/school-analytics/attendance-activities'}>
            Attendance Activities
          </NavLink>
          <NavLink to={'/schools/school-analytics/academic-extra-curriculars'}>
            Academic & Extra-Curricular Activities
          </NavLink>
          <NavLink to={'/schools/school-analytics/food-menu-implementation'}>
            Food Menu Implementation Activities
          </NavLink>
          <NavLink to={'/schools/school-analytics/new-programme'}>
            New Programme
          </NavLink>
        </Box>
      </Box>
      <Outlet context={[date]} />
    </div>
  )
}

export default SchoolAnalytics
