import { ThemeProvider } from '@mui/material'
import Layout from 'Layout'
import Login from 'pages/LoginAndRegistration/Login'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import muiTheme from 'theme/muiTheme'
import AksharaJyothiDashboard from 'pages/AksharaJyothiDashboard/AksharaJyouthyMobile'
import AksharaJyothiTests from 'pages/AksharaJyothiDashboard/AksharaJyouthyMobile/testsMobile'
import AksharaJyothiComparison from 'pages/AksharaJyothiDashboard/AksharaJyouthyMobile/performanceComparison'
import AksharaJyothiPerformanceReport from 'pages/AksharaJyothiDashboard/AksharaJyouthyMobile/performanceReport'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

function App() {
  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route
                path="/akshara-jyothi-dashboard"
                element={<AksharaJyothiDashboard />}
              />
              <Route
                path="/akshara-jyothi-dashboard/tests"
                element={<AksharaJyothiTests />}
              />
              <Route
                path="/akshara-jyothi-dashboard/test-comparison"
                element={<AksharaJyothiComparison />}
              />
              <Route
                path="/akshara-jyothi-dashboard/performance-report"
                element={<AksharaJyothiPerformanceReport />}
              />
              <Route path="/*" element={<Layout />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  )
}

export default App
