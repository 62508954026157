import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  CircularProgress,
  IconButton,
  Pagination,
} from '@mui/material'
import { ViewButton } from 'components/IconButtons/IconButtons'
import TextField from 'components/Mui/Form/TextField'
import Table from 'components/Mui/TableComponent'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { http } from 'services/http'

const StockInventoryBalance = () => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [count, setCount] = useState()
  const [schools, setSchools] = useState()
  const [page, setPage] = useState(1)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const navigate = useNavigate()
  const iconSX = {
    '&:hover': {
      cursor: 'pointer',
      color: '#0A1160',
    },
    color: '#878787',
  }

  async function getSchools() {
    setSearchLoading(true)
    try {
      http
        .get(`/statistics/master-data/schools?search=${search}`)
        .then((res) => {
          setPage(1)
          setCount(res?.data?.schools?.length)
          setSchools(res?.data?.schools?.slice(0, 10))
          setSearchLoading(false)
        })
    } catch (err) {
    }
  }
  useEffect(() => {
    getSchools()
  }, [search])
  const handleSearch = (e) => {
    e?.preventDefault()
    getSchools()
  }

  const { data, isLoading } = useQuery(
    ['schoolDetails'],
    () =>
      http
        .get(`/statistics/master-data/schools`)
        .then((res) => {
          return res?.data?.schools
        })
        .catch((err) => {
          enqueueSnackbar('Network Error', {
            variant: 'error',
          })
        }),
    {
      onSuccess: (data) => {
        setCount(data?.length)
        setSchools(data?.slice(0, 10))
      },
    },
  )


  const columns = [
    {
      title: 'School ID',
      dataIndex: 'schoolId',
      key: 'schoolId',
    },
    {
      title: 'School Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ATDO Point',
      dataIndex: 'atdoPoint.name',
      key: 'atdoPoint.name',
    },
    {
      title: 'School Type',
      dataIndex: 'schoolType',
      key: 'schoolType',
    },
    // {
    //   title: 'Mandal',
    //   dataIndex: 'mandal.name',
    //   key: 'mandal.name',
    // },
    {
      title: 'District',
      dataIndex: 'district.name',
      key: 'district.name',
    },
    {
      title: 'Students',
      dataIndex: 'totalStudents',
      key: 'totalStudents',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        return (
          <Box display="flex" gap="10px">
            <ViewButton
              onClick={() => {
                localStorage.setItem('schoolId', row?.id)
                localStorage.setItem('schoolID', row?.schoolId)

                navigate(`/gcc/stockitem-table/${row?.schoolId}`)
              }}
            />
   
          </Box>
        )
      },
    },
  ]

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/statistics/master-data/schools?limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&search=${search}`,
      )
      setSchools(res?.data?.schools)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  if (isLoading || loading || !schools) {
    return <CircularProgress />
  }
  return (
    <Box sx={{ marginTop: '1rem' }}>
      <form className="mb-4" onSubmit={handleSearch}>
        <TextField
          label="Search by School name"
          sx={{ width: '494px', marginBottom: '1.5rem' }}
          hookForm={false}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
      </form>
      {schools?.length == 0 ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'schools'}
            dataSource={schools}
            rowKey={'id'}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />
          </Box>
        </>
      )}

    </Box>
  )
}

export default StockInventoryBalance;
