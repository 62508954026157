import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useOutletContext } from 'react-router-dom'

const FeedAcademic = () => {
  const [item] = useOutletContext()
  return (
    <div style={{ marginTop: '15px' }}>
      <Grid container spacing={2}>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.PhysicalActivity?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Morning Physical Activity
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.PhysicalActivity?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.PhysicalActivity?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.PhysicalActivity?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.MorningStudyHour?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Morning Study Hour
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.MorningStudyHour?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.MorningStudyHour?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.MorningStudyHour?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.Prayer?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Prayer
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.Prayer?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.Prayer?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment.utc(item?.Prayer?.updatedAt)?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.NightStudyHour?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Night Study Hour
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.NightStudyHour?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.NightStudyHour?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.NightStudyHour?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.SportsActivity?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Sports Activity
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.SportsActivity?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.SportsActivity?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.SportsActivity?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2.4}>
          {' '}
          <Box
            sx={{
              height: '76%',
              border: item?.SpecialClass?.status
                ? '1px solid #01010129'
                : '1px solid #AF1131',
              padding: '12px',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '16px',
              }}
            >
              Special Class (Bridge/SSC Courses)
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '7px',
              }}
              gap={'13px'}
            >
              {item?.SpecialClass?.status ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#3A36F9',
                    }}
                  >
                    YES
                  </Typography>
                  <DoneIcon
                    sx={{ color: '#3A36F9', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'7px'}>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '15px',
                      color: '#AF1131',
                    }}
                  >
                    NO
                  </Typography>
                  <ClearIcon
                    sx={{ color: '#AF1131', height: '18.5px', width: '18.5px' }}
                  />
                </Box>
              )}
              {item?.SpecialClass?.updatedAt && (
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '13px',
                    opacity: '0.7',
                  }}
                >
                  <i>
                    {moment
                      .utc(item?.SpecialClass?.updatedAt)
                      ?.format('hh:mm A')}
                  </i>
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default FeedAcademic
