import { alpha, darken, lighten } from '@mui/material'
import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import '../App.css'

const borderRadius = {
  small: '5px',
  large: '10px',
}

const PRIMARY_FONT = 'Avenir'
const SECONDARY_FONT = 'Poppins'

const themeColors = {
  primary: '#1E19F5',
  secondary: '#121212',
  secondaryLight: '#1212121A',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#BC2C3D',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#000C57',
  yellow: '#FFAF20',
  green: '#68C689',
  grayLight: '#F2F2F2',
  pureBlack: '#000000',
  pureWhite: '#ffffff',
  secondaryDark: '#EFEFEF',
  offsetBlue: '#149ECD',
}

const colors = {
  primary: {
    main: themeColors.primary,
    light: alpha(themeColors.primary, 0.5),
    lighter: alpha(themeColors.primary, 0.1),
  },
  secondary: {
    main: themeColors.secondary,
    light: themeColors.grayLight,
    lighter: themeColors.secondaryLight,
    dark: themeColors.secondaryDark,
  },
  common: {
    green: themeColors.green,
    yellow: themeColors.yellow,
    black: themeColors.pureBlack,
    white: themeColors.pureWhite,
    blue: themeColors.offsetBlue,
  },
  error: {
    lighter: alpha(themeColors.error, 0.1),
    light: lighten(themeColors.error, 0.3),
    main: themeColors.error,
    dark: darken(themeColors.error, 0.2),
  },
}

const borders = {
  primary: `1px solid ${themeColors.primary}`,
  grayLight: `1px solid ${themeColors.secondaryLight}`,
}

// A custom theme for this app
const muiTheme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'Avenir', 'sans-serif'].join(','),
  },
  borders: {
    ...borders,
  },
  palette: {
    primary: {
      dark: '#1E19F5',
      main: colors.primary.main,
      light: colors.primary.light,
      lighter: colors.primary.lighter,
    },
    secondary: {
      main: '#121212',
      light: colors.secondary.light,
      dark: themeColors.secondaryDark,
      lighter: themeColors.secondaryLight,
    },
    common: {
      green: themeColors.green,
      yellow: themeColors.yellow,
      blue: themeColors.offsetBlue,
      black: themeColors.black,
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#121212',
      secondary: '#959595',
      disabled: '#12121280',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#121212',
          fontSize: '14px',
          fontWeight: 500,
          fontFamily: SECONDARY_FONT,
        },
        h1: {
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: SECONDARY_FONT,
        },
        h3: {
          fontSize: '14px',
          fontWeight: 600,
          fontFamily: SECONDARY_FONT,
        },
        h5: {
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: SECONDARY_FONT,
        },
        h6: {
          fontSize: '13px',
          fontWeight: 600,
          fontFamily: SECONDARY_FONT,
        },
        paragraph: {
          fontSize: '12px',
          marginBottom: 0,
        },
        body2: {
          fontWeight: 600,
        },
        subtitle1: {
          fontSize: '10px',
          fontWeight: 400,
          color: '#959595',
        },
        subtitle2: {
          fontSize: '11px',
          fontWeight: 400,
          color: '#959595',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: SECONDARY_FONT,
          height: '40px',
          textTransform: 'capitalize',
          fontWeight: 600,
          borderRadius: borderRadius.small,
          '&.MuiButton-sizeSmall': {
            padding: '6px 8px',
            height: '32px',
            fontSize: '11px',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: SECONDARY_FONT,
          '&.MuiButton-textSizeSmall': {
            padding: '6px 8px',
            height: '32px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          minHeight: '50px',
          borderRadius: borderRadius.large,
          '&.MuiInputBase-sizeSmall': {
            minHeight: '30px',
          },
          '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 2,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(colors.common.black, 0.15),
            '& legend': {
              '& > span': {
                paddingRight: 2,
              },
            },
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(colors.common.black, 0.3),
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main,
            borderWidth: '0.5px',
            '& :hover': {
              borderColor: colors.primary.main,
              borderWidth: '0.5px',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1,
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
            {
              right: 14,
            },
        },
        clearIndicator: {
          background: colors.error.lighter,
          color: colors.error.main,
          marginRight: 8,

          '&:hover': {
            background: colors.error.lighter,
            color: colors.error.dark,
          },
        },
        popupIndicator: {
          color: alpha(colors.common.black, 0.5),

          '&:hover': {
            background: colors.primary.lighter,
            color: colors.primary.main,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: '20px',
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              color: colors.common.green,
              '& .MuiSwitch-thumb': {
                marginLeft: '0px',
              },
              '& + .MuiSwitch-track': {
                opacity: '1',
                border: `0.5px solid ${colors.common.green}`,
                background: '#EDEDED 0% 0% no-repeat padding-box',
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: colors.common.green,
              opacity: '0.5',
            },
          },
          '& .MuiSwitch-thumb': {
            width: '18px',
            height: '16px',
            borderRadius: '5px',
            marginTop: '2px',
            boxShadow: 'none',
            marginLeft: '2px',
          },
          '& .MuiSwitch-track': {
            borderRadius: '5px',
            width: '40px',
            height: '20px',
            backgroundColor: '#EDEDED',
            paddingLeft: '12px',
            opacity: '1',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.5em',
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            borderRadius: '3px',

            '&:hover': {
              background: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          color: '#959595',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: borders.grayLight,
          fontFamily: PRIMARY_FONT,
          fontSize: '12px',
          fontWeight: 600,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.grayLight,
          '& th': {
            lineHeight: '20px',
            fontSize: '12px',
            fontWeight: 600,
            fontFamily: SECONDARY_FONT,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: '20px',
          padding: '15px',
          fontWeight: 600,
          fontFamily: PRIMARY_FONT,
          fontSize: '12px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: borders.grayLight,
          boxShadow: 'none',
          borderRadius: '10px',
          '&.MuiPaper-root': {
            height: 'fit-content',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '20px',
          '& .MuiCardHeader-title': {
            fontSize: '13px',
            fontFamily: SECONDARY_FONT,
            fontWeight: 500,
          },
          '& .MuiTypography-root.MuiCardHeader-subheader': {
            marginTop: '3px',
            fontSize: '11px',
            fontFamily: PRIMARY_FONT,
            fontWeight: 400,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '20px',
          paddingTop: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: PRIMARY_FONT,
          fontSize: '10px',
          color: themeColors.error,
          marginLeft: '2px',
          marginRight: '2px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: SECONDARY_FONT,
          fontSize: '12px',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontFamily: SECONDARY_FONT,
          fontSize: '12px',
          '& .MuiListItemText-primary.MuiTypography-root': {
            fontFamily: SECONDARY_FONT,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          fontFamily: SECONDARY_FONT,
          '& .MuiTabs-indicator': {
            backgroundColor: themeColors.yellow,
          },
          '& .MuiTabs-flexContainer': {
            height: '100%',
            width: '100%',
            alignItems: 'end',
          },
          '& .MuiTab-root': {
            fontWeight: 500,
            textTransform: 'none',
            transition: 'color 0.2s ease-in-out',
            minHeight: '100%',
            padding: 0,
          },
          '& .MuiTab-root.Mui-selected': {
            color: themeColors.secondary,
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {},
      },
    },
  },
})

export default muiTheme
