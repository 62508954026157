import { http } from 'services/http'

const getTests = ({ queryKey }) => {
  return http.get(`/test`, {
    params: { ...queryKey[1] },
  })
}

const getSchoolClassWithSubjects = ({ queryKey }) => {
  return http.get(`/class/master-data/school/${queryKey[1]}`, {
    params: { ...queryKey[2] },
  })
}

const getTestDetails = ({ queryKey }) => {
  return http.get(`/akshara-jyothi/test-details`, {
    params: { ...queryKey[1] },
  })
}

const getTestComparision = ({ queryKey }) => {
  return http.get(`/akshara-jyothi/test-comparison`, {
    params: { ...queryKey[1] },
  })
}

const getSchoolPerformance = ({ queryKey }) => {
  return http.get(`/akshara-jyothi/school-performance`, {
    params: { ...queryKey[1] },
  })
}

const getRoleBasedSchool = ({ queryKey }) => {
  return http.get('statistics/master-data/schools', {
    params: { ...queryKey[1] },
  })
}

const getUserTypeClass = ({ queryKey }) => {
  return http.get('/class/master-data', {
    params: { ...queryKey[1] },
  })
}

export {
  getUserTypeClass,
  getSchoolPerformance,
  getTestComparision,
  getTests,
  getTestDetails,
  getSchoolClassWithSubjects,
  getRoleBasedSchool,
}
