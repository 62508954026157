import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EastIcon from '@mui/icons-material/East'
import { useQuery } from 'react-query'
import { http } from 'services/http'

const AdminUsers = () => {
  const [usersCount, setUsersCount] = useState([])
  const image = '/images/admin (1).png'

  const { data, isLoading } = useQuery(
    ['usersCount'],
    () =>
      http.get(`/users/count`).then((res) => {
        console.log(res)
        return res?.data
      }),
    {
      onSuccess: (data) => {
        setUsersCount(data)
      },
    },
  )
  //   if (foodMenu) {
  //     foodMenu.forEach((item) => {
  //       item.image = images[item.name]
  //     })
  //   }

  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: '-15px',
        }}
      >
        <Grid item xl={4} lg={4} md={4}>
          <Card title="Admin" itemsCount={usersCount?.Admin} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="DD" itemsCount={usersCount?.DD} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="ATDO" itemsCount={usersCount?.ATDO} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="AE" itemsCount={usersCount?.AE} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="DE" itemsCount={usersCount?.DE} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="MHO" itemsCount={usersCount?.MHO} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="DMHO" itemsCount={usersCount?.DMHO} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="MGCC" itemsCount={usersCount?.MGCC} img={image} />
        </Grid>
        <Grid item xl={4} lg={4} md={4}>
          <Card title="DMGCC" itemsCount={usersCount?.DMGCC} img={image} />
        </Grid>
      </Grid>
    </div>
  )
}

export default AdminUsers

export const Card = ({ title, img, itemsCount }) => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        borderRadius: '10px',
        boxShadow: '0px 3px 13px #2A32711A',
        margin: '28px 28px 0px 0px',
      }}
    >
      <Box
        onClick={() => {
          navigate({
            pathname: '/admin-users/details',
            search: `?type=${title}`,
          })
        }}
        sx={{
          padding: '30px',
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ padding: '10px', marginBottom: '27px' }}>
          <Box>
            <img src={img} />
          </Box>
          <Box sx={{ marginTop: '8px' }}>
            <Typography
              sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: 'poppins_medium',
                marginTop: '8px',
              }}
            >
              Number of users added : {itemsCount}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <IconButton sx={{ color: 'black' }}>
            <EastIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
