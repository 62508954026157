import { Box, Divider, Grid, Modal, Typography } from '@mui/material'
import moment from 'moment'

export const TextModal = ({ setOpen, row }) => {
  console.log(row)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 650,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    outline: 0,
  }

  const handleClose = () => setOpen(false)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{
            fontFamily: 'poppins_semibold',
            fontSize: '16px',
            padding: '23px',
          }}
        >
          Health Checkups
        </Typography>
        <Divider />
        <Grid container sx={{ padding: '23px' }} rowSpacing={5}>
          <Grid item xl={4} lg={4} md={4}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Health Checkup Number
            </Typography>
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '14px',
                marginTop: '8px',
              }}
            >
              {row?.id ? `HC${row?.id.toString().padStart(4, '0')}` : 'NA'}
            </Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Date
            </Typography>
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '14px',
                marginTop: '8px',
              }}
            >
              {row?.date ? moment(row?.date).format('DD-MM-YYYY') : 'NA'}
            </Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Number of Male Students
            </Typography>
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '14px',
                marginTop: '8px',
              }}
            >
              {row?.NoOfMaleStudents ?? 'NA'}
            </Typography>
          </Grid>
          <Grid item xl={4} lg={4} md={4}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Number of Female Students
            </Typography>
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '14px',
                marginTop: '8px',
              }}
            >
              {row?.NoOfFemaleStudents ?? 'NA'}
            </Typography>
          </Grid>
          <Grid item xl={8} lg={8} md={8}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Number of Students with Emergency Issues
            </Typography>
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '14px',
                marginTop: '8px',
              }}
            >
              {row?.NoOfStudentsWithEmergencyIssues ?? 'NA'}
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Number of Hospitalised Students
            </Typography>
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '14px',
                marginTop: '8px',
              }}
            >
              {row?.NoOfHospitalizedStudents ?? 'NA'}
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Issue
            </Typography>
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '14px',
                marginTop: '8px',
                padding: '8px 15px',
                backgroundColor: '#F2F2F2',
                width: 'fit-content',
                borderRadius: '999px',
              }}
            >
              {row?.medicalIssue?.name ?? 'NA'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
