import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const CRTteachersForm = ({ data, toggleDrawer }) => {
  const [schools, setSchools] = useState()
  const [subjects, setSubjects] = useState()
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      console.log(res)
      setSchools(res?.data?.schools)
    })
  }, [])

  useEffect(() => {
    http.get(`/subjects`).then((res) => {
      console.log(res)
      setSubjects(res?.data?.subjects)
    })
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      schoolId: data?.school?.id,
      subjectId: data?.subject?.id,
      teacherType: data?.type,
      name: data?.name,
      mobileNumber: data?.mobileNumber,
      gender: data?.gender,
      designation: data?.designation,
    },
  })

  const onSubmit = (data) => {
    // "teacherType": "CRT",

    const body = { ...data, teacherType: 'CRT' }
    if (edit) {
      console.log(id)

      http
        .put(`/teachers/${id}`, body)
        .then((res) => {
          enqueueSnackbar('Teacher details have been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('CRTTeachers')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      http
        .post(`/teachers`, body)
        .then((res) => {
          enqueueSnackbar('Teacher has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('CRTTeachers')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }

    console.log(data)
  }
  console.log(data)

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="schoolId">School Name *</InputLabel>
            <Select
              labelId="schoolId"
              id="schoolId"
              // disabled={data ? true : false}
              label="School Name *"
              {...register('schoolId', { required: true })}
              required={true}
              defaultValue={data?.school?.id}
            >
              {schools?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          {errors?.schoolId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="subjectId">Subject *</InputLabel>
            <Select
              labelId="subjectId"
              id="subjectId"
              label="Subject *"
              {...register('subjectId', { required: true })}
              required={true}
              defaultValue={data?.subject?.id}
            >
              {subjects?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          {errors?.subjectId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <TextField
            type={'text'}
            {...register('name', {
              required: true,
              pattern: { value: /^[A-Za-z0-9 ]+$/, message: 'Invalid Name' },
            })}
            label={'Teacher Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.name?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.name?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}

          <TextField
            type={'text'}
            {...register('mobileNumber', {
              required: true,
              pattern: {
                value: /^\d{10}$/,
              },
            })}
            label={'Mobile Number'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.mobileNumber?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.mobileNumber?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Mobile Number
            </Typography>
          )}

          <FormControl component="fieldset" sx={{ marginTop: '24px' }}>
            <FormLabel component="legend">Gender *</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="gender"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {errors?.gender && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <TextField
            type={'text'}
            {...register('designation', {
              required: true,
              pattern: { value: /^[A-Za-z  ()-.]+$/ },
            })}
            label={'Designation'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.designation?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.designation?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {data ? 'Edit CRT Teacher' : 'Add CRT Teacher'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default CRTteachersForm
