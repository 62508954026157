import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Breadcrumbs from 'components/Breadcrumbs'
import DividerHeading from 'components/DividerHeading'
import PageHeader from 'components/Mui/PageHeader'
import { useEffect, useState } from 'react'
import { http } from 'services/http'
import SearchIcon from '@mui/icons-material/Search'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import Table from 'components/Mui/TableComponent'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

const UnmarkedSchools = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const userType = localStorage.getItem('user_type')
  const districtID = localStorage.getItem('districtId')
  const atdoPointID = localStorage.getItem('atdoPointId')
  const DATE = localStorage.getItem('date')
  const [loading, setLoading] = useState()
  const [date, setDate] = useState(DATE ? new Date(DATE) : new Date())

  // const [date, setDate] = useState(new Date())
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [districtId, setDistrictId] = useState(districtID ?? '')
  const [atdoPointId, setAtdoPointId] = useState(atdoPointID ?? '')
  // const [attendanceDetails, setAttendanceDetails] = useState()
  const [schools, setSchools] = useState()
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')
  const heading = localStorage.getItem('heading')
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)

  const getUnmarkedSchools = () => {
    setSearchLoading(true)
    try {
      http
        .get(
          `/statistics/activity/unmarked/type?type=${type}&date=${date}&search=${search}&districtId=${districtId}&atdoPointId=${atdoPointId}`,
        )
        .then((res) => {
          console.log(res)
          setPage(1)
          setCount(res?.data?.count)
          setSchools(res?.data?.data?.slice(0, 10))
          setSearchLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  const handleExcelImport = (e) => {
    e?.preventDefault()
    try {
      http
        .get(
          `/statistics/activity/report?type=${type}&date=${date}&districtId=${districtId}&atdoPointId=${atdoPointId}&search=${search}`,
        )
        .then((res) => {
          const arr = new Uint8Array(res.data?.data)
          const blob = new Blob([arr], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const file = window.URL.createObjectURL(blob)
          let link = document.createElement('a')
          link.href = file
          link.download = `report.xlsx`
          link.click()
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  const handleSubmit = (e) => {
    e?.preventDefault()
    setLoading(true)
    try {
      http
        .get(
          `/statistics/activity/unmarked/type?type=${type}&date=${date}&search=${search}&districtId=${districtId}&atdoPointId=${atdoPointId}`,
        )
        .then((res) => {
          setCount(res?.data?.count)
          setSchools(res?.data?.data?.slice(0, 10))
          setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }
  useEffect(() => {
    getUnmarkedSchools()
  }, [search])

  const handleSearch = (e) => {
    e?.preventDefault()
    getUnmarkedSchools()
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      // setLoading(true)
      http
        .get(
          `/statistics/activity/unmarked/type?type=${type}&date=${date}&districtId=${districtId}&atdoPointId=${atdoPointId}&limit=10&offset=${
            v > 0 ? (v - 1) * 10 : v * 10
          }&search=${search}`,
        )
        .then((res) => {
          console.log(res)
          // setCount(res?.data?.count)
          setSchools(res?.data?.data)
          // setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      console.log(res)
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  useEffect(() => {
    setLoading(true)
    http
      .get(`/statistics/activity/unmarked/type?type=${type}&date=${date}`)
      .then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        setSchools(res?.data?.data?.slice(0, 10))
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [date])

  const columns = [
    {
      title: 'School ID',
      dataIndex: 'schoolId',
      key: 'schoolId',
    },
    {
      title: 'School Name',
      dataIndex: 'school.name',
      key: 'school.name',
    },
    {
      title: 'Headmaster Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
  ]

  useEffect(() => {
    getUnmarkedSchools()
  }, [search])

  if (loading) {
    return <CircularProgress />
  }
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Activity Dashboard', to: '/activity-dashboard' },
              {
                name: 'Unmarked Schools' + '  /  ' + heading,
                to: null,
              },
            ]}
          />
        }
      />

      {userType == 'Admin' || userType == 'DD' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              padding: '30px',
              boxShadow: '0px 3px 16px #2A32710F',
              borderRadius: '10px',
              width: '150px',
            }}
          >
            <Typography
              sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
            >
              {count}
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                fontFamily: 'poppins_medium',
                opacity: '0.7',
              }}
            >
              Unmarked Schools
            </Typography>
          </Box>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Stack
              gap={3}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="districtId">Select District</InputLabel>
                <Select
                  labelId="districtId"
                  id="districtId"
                  label="Select District"
                  value={districtId}
                  onChange={(e) => {
                    setAtdoPointId('')
                    setDistrictId(e?.target?.value)
                  }}
                >
                  {districts?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                <Select
                  labelId="atdoPointId"
                  id="atdoPointId"
                  label="Select ATDO Point"
                  value={atdoPointId}
                  onChange={(e) => setAtdoPointId(e?.target?.value)}
                >
                  {atdoPoints?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '145px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                </Box>
              </LocalizationProvider>{' '}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                }}
                type="submit"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  Apply
                </Typography>
              </Button>
            </Stack>
          </form>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              padding: '30px',
              boxShadow: '0px 3px 16px #2A32710F',
              borderRadius: '10px',
              width: '150px',
            }}
          >
            <Typography
              sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
            >
              {count}
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                fontFamily: 'poppins_medium',
                opacity: '0.7',
              }}
            >
              Unmarked Schools
            </Typography>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ width: '145px', height: '50px' }}>
              <DatePicker
                value={date}
                onChange={(newValue) => {
                  setDate(newValue)
                }}
                inputFormat="DD MMM, YYYY"
                maxDate={new Date()}
                renderInput={(params) => <TextField {...params} />}
              />{' '}
            </Box>
          </LocalizationProvider>{' '}
        </Box>
      )}

      <DividerHeading title={'Unmarked Schools'} />
      <Box sx={{ marginTop: '1rem' }}>
        <Box display="flex" justifyContent="space-between">
          <form className="mb-4" onSubmit={handleSearch}>
            <TextField
              label="Search by School name"
              sx={{ width: '494px', marginBottom: '1.5rem' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>

          <Button
            variant="contained"
            sx={{
              backgroundColor: 'black !important',
              height: '50px',
              width: '160px',
              borderRadius: '10px',
            }}
            onClick={handleExcelImport}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'white',
                fontSize: '14px',
              }}
            >
              Download Report
            </Typography>
          </Button>
        </Box>

        <Box>
          {schools?.length == 0 ? (
            <center>
              <SchoolAnalyticsEmptyScreen />
            </center>
          ) : (
            <>
              <Table
                key={'schools'}
                dataSource={schools}
                rowKey={'id'}
                columns={columns}
                loading={loading}
              />
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={count > 10 ? Math.ceil(count / 10) : 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePagination}
                  page={page}
                />{' '}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default UnmarkedSchools
