import { AppBar, Box, Typography } from '@mui/material'
import { NavLink, Outlet } from 'react-router-dom'
import '../../App.css'

const UsersMasterData = () => {
  const userType = localStorage.getItem('user_type')

  return (
    <div>
      <AppBar
        style={{
          // position: 'absolute',
          top: 74,
          left: '162px',
          backgroundColor: '#F4F4F4',
          boxShadow: 'none',
        }}
      >
        <nav
          style={{
            display: 'flex',
            paddingLeft: '38px',
          }}
        >
          {/* {userType == 'Admin' && (
            <> */}
          <NavLink
            className={'masterDataNavLink'}
            to={'/users-master-data'}
            end
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              Head Masters
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={'masterDataNavLink'}
            to={'/users-master-data/regular-teachers'}
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              Regular Teachers
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={'masterDataNavLink'}
            to={'/users-master-data/crt-teachers'}
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              CRT Teachers
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={'masterDataNavLink'}
            to={'/users-master-data/school-staff'}
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              School Staff
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          {/* </>
          )} */}
        </nav>
      </AppBar>
      <Box sx={{ marginTop: '50px' }}>
        <Outlet />
      </Box>
    </div>
  )
}

export default UsersMasterData
