import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getSchoolPerformance } from 'services/api/aksharaJyothi'
import SideBar from './SideBar'

const PerformanceReport = () => {
  const navigate = useNavigate()
  const [filterData, setFilterData] = useState({
    value: null,
    testId: null,
    classId: null,
    subjectId: null,
  })
  const { data, isLoading, error } = useQuery(
    [
      'test-report',
      {
        testId: filterData?.testId,
        classId: filterData?.classId,
        subjectId: filterData?.subjectId,
        value: filterData?.value,
      },
    ],
    getSchoolPerformance,
    {
      enabled:
        filterData?.testId &&
        filterData?.value &&
        filterData?.classId &&
        filterData?.subjectId
          ? true
          : false,
    },
  )

  return (
    <Box p={2} pt={3}>
      <Button
        onClick={() => navigate('/akshara-jyothi-dashboard')}
        variant="text"
        sx={{ color: 'black' }}
        startIcon={<ArrowBack />}
      >
        School Performance Report
      </Button>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3.5} lg={3.3} xl={3}>
            <SideBar setFilterData={setFilterData} filterData={filterData} />
          </Grid>
          <Grid item xs={12} md={8.5} lg={8.7} xl={9}>
            {isLoading ? (
              <Box>
                <Loader />
              </Box>
            ) : (
              <Box>
                <DividerHeading title="Performance Reports" />
                <Grid p={4} pt={2} container spacing={2}>
                  {!data?.data?.result ? (
                    <Box>
                      <Typography variant="h5" sx={{ color: 'red' }}>
                        Please Select Valid Inputs
                      </Typography>
                    </Box>
                  ) : data?.data?.result?.length === 0 ? (
                    <Box textAlign="center" ml={'7px'}>
                      <SchoolAnalyticsEmptyScreen />
                    </Box>
                  ) : (
                    data?.data?.result?.map((item) => (
                      <Grid
                        mt={1}
                        item
                        xs={12}
                        sx={{
                          p: 2,
                          borderRadius: '10px 10px 10px 10px',
                          boxShadow: '0px 3px 13px #2A32711A',
                        }}
                      >
                        <Grid container spacing={2}>
                          <DataCard
                            title="School Name"
                            desc={item?.school?.name}
                          />
                          <DataCard title="Test Name" desc={item?.test?.name} />
                          <DataCard
                            title={`Grade ${item?.grades[0]?.grade}`}
                            desc={`${item?.grades[0]?.percentage.toFixed(1)} %`}
                          />
                          <DataCard
                            title={`Grade ${item?.grades[1]?.grade}`}
                            desc={`${item?.grades[1]?.percentage.toFixed(1)} %`}
                          />
                        </Grid>
                      </Grid>
                    ))
                  )}
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PerformanceReport

const DataCard = ({ title, desc }) => {
  return (
    <Grid item xs={6} md={3}>
      <Box>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="h5">{desc}</Typography>
      </Box>
    </Grid>
  )
}
