import { Box, Typography } from '@mui/material'
import DropDownButton from 'components/DropDownButton'
import React, { useEffect, useRef, useState } from 'react'
import { StyledPagination, StyledTableFooter } from './styles'

interface TableFooterProps {
  totalCount: number
  limit: number
  handlePagination: (v: number) => void
  page: number
  handlePageLimit?: (v: number) => void
}

export default function TableFooter({
  totalCount,
  limit,
  handlePagination,
  page,
  handlePageLimit,
}: TableFooterProps) {
  const [limitPerPage, setLimitPerPage] = useState()
  const onChange = (v: number) => {
    handlePagination(v)
  }
  return (
    <StyledTableFooter>
      <TableStats
        limit={limit}
        tableRowCount={Math.ceil(totalCount / limit)}
        totalCount={totalCount}
        page={page}
      />
      <StyledPagination
        page={page}
        count={Math.ceil(totalCount / limit)}
        onChange={(e, v) => onChange(v)}
        variant="outlined"
        shape="rounded"
      />
      {handlePageLimit ? (
        <LimitField handlePageLimit={handlePageLimit} limit={limit} />
      ) : (
        <Box></Box>
      )}
    </StyledTableFooter>
  )
}

const TableStats = ({
  limit,
  tableRowCount,
  totalCount,
  page,
}: {
  tableRowCount: number
  limit: number
  page: number
  totalCount: number
}) => {
  let modulus = totalCount % limit
  return (
    <Box>
      {limit < totalCount && page != tableRowCount && (
        <Typography
          sx={{ opacity: '0.5' }}
        >{`Showing ${limit} of ${totalCount} entries`}</Typography>
      )}
      {limit < totalCount && page == tableRowCount && (
        <Typography
          sx={{ opacity: '0.5' }}
        >{`Showing ${modulus} of ${totalCount} entries`}</Typography>
      )}
      {limit > totalCount && (
        <Typography
          sx={{ opacity: '0.5' }}
        >{`Showing ${modulus} of ${modulus} entries`}</Typography>
      )}
      {}
    </Box>
  )
}

const LimitField = ({ handlePageLimit, limit }) => {
  return (
    <Box>
      <DropDownButton
        buttonProps={{
          variant: 'outlined',
          color: 'secondary',
          sx: {
            padding: '0px 10px',
            color: '#121212',
            fontSize: '11px',
            border: '1px solid #1212121A',
          },
        }}
        label={`${limit} / page`}
        menu={[
          {
            label: 10,
            onClick: () => handlePageLimit(10),
          },

          {
            label: 20,
            onClick: () => handlePageLimit(20),
          },
          {
            label: 30,
            onClick: () => handlePageLimit(30),
          },
          {
            label: 50,
            onClick: () => handlePageLimit(50),
          },
          {
            label: 100,
            onClick: () => handlePageLimit(100),
          },
        ]}
      />
    </Box>
  )
}
