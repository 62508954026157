import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const Card = ({
  title,
  presentCount,
  presentContent,
  absentCount,
  absentContent,
  link = '',
  presentPercentage,
}) => {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 6px 16px #2A32711A',
          margin: '29px 29px 0px 0px',
        }}
      >
        <Box>
          <Box sx={{ borderBottom: '1px solid #E7E7E7' }}>
            <Box sx={{ padding: '23px' }}>
              <Typography
                sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: '23px' }}>
          <Box
            sx={{
              padding: '23px 0px',
              display: 'flex',
              borderBottom: '1px solid #E7E7E7',
            }}
            gap={'66px'}
          >
            <Box>
              <Typography
                sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
              >
                {presentCount}
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
              >
                {presentContent}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
              >
                {absentCount}
              </Typography>
              <Typography
                sx={{ fontSize: '11px', fontFamily: 'poppins_medium' }}
              >
                {absentContent}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '22px',
            }}
          >
            <Typography sx={{ fontSize: '13px', fontFamily: 'poppins_medium' }}>
              Present Percentage
            </Typography>
            <Typography
              sx={{ fontSize: '22px', fontFamily: 'poppins_semibold' }}
            >
              {presentPercentage}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

export default Card
