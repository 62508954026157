import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DividerHeading from 'components/DividerHeading'
import PaperCard from 'components/Mui/PaperCard/PaperCard'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { http } from 'services/http'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const ActivityDashboard = () => {
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [mandals, setMandals] = useState()
  const [schools, setSchools] = useState()
  const userType = localStorage.getItem('user_type')
  const navigate = useNavigate()
  const [districtId, setDistrictId] = useState('')
  const [atdoPointId, setAtdoPointId] = useState('')
  const [mandalId, setMandalId] = useState('')
  const [schoolId, setSchoolId] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [date, setDate] = useState(new Date())
  const [isLoading, setIsLoading] = useState()
  const [hitApi, setHitApi] = useState()

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      setSchools(
        res?.data?.schools.filter((item) => {
          if (districtId && !atdoPointId) {
            return item?.districtId == districtId
          }

          if (districtId && atdoPointId) {
            return (
              item?.districtId == districtId && item?.atdoPointId == atdoPointId
            )
          }
        }),
      )
    })
  }, [districtId, atdoPointId])

  let regularCount = 0
  let crtCount = 0
  let headmasterCount = 0

  const handleSubmit = (e) => {
    e?.preventDefault()
    setHitApi(true)
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              marginTop: '30px',
              padding: '30px',
              borderRadius: '10px',
              backgroundColor: '#F6F6F6',
              // boxShadow: '0px 3px 13px #2A32711A',
              // width: '600px',
            }}   >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'28px'}>
                <img src={'/images/element-equal.png'} />
                <Box>
                  <Typography
                    sx={{ fontFamily: 'poppins_semibold', fontSize: '22px' }}
                  >
                    Overall schools activity Report
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '16px',
                      opacity: '0.7',
                    }}
                  >
                    Tap on view more to see the detailed activity report of all
                    the schools{' '}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  border: '1px solid #24272C',
                  padding: '11px 19px',
                  borderRadius: '10px',
                }}
                gap={'55px'}
                onClick={() => {
                  navigate('/activity-dashboard/activity-feed')
                }}
              >
                <Typography
                  sx={{ fontFamily: 'poppins_semibold', fontSize: '13px' }}
                >
                  View more
                </Typography>
                <KeyboardArrowRightIcon />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '37px' }}>
        {userType == 'Admin' || userType == 'DD' ? (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Stack
              gap={3}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="districtId">Select District</InputLabel>
                <Select
                  labelId="districtId"
                  id="districtId"
                  label="Select District"
                  value={districtId}
                  onChange={(e) => {
                    setAtdoPointId('')

                    setDistrictId(e?.target?.value)
                  }}
                >
                  {districts?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                <Select
                  labelId="atdoPointId"
                  id="atdoPointId"
                  label="Select ATDO Point"
                  value={atdoPointId}
                  onChange={(e) => setAtdoPointId(e?.target?.value)}
                >
                  {atdoPoints?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '145px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                </Box>
              </LocalizationProvider>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                }}
                type="submit"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  Apply
                </Typography>
              </Button>
            </Stack>
          </form>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ width: '145px', height: '50px' }}>
                <DatePicker
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue)
                  }}
                  inputFormat="DD MMM, YYYY"
                  maxDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                />{' '}
              </Box>
            </LocalizationProvider>{' '}
          </Box>
        )}
      </Box>

      <Box sx={{ marginTop: '15px' }}>
        <DividerHeading title={'Today’s Activities'} />
        <Box className="linksContainer" sx={{ display: 'flex' }} gap={'18px'}>
          <NavLink to={'/activity-dashboard'} end>
            Attendance Activities
          </NavLink>
          <NavLink to={'/activity-dashboard/academic-extra-curriculars'}>
            Academic & Extra-Curricular Activities
          </NavLink>
          <NavLink to={'/activity-dashboard/food-menu-implementation'}>
            Food Menu Implementation Activities
          </NavLink>
          {/* <NavLink to={'/activity-dashboard/new-programme'}>
            New Programme
          </NavLink> */}
        </Box>
      </Box>
      <Outlet
        context={[
          date,
          districtId,
          atdoPointId,
          mandalId,
          schoolId,
          hitApi,
          setHitApi,
        ]}
      />
    </div>
  )
}

export default ActivityDashboard
