import { Box, Grid, Stack, Typography } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import Filters from 'components/Filters'
import PageHeader from 'components/Mui/PageHeader'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import { StyledTitle } from './styledComponents'
import VerticalBarChart from './VerticalBarChart'
import * as moment from 'moment'
import DividerHeading from 'components/DividerHeading/DividerHeading'
import ATDOWiseStats from './ATDOWiseStats'
import Loader from 'components/Loader'

const MedicalDashboard = () => {
  const [districtId, setDistrictId] = useState('')
  const [atdoPointId, setAtdoPointId] = useState('')
  const [date, setDate] = useState(new Date())
  const emergencyStatuses = [
    'PENDING',
    'ASSIGNED',
    'ACKNOWLEDGED',
    'HOSPITALIZED',
    'COMPLETED',
  ]
  const fetchEmergencies = async (statuses, districtId, atdoPointId, date) => {
    const res = await http.get(
      `/medical-emergencies?statuses=${statuses}&districtId=${districtId}&atdoPointId=${atdoPointId}&date=${date}`,
    )
    return res?.data
  }

  const { data: medicalEmergencies, isLoading: emergenciesLoading } = useQuery(
    ['getEmergencies', emergencyStatuses, districtId, atdoPointId, date],
    () => fetchEmergencies(emergencyStatuses, districtId, atdoPointId, date),
  )

  const hospitalizedStatuses = ['ADMITTED', 'DISCHARGED']
  const fetchHospitalized = async (statuses, districtId, atdoPointId, date) => {
    const res = await http.get(
      `/hospitalized-students?statuses=${statuses}&districtId=${districtId}&atdoPointId=${atdoPointId}&date=${date}`,
    )
    return res?.data
  }

  const { data: hospitalizedStudents, isLoading: hospitalizedLoading } =
    useQuery(
      ['getHospitalized', hospitalizedStatuses, districtId, atdoPointId, date],
      () =>
        fetchHospitalized(hospitalizedStatuses, districtId, atdoPointId, date),
    )

  const fetchTotalHealthEmergencies = async (
    statuses,
    districtId,
    atdoPointId,
    date,
  ) => {
    const year = moment(date)?.year()
    const res = await http.get(
      `/medical-emergencies/emergency-by-issues?statuses=${statuses}&districtId=${districtId}&atdoPointId=${atdoPointId}&year=${year}`,
    )
    return res?.data
  }

  const { data: totalHealthEmergencies, isLoading: healthEmergenciesLoading } =
    useQuery(
      [
        'getTotalHealthEmergencies',
        emergencyStatuses,
        districtId,
        atdoPointId,
        date,
      ],
      () =>
        fetchTotalHealthEmergencies(
          emergencyStatuses,
          districtId,
          atdoPointId,
          date,
        ),
    )

  console.log(medicalEmergencies)
  const emergenciesCount = medicalEmergencies?.length
  const hospitalizedCount = hospitalizedStudents?.length

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Medical', to: '/Medical' },
              { name: 'Dashboard', to: null },
            ]}
          />
        }
      />
      <Box>
        <Filters
          districtId={districtId}
          setDistrictId={setDistrictId}
          atdoPointId={atdoPointId}
          setAtdoPointId={setAtdoPointId}
          date={date}
          setDate={setDate}
        />
        <Grid container gap={3} ml={1} mt={2.8}>
          <Cards
            img={'/images/MedicalEmergenciesCount.png'}
            title={'Number of Medical Emergencies'}
            count={emergenciesCount ?? 'NA'}
            loading={emergenciesLoading}
          />
          <Cards
            img={'/images/hospitalized.png'}
            title={'Number of Hospitalized Students'}
            count={hospitalizedCount ?? 'NA'}
            loading={hospitalizedLoading}
          />
        </Grid>
        <Box mt={'23px'}>
          <DividerHeading title={'Issue wise Health Emergencies'} />
          <VerticalBarChart
            date={date}
            data={totalHealthEmergencies}
            loading={healthEmergenciesLoading}
          />
        </Box>
        <ATDOWiseStats
          date={date}
          districtId={districtId}
          atdoPointId={atdoPointId}
        />
      </Box>
    </div>
  )
}

export default MedicalDashboard

const Cards = ({ img, title, count, loading }) => {
  return (
    <Grid
      item
      xl={2.4}
      lg={3.5}
      sm={4}
      sx={{
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0px 3px 13px #2A32711A;',
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Box sx={{ marginTop: '10px' }}>
            <img src={img} style={{ width: '65px', height: '65px' }} />
          </Box>
          <StyledTitle mt={'30px'}>{title}</StyledTitle>
          <Typography
            sx={{
              fontFamily: 'poppins_semibold',
              fontSize: '38px',
              marginTop: '7px',
            }}
          >
            {count}
          </Typography>
        </Box>
      )}
    </Grid>
  )
}
