import { Box, CircularProgress, Grid } from '@mui/material'
import Card from 'components/Mui/Card'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { http } from 'services/http'

const FoodMenuImplementation = () => {
  const type = 'FoodMenu'
  const [schoolActivities, setSchoolActivities] = useState()
  const [date] = useOutletContext()
  const id = localStorage.getItem('schoolId')
  const [loading, setLoading] = useState(false)
  console.log(id, date)
  useEffect(() => {
    setLoading(true)
    http
      .get(`/statistics/school/${id}?type=${type}&date=${date}`)
      .then((res) => {
        console.log(res)
        setSchoolActivities(res?.data)
        setLoading(false)
      })
  }, [date])
  const cards = [
    {
      title: 'Breakfast Drink',
      presentCount: `${schoolActivities?.milk?.completed}`,
      presentContent: 'Number of students present',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.milk?.percentage)
          ? schoolActivities?.milk?.percentage
          : schoolActivities?.milk?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Breakfast',
      presentCount: `${schoolActivities?.breakfast?.completed}`,
      presentContent: 'Number of students present',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.breakfast?.percentage)
          ? schoolActivities?.breakfast?.percentage
          : schoolActivities?.breakfast?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Lunch',
      presentCount: `${schoolActivities?.lunch?.completed}`,
      presentContent: 'Number of students present',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.lunch?.percentage)
          ? schoolActivities?.lunch?.percentage
          : schoolActivities?.lunch?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Snacks',
      presentCount: `${schoolActivities?.snack?.completed}`,
      presentContent: 'Number of students present',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.snack?.percentage)
          ? schoolActivities?.snack?.percentage
          : schoolActivities?.snack?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Dinner',
      presentCount: `${schoolActivities?.dinner?.completed}`,
      presentContent: 'Number of students present',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.dinner?.percentage)
          ? schoolActivities?.dinner?.percentage
          : schoolActivities?.dinner?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Special Menu',
      presentCount: `${schoolActivities?.special?.completed}`,
      presentContent: 'Number of students present',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.special?.percentage)
          ? schoolActivities?.special?.percentage
          : schoolActivities?.special?.percentage?.toFixed(1)
      }%`,
    },
  ]
  return (
    <Grid container spacing={1}>
      {cards?.map((item) => {
        if (loading) {
          return <CircularProgress />
        }
        return (
          <Grid item xl={3} lg={4} md={4}>
            <Card
              link={`/schools/food-menu-implementation-details?item=${item?.title}`}
              title={item?.title}
              presentCount={item?.presentCount}
              presentContent={item?.presentContent}
              absentCount={item?.absentCount}
              absentContent={item?.absentContent}
              presentPercentage={item?.presentPercentage}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default FoodMenuImplementation
