import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PageHeader from 'components/Mui/PageHeader'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import Table from 'components/Mui/TableComponent'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Breadcrumbs from 'components/Breadcrumbs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { http } from 'services/http'
import DividerHeading from 'components/DividerHeading'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { ViewButton } from 'components/IconButtons'

const TeacherAndStaffAttendanceDetailsScreen = ({ attType }) => {
  const [loading, setLoading] = useState()
  const districtID = localStorage.getItem('districtId')
  const atdoPointID = localStorage.getItem('atdoPointId')
  const DATE = localStorage.getItem('date')
  const [date, setDate] = useState(DATE ? new Date(DATE) : new Date())
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const userType = localStorage.getItem('user_type')
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [districtId, setDistrictId] = useState(districtID ?? '')
  const [atdoPointId, setAtdoPointId] = useState(atdoPointID ?? '')
  const [attendanceDetails, setAttendanceDetails] = useState()
  const [tableData, setTableData] = useState()
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')
  const title =
    type == 'Regular'
      ? 'Regular Teachers'
      : type == 'CRT'
      ? 'CRT Teachers'
      : 'Staff Members'
  const attenType =
    attType == 'present'
      ? 'Present'
      : attType == 'absent'
      ? 'Absent'
      : attType == 'sick'
      ? 'Sick'
      : 'Overall'

  useEffect(() => {
    localStorage.setItem('date', date)
  }, [date])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  // const [sampleBuffer, setSampleBuffer] = useState()
  let columns

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      console.log(res)
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  useEffect(() => {
    setLoading(true)
    http
      .get(
        `/statistics/activity/type?date=${date}&type=${type}&districtId=${districtId}&atdoPointId=${atdoPointId}`,
      )
      .then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        setAttendanceDetails(res?.data?.data)
        setTableData(res?.data?.data?.slice(0, 10))
        setLoading(false)
      })
    //   http
    //     .get(
    //       `/statistics/school/${id}/report?type=${type}&fromDate=${date}&toDate=${date}`,
    //     )
    //     .then((res) => {
    //       console.log(res?.data?.data)
    //       setSampleBuffer(res?.data?.data)
    //     })
  }, [date])

  attType == 'present'
    ? (columns = [
        {
          title: 'School ID',
          dataIndex: 'schoolId',
          key: 'schoolId',
        },
        {
          title: 'School Name',
          dataIndex: 'school.name',
          key: 'school.name',
        },
        {
          title:
            type == 'Staff'
              ? 'Total Number of Staff Members'
              : 'Total Number of Teachers',
          dataIndex:
            type == 'Regular' || type == 'CRT' ? 'totalTeachers' : 'totalStaff',
          key:
            type == 'Regular' || type == 'CRT' ? 'totalTeachers' : 'totalStaff',
        },
        {
          title:
            type == 'Staff'
              ? 'Number of Staff Members Present'
              : 'Number of Teachers Present',
          dataIndex: 'present',
          key: 'present',
        },
        {
          title: 'Attendance percentage',
          dataIndex: 'percentage',
          key: 'percentage',
          render: (row) => {
            const perc = Number.isInteger(row)
              ? row + '%'
              : row?.toFixed(1) + '%'
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'10px'}>
                <Typography>{perc}</Typography>
                {row < 50 && (
                  <Box
                    sx={{
                      borderRadius: '999px',
                      padding: '3px 13px',
                      backgroundColor: '#FE214F',
                    }}
                  >
                    <Typography sx={{ color: 'white' }}>Low</Typography>
                  </Box>
                )}
              </Box>
            )
          },
        },
        {
          title: 'Marked At',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (row) => {
            return moment.utc(row)?.format('h:mm A')
          },
        },
        {
          title: 'Actions',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <Box display="flex" gap="10px">
              <ViewButton
                onClick={() =>
                  navigate(
                    `/activity-dashboard/attendance-details-inner-screen?id=${row?.id}&type=${type}`,
                  )
                }
              />
            </Box>
          ),
        },
      ])
    : attType == 'absent'
    ? (columns = [
        {
          title: 'School ID',
          dataIndex: 'schoolId',
          key: 'schoolId',
        },
        {
          title: 'School Name',
          dataIndex: 'school.name',
          key: 'school.name',
        },
        {
          title:
            type == 'Staff'
              ? 'Total Number of Staff Members'
              : 'Total Number of Teachers',
          dataIndex:
            type == 'Regular' || type == 'CRT' ? 'totalTeachers' : 'totalStaff',
          key:
            type == 'Regular' || type == 'CRT' ? 'totalTeachers' : 'totalStaff',
        },
        {
          title:
            type == 'Staff'
              ? 'Number of Staff Members Absent'
              : 'Number of Teachers Absent',
          render: (_, colData) => {
            return colData?.cl + colData?.ml + colData?.od
          },
        },
        {
          title: 'Attendance percentage',
          dataIndex: 'percentage',
          key: 'percentage',
          render: (row) => {
            console.log(row)
            const perc = Number.isInteger(row)
              ? row + '%'
              : row?.toFixed(1) + '%'
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'10px'}>
                <Typography>{perc}</Typography>
                {row < 60 && (
                  <Box
                    sx={{
                      borderRadius: '999px',
                      padding: '3px 13px',
                      backgroundColor: '#FE214F',
                    }}
                  >
                    <Typography sx={{ color: 'white' }}>Low</Typography>
                  </Box>
                )}
              </Box>
            )
          },
        },
        { title: 'CL', dataIndex: 'cl', key: 'cl' },
        { title: 'OD', dataIndex: 'od', key: 'od' },
        { title: 'ML', dataIndex: 'ml', key: 'ml' },
        {
          title: 'Marked At',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (row) => {
            return moment.utc(row)?.format('h:mm A')
          },
        },
        {
          title: 'Actions',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <Box display="flex" gap="10px">
              <ViewButton
                onClick={() =>
                  navigate(
                    `/activity-dashboard/attendance-details-inner-screen?id=${row?.id}&type=${type}`,
                  )
                }
              />
            </Box>
          ),
        },
      ])
    : (columns = [
        {
          title: 'School ID',
          dataIndex: 'schoolId',
          key: 'schoolId',
        },
        {
          title: 'School Name',
          dataIndex: 'school.name',
          key: 'school.name',
        },
        {
          title:
            type == 'Staff'
              ? 'Total Number of Staff Members'
              : 'Total Number of Teachers',
          dataIndex:
            type == 'Regular' || type == 'CRT' ? 'totalTeachers' : 'totalStaff',
          key:
            type == 'Regular' || type == 'CRT' ? 'totalTeachers' : 'totalStaff',
        },
        {
          title:
            type == 'Staff'
              ? 'Number of Staff Members Present'
              : 'Number of Teachers Present',
          dataIndex: 'present',
          key: 'present',
        },
        {
          title:
            type == 'Staff'
              ? 'Number of Staff Members Absent'
              : 'Number of Teachers Absent',
          render: (_, colData) => {
            return colData?.cl + colData?.ml + colData?.od
          },
        },
        {
          title: 'Attendance percentage',
          dataIndex: 'percentage',
          key: 'percentage',
          render: (row) => {
            const perc = Number.isInteger(row)
              ? row + '%'
              : row?.toFixed(1) + '%'
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'10px'}>
                <Typography>{perc}</Typography>
                {row < 60 && (
                  <Box
                    sx={{
                      borderRadius: '999px',
                      padding: '3px 13px',
                      backgroundColor: '#FE214F',
                    }}
                  >
                    <Typography sx={{ color: 'white' }}>Low</Typography>
                  </Box>
                )}
              </Box>
            )
          },
        },
        { title: 'CL', dataIndex: 'cl', key: 'cl' },
        { title: 'OD', dataIndex: 'od', key: 'od' },
        { title: 'ML', dataIndex: 'ml', key: 'ml' },
        {
          title: 'Marked At',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (row) => {
            return moment.utc(row)?.format('h:mm A')
          },
        },
        {
          title: 'Actions',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <Box display="flex" gap="10px">
              <ViewButton
                onClick={() =>
                  navigate(
                    `/activity-dashboard/attendance-details-inner-screen?id=${row?.school?.id}&type=${type}`,
                  )
                }
              />
            </Box>
          ),
        },
      ])

  const handleSubmit = (e) => {
    e?.preventDefault()
    setLoading(true)
    http
      .get(
        `/statistics/activity/type?date=${date}&type=${type}&districtId=${districtId}&atdoPointId=${atdoPointId}`,
      )
      .then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        setAttendanceDetails(res?.data?.data)
        setTableData(res?.data?.data?.slice(0, 10))
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      setLoading(true)
      http
        .get(
          `/statistics/activity/type?date=${date}&type=${type}&districtId=${districtId}&atdoPointId=${atdoPointId}&limit=10&offset=${
            v > 0 ? (v - 1) * 10 : v * 10
          }`,
        )
        .then((res) => {
          console.log(res)
          setAttendanceDetails(res?.data?.data)
          setTableData(res?.data?.data)
          setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  if (loading) {
    return <CircularProgress />
  }

  // useEffect(() => {
  //   // fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
  //   // toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
  //   http
  //     .get(
  //       `/statistics/school/${id}/report?type=${type}&fromDate=${date}&toDate=${date}`,
  //     )
  //     .then((res) => {
  //       console.log(res?.data?.data)
  //       setSampleBuffer(res?.data?.data)
  //     })
  // }, [date])

  const handleExport = () => {
    if (sampleBuffer === undefined) {
      enqueueSnackbar('Sample Excel Sheet Not Generated', {
        variant: 'error',
      })
      return
    }
    const arr = new Uint8Array(sampleBuffer)
    const blob = new Blob([arr], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const file = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.href = file
    link.download = 'Source_Document_Sample_Sheet.xlsx'
    link.click()
  }

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Activity Dashboard', to: '/activity-dashboard' },
              {
                name: `${attenType} ${title} Attendance`,
                to: null,
              },
            ]}
          />
        }
      />
      {userType == 'Admin' || userType == 'DD' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: !attType ? 'space-between' : 'flex-end',
          }}
        >
          {!attType && (
            <Box
              sx={{
                padding: '30px',
                boxShadow: '0px 3px 16px #2A32710F',
                borderRadius: '10px',
                width: '150px',
              }}
            >
              <Typography
                sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
              >
                {count}
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'poppins_medium',
                  opacity: '0.7',
                }}
              >
                Marked Schools
              </Typography>
            </Box>
          )}
          <form onSubmit={(e) => handleSubmit(e)}>
            <Stack
              gap={3}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="districtId">Select District</InputLabel>
                <Select
                  labelId="districtId"
                  id="districtId"
                  label="Select District"
                  value={districtId}
                  onChange={(e) => {
                    setAtdoPointId('')
                    setDistrictId(e?.target?.value)
                  }}
                >
                  {districts?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                <Select
                  labelId="atdoPointId"
                  id="atdoPointId"
                  label="Select ATDO Point"
                  value={atdoPointId}
                  onChange={(e) => setAtdoPointId(e?.target?.value)}
                >
                  {atdoPoints?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '145px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                </Box>
              </LocalizationProvider>{' '}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                }}
                type="submit"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  Apply
                </Typography>
              </Button>
            </Stack>
          </form>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: !attType ? 'space-between' : 'flex-end',
            alignItems: 'center',
          }}
        >
          {!attType && (
            <Box
              sx={{
                padding: '30px',
                boxShadow: '0px 3px 16px #2A32710F',
                borderRadius: '10px',
                width: '150px',
              }}
            >
              <Typography
                sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
              >
                {count}
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'poppins_medium',
                  opacity: '0.7',
                }}
              >
                Marked Schools
              </Typography>
            </Box>
          )}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ width: '145px', height: '50px' }}>
              <DatePicker
                value={date}
                onChange={(newValue) => {
                  setDate(newValue)
                }}
                inputFormat="DD MMM, YYYY"
                maxDate={new Date()}
                renderInput={(params) => <TextField {...params} />}
              />{' '}
            </Box>
          </LocalizationProvider>{' '}
        </Box>
      )}

      <DividerHeading title={`${attenType} ${title} Attendance`} />
      <Box>
        {attendanceDetails?.length == 0 ? (
          <SchoolAnalyticsEmptyScreen />
        ) : (
          <>
            <Table
              key={'overallAttendance'}
              dataSource={tableData}
              rowKey={'id'}
              columns={columns}
              loading={loading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />{' '}
            </Box>
          </>
        )}
      </Box>
    </div>
  )
}

export default TeacherAndStaffAttendanceDetailsScreen
