import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useQuery } from 'react-query'
import { http } from 'services/http'

const Filters = ({
  search,
  setSearch,
  districtId,
  setDistrictId,
  atdoPointId,
  setAtdoPointId,
  schoolId,
  setSchoolId,
  date,
  setDate,
}) => {
  const userType = localStorage.getItem('user_type')

  const fetchDistricts = async () => {
    const res = await http.get(`/statistics/master-data/district`)
    return res?.data
  }

  const { data: districts, isLoading } = useQuery(['getDistricts'], () =>
    fetchDistricts(),
  )

  const fetchAtdoPoints = async (districtId) => {
    const res = await http.get(`/statistics/master-data/atdo`)
    return res?.data?.filter((item) => {
      return item?.districtId === districtId
    })
  }

  const { data: atdoPoints, isLoading: atdoLoading } = useQuery(
    ['getAtdoPoints', districtId],
    () => fetchAtdoPoints(districtId),
    {
      enabled: Boolean(districtId),
    },
  )

  const fetchSchools = async (districtId, atdoPointId) => {
    const res = await http.get(`/schools`)
    return res?.data?.schools.filter((item) => {
      if (districtId && !atdoPointId) {
        return item?.districtId == districtId
      }

      if (districtId && atdoPointId) {
        return (
          item?.districtId == districtId && item?.atdoPointId == atdoPointId
        )
      }
    })
  }

  const { data: schools, isLoading: schoolsLoading } = useQuery(
    ['getSchools', districtId, atdoPointId],
    () => fetchSchools(districtId, atdoPointId),
    {
      enabled: Boolean(districtId),
    },
  )

  const handleClear = (e) => {
    e?.preventDefault()
    setDistrictId('')
    setAtdoPointId('')
    setDate(new Date())
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <TextField
          label="Search by Stock Item"
          sx={{ width: '218px' }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={({ target }) => {
            setSearch(target.value)
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {userType == 'Admin' || userType == 'DD' ? (
            <Stack
              gap={1}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="districtId">Select District</InputLabel>
                <Select
                  labelId="districtId"
                  id="districtId"
                  label="Select District"
                  value={districtId}
                  onChange={(e) => {
                    setAtdoPointId('')
                    setDistrictId(e?.target?.value)
                  }}
                >
                  {districts?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                <Select
                  labelId="atdoPointId"
                  id="atdoPointId"
                  label="Select ATDO Point"
                  value={atdoPointId}
                  onChange={(e) => setAtdoPointId(e?.target?.value)}
                >
                  {atdoPoints?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="schoolId">Select School</InputLabel>
                <Select
                  labelId="schoolId"
                  id="schoolId"
                  label="Select School"
                  value={schoolId}
                  onChange={(e) => setSchoolId(e?.target?.value)}
                >
                  {schools?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '145px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                </Box>
              </LocalizationProvider>{' '}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: ' white !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                  border: '1px solid black',
                }}
                onClick={() => handleClear()}
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  Clear
                </Typography>
              </Button>
            </Stack>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              gap={3}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '145px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />{' '}
                </Box>
              </LocalizationProvider>{' '}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: ' white !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                  border: '1px solid black',
                }}
                onClick={() => handleClear()}
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  Clear
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default Filters
