import { Box, Grid, TextField, Typography } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import DividerHeading from 'components/DividerHeading/DividerHeading'
import PageHeader from 'components/Mui/PageHeader'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React, { useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PaperCard from 'components/Mui/PaperCard/PaperCard'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import Loader from 'components/Loader'




function StockUsageAnalytics() {
  let { id } = useParams();

    const [date, setDate] = useState(new Date())
    const navigate = useNavigate()

    const fetchData = async () => {
      const res = await http.get(`/stock-indents/dashboard?schoolId=${id}`)
      return res?.data
    }
  
    const { data: statistics, isLoading } = useQuery(
      ['gccDashboardStatisticsbyschool'],
      () => fetchData(),
    )

    if(isLoading)<Loader/>

    console.log(statistics,"stat");


  return (
    <div>
         <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'GCC Stock', to: '/gcc' },
              {
                name: 'Stock Usage',
                to: null,
              },
            ]}
          />
        }
      />
            <DividerHeading title={'Stock Usage Analytics'} />
            <Box
            sx={{
              marginTop: '30px',
              padding: '30px',
              borderRadius: '10px',
              backgroundColor: '#F6F6F6',
              // boxShadow: '0px 3px 13px #2A32711A',
              // width: '600px',
            }}   >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'28px'}>
                <img src={'/images/element-equal.png'} />
                <Box>
                  <Typography
                    sx={{ fontFamily: 'poppins_semibold', fontSize: '22px' }}
                  >
                    Overall schools activity Report
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'poppins_medium',
                      fontSize: '16px',
                      opacity: '0.7',
                    }}
                  >
                    Tap on view more to see the detailed activity report of all
                    the schools{' '}
                  </Typography>
                </Box>
              </Box>
           <Box display="flex" gap={4}>
           {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '155px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider> */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  border: '1px solid #24272C',
                  padding: '11px 19px',
                  borderRadius: '10px',
                }}
                gap={'55px'}
                onClick={() => {
                    navigate(`/gcc/stockusagetable/${id}`)
                }}
              >
                <Typography
                  sx={{ fontFamily: 'poppins_semibold', fontSize: '13px' }}
                >
                  View more
                </Typography>
                <KeyboardArrowRightIcon />
              </Box>
           </Box>
            </Box>
          </Box>
          <Grid container spacing={2} >
            <Grid xs={4}>
            <PaperCard
                title="Total Number of students"
                count={
                 statistics?.totalNoOfPrimarySchoolStudents + statistics?.totalNoOfSecondarySchoolStudents
                }
                img="/images/Group 20896.png"
              />

            </Grid>
            <Grid xs={4}>
            <PaperCard
                title="Total number of primary school students"
                count={
                  statistics?.totalNoOfPrimarySchoolStudents
                }
                img="/images/Group 20896.png"
              />

            </Grid>
            <Grid xs={4}>
            <PaperCard
                title="Total number of secondary school students"
                count={
                  statistics?.totalNoOfSecondarySchoolStudents
                }
                img="/images/Group 20896.png"
              />

            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid xs={4}>
            <PaperCard
                title="Total Cost for stock usage"
                count={
                  statistics?.totalAmountUsedBySchools

                }
                img="/images/Group 20896.png"
              />

            </Grid>
            <Grid xs={4}>
            <PaperCard
                title="Total Amount used for Primary school students"
                count={
                  statistics?.totalAmountUsedForPrimarySchoolStudents
                }
                img="/images/Group 20896.png"
              />

            </Grid>
            <Grid xs={4}>
            <PaperCard
                title="Total Amount used for Secondary school students"
                count={
                  statistics?.totalAmountUsedForSecondarySchoolStudents
                }
                img="/images/Group 20896.png"
              />

            </Grid>
          </Grid>
       
    </div>
  )
}

export default StockUsageAnalytics