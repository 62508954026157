import { AppBar, Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import '../../App.css'

const MasterData = () => {
  const userType = localStorage.getItem('user_type')

  return (
    <div>
      <AppBar
        style={{
          // position: 'absolute',
          top: 74,
          left: '162px',
          backgroundColor: '#F4F4F4',
          boxShadow: 'none',
        }}
      >
        <nav
          style={{
            display: 'flex',
            paddingLeft: '38px',
          }}
        >
          {userType == 'Admin' && (
            <>
              <NavLink className={'masterDataNavLink'} to={'/master-data'} end>
                <Typography
                  className="masterDataNavLinkText"
                  sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
                >
                  Districts
                </Typography>
                <Box id="borderBottom"></Box>
              </NavLink>
              <NavLink
                className={'masterDataNavLink'}
                to={'/master-data/atdo-points'}
              >
                <Typography
                  className="masterDataNavLinkText"
                  sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
                >
                  ATDO Points
                </Typography>
                <Box id="borderBottom"></Box>
              </NavLink>
              <NavLink
                className={'masterDataNavLink'}
                to={'/master-data/mandals'}
              >
                <Typography
                  className="masterDataNavLinkText"
                  sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
                >
                  Mandals
                </Typography>
                <Box id="borderBottom"></Box>
              </NavLink>
            </>
          )}

          {userType == 'Admin' ? (
            <>
              <NavLink
                className={'masterDataNavLink'}
                to={'/master-data/food-menu'}
              >
                <Typography
                  className="masterDataNavLinkText"
                  sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
                >
                  Food Menu
                </Typography>
                <Box id="borderBottom"></Box>
              </NavLink>
            </>
          ) : (
            <>
              <NavLink className={'masterDataNavLink'} to={'/master-data'} end>
                <Typography
                  className="masterDataNavLinkText"
                  sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
                >
                  Food Menu
                </Typography>
                <Box id="borderBottom"></Box>
              </NavLink>
            </>
          )}
        </nav>
      </AppBar>
      <Box sx={{ marginTop: '50px' }}>
        <Outlet />
      </Box>
    </div>
  )
}

export default MasterData
