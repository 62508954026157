import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const IssueTypesForm = ({ data, toggleDrawer }) => {
  const [issues, setIssues] = useState([])
  const [issueIds, setIssueIds] = useState([])
  const [categories, setCategories] = useState([])
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  useEffect(() => {
    http.get(`/subjects`).then((res) => {
      console.log(res)
      setIssues(res?.data?.subjects)
    })
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: { name: data?.name, categoryId: data?.category?.id },
  })

  const queryClient = useQueryClient()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const onSubmit = (formData) => {
    if (edit) {
      http
        .patch(`/issues/${id}`, formData)
        .then((res) => {
          enqueueSnackbar('The issue has been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('issues')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      http
        .post(`/issues`, formData)
        .then((res) => {
          enqueueSnackbar('The issue has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('issues')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }
  console.log(data)

  useEffect(() => {
    http.get('/categories').then((res) => setCategories(res?.data?.categories))
  }, [])

  console.log(issueIds)

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={'text'}
            {...register('name', {
              required: true,
              pattern: { value: /^[A-Za-z,\-0-9 .]+$/ },
            })}
            label={'Issue Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.name?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.name?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="category">Category *</InputLabel>
            <Select
              labelId="category"
              id="category"
              label="Category *"
              {...register('categoryId', { required: true })}
              defaultValue={data?.category?.id}
              required={true}
            >
              {categories?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {edit ? 'Edit Issue' : 'Create Issue'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default IssueTypesForm
