import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const Form = ({ data, toggleDrawer }) => {
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState('')
  const [measure, setMeasure] = useState('')
  const [items, setItems] = useState([])
  const schoolId = localStorage?.getItem('schoolID')

  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      stockMaterialId: data?.stockMaterial,
    },
  })

  console.log(data)

  const queryClient = useQueryClient()
  const itemId = watch('stockMaterialId')

  useEffect(() => {
    itemId &&
      setMeasure(items?.filter((item) => item?.id == itemId)[0]?.unitOfMeasure)
  }, [itemId])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    http.get(`/stock-materials`).then((res) => {
      console.log(res)
      setItems(res?.data)
    })
  }, [])

  const onSubmit = (formData) => {
    console.log(formData)
    if (edit) {
      const payload = {
        lastUpdatedDate: moment(formData?.lastUpdatedAt).format('YYYY-MM-DD'),
        schoolId: schoolId,
        quantity: formData?.currentQuantity,
        stockMaterialId: formData?.stockMaterialId?.id,
      }
      console.log(payload)

      http
        .post(`/stocks`, payload)
        .then((res) => {
          console.log(res)
          enqueueSnackbar(res?.data, {
            variant: 'success',
          })
          queryClient.invalidateQueries('schoolStock')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      const payload = {
        lastUpdatedDate: moment(formData?.lastUpdatedAt).format('YYYY-MM-DD'),
        quantity: formData?.currentQuantity,
        stockMaterialId: formData?.stockMaterialId,
        schoolId: schoolId,
      }
      http
        .post(`/stocks`, payload)
        .then((res) => {
          console.log(res)
          enqueueSnackbar(res?.data, {
            variant: 'success',
          })
          queryClient.invalidateQueries('schoolStock')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
  }

  const UNIT = data
    ? data?.stockMaterial?.unitOfMeasure
    : measure?.length != 0
    ? measure
    : 'Unit'

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="stockMaterialId"
            render={({ field: { onChange, value } }) => {
              console.log(value)
              return (
                <Autocomplete
                  id="country-select-demo"
                  sx={{ marginTop: '25px' }}
                  options={items}
                  disabled={data ? true : false}
                  defaultValue={value}
                  getOptionLabel={(option) => option.itemName}
                  renderOption={(props, option) => {
                    return (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.itemName}
                      </Box>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      required={edit ? false : true}
                      {...params}
                      label="Select Material"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                    />
                  )}
                  onChange={(_, data) => {
                    console.log(data)
                    onChange(data?.id)
                  }}
                />
              )
            }}
          />
          <Box
            m={0}
            p={0}
            sx={{ display: 'flex', alignItems: 'center' }}
            gap={'5px'}
          >
            <TextField
              type={'text'}
              {...register('currentQuantity', {
                required: edit ? false : true,
              })}
              sx={{ marginTop: '25px', width: '300px' }}
              label={edit ? 'Add Quantity' : 'Quantity'}
              required={edit ? false : true}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'5px'}>
              <Typography
                sx={{
                  padding: '13.38px',
                  border: '1px solid #D9D9D9',
                  marginTop: '23px',
                  borderRadius: '9px',
                }}
              >
                {UNIT}
              </Typography>
            </Box>
            {errors?.currentQuantity?.type == 'required' && (
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  color: 'red',
                }}
              >
                This field is required
              </Typography>
            )}
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ marginTop: '24px' }}>
              <Controller
                control={control}
                name="lastUpdatedAt"
                render={({ field: { ref, onBlur, name, ...field } }) => {
                  console.log(field)
                  return (
                    <DatePicker
                      {...field}
                      label="Last Updated Date"
                      maxDate={new Date()}
                      inputFormat={'YYYY-MM-DD'}
                      renderInput={(inputProps) => (
                        <TextField
                          {...inputProps}
                          helperText={null}
                          fullWidth={true}
                          required={edit ? false : true}
                        />
                      )}
                      onChange={(value) =>
                        field.onChange(
                          moment(new Date(value)).format('YYYY-MM-DD'),
                        )
                      }
                    />
                  )
                }}
                defaultValue={
                  data
                    ? moment(new Date(data?.lastUpdatedDate))
                    : moment(new Date())
                }
              />
            </Box>
          </LocalizationProvider>
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {edit ? 'Edit Stock Item' : 'Add Stock Item'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default Form
