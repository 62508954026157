import { Box, IconButton, IconButtonProps, styled } from '@mui/material'
import { ReactNode } from 'react'
import {
  DeleteIcon,
  DisabledDeleteIcon,
  DisabledEditIcon,
  EditIcon,
  ViewIcon,
  PrintIcon,
} from './Icons'

interface ButtonProps extends IconButtonProps {
  disabledText?: string
  actionType?: 'delete' | 'edit' | 'view' | 'print'
}

function Button({ ...props }: ButtonProps) {
  const handleClick = (e) => {
    e.stopPropagation()
    if (!props.onClick) return
    props.onClick(e)
  }
  return (
    <StyledIconButton
      {...props}
      onClick={handleClick}
      actionType={props.actionType ? props.actionType : 'view'}
    >
      {props.children}
    </StyledIconButton>
  )
}

function DisabledButton({
  disabledText,
  children,
}: {
  disabledText?: string
  children: ReactNode
}) {
  return (
    <Box
      sx={{
        maxHeight: '14px',
      }}
      title={disabledText ? disabledText : 'disabled'}
    >
      {children}
    </Box>
  )
}

export function ViewButton({ disabled, disabledText, ...props }: ButtonProps) {
  if (disabled) {
    return (
      <DisabledButton {...props} disabledText={disabledText}>
        <ViewIcon />
      </DisabledButton>
    )
  }
  return (
    <Button {...props} actionType="view">
      <ViewIcon />
    </Button>
  )
}

export function EditButton({ disabled, disabledText, ...props }: ButtonProps) {
  if (disabled) {
    return (
      <DisabledButton
        disabledText={disabledText ?? 'Default types can not be edited'}
      >
        <DisabledEditIcon />
      </DisabledButton>
    )
  }
  return (
    <Button {...props} actionType="edit">
      <EditIcon />
    </Button>
  )
}

export function PrintButton({ disabled, disabledText, ...props }: ButtonProps) {
  if (disabled) {
    return (
      <DisabledButton
        disabledText={disabledText ?? 'Default types can not be edited'}
      >
        <DisabledEditIcon />
      </DisabledButton>
    )
  }
  return (
    <Button {...props} actionType="print">
      <PrintIcon />
    </Button>
  )
}

export function DeleteButton({
  disabled,
  disabledText,
  ...props
}: ButtonProps) {
  if (disabled) {
    return (
      <DisabledButton
        disabledText={disabledText ?? 'Default types can not be deleted'}
      >
        <DisabledDeleteIcon />
      </DisabledButton>
    )
  }
  return (
    <Button {...props} actionType="delete">
      <DeleteIcon />
    </Button>
  )
}

const StyledIconButton = styled(IconButton)<{
  actionType: 'delete' | 'edit' | 'view' | 'print'
}>(({ theme, actionType }) => ({
  padding: 0,
  '&:hover': {
    '& path': {
      fill:
        actionType === 'delete'
          ? 'red'
          : actionType === 'edit'
          ? theme.palette.common.yellow
          : actionType === 'view'
          ? '#0A1160'
          : theme.palette.error.main,
    },
  },
}))
