import { Box, Grid, Typography } from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useNavigate } from 'react-router-dom'

const AksharaDashboard = () => {
  const navigate = useNavigate()

  return (
    <Box p={2} pt={0}>
      <Box>
        <DividerHeading title="Test Performance" />
        <Box>
          <Grid container spacing={2}>
            {tabData?.map((item, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Box
                  width="100%"
                  height="100%"
                  sx={{
                    boxShadow: '0px 3px 13px #2A32711A',
                    cursor: 'pointer',
                    borderRadius: '10px',
                  }}
                  onClick={() => navigate(item.path)}
                >
                  <Box p={2}>
                    <img
                      src={item?.img}
                      style={{ width: '50px', height: '50px' }}
                    />
                    <Box
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box p={1}>
                        <Typography
                          sx={{
                            fontSize: '15px',
                            fontFamily: 'poppins_medium',
                            opacity: '0.7',
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box p={1}>
                        <ArrowForwardIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default AksharaDashboard

const tabData = [
  {
    title: 'Akshara Jyothi Tests',
    img: '/images/Group 21585.svg',
    path: '/akshara-jyothi/tests',
  },
  {
    title: 'Test Performance Comparison',
    img: '/images/Group 21586.svg',
    path: '/akshara-jyothi/test-comparison',
  },
  {
    title: 'School Performance Report',
    img: '/images/Group 21587.svg',
    path: '/akshara-jyothi/performance-report',
  },
]
