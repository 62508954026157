import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material'
import Loader from 'components/Loader'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  getSchoolClassWithSubjects,
  getTestComparision,
  getTests,
} from 'services/api/aksharaJyothi'
import Schools from '../Schools'
import TestDetails from './TestDetails'

const PerformanceComparison = () => {
  const navigate = useNavigate()
  const [filterData, setFilterData] = useState({
    id: null,
    schoolId: null,
    classId: null,
    subjectId: null,
    testOne: null,
    testTwo: null,
  })

  const { data: tests, isLoading: testLoading } = useQuery(['tests'], getTests)

  const {
    data: comparisionData,
    isLoading: comparisionLoading,
    error,
  } = useQuery(
    [
      'test-comparision',
      {
        schoolId: filterData?.id,
        classId: filterData?.classId,
        subjectId: filterData?.subjectId,
        testOne: filterData?.testOne,
        testTwo: filterData?.testTwo,
      },
    ],
    getTestComparision,
    {
      enabled:
        filterData?.schoolId && filterData?.classId && filterData?.subjectId
          ? true
          : false,
    },
  )

  const { data, isLoading } = useQuery(
    ['class', filterData?.schoolId],
    getSchoolClassWithSubjects,
    {
      enabled: filterData?.schoolId ? true : false,
    },
  )
  useEffect(() => {
    setFilterData({
      ...filterData,
      classId: data?.data?.[0]?.id,
      subjectId: data?.data[0]?.subjects[0]?.id,
      testOne: tests?.data[0]?.id,
      testTwo: tests?.data[1]?.id,
    })
  }, [data?.data, tests?.data])

  const handleChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value })
  }

  return (
    <Box p={2} pt={3}>
      <Button
        onClick={() => navigate('/akshara-jyothi-dashboard')}
        variant="text"
        sx={{ color: 'black' }}
        startIcon={<ArrowBack />}
      >
        Test Performance Comparison
      </Button>
      <Box mt={1}>
        <Grid container spacing={2}>
          <Grid item sx={12} md={3.5} lg={3.3} xl={3}>
            <Box>
              <Schools filterData={filterData} setFilterData={setFilterData} />
            </Box>
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <Grid item sx={12} md={8.5} lg={8.7} xl={9}>
              <Grid container spaacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    '@media (min-width: 900px)': {
                      textAlign: 'right',
                    },
                  }}
                >
                  <TextField
                    sx={{ width: 200, mt: 2, textAlign: 'left' }}
                    select
                    name="classId"
                    label="Select Class"
                    onChange={handleChange}
                    value={filterData?.classId || ''}
                  >
                    {data?.data?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        class {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{
                      width: 200,
                      mt: 2,
                      '@media (min-width: 900px)': {
                        ml: 1,
                      },
                    }}
                    select
                    name="subjectId"
                    label="Select Subject"
                    onChange={handleChange}
                    value={filterData?.subjectId || ''}
                  >
                    {data?.data
                      ?.filter((item) => item?.id === filterData?.classId)[0]
                      ?.subjects?.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Box>
                <TestDetails
                  filterData={filterData}
                  handleChange={handleChange}
                  setFilterData={setFilterData}
                  tests={tests?.data}
                  testLoading={testLoading}
                  comparisionData={comparisionData?.data}
                  comparisionLoading={comparisionLoading}
                  error={error?.response?.data?.message}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default PerformanceComparison
