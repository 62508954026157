import { Box, styled, Typography } from '@mui/material'

export default function DividerHeading({ title }) {
  return (
    <StyledContainer title={title}>
      <Typography sx={{ fontSize: '18px', fontFamily: 'poppins_semibold' }}>
        {title}
      </Typography>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)(({ theme, title }) => ({
  margin: '20px 0',
  borderBottom: theme.borders.grayLight,
  '&::after': {
    borderRadius: '2px',
    content: '""',
    display: 'block',
    marginTop: '5px',
    width: `${title?.length - 2}rem`,
    height: '4px',
    backgroundColor: theme.palette.common.green,
    bottom: '10px',
  },
}))
