import { Box, CircularProgress, Grid } from '@mui/material'
import Card from 'components/Mui/Card'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { http } from 'services/http'

const AcademicAndExtraCurriculars = () => {
  const type = 'Academic'
  const [schoolActivities, setSchoolActivities] = useState()
  const [date] = useOutletContext()
  const id = localStorage.getItem('schoolId')
  const [loading, setLoading] = useState(false)
  console.log(id, date)
  useEffect(() => {
    setLoading(true)
    http
      .get(`/statistics/school/${id}?type=${type}&date=${date}`)
      .then((res) => {
        console.log(res)
        setSchoolActivities(res?.data)
        setLoading(false)
      })
  }, [date])
  const cards = [
    {
      title: 'Morning Physical Activity',
      presentCount: `${schoolActivities?.physicalActivity?.completed}`,
      presentContent: 'Number of students Participating',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.physicalActivity?.percentage)
          ? schoolActivities?.physicalActivity?.percentage
          : schoolActivities?.physicalActivity?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Morning Study Hour',
      presentCount: `${schoolActivities?.studyHour?.completed}`,
      presentContent: 'Number of students Participating',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.studyHour?.percentage)
          ? schoolActivities?.studyHour?.percentage
          : schoolActivities?.studyHour?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Prayer',
      presentCount: `${schoolActivities?.prayer?.completed}`,
      presentContent: 'Number of students Participating',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.prayer?.percentage)
          ? schoolActivities?.prayer?.percentage
          : schoolActivities?.prayer?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Night Study Hour',
      presentCount: `${schoolActivities?.nightStudyHour?.completed}`,
      presentContent: 'Number of students Participating',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.nightStudyHour?.percentage)
          ? schoolActivities?.nightStudyHour?.percentage
          : schoolActivities?.nightStudyHour?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Sports Activity',
      presentCount: `${schoolActivities?.sports?.completed}`,
      presentContent: 'Number of students Participating',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.sports?.percentage)
          ? schoolActivities?.sports?.percentage
          : schoolActivities?.sports?.percentage?.toFixed(1)
      }%`,
    },
    {
      title: 'Special Class (Bridge/SSC Courses)',
      presentCount: `${schoolActivities?.specialClass?.completed}`,
      presentContent: 'Number of students present',
      presentPercentage: `${
        Number.isInteger(schoolActivities?.specialClass?.percentage)
          ? schoolActivities?.specialClass?.percentage
          : schoolActivities?.specialClass?.percentage?.toFixed(1)
      }%`,
    },
  ]
  return (
    <Grid container spacing={1}>
      {cards?.map((item) => {
        if (loading) {
          return <CircularProgress />
        }
        return (
          <Grid item xl={3} lg={4} md={4}>
            <Card
              link={`/schools/academic-and-extra-curriculars-details?activity=${item?.title}`}
              title={item?.title}
              presentCount={item?.presentCount}
              presentContent={item?.presentContent}
              presentPercentage={item?.presentPercentage}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default AcademicAndExtraCurriculars
