const userType = localStorage.getItem('user_type')
export const menu = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: '/images/element-4.png',
    children: [],
  },
  {
    title: 'Activity Dashboard',
    path: '/activity-dashboard',
    icon: '/images/vuesax-outline-message-text.png',
    children: [],
  },
  {
    title: 'Akshara Jyothi Dashboard',
    path: '/akshara-jyothi',
    icon: '/images/Akshara.png',
    children: [],
  },
  {
    title: 'Schools',
    path: '/schools',
    icon: '/images/vuesax-twotone-building-4.png',
    children: [],
  },
  {
    title: 'Engineering',
    path: '/engineering',
    icon: '/images/enginnering.png',
    children: [],
  },
  {
    title: 'Medical',
    path: '/Medical',
    icon: '/images/Medical.png',
    children: [],
  },
  {
    title: 'GCC',
    path: '/gcc',
    icon: '/images/GCC.png',
    children: [],
  },
]
