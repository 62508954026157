import { Box, Grid, Typography } from '@mui/material'
import Loader from 'components/Loader'
import SearchContainer from 'components/SearchContainer'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getRoleBasedSchool } from 'services/api/aksharaJyothi'

const Schools = ({ filterData, setFilterData }) => {
  const [search, setSearch] = useState('')
  const [state, setState] = useState()

  const { data, isLoading } = useQuery(
    [
      'role-based-school',
      {
        search: search,
      },
    ],
    getRoleBasedSchool,
    {
      onSuccess: (res) => {
        setState(res?.data)
      },
    },
  )

  useEffect(() => {
    if (!isLoading) {
      setFilterData({
        ...filterData,
        schoolId: data?.data?.schools[0]?.schoolId,
        id: data?.data?.schools[0]?.id,
      })
    }
  }, [state])

  return (
    <Box
      sx={{
        borderRadius: '10px 10px 10px 10px',
        boxShadow: '0px 3px 13px #2A32711A',
        width: '90vw',
        '@media (min-width: 1000px)': {
          position: 'fixed',
          width: '230px',
        },
        '@media (min-width: 1200px)': {
          position: 'fixed',
          width: '250px',
        },
        '@media (min-width: 1400px)': {
          position: 'fixed',
          width: '300px',
        },
      }}
    >
      <Box>
        <Box
          p={1.3}
          sx={{
            backgroundColor: '#F2F2F2',
            borderRadius: '10px 10px 0px 0px',
          }}
        >
          <Typography variant="h4">Schools</Typography>
        </Box>

        <Box p={1}>
          <SearchContainer
            debounced
            minWidth="100%"
            placeHolder="Search by School Name"
            onChange={setSearch}
          />
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            width: '230px',
            height: '210px',
            '@media (min-width: 900px)': {
              height: '68vh',
            },
          }}
        >
          <Loader minHeight={100} />
        </Box>
      ) : (
        <Box
          p={1}
          pt={0}
          sx={{
            height: '210px',
            '@media (min-width: 900px)': {
              height: '68vh',
            },
          }}
          overflow="auto"
        >
          {data?.data?.schools?.map((item, index) => (
            <Box
              mt={index ? 1 : 0}
              key={index}
              sx={{
                border:
                  item?.schoolId == filterData.schoolId
                    ? '1px solid #02A208'
                    : '1px solid #2B2D221C',
                borderRadius: '10px 10px 10px 10px',
                cursor: 'pointer',
              }}
              onClick={() =>
                setFilterData({
                  ...filterData,
                  schoolId: item?.schoolId,
                  id: item.id,
                })
              }
            >
              {console.log(item?.schoolId == filterData.schoolId)}
              <DataCard
                name={item?.name}
                atdo={item?.atdoPoint?.name}
                headMaster={item?.headMaster?.fullName}
                mobile={item?.headMaster?.mobileNumber}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default Schools

// const data = [
//   {
//     name: 'School Name',
//     point: 'ATDO Point',
//     headmaster: 'Headmaster',
//     number: 'Mobile Number',
//   },
// ]

const DataCard = ({ name, headMaster, atdo, mobile }) => {
  return (
    <Box p={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">School Name</Typography>
          <Typography variant="h5">{name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">ATDO Point</Typography>
          <Typography variant="h5">{atdo}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Headmaster</Typography>
          <Typography variant="h5">{headMaster}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Mobile Number</Typography>
          <Typography variant="h5">{mobile}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
