import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const TicketDetails = ({
  data,
  ticket,
  setTicket,
  search,
  setSearch,
  loading,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const ticketID = localStorage.getItem('TicketID')
  const [engTickets, setEngTickets] = useState(data)
  const [status, setStatus] = useState([
    'PENDING',
    'APPROVED',
    'COMPLETED',
    'CANCELLED',
    'PAID',
  ])

  console.log(data)
  useEffect(() => {
    data?.length != 0 &&
      (!ticketID
        ? setEngTickets(data)
        : setEngTickets(data?.filter((item) => item?.id == ticketID)))
  }, [data])

  useEffect(() => {
    if (ticketID) {
      console.log(ticketID)
      console.log(data?.filter((item) => item?.id == ticketID)[0])

      setTicket(data?.filter((item) => item?.id == ticketID)[0])
    }
  }, [])

  useEffect(() => {
    if (ticketID) {
      setEngTickets(data?.filter((item) => item?.id == ticketID))
    }
  }, [ticketID])

  useEffect(() => {
    engTickets?.length !== 0 && setTicket({ ...engTickets[0] })
  }, [engTickets])

  const StatusBox = styled(Box)(({ status }) => {
    return {
      backgroundColor:
        status === 'PENDING'
          ? '#FFFAEF'
          : status === 'WORK IN PROGRESS'
          ? '#FEF2E6'
          : status === 'ESCALATED'
          ? '#E9F6FA'
          : status === 'ASSIGNED'
          ? '#0a11601a'
          : '#00d9a626',
      //   color: theme.colors.warning.main,
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '8px 8px',
    }
  })

  const num = 1

  console.log(ticketID)

  const changeSelected = (item) => {
    setTicket(item)
  }
  return (
    <Box>
      <TextField
        label="Search by Ticket ID"
        sx={{ mb: 2, width: '100%' }}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {data?.length === 0 ? (
        <Box>
          <SchoolAnalyticsEmptyScreen />
        </Box>
      ) : loading ? (
        <Loader />
      ) : (
        <Box overflow="auto" maxHeight="700px" className="scrollBox">
          {engTickets?.map((item, index) => {
            let desc =
              item?.description?.length > 500
                ? item?.description?.slice(0, 500) + '...'
                : item?.description
            console.log(ticket?.id)
            console.log(item?.id)
            return (
              <Box
                // key={index}
                p={2}
                mb={1.5}
                border={
                  item?.id == ticket?.id
                    ? `1px solid black`
                    : '1px solid rgba(34, 34, 34, 0.08)'
                }
                borderRadius="5px"
                sx={{ cursor: 'pointer' }}
                onClick={() => changeSelected(item)}
              >
                <Grid container sx={{ width: '100%' }}>
                  <Grid item xs={6}>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Ticket ID
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {item?.ticketName ?? 'NA'}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Date
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {moment(item?.createdAt).format('DD-MM-YYYY')}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Category
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {item?.category?.name ?? 'NA'}
                      </Typography>
                    </Box>
                    <Box pt={2.5}>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_medium',
                          fontSize: '14px',
                          opacity: '0.5',
                        }}
                      >
                        Description
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'poppins_semibold',
                          fontSize: '14px',
                        }}
                      >
                        {desc}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Box>
                      <StatusBox status={item?.status}>
                        <Typography
                          sx={{
                            color:
                              item?.status === 'PENDING'
                                ? '#FFCF64'
                                : item?.status === 'WORK IN PROGRESS'
                                ? '#F27F0C'
                                : item?.status === 'ESCALATED'
                                ? `#25A4CF`
                                : item?.status === 'ASSIGNED'
                                ? `#0A1160`
                                : '#00D9A6',
                          }}
                        >
                          {item?.status}
                        </Typography>
                      </StatusBox>
                      <Box
                        pt={2.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          School Name
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {item?.school?.name}
                        </Typography>
                      </Box>
                      <Box
                        pt={2.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: 'poppins_medium',
                            fontSize: '14px',
                            opacity: '0.5',
                          }}
                        >
                          Priority
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'poppins_semibold',
                            fontSize: '14px',
                          }}
                        >
                          {item?.priority}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
