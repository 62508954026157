import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { http } from 'services/http'
import './ImagePopup.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper'
import moment from 'moment'

const ImagePopupFood = ({ setOpen, schoolDetails }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    outline: 'none',
    // border: '0px',
  }

  const handleClose = () => setOpen(false)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            height: '445px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper"
            style={{
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            {schoolDetails?.media?.length == 0 ? (
              <SwiperSlide>
                {' '}
                <img
                  src={
                    'https://jss-giri.s3.ap-south-1.amazonaws.com/blank_image.png'
                  }
                  style={{
                    border: 'none',
                    height: '445px',
                    width: '100%',
                    objectFit: 'cover',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  }}
                />
              </SwiperSlide>
            ) : (
              schoolDetails?.media?.map((item) => {
                return (
                  <SwiperSlide>
                    {' '}
                    <img
                      src={item}
                      style={{
                        border: 'none',
                        height: '445px',
                        width: '100%',
                        objectFit: 'cover',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                      }}
                    />
                  </SwiperSlide>
                )
              })
            )}
          </Swiper>
        </Box>
        <Box sx={{ height: '250px', padding: '38px' }}>
          <Stack direction={'row'} gap={'18px'}>
            <Typography
              sx={{ fontFamily: 'poppins_semibold', fontSize: '26px' }}
            >
              {schoolDetails?.school?.name ?? 'NA'}
            </Typography>
            <Box
              sx={{
                backgroundColor: '#FFF1CE',
                borderRadius: '10px',
                padding: '6px 13px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ fontFamily: 'poppins_semibold', fontSize: '14px' }}
              >{`District: ${
                schoolDetails?.school?.district?.name ?? 'NA'
              }`}</Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: '#FFF1CE',
                borderRadius: '10px',
                padding: '6px 13px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ fontFamily: 'poppins_semibold', fontSize: '14px' }}
              >{`ATDO Point: ${
                schoolDetails?.school?.atdoPoint?.name ?? 'NA'
              }`}</Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: '#FFF1CE',
                borderRadius: '10px',
                padding: '6px 13px',
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ fontFamily: 'poppins_semibold', fontSize: '14px' }}
              >{`Mandal: ${
                schoolDetails?.school?.mandal?.name ?? 'NA'
              }`}</Typography>
            </Box>
          </Stack>
          <Grid container sx={{ marginTop: '1px' }} rowSpacing={5}>
            <Grid item xl={4} lg={4} md={4}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Date
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                  marginTop: '8px',
                }}
              >
                {schoolDetails?.date ?? 'NA'}
              </Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Number of students present
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                  marginTop: '8px',
                }}
              >
                {schoolDetails?.noOfStudents ?? 'NA'}
              </Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Attendance Percentage
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                  marginTop: '8px',
                }}
              >
                {schoolDetails?.percentage
                  ? (Number.isInteger(schoolDetails?.percentage)
                      ? schoolDetails?.percentage
                      : schoolDetails?.percentage?.toFixed(1)) + '%'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Ingredient
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                  marginTop: '8px',
                }}
              >
                {schoolDetails?.foodMenu?.name ?? 'NA'}
              </Typography>
            </Grid>

            <Grid item xl={4} lg={4} md={4}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Updated on
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                  marginTop: '8px',
                }}
              >
                {schoolDetails?.updatedAt
                  ? moment(schoolDetails?.updatedAt)?.format('YYYY-MM-DD')
                  : 'NA'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  )
}

export default ImagePopupFood
