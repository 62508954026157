import { Box, Paper, Typography } from '@mui/material'
import React from 'react'

const SmallCard = ({ title, img, count }) => {
  return (
    <Box
      sx={{
        height: '95.5px',
        padding: '4px 20px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 3px 13px #2A32711A',
        marginRight: '17px',
        marginTop: '17px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
        <Box>
          <img src={img} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_medium',
              opacity: '0.5',
              marginBottom: '10px',
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ fontSize: '14px', fontFamily: 'poppins_semibold' }}>
            {count}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default SmallCard
