import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import '../../App.css'
import { useForm } from 'react-hook-form'
import { http } from 'services/http'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const Login = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()

  useEffect(() => {
    const authenticate = localStorage.getItem('token') ? true : false
    authenticate && navigate('/', { replace: true })
  }, [])
  const onSubmit = (data) => {
    http
      .post('/auth/user', data)
      .then((res) => {
        localStorage.setItem('token', res.data.access_token)
        localStorage.setItem('user_type', res?.data?.type)
        window.location.href = '/'
      })
      .catch((err) => {
        enqueueSnackbar('Bad Credentials, try again', { variant: 'warning' })
      })
  }

  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ background: '#FAF5F2', height: '100vh', width: '50vw' }}>
        <Box
          gap={2.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '8.5rem',
          }}
        >
          <img src={'/images/Group 21087.png'} />
          {/* <Typography
            sx={{ fontSize: '48px', fontFamily: 'modesto_expanded_light' }}
          >
            ITDA
          </Typography> */}
        </Box>
        <Box sx={{ marginTop: '-8rem' }}>
          <img
            style={{ width: '100%' }}
            src={'/images/Asset 1@4x.png'}
            alt="School"
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: '100vh',
          width: '50vw',
          display: 'flex',
          alignItems: 'center',
          marginLeft: '80px',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{ fontSize: '38px', fontFamily: 'poppins_semibold' }}>
            Welcome to ITDA Utnoor
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontFamily: 'poppins_regular',
              opacity: '0.7',
              marginTop: '16px',
            }}
          >
            Enter the following details to proceed to login
          </Typography>
          <InputLabel sx={{ marginTop: '40px' }}>
            <Typography
              variant="h6"
              sx={{ fontFamily: 'poppins_medium', opacity: '0.85' }}
            >
              Email Address
            </Typography>
          </InputLabel>
          <TextField
            {...register('username', { required: true })}
            sx={{ marginTop: '11px' }}
            required={true}
          />
          {errors?.username && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <InputLabel sx={{ marginTop: '30px' }}>
            <Typography
              variant="h6"
              sx={{ fontFamily: 'poppins_medium', opacity: '0.85' }}
            >
              Password
            </Typography>
          </InputLabel>
          <TextField
            {...register('password', { required: true })}
            type={showPassword ? 'text' : 'password'}
            sx={{ marginTop: '11px' }}
            required={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors?.password && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <Button
            variant="contained"
            sx={{ backgroundColor: 'black !important', marginTop: '50px' }}
            type="submit"
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              Login
            </Typography>
          </Button>
        </form>
      </Box>
    </Box>
  )
}

export default Login
