import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import { EditButton, ViewButton } from 'components/IconButtons'
import Loader from 'components/Loader'
import Table from 'components/Mui/TableComponent'
import { TextModal } from 'components/TextModal'
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from 'pages/MasterData/DrawerTemplate'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import EmptyScreen from './EmptyScreen'
import Form from './Form'

const StockMaterials = () => {
  const [formData, setFormData] = useState([])
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [search, setSearch] = useState('')
  const [tableData, setTableData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [details, setDetails] = useState({})
  const [lowerClassTotalPrice, setLowerClassTotalPrice] = useState(0)
  const [upperClassTotalPrice, setUpperClassTotalPrice] = useState(0)

  const fetchAssets = async (search, page) => {
    const res = await http.get(
      `/stock-materials?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`,
    )
    setTableData(res?.data)
  }

  const { isLoading } = useQuery(['stock-materials', search, page], () =>
    fetchAssets(search, page),
  )

  useEffect(() => {
    if (tableData?.length !== 0) {
      http.get(`/stock-materials?search=${search}`).then((res) => {
        setCount(res?.data?.length)
        setLowerClassTotalPrice(
          '₹ ' +
            (Math.round(
              res?.data?.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  currentValue.lowerClassQuantityPerDay * currentValue.rate,
                0,
              ),
            ) *
              100) /
              100,
        )
        setUpperClassTotalPrice(
          '₹ ' +
            (Math.round(
              res?.data?.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  currentValue.upperClassQuantityPerDay * currentValue.rate,
                0,
              ),
            ) *
              100) /
              100,
        )
      })
    }
  }, [tableData, search])

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const handleViewClick = (row) => {
    setModalOpen(true)
    setDetails(row)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'itemName',
      key: 'itemName',
    },
    {
      title: 'Unit of Measure',
      dataIndex: 'unitOfMeasure',
      key: 'unitOfMeasure',
    },
    {
      title: 'Price per Unit',
      dataIndex: 'rate',
      key: 'rate',
      render: (colData) => {
        const price = colData
        return '₹ ' + Math.round(price * 100) / 100
      },
    },
    {
      title: 'Total Cost per day',
      render: (_, row) => {
        if (!row?.lowerClassQuantityPerDay || !row?.upperClassQuantityPerDay) {
          return 'NA'
        }
        const sum =
          (row?.lowerClassQuantityPerDay + row?.upperClassQuantityPerDay) *
          row?.rate
        if (sum % 1 !== 0) {
          return '₹ ' + Math.round(sum * 100) / 100
        } else {
          return '₹ ' + sum
        }
      },
    },

    {
      title: 'Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (rowData) =>
        rowData == 'nonPerishable' ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Non-Perishable
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Perishable
          </Box>
        ),
    },
    {
      title: 'Minimum Indent Quantity',
      dataIndex: 'minimumIndentQuantity',
      key: 'minimumIndentQuantity',
      render: (colData, row) => {
        return colData
          ? Math.round(colData * 1000) / 1000 + ' ' + row?.unitOfMeasure
          : 'NA'
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => (
        <Box display="flex" gap="10px">
          <ViewButton onClick={() => handleViewClick(row)} />
          <EditButton onClick={() => handleEditClick(row)} />
        </Box>
      ),
    },
  ]

  function handlePagination(e, v) {
    setPage(v)
  }

  return (
    <div>
      {modalOpen && <TextModal setOpen={setModalOpen} row={details} />}{' '}
      <Box>
        <DividerHeading title={'Stock Materials'} />

        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <form className="mb-4" onSubmit={() => {}}>
              <TextField
                label="Search by Name"
                sx={{ width: '494px', marginBottom: '1.5rem' }}
                hookForm={false}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => {}}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
            </form>

            <Box sx={{ display: 'flex' }} gap={'18px'}>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: '10px',
                  borderColor: 'black !important',
                  background: 'transparent !important',
                }}
                onClick={() => {
                  setFormData(null)
                  toggleDrawer(true)
                }}
              >
                {' '}
                <Typography
                  sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
                >
                  + Add Material
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box mt={'-5px'} mb={'20px'} sx={{ display: 'flex' }} gap={'23px'}>
            <Box
              sx={{
                padding: '30px',
                boxShadow: '0px 3px 16px #2A32710F',
                borderRadius: '10px',
                width: '250px',
              }}
            >
              <Typography
                sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
              >
                {lowerClassTotalPrice ?? <Loader />}
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'poppins_medium',
                  opacity: '0.7',
                }}
              >
                Total Price per day (III-VII Class)
              </Typography>
            </Box>
            <Box
              sx={{
                padding: '30px',
                boxShadow: '0px 3px 16px #2A32710F',
                borderRadius: '10px',
                width: '250px',
              }}
            >
              <Typography
                sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
              >
                {upperClassTotalPrice ?? <Loader />}
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'poppins_medium',
                  opacity: '0.7',
                }}
              >
                Total Price per day (VIII-X Class)
              </Typography>
            </Box>
          </Box>
          {isLoading || !tableData ? (
            <CircularProgress />
          ) : tableData?.length == 0 ? (
            <center>
              <EmptyScreen />
            </center>
          ) : (
            <>
              <Table
                key={'Stock Materials'}
                dataSource={tableData}
                rowKey={'id'}
                columns={columns}
                loading={isLoading}
              />
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={count > 10 ? Math.ceil(count / 10) : 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePagination}
                  page={page}
                />
              </Box>
            </>
          )}

          <Drawer
            anchor="right"
            open={open}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
          >
            <StyledDrawerHeader>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {formData ? 'Edit Stock Material' : 'Add Stock Material'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  opacity: '0.5',
                  cursor: 'pointer',
                }}
                onClick={() => toggleDrawer(false)}
              >
                Cancel
              </Typography>
            </StyledDrawerHeader>
            <StyledDrawerContent>
              <Box sx={{ width: '550px' }}>
                <Form data={formData} toggleDrawer={toggleDrawer} />
              </Box>
            </StyledDrawerContent>
          </Drawer>
        </Box>
      </Box>
    </div>
  )
}

export default StockMaterials
