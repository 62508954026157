import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import MasterDataEmptyScreen from 'components/MasterDataEmptyScreen'
import SearchIcon from '@mui/icons-material/Search'
import Drawer from '@mui/material/Drawer'
import React, { useEffect, useState } from 'react'
import Table from 'components/Mui/TableComponent'
import { useNavigate } from 'react-router-dom'
import { EditButton, ViewButton } from 'components/IconButtons'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import { Close } from '@mui/icons-material'
import SchoolStaffForm from './SchoolStaffForm'
import moment from 'moment'
import { http } from 'services/http'
import { useQuery, useQueryClient } from 'react-query'
import { UploadModal } from 'components/UploadModal/UploadModal'
import SchoolStaffEmptyScreen from './SchoolStaffEmptyScreen'

const SchoolStaff = () => {
  const userType = localStorage.getItem('user_type')

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [count, setCount] = useState()
  const [employees, setEmployees] = useState()
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState()

  const [page, setPage] = useState(1)

  const [open, setOpen] = React.useState(false)
  const [formData, setFormData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()

  useEffect(() => {
    modalOpen == false && queryClient.invalidateQueries('Employees')
  }, [modalOpen])

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  async function getEmployees() {
    setSearchLoading(true)
    try {
      http
        .get(`/statistics/master-data/school-staff?search=${search}`)
        .then((res) => {
          console.log(res)
          setPage(1)
          setCount(res?.data?.staffs?.length)
          setEmployees(res?.data?.staffs?.slice(0, 10))
          setSearchLoading(false)
        })
    } catch (err) {
      console.log(err)
    }
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/statistics/master-data/school-staff?limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&search=${search}`,
      )
      console.log(res)
      setEmployees(res?.data?.staffs)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    getEmployees()
  }, [search])
  const handleSearch = (e) => {
    e?.preventDefault()
    getEmployees()
  }

  const { data, isLoading } = useQuery(
    ['Employees'],
    () =>
      http.get(`/statistics/master-data/school-staff`).then((res) => {
        console.log(res)
        return res?.data?.staffs
      }),
    {
      onSuccess: (data) => {
        setCount(data?.length)
        setEmployees(data?.slice(0, 10))
      },
    },
  )

  const columns = [
    {
      title: 'School Name',

      render: (colData, row) => {
        return row?.school?.name
      },
    },
    {
      title: 'Employee Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },

    {
      title: 'Designation',
      dataIndex: 'designation.name',
      key: 'designation.name',
    },
    {
      title: 'Added on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (colData, row) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'15px'}>
          <EditButton
            onClick={() => {
              handleEditClick(row)
            }}
          />
          <ViewButton onClick={() => navigate(`/staff-attendance`)} />
        </Box>
      ),
    },
  ]

  if (isLoading || loading || !employees) {
    return <CircularProgress />
  }

  return (
    <div>
      {modalOpen && (
        <UploadModal
          setOpen={setModalOpen}
          img={'/images/Rectangle 11247.png'}
          title={'School Staff'}
          section={'staff'}
        />
      )}{' '}
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <form className="mb-4" onSubmit={handleSearch}>
            <TextField
              label="Search by Employee Name"
              sx={{ width: '494px', marginBottom: '1.5rem' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <Box sx={{ display: 'flex' }} gap={'18px'}>
            {userType == 'Admin' && (
              <Button
                variant="outlined"
                sx={{
                  borderRadius: '10px',
                  borderColor: 'black !important',
                  background: 'transparent !important',
                }}
                onClick={() => setModalOpen(true)}
              >
                {' '}
                <Typography
                  sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
                >
                  + Import XLS
                </Typography>
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                borderRadius: '10px',
                borderColor: 'black !important',
                background: 'transparent !important',
              }}
              onClick={() => {
                setFormData(null)
                toggleDrawer(true)
              }}
            >
              <Typography
                sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
              >
                + Add School Staff
              </Typography>
            </Button>
          </Box>
        </Box>
        {employees?.length == 0 ? (
          userType == 'Admin' ? (
            <MasterDataEmptyScreen title="School Staff" section={'staff'} />
          ) : (
            <SchoolStaffEmptyScreen />
          )
        ) : (
          <>
            <Table
              key={'School Staff'}
              dataSource={employees}
              rowKey={'id'}
              columns={columns}
              loading={isLoading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />{' '}
            </Box>
          </>
        )}
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            {formData ? 'Edit School Staff' : 'Add School Staff'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <SchoolStaffForm data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  )
}

export default SchoolStaff
