import { Box, Button, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { http } from 'services/http'
import { useQueryClient } from 'react-query'

const DistrictsForm = ({ data, toggleDrawer }) => {
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onSubmit', defaultValues: { name: data?.name } })

  const queryClient = useQueryClient()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const onSubmit = (data) => {
    if (edit) {
      console.log(id)
      http
        .put(`/districts/${id}`, data)
        .then((res) => {
          enqueueSnackbar('The district has been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('districts')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      http
        .post(`/districts`, data)
        .then((res) => {
          enqueueSnackbar('The district has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('districts')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }
  console.log(data)
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type={'text'}
          {...register('name', {
            required: true,
            pattern: { value: /^[A-Za-z0-9 ]+$/ },
          })}
          label={'District Name'}
          required={true}
        />
        {errors?.name?.type == 'required' && (
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              color: 'red',
            }}
          >
            This field is required
          </Typography>
        )}
        {errors?.name?.type == 'pattern' && (
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              color: 'red',
            }}
          >
            Invalid Name
          </Typography>
        )}
        <Box
          sx={{
            padding: '28px',
            paddingTop: '0px',
            paddingLeft: '0px',
            marginTop: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
          gap={'18px'}
        >
          <Button
            sx={{
              backgroundColor: '#24272C',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#24272C',
              },
            }}
            type="submit"
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              {data ? 'Edit District' : 'Add District'}
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: '#F2F2F2',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
            }}
            onClick={() => toggleDrawer(false)}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'black',
              }}
            >
              Cancel
            </Typography>
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default DistrictsForm
