import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import MasterDataEmptyScreen from 'components/MasterDataEmptyScreen'
import SearchIcon from '@mui/icons-material/Search'
import Drawer from '@mui/material/Drawer'
import React, { useEffect, useState } from 'react'
import Table from 'components/Mui/TableComponent'
import { useNavigate } from 'react-router-dom'
import { EditButton } from 'components/IconButtons'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import { Close } from '@mui/icons-material'
import SubjectsForm from './SubjectsForm'
import { http } from 'services/http'
import { useSnackbar } from 'notistack'
import { useQuery, useQueryClient } from 'react-query'
import { UploadModal } from 'components/UploadModal/UploadModal'
const Subjects = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState()

  const [subjects, setSubjects] = useState()
  const [open, setOpen] = React.useState(false)
  const [formData, setFormData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  useEffect(() => {
    modalOpen == false && queryClient.invalidateQueries('subjects')
  }, [modalOpen])

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  async function getSubjects() {
    setSearchLoading(true)
    try {
      http.get(`/subjects?search=${search}`).then((res) => {
        console.log(res)
        setSubjects(res?.data?.subjects)
        setSearchLoading(false)
      })
    } catch (err) {
      console.log(err)
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }
  useEffect(() => {
    getSubjects()
  }, [search])
  const handleSearch = (e) => {
    e?.preventDefault()
    getSubjects()
  }

  const { data, isLoading } = useQuery(
    ['subjects'],
    () =>
      http.get(`/subjects`).then((res) => {
        console.log(res)
        return res?.data?.subjects
      }),
    {
      onSuccess: (data) => {
        setSubjects(data)
      },
    },
  )

  if (isLoading || loading || !subjects) {
    return <CircularProgress />
  }

  return (
    <div>
      {modalOpen && (
        <UploadModal
          setOpen={setModalOpen}
          img={'/images/Rectangle 11247.png'}
          title={'Subjects'}
          section={'subjects'}
        />
      )}{' '}
      {subjects?.length == 0 ? (
        <MasterDataEmptyScreen title="Subjects" section={'subjects'} />
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <form className="mb-4" onSubmit={handleSearch}>
              <TextField
                label="Search by Subject Name"
                sx={{ width: '494px', marginBottom: '1.5rem' }}
                hookForm={false}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
            </form>
            <Box sx={{ display: 'flex' }} gap={'18px'}>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: '10px',
                  borderColor: 'black !important',
                  background: 'transparent !important',
                }}
                onClick={() => setModalOpen(true)}
              >
                {' '}
                <Typography
                  sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
                >
                  + Import XLS
                </Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: '10px',
                  borderColor: 'black !important',
                  background: 'transparent !important',
                }}
                onClick={() => {
                  setFormData(null)
                  toggleDrawer(true)
                }}
              >
                <Typography
                  sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
                >
                  + Add Subject
                </Typography>
              </Button>
            </Box>
          </Box>
          {searchLoading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={1}>
              {subjects?.map((item) => {
                return (
                  <Grid item xl={3} lg={4} md={4}>
                    <SmallCard
                      img="/images/vuesax-outline-book.png"
                      title={item?.name}
                    />
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Box>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            Add Subject
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <SubjectsForm toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  )
}

export default Subjects

const SmallCard = ({ title, img }) => {
  return (
    <Box
      sx={{
        // width: '350px',
        height: '105px',
        padding: '4px 20px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 3px 13px #2A32711A',
        margin: '18px 18px 0px 0px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
        <Box>
          <img src={img} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
