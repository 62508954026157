import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useNavigate } from 'react-router-dom'

const Banner = ({ districtId, atdoPointId }) => {
  const navigate = useNavigate()
  const handleViewClick = () => {
    localStorage.removeItem('TicketID')
    localStorage.setItem('districtID', districtId)
    localStorage.setItem('atdoPointID', atdoPointId)
    navigate('/engineering/tickets')
  }
  return (
    <div>
      <Box
        sx={{
          borderRadius: '10px',
          padding: '17px 27px',
          paddingRight: '60px',
          backgroundColor: '#F2F6FD',
          marginTop: '35px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'20px'}>
            <img src={'/images/ticketsWhite.png'} />
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                fontSize: '24px',
                color: '#0A1160',
              }}
            >
              View all tickets
            </Typography>
          </Box>
          <Button
            variant="contained"
            endIcon={<ArrowRightAltIcon />}
            sx={{
              backgroundColor: '#0A1160',
              '&:hover': {
                backgroundColor: '#0A1160',
              },
            }}
            onClick={() => handleViewClick()}
          >
            View More
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default Banner
