import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const HeadMastersForm = ({ data, toggleDrawer }) => {
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [mandals, setMandals] = useState()
  const [schools, setSchools] = useState()
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      districtId: data?.districtId,
      atdoPointId: data?.atdoPointId,
      mandalId: data?.mandalId,
      schoolId: data?.school?.id,
      employeeCode: data?.employeeCode,
      fullName: data?.fullName,
      mobileNumber: data?.mobileNumber,
      gender: data?.gender,
    },
  })
  const districtid = watch('districtId')
  const atdopointid = watch('atdoPointId')
  const mandalid = watch('mandalId')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  console.log(data)
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  useEffect(() => {
    http.get(`/districts`).then((res) => {
      console.log(res)
      setDistricts(res?.data?.districts)
    })
  }, [])

  useEffect(() => {
    http.get(`/atdo-point`).then((res) => {
      edit
        ? setAtdoPoints(res?.data?.atdoPoints)
        : setAtdoPoints(
            res?.data?.atdoPoints.filter((item) => {
              return item?.districtId == districtid
            }),
          )
    })
  }, [edit ? '' : districtid])

  console.log(atdoPoints)
  useEffect(() => {
    http.get(`/mandals`).then((res) => {
      console.log(res)
      edit
        ? setMandals(res?.data?.mandals)
        : setMandals(
            res?.data?.mandals.filter((item) => {
              return item?.districtId == districtid
            }),
          )
    })
  }, [edit ? '' : districtid])

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      console.log(res)
      edit
        ? setSchools(res?.data?.schools)
        : setSchools(
            res?.data?.schools.filter((item) => {
              return (
                item?.districtId == districtid &&
                item?.atdoPointId == atdopointid &&
                item?.mandalId == mandalid
              )
            }),
          )
    })
  }, [edit ? '' : (districtid, atdopointid, mandalid)])

  const onSubmit = (submitData) => {
    console.log(submitData)
    if (edit) {
      console.log(id)
      const FormData = { ...submitData, type: 'HeadMaster' }

      http
        .put(`/users/${id}`, FormData)
        .then((res) => {
          enqueueSnackbar('Head Master details have been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('headMasters')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      const FormData = { ...submitData, type: 'HeadMaster' }
      http
        .post(`/users`, FormData)
        .then((res) => {
          enqueueSnackbar('Head Master has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('headMasters')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
  }

  console.log(errors)

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <InputLabel id="districtId">District Name *</InputLabel>
            <Select
              labelId="districtId"
              id="districtId"
              label="District Name *"
              disabled={data ? true : false}
              {...register('districtId', { required: true })}
              defaultValue={data?.districtId}
              required={true}
            >
              {districts?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.districtId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="atdoPointId">ATDO Point Name *</InputLabel>
            <Select
              labelId="atdoPointId"
              id="atdoPointId"
              label="ATDO Point Name *"
              disabled={data ? true : false}
              {...register('atdoPointId', { required: true })}
              defaultValue={data?.atdoPointId}
              required={true}
            >
              {atdoPoints?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.atdoPointId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="mandalId">Mandal Name *</InputLabel>
            <Select
              labelId="mandalId"
              id="mandalId"
              label="Mandal Name *"
              disabled={data ? true : false}
              {...register('mandalId', { required: true })}
              defaultValue={data?.mandalId}
              required={true}
            >
              {mandals?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.mandalId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="schoolId">School Name *</InputLabel>
            <Select
              labelId="schoolId"
              id="schoolId"
              disabled={data ? true : false}
              label="School Name *"
              {...register('schoolId', { required: true })}
              defaultValue={data?.school?.id}
              required={true}
            >
              {schools?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.schoolId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <TextField
            type={'text'}
            {...register('employeeCode', {
              required: true,
              pattern: {
                value: /^[A-Z0-9]+$/,
                message: 'Invalid ID',
              },
            })}
            label={'Head Master ID'}
            required={true}
            disabled={data ? true : false}
            sx={{ marginTop: '24px' }}
            defaultValue={data?.employeeCode}
          />
          {errors?.employeeCode?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          {errors?.employeeCode?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid ID
            </Typography>
          )}

          <TextField
            type={'text'}
            {...register('fullName', {
              required: true,
              pattern: { value: /^[A-Za-z,\- .]+$/ },
            })}
            label={'Head Master Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.fullName?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.fullName?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}

          <TextField
            type={'text'}
            {...register('mobileNumber', {
              required: true,
              pattern: /^\d{10}$/,
            })}
            label={'Mobile Number'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.mobileNumber?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.mobileNumber?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Mobile Number
            </Typography>
          )}

          <FormControl component="fieldset" sx={{ marginTop: '24px' }}>
            <FormLabel component="legend">Gender *</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="gender"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {errors?.gender && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <TextField
            type={'password'}
            {...register('password', { required: true })}
            label={'Password'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.password && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {data ? 'Edit Head Master' : 'Add Head Master'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default HeadMastersForm
