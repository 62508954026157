import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  Typography,
} from '@mui/material'
import { EditButton } from 'components/IconButtons'
import { DeleteButton } from 'components/IconButtons/IconButtons'
import Table from 'components/Mui/TableComponent'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import DividerHeading from 'components/DividerHeading'
import TextField from 'components/Mui/Form/TextField'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from 'pages/MasterData/DrawerTemplate'
import { useQuery, useQueryClient } from 'react-query'
import { http } from 'services/http'
import AssetsForm from './AssetsForm'

const Assets = () => {
  const [search, setSearch] = useState('')
  const [count, setCount] = useState()
  const [assets, setAssets] = useState([])
  const [page, setPage] = useState(1)
  const [formData, setFormData] = useState([])
  const [open, setOpen] = useState(false)
  const schoolId = localStorage?.getItem('schoolId')
  const queryClient = useQueryClient()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const navigate = useNavigate()
  const buttonSX = {
    '&:hover': {
      borderColor: '#24272C',
      color: '#24272C',
      background: 'none',
    },
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    borderColor: '#24272C',
    color: '#24272C',
  }

  const fetchAssets = async (search, page) => {
    const res = await http.get(
      `/school-assets/${schoolId}?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`,
    )
    setAssets(res?.data?.result)
    setCount(res?.data?.count)
  }

  const { data, isLoading } = useQuery(['schoolAssets', search, page], () =>
    fetchAssets(search, page),
  )

  const handleDelete = (id) => {
    http
      .delete(`/school-assets/${id}`)
      .then((res) => {
        console.log(res)
        queryClient.invalidateQueries('schoolAssets')
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(err?.response?.data?.message)
      })
  }

  const columns = [
    {
      title: 'Asset Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Category',
      dataIndex: 'assetCategory.name',
      key: 'assetCategory.name',
      render: (_, colData) => {
        return colData?.assetCategory?.name
          ? colData?.assetCategory?.name
          : 'NA'
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    // {
    //   title: 'Mandal',
    //   dataIndex: 'mandal.name',
    //   key: 'mandal.name',
    // },
    {
      title: 'Updated date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Deployed date',
      dataIndex: 'deployedAt',
      key: 'deployedAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        console.log(row)
        return (
          <Box sx={{ display: 'flex' }} gap={'18px'}>
            <EditButton
              onClick={() => {
                handleEditClick(row)
              }}
            />
            <DeleteButton onClick={() => handleDelete(row?.id)} />
          </Box>
        )
      },
    },
  ]

  function handlePagination(e, v) {
    setPage(v)
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <DividerHeading title={'Assets'} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '18px',
        }}
      >
        <form className="mb-4">
          <TextField
            label="Search by Asset name"
            sx={{ width: '494px' }}
            hookForm={false}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={search}
            onChange={({ target }) => setSearch(target.value)}
          />
        </form>
        <Button
          sx={buttonSX}
          variant="outlined"
          onClick={() => {
            console.log('hi')
            setFormData(null)
            toggleDrawer(true)
          }}
        >
          + Add Asset
        </Button>
      </Box>
      {isLoading || !assets ? (
        <CircularProgress />
      ) : assets?.length == 0 ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'assets'}
            dataSource={assets}
            rowKey={'id'}
            columns={columns}
            loading={isLoading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            {formData ? 'Edit Asset' : 'Add Asset'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <AssetsForm data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </Box>
  )
}

export default Assets
