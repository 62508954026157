import { Box, Typography } from '@mui/material'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'

const PieChartCard = ({ data, COLORS, name }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 65, left: 65 }}>
        <Typography>{name}</Typography>
      </Box>
      <PieChart width={180} height={180}>
        <Pie
          data={data}
          cx={75}
          cy={75}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </Box>
  )
}

export default PieChartCard
