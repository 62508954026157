import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import React, { useEffect, useState, PureComponent } from 'react'
import { http } from 'services/http'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts'
import PieChartCard from 'pages/Engineering/PieChart'
import Loader from 'components/Loader'
import Banner from './Banner'
import EngTable from './Table'
import { useQuery, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'

const Engineering = () => {
  const [districtId, setDistrictId] = useState('')
  const [districts, setDistricts] = useState([])
  const [atdoPoints, setAtdoPoints] = useState([])
  const [atdoPointId, setAtdoPointId] = useState('')
  const [tableData, setTableData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [count, setCount] = useState(0)
  const userType = localStorage.getItem('user_type')
  const [page, setPage] = useState(1)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [ticketsByCategory, setTicketsByCategory] = useState([])

  const queryClient = useQueryClient()

  const fetchData = async (page, districtId, atdoPointId) => {
    const res = await http.get(
      `/tickets?limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }&districtId=${districtId}&atdoPointId=${atdoPointId}`,
    )
    setTableData(res?.data)
  }

  useEffect(() => {
    http
      .get(`/tickets?districtId=${districtId}&atdoPointId=${atdoPointId}`)
      .then((res) => {
        setCount(res?.data?.length)
        setTotalCount(res?.data?.length)
      })
  }, [districtId, atdoPointId])

  const { data, isLoading } = useQuery(
    ['tickets', page, districtId, atdoPointId],
    () => fetchData(page, districtId, atdoPointId),
  )

  const { data: categories, isLoading: categoriesLoading } = useQuery(
    ['categories', districtId, atdoPointId],
    () =>
      http
        .get(
          `/tickets/statistics?districtId=${districtId}&atdoPointId=${atdoPointId}`,
        )
        .then((res) =>
          setTicketsByCategory(
            res?.data?.map((item) => {
              return { ...item, value: Number(item?.value) }
            }),
          ),
        )
        .catch((err) =>
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          }),
        ),
  )

  const { data: districtsData, isLoading: districtsLoading } = useQuery(
    ['districtsData'],
    () =>
      http
        .get(`/statistics/master-data/district`)
        .then((res) => {
          setDistricts(res?.data)
        })
        .catch((err) =>
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          }),
        ),
  )

  useEffect(() => {
    if (districtId) {
      http
        .get(`/statistics/master-data/atdo`)
        .then((res) => {
          console.log(districtId)
          setAtdoPoints(
            res?.data?.filter((item) => {
              return item?.districtId == districtId
            }),
          )
        })
        .catch((err) =>
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          }),
        )
    }
  }, [districtId])

  const Tickets = [...ticketsByCategory]

  const COLORS = ['#F7AD19', '#429EBD', '#0A1160', '#F27F0C', '#2DE0B6']

  console.log(typeof districtId)

  const handleClear = (e) => {
    e?.preventDefault()
    setDistrictId('')
    setAtdoPointId('')
  }

  return (
    <div>
      {(userType == 'Admin' || userType == 'DD') && (
        <form>
          <Stack
            gap={3}
            direction="row"
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ width: '170px' }}>
              <InputLabel id="districtId">Select District</InputLabel>
              <Select
                labelId="districtId"
                id="districtId"
                label="Select District"
                value={districtId}
                onChange={(e) => {
                  setAtdoPointId('')
                  setDistrictId(e?.target?.value)
                }}
              >
                {districts?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '170px' }}>
              <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
              <Select
                labelId="atdoPointId"
                id="atdoPointId"
                label="Select ATDO Point"
                value={atdoPointId}
                onChange={(e) => setAtdoPointId(e?.target?.value)}
              >
                {atdoPoints?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              sx={{
                backgroundColor: ' white !important',
                height: '50px',
                width: '130px',
                borderRadius: '10px',
                border: '1px solid black',
              }}
              onClick={() => handleClear()}
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                Clear
              </Typography>
            </Button>
          </Stack>
        </form>
      )}
      <Box sx={{ marginTop: '25px' }}>
        <DividerHeading title="Ticket Metrics" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={3.5}>
                <Box
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 5px 15px #2D388B1A;',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#F3F7FD',
                      padding: '20px',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  >
                    <Typography
                      sx={{ fontSize: '14px', fontFamily: 'poppins_medium' }}
                    >
                      Total Tickets
                    </Typography>
                  </Box>
                  <Box sx={{ padding: '40px' }}>
                    <Box sx={{ marginTop: '10px' }}>
                      <img src={'/images/tickets.png'} />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: 'poppins_medium',
                        fontSize: '16px',
                        opacity: '0.7',
                        marginTop: '25px',
                      }}
                    >
                      Total number of Tickets
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'poppins_semibold',
                        fontSize: '38px',
                        marginTop: '5px',
                      }}
                    >
                      {totalCount}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8.5}>
                <Box
                  sx={{
                    borderRadius: '10px',
                    boxShadow: '0px 5px 15px #2D388B1A;',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#F3F7FD',
                      padding: '20px',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  >
                    <Typography
                      sx={{ fontSize: '14px', fontFamily: 'poppins_medium' }}
                    >
                      Tickets Statistics
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '0px 10px',
                      }}
                    >
                      {Tickets?.length == 0 ? (
                        <Box sx={{ marginTop: '10px' }}>
                          <SchoolAnalyticsEmptyScreen height={'209px'} />
                        </Box>
                      ) : (
                        <PieChartCard data={Tickets} colors={COLORS} />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Banner districtId={districtId} atdoPointId={atdoPointId} />
            {tableData?.length == 0 ? (
              <Box sx={{ marginTop: '20px' }}>
                <SchoolAnalyticsEmptyScreen />
              </Box>
            ) : (
              <EngTable
                data={tableData}
                count={count}
                loading={isLoading}
                page={page}
                setPage={setPage}
              />
            )}
          </>
        )}
      </Box>
    </div>
  )
}

export default Engineering
