import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const SchoolsForm = ({ data, toggleDrawer }) => {
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [mandals, setMandals] = useState()

  const [designations, setDesignations] = useState()
  const queryClient = useQueryClient()
  const [id, setId] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    data && setId(data?.id)
  }, [])

  useEffect(() => {
    http.get(`/staff/designations`).then((res) => {
      console.log(res)
      setDesignations(res?.data)
    })
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      districtId: data?.districtId,
      atdoPointId: data?.atdoPointId,
      mandalId: data?.mandalId,
      name: data?.name,
      address: data?.address,
      latitude: data?.latitude,
      longitude: data?.longitude,
      schoolType: data?.schoolType,
      lowerClassStudents: data?.lowerClassStudents,
      upperClassStudents: data?.upperClassStudents,
      totalStudents: data?.totalStudents,
      noOfClassrooms: data?.noOfClassrooms,
      boyStudents: data?.boyStudents,
      girlStudents: data?.girlStudents,
      sanctionedTeachers: data?.sanctionedTeachers,
      workingTeachers: data?.workingTeachers,
      crtTeachers: data?.crtTeachers,
    },
  })

  const districtid = watch('districtId')

  useEffect(() => {
    http.get(`/districts`).then((res) => {
      console.log(res)
      setDistricts(res?.data?.districts)
    })
  }, [])

  useEffect(() => {
    http.get(`/atdo-point`).then((res) => {
      setAtdoPoints(res?.data?.atdoPoints)
    })
  }, [])

  console.log(atdoPoints)
  useEffect(() => {
    http.get(`/mandals`).then((res) => {
      console.log(res)
      setMandals(res?.data?.mandals)
    })
  }, [])

  const onSubmit = (editData) => {
    console.log(editData)
    console.log(data)
    const formData = { ...data, ...editData }
    console.log(formData)
    http
      .put(`/schools/${id}`, formData)
      .then((res) => {
        enqueueSnackbar('Staff member details have been updated', {
          variant: 'success',
        })
        queryClient.invalidateQueries('schoolDetails')
        toggleDrawer(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }
  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <InputLabel id="districtId">District Name *</InputLabel>
            <Select
              labelId="districtId"
              id="districtId"
              label="District Name *"
              {...register('districtId', { required: true })}
              defaultValue={data?.districtId}
              required={true}
            >
              {districts?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.districtId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="atdoPointId">ATDO Point Name *</InputLabel>
            <Select
              labelId="atdoPointId"
              id="atdoPointId"
              label="ATDO Point Name *"
              {...register('atdoPointId', { required: true })}
              defaultValue={data?.atdoPointId}
              required={true}
            >
              {atdoPoints?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.atdoPointId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="mandalId">Mandal Name *</InputLabel>
            <Select
              labelId="mandalId"
              id="mandalId"
              label="Mandal Name *"
              {...register('mandalId', { required: true })}
              defaultValue={data?.mandalId}
              required={true}
            >
              {mandals?.map((item) => {
                return <MenuItem value={item?.id}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>

          {errors?.mandalId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('name', {
              required: true,
              pattern: /^[A-Za-z.\- ()]+$/,
            })}
            label={'School Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.name?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.name?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('address', {
              required: true,
            })}
            label={'Address'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.address?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('latitude', {
              required: true,
            })}
            label={'Latitude'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.latitude?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('longitude', {
              required: true,
            })}
            label={'Longitude'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.longitude?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl component="fieldset" sx={{ marginTop: '24px' }}>
            <FormLabel component="legend">School Type *</FormLabel>
            <Controller
              control={control}
              name="schoolType"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="Boys School"
                    control={<Radio />}
                    label="Boys School"
                  />
                  <FormControlLabel
                    value="Girls School"
                    control={<Radio />}
                    label="Girls School"
                  />
                  <FormControlLabel
                    value="Co-Education"
                    control={<Radio />}
                    label="Co-Education"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {errors?.schoolType && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('lowerClassStudents', {
                required: true,
              })}
              label={'Total Number of Lower Class Students'}
              required={true}
            />
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('upperClassStudents', {
                required: true,
              })}
              label={'Total Number of Upper Class Students'}
              required={true}
            />
          </FormControl>

          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('totalStudents', {
                required: true,
              })}
              label={'Total Number of Students'}
              required={true}
            />
          </FormControl>
          {errors?.totalStudents && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('noOfClassrooms', {
                required: true,
              })}
              label={'Total Number of Classrooms'}
              required={true}
            />
          </FormControl>
          {errors?.noOfClassrooms && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('boyStudents', {
                required: true,
              })}
              label={'Total Number of Male Students'}
              required={true}
            />
          </FormControl>
          {errors?.boyStudents && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('girlStudents', {
                required: true,
              })}
              label={'Total Number of Female Students'}
              required={true}
            />
          </FormControl>
          {errors?.girlStudents && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('sanctionedTeachers', {
                required: true,
              })}
              label={'Total Number of Sanctioned Teachers'}
              required={true}
            />
          </FormControl>
          {errors?.sanctionedTeachers && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('workingTeachers', {
                required: true,
              })}
              label={'Total Number of Working Teachers'}
              required={true}
            />
          </FormControl>
          {errors?.workingTeachers && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <TextField
              type={'number'}
              inputProps={{ min: 0 }}
              {...register('crtTeachers', {
                required: true,
              })}
              label={'Total Number of CRT Teachers'}
              required={true}
            />
          </FormControl>
          {errors?.crtTeachers && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {'Edit School'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default SchoolsForm
