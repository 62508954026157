import { Box, CircularProgress, Grid } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import DividerHeading from 'components/DividerHeading'
import DetailsBanner from 'components/Mui/DetailsBanner'
import PageHeader from 'components/Mui/PageHeader'
import PaperCard from 'components/Mui/PaperCard/PaperCard'
import SmallCard from 'components/Mui/SmallCard/SmallCard'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { http } from 'services/http'

const SchoolDetails = () => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [schoolStats, setSchoolStats] = useState()
  let regularCount = 0
  let crtCount = 0

  const { data, isLoading } = useQuery(
    ['schoolStatistics'],
    () =>
      http.get(`/statistics/school/${id}/info`).then((res) => {
        console.log(res)
        return res?.data
      }),
    {
      onSuccess: (data) => {
        setSchoolStats(data)
      },
    },
  )
  console.log(schoolStats)
  if (schoolStats) {
    for (let i = 0; i < schoolStats?.regularTeacherSubject?.length; i++) {
      regularCount = regularCount + schoolStats?.regularTeacherSubject[i]?.count
    }
    for (let i = 0; i < schoolStats?.crtTeacherSubject?.length; i++) {
      crtCount = crtCount + schoolStats?.crtTeacherSubject[i]?.count
    }
  }

  return (
    <div style={{ marginBottom: '80px' }}>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Schools', to: '/schools' },
              {
                name: 'School Analytics',
                to: '/schools/school-analytics/attendance-activities',
              },
              { name: 'School Information', to: null },
            ]}
          />
        }
      />

      <DividerHeading title={'School Details'} />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ marginTop: '20px' }}>
          <DetailsBanner
            items={[
              { name: 'School ID', value: schoolStats?.school?.schoolId },
              { name: 'School Name', value: schoolStats?.school?.name },
              {
                name: 'ATDO Point',
                value: schoolStats?.school?.atdoPoint?.name,
              },
              { name: 'School Type', value: schoolStats?.school?.schoolType },
              { name: 'Mandal', value: schoolStats?.school?.mandal?.name },
              { name: 'District', value: schoolStats?.school?.district?.name },
            ]}
          />
        </Box>
      )}

      <Box sx={{ marginTop: '30px' }}>
        <DividerHeading title={'Head Master Details'} />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ marginTop: '20px' }}>
            <DetailsBanner
              items={[
                {
                  name: 'Head Master ID',
                  value: schoolStats?.headMaster?.headMasterId,
                },
                {
                  name: 'Head Master Name',
                  value: schoolStats?.headMaster?.fullName,
                },
                {
                  name: 'Mobile Number',
                  value: schoolStats?.headMaster?.mobileNumber,
                },
                { name: 'Gender', value: schoolStats?.headMaster?.gender },
              ]}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <DividerHeading title={'School Details'} />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            spacing={1}
            sx={{
              marginTop: '-29px',
            }}
          >
            <Grid item xl={2} lg={3} md={3}>
              <PaperCard
                // width="250px"

                title="Number of Students"
                count={schoolStats?.school?.totalStudents}
                img="/images/Group 20897.png"
              />
            </Grid>
            <Grid item xl={2} lg={3} md={3}>
              <PaperCard
                // width="250px"

                title="Sanctioned Teachers"
                count={schoolStats?.school?.sanctionedTeachers}
                img="/images/Group 20926.png"
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Grid sx={{ marginTop: '30px' }}>
        <DividerHeading title="Subject Wise Details - Regular Teachers" />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            <Grid item xl={2} lg={3} md={3} sx={{ marginTop: '-29px' }}>
              <PaperCard
                height="363px"
                title="Regular Teachers"
                count={regularCount}
                img="/images/Group 20997.png"
                link="/regular-teachers"
                linkText="View Teachers"
                linkColor="#1BC0FA"
              />
            </Grid>

            <Grid
              item
              xl={10}
              lg={9}
              md={9}
              sx={{
                marginTop: '-17px',
              }}
            >
              <Grid container spacing={1}>
                {schoolStats?.regularTeacherSubject?.map((item) => (
                  <Grid item xl={3} lg={4} md={4}>
                    <SmallCard
                      img={
                        item?.name == 'All Subjects'
                          ? '/images/vuesax-outline-3square.png'
                          : '/images/vuesax-outline-book.png'
                      }
                      title={item?.name}
                      count={item?.count}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Box sx={{ marginTop: '85px' }}>
        <DividerHeading title="Subject Wise Details - CRT Teachers" />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            <Grid item xl={2} lg={3} md={3} sx={{ marginTop: '-29px' }}>
              <PaperCard
                height="363px"
                title="CRT Teachers"
                count={crtCount}
                img="/images/Group 20895.png"
                link="/crt-teachers"
                linkText="View Teachers"
                linkColor="#159947"
              />
            </Grid>
            <Grid
              item
              xl={10}
              lg={9}
              md={9}
              sx={{
                marginTop: '-17px',
              }}
            >
              <Grid container spacing={1}>
                {schoolStats?.crtTeacherSubject?.map((item) => (
                  <Grid item xl={3} lg={4} md={4}>
                    <SmallCard
                      img={
                        item?.name == 'All Subjects'
                          ? '/images/vuesax-outline-3square.png'
                          : '/images/vuesax-outline-book.png'
                      }
                      title={item?.name}
                      count={item?.count}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Box sx={{ marginTop: '85px' }}>
          <DividerHeading title="Staff Metrics" />
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Grid
              container
              spacing={1}
              sx={{
                marginTop: '-29px',
              }}
            >
              {schoolStats?.staff?.map((item) => {
                return (
                  <Grid item xl={2.2} lg={3} md={3}>
                    <PaperCard
                      // width="250px"

                      title={item?.name}
                      count={item?.count ?? 'NA'}
                      img={item?.imageUrl}
                    />
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default SchoolDetails
