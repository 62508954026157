import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PageHeader from 'components/Mui/PageHeader'
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import Table from 'components/Mui/TableComponent'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useNavigate } from 'react-router-dom'
import Breadcrumbs from 'components/Breadcrumbs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { http } from 'services/http'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { ViewButton } from 'components/IconButtons'

const StaffAttendance = () => {
  const [loading, setLoading] = useState()
  const selectedDate = localStorage.getItem('selectedDate')

  const [date, setDate] = useState(new Date(selectedDate ? selectedDate : ''))
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)

  let fromDate = moment(new Date(selectedDate ? selectedDate : '')).format(
    'YYYY-MM-DD',
  )
  let toDate = moment(new Date(selectedDate ? selectedDate : '')).format(
    'YYYY-MM-DD',
  )
  const [attendanceDetails, setAttendanceDetails] = useState()
  const [tableData, setTableData] = useState()
  const type = 'Staff'
  const id = localStorage.getItem('schoolId')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState()

  const [sampleBuffer, setSampleBuffer] = useState()

  useEffect(() => {
    fromDate = moment(new Date(date)).format('YYYY-MM-DD')
    toDate = moment(new Date(date)).format('YYYY-MM-DD')
    setLoading(true)
    http
      .get(
        `/statistics/school/${id}/analytics?type=${type}&fromDate=${fromDate}&toDate=${toDate}`,
      )
      .then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        setAttendanceDetails(res?.data)
        setTableData(res?.data?.data?.slice(0, 10))
        setLoading(false)
      })
    // http
    //   .get(
    //     `/statistics/school/${id}/report?type=${type}&fromDate=${fromDate}&toDate=${toDate}`,
    //   )
    //   .then((res) => {
    //     console.log(res?.data?.data)
    //     setSampleBuffer(res?.data?.data)
    //   })
  }, [date])

  const iconSX = {
    '&:hover': {
      cursor: 'pointer',
      color: '#0A1160',
    },
    color: '#878787',
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'staff.name',
      key: 'staff.name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'staff.mobileNumber',
      key: 'staff.mobileNumber',
    },
    {
      title: 'Gender',
      dataIndex: 'staff.gender',
      key: 'staff.gender',
    },
    {
      title: 'Designation',
      dataIndex: 'staff.designation.name',
      key: 'staff.designation.name',
    },
    {
      title: 'Attendance Status',
      dataIndex: 'status',
      key: 'status',
      render: (rowData) =>
        rowData ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Present
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Absent
          </Box>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => (
        <Box display="flex" gap="10px">
          <ViewButton
            onClick={() =>
              navigate(`/schools/staff-attendance-details?id=${row?.id}`)
            }
          />
        </Box>
      ),
    },
  ]

  async function getStaff() {
    fromDate = moment(new Date(date)).format('YYYY-MM-DD')
    toDate = moment(new Date(date)).format('YYYY-MM-DD')
    setSearchLoading(true)
    try {
      http
        .get(
          `/statistics/school/${id}/analytics?type=${type}&fromDate=${fromDate}&toDate=${toDate}&name=${search}`,
        )
        .then((res) => {
          console.log(res)
          setPage(1)
          setCount(res?.data?.count)
          setAttendanceDetails(res?.data)
          setTableData(res?.data?.data?.slice(0, 10))
          setSearchLoading(false)
        })
    } catch (err) {
      console.log(err)
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      fromDate = moment(new Date(date)).format('YYYY-MM-DD')
      toDate = moment(new Date(date)).format('YYYY-MM-DD')
      setLoading(true)
      http
        .get(
          `/statistics/school/${id}/analytics?type=${type}&fromDate=${fromDate}&toDate=${toDate}&limit=10&offset=${
            v > 0 ? (v - 1) * 10 : v * 10
          }&name=${search}`,
        )
        .then((res) => {
          console.log(res)
          setAttendanceDetails(res?.data)
          setTableData(res?.data?.data)
          setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    localStorage.setItem('date', date)
  }, [date])

  useEffect(() => {
    getStaff()
  }, [search])
  const handleSearch = (e) => {
    e?.preventDefault()
    getStaff()
  }

  if (loading) {
    return <CircularProgress />
  }

  const handleExport = () => {
    if (sampleBuffer === undefined) {
      enqueueSnackbar('Sample Excel Sheet Not Generated', {
        variant: 'error',
      })
      return
    }
    const arr = new Uint8Array(sampleBuffer)
    const blob = new Blob([arr], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const file = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.href = file
    link.download = 'Source_Document_Sample_Sheet.xlsx'
    link.click()
  }

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Schools', to: '/schools' },
              {
                name: 'School Analytics',
                to: '/schools/school-analytics/attendance-activities',
              },
              { name: 'Staff Attendance', to: null },
            ]}
          />
        }
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '18px',
          }}
        >
          <form className="mb-4" onSubmit={handleSearch}>
            <TextField
              label="Search by Name"
              sx={{ width: '494px' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <Box
            gap={'18px'}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ width: '190px', height: '50px' }}>
                <DatePicker
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue)
                  }}
                  inputFormat="DD MMM, YYYY"
                  maxDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                />{' '}
              </Box>
            </LocalizationProvider>{' '}
            {/* <Button
              variant="contained"
              sx={{
                backgroundColor: 'black !important',
                height: '50px',
                width: '190px',
                borderRadius: '10px',
              }}
              onClick={() => handleExport()}
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  fontSize: '13px',
                }}
              >
                Export Data
              </Typography>
            </Button> */}
          </Box>
        </Box>
        {attendanceDetails?.count == 0 ? (
          <SchoolAnalyticsEmptyScreen />
        ) : (
          <>
            <Table
              key={'staffAttendance'}
              dataSource={tableData}
              rowKey={'id'}
              columns={columns}
              loading={loading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />{' '}
            </Box>
          </>
        )}
      </Box>
    </div>
  )
}

export default StaffAttendance
