import { Close } from '@mui/icons-material'
import {
  alpha,
  Box,
  Drawer,
  IconButton,
  styled,
  Typography,
} from '@mui/material'

export const StyledDrawerHeader = styled(Box)(({ theme }) => ({
  height: '64px',
  backgroundColor: '#182F53',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.6rem 1rem',
}))

export const StyledDrawerContent = styled(Box)(({ theme }) => ({
  padding: '1rem',
  height: 'calc(100vh - 64px)',
  overflowY: 'auto',
  paddingBottom: '4rem',
}))
