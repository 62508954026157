import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ContentCards } from 'components/ContentCards'
import DividerHeading from 'components/DividerHeading'
import SingleSelect from 'components/Mui/Form/SingleSelect'
import PaperCard from 'components/Mui/PaperCard/PaperCard'
import SmallCard from 'components/Mui/SmallCard/SmallCard'
import VisibilityIcon from '@mui/icons-material/Visibility'

import React, { useEffect, useState } from 'react'
import Table from 'components/Mui/TableComponent'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import { useSnackbar } from 'notistack'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { ViewIcon } from 'components/IconButtons/Icons'
import { ViewButton } from 'components/IconButtons'

const CRTTeachers = () => {
  const navigate = useNavigate()
  const [stats, setStats] = useState()
  const [teachers, setTeachers] = useState()
  const [count, setCount] = useState()
  const [page, setPage] = useState(1)
  const userType = localStorage.getItem('user_type')
  const [paginationLoading, setPaginationLoading] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [mandals, setMandals] = useState()
  const [schools, setSchools] = useState()
  const [districtId, setDistrictId] = useState('')
  const [atdoPointId, setAtdoPointId] = useState('')
  const [mandalId, setMandalId] = useState('')
  const [schoolId, setSchoolId] = useState('')
  const [date, setDate] = useState(new Date())
  const [loading, setLoading] = useState()

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      console.log(res)
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  useEffect(() => {
    http.get(`/mandals`).then((res) => {
      console.log(res)
      setMandals(
        res?.data?.mandals.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      console.log(res)
      setSchools(
        res?.data?.schools.filter((item) => {
          if (districtId && !atdoPointId) {
            return item?.districtId == districtId
          }

          if (districtId && atdoPointId) {
            return (
              item?.districtId == districtId && item?.atdoPointId == atdoPointId
            )
          }
        }),
      )
    })
  }, [districtId, atdoPointId])

  useEffect(() => {
    localStorage.setItem('staffAndTeacherDate', date)
  }, [date])

  let crtCount = 0

  // const { data, isLoading } = useQuery(
  //   ['regularTeacherStats'],
  //   () =>
  //     http.get(`/statistics/dashboard`).then((res) => {
  //       console.log(res)
  //       return res?.data?.regularTeacherSubject
  //     }),
  //   {
  //     onSuccess: (data) => {
  //       setStats(data)
  //     },
  //   },
  // )

  useEffect(() => {
    setLoading(true)
    http
      .get(
        `/attendance-activities/teacher?type=CRT&date=${date}&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      .then((res) => {
        console.log(res)
        setStats(res?.data?.crtTeacherSubject)
        setCount(res?.data?.stats?.teacher?.count)
        setTeachers(res?.data?.stats?.teacher?.result?.slice(0, 10))
        setLoading(false)
      })
  }, [date])
  console.log(stats)

  if (stats) {
    for (let i = 0; i < stats?.length; i++) {
      crtCount = crtCount + stats[i]?.count
    }
  }

  const iconSX = {
    '&:hover': {
      cursor: 'pointer',
      color: '#0A1160',
    },
    color: '#878787',
  }

  // const cards = [
  //   { count: '824', text: 'Overall Regular Teachers' },
  //   { count: '600', text: 'Present Teachers' },
  //   { count: '224', text: 'Absent Teachers' },
  //   {
  //     percentage: '72%',
  //     img: '/images/Group 21049.png',
  //     text: 'Present Percentage',
  //   },
  // ]

  const columns = [
    {
      title: 'School Name',
      dataIndex: 'school.name',
      key: 'school.name',
    },
    {
      title: 'Teacher Name',
      dataIndex: 'teacher.name',
      key: 'teacher.name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'teacher.mobileNumber',
      key: 'teacher.mobileNumber',
    },
    {
      title: 'Gender',
      dataIndex: 'teacher.gender',
      key: 'teacher.gender',
    },
    {
      title: 'Subject',
      dataIndex: 'teacher.subject.name',
      key: 'teacher.subject.name',
      render: (_, row) => {
        return row?.teacher?.subject?.name ?? 'NA'
      },
    },
    // {
    //   title: 'Designation',
    //   dataIndex: 'teacher.designation',
    //   key: 'teacher.designation',
    // },
    {
      title: 'Attendance Status',
      dataIndex: 'status',
      key: 'status',
      render: (rowData) =>
        rowData ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Present
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Absent
          </Box>
        ),
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => (
        <Box display="flex" gap="10px">
          <ViewButton
            onClick={() =>
              navigate(`/crt-teachers/attendance-details?id=${row?.teacherId}`)
            }
          />
        </Box>
      ),
    },
  ]

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/attendance-activities/teacher?type=CRT&date=${date}&limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      console.log(res)
      setTeachers(res?.data?.stats?.teacher?.result)
      setPaginationLoading(false)
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
      setPaginationLoading(false)
    }
  }

  const handleSubmit = (e) => {
    e?.preventDefault()
    setLoading(true)
    http
      .get(
        `/attendance-activities/teacher?type=CRT&date=${date}&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      .then((res) => {
        console.log(res)
        setStats(res?.data?.crtTeacherSubject)
        setCount(res?.data?.stats?.teacher?.count)
        setTeachers(res?.data?.stats?.teacher?.result?.slice(0, 10))
        setLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div>
      <Box sx={{ marginTop: '1rem' }}>
        {(userType == 'Admin' || userType == 'DD') && (
          <form onSubmit={handleSubmit}>
            <Stack
              gap={3}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                // width: '100%',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="districtId">Select District</InputLabel>
                <Select
                  labelId="districtId"
                  id="districtId"
                  label="Select District"
                  value={districtId}
                  onChange={(e) => {
                    setAtdoPointId('')
                    setDistrictId(e?.target?.value)
                  }}
                >
                  {districts?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                <Select
                  labelId="atdoPointId"
                  id="atdoPointId"
                  label="Select ATDO Point"
                  value={atdoPointId}
                  onChange={(e) => setAtdoPointId(e?.target?.value)}
                >
                  {atdoPoints?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="schoolId">Select School</InputLabel>
                <Select
                  labelId="schoolId"
                  id="schoolId"
                  label="Select School"
                  value={schoolId}
                  onChange={(e) => setSchoolId(e?.target?.value)}
                >
                  {schools?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: ' white !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                  border: '1px solid black',
                }}
                onClick={() => window.location.reload(true)}
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  Clear
                </Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                }}
                type="submit"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  Apply
                </Typography>
              </Button>
            </Stack>
          </form>
        )}
      </Box>
      <Box>
        <DividerHeading title={'Subjects Metrics'} />
      </Box>
      <Grid container spacing={1}>
        <Grid item xl={2} lg={3} md={3} sx={{ marginTop: '-29px' }}>
          <PaperCard
            height="363px"
            title="CRT Teachers"
            count={crtCount}
            img="/images/Group 20997.png"
            linkText="View Teachers"
            linkColor="#1BC0FA"
          />
        </Grid>
        <Grid
          item
          xl={10}
          lg={9}
          md={9}
          sx={{
            marginTop: '-17px',
          }}
        >
          <Grid container spacing={1}>
            {stats?.map((item) => (
              <Grid item xl={3} lg={4} md={4}>
                <SmallCard
                  img={
                    item?.name == 'All Subjects'
                      ? '/images/vuesax-outline-3square.png'
                      : '/images/vuesax-outline-book.png'
                  }
                  title={item?.name}
                  count={item?.count}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: '180px', height: '50px' }}>
            <DatePicker
              value={date}
              onChange={(newValue) => {
                setDate(newValue)
              }}
              inputFormat="DD MMM, YYYY"
              maxDate={new Date()}
              renderInput={(params) => <TextField {...params} />}
            />{' '}
          </Box>
        </LocalizationProvider>{' '}
      </Box>
      <Box sx={{ marginTop: '-40px' }}>
        <DividerHeading title={'Attendance Details'} />
      </Box>{' '}
      {paginationLoading ? (
        <CircularProgress />
      ) : count == 0 ? (
        <SchoolAnalyticsEmptyScreen />
      ) : (
        <>
          <Table
            key={'crtTeachers'}
            dataSource={teachers}
            rowKey={'id'}
            columns={columns}
            loading={loading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
    </div>
  )
}

export default CRTTeachers
