import { AppBar, Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import '../../App.css'

const AcademicMasterData = () => {
  const userType = localStorage.getItem('user_type')

  return (
    <div>
      <AppBar
        style={{
          // position: 'absolute',
          top: 74,
          left: '162px',
          backgroundColor: '#F4F4F4',
          boxShadow: 'none',
        }}
      >
        <nav
          style={{
            display: 'flex',
            paddingLeft: '38px',
          }}
        >
          {/* {userType == 'Admin' && (
              <> */}
          <NavLink
            className={'masterDataNavLink'}
            to={'/academic-master-data'}
            end
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              Classrooms
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={'masterDataNavLink'}
            to={'/academic-master-data/tests'}
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              Tests
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={'masterDataNavLink'}
            to={'/academic-master-data/subjects'}
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              Subjects
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={'masterDataNavLink'}
            to={'/academic-master-data/test-subjects'}
          >
            <Typography
              className="masterDataNavLinkText"
              sx={{ fontSize: '15px', fontFamily: 'poppins_semibold' }}
            >
              Classroom Subjects
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          {/* </>)} */}
        </nav>
      </AppBar>
      <Box sx={{ marginTop: '50px' }}>
        <Outlet />
      </Box>
    </div>
  )
}

export default AcademicMasterData
