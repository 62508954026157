import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import MasterDataEmptyScreen from 'components/MasterDataEmptyScreen'
import SearchIcon from '@mui/icons-material/Search'
import Drawer from '@mui/material/Drawer'
import React, { useEffect, useState } from 'react'
import Table from 'components/Mui/TableComponent'
import { useNavigate } from 'react-router-dom'
import { EditButton } from 'components/IconButtons'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import { Close, SettingsCellOutlined } from '@mui/icons-material'
import DistrictsForm from './DistrictsForm'
import { useQueryClient, useQuery } from 'react-query'
import { http } from 'services/http'
import moment from 'moment'
import { UploadModal } from 'components/UploadModal/UploadModal'
import TestsEmptyScreen from './TestsEmptyScreen'
import TestsForm from './TestsForm'

const Tests = () => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [tests, setTests] = useState()
  const [formData, setFormData] = useState([])
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }
  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery(
    ['tests'],
    () =>
      http.get(`/test`).then((res) => {
        console.log(res)
        return res?.data
      }),
    {
      onSuccess: (data) => {
        setCount(data?.length)
        setTests(data?.slice(0, 10))
      },
    },
  )

  async function getTests() {
    setSearchLoading(true)
    try {
      http.get(`/tests?search=${search}`).then((res) => {
        console.log(res)
        setPage(1)
        setCount(res?.data?.tests?.length)
        setDistricts(res?.data?.tests?.slice(0, 10))
        setSearchLoading(false)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const columns = [
    {
      title: 'Test Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Added on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (colData, row) => (
        <EditButton
          onClick={() => {
            handleEditClick(row)
          }}
        />
      ),
    },
  ]

  if (isLoading) {
    return <CircularProgress />
  }

  const handleSearch = (e) => {
    e?.preventDefault()
    getTests()
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/tests?limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&search=${search}`,
      )
      console.log(res)
      setTests(res.data?.tests)
      setLoading(false)
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
      setLoading(false)
    }
  }

  console.log(tests)
  return (
    <div>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <form className="mb-4" onSubmit={handleSearch}>
            <TextField
              label="Search by School Name"
              sx={{ width: '494px', marginBottom: '1.5rem' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <Box sx={{ display: 'flex' }} gap={'18px'}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: '10px',
                borderColor: 'black !important',
                background: 'transparent !important',
              }}
              onClick={() => {
                setFormData(null)
                toggleDrawer(true)
              }}
            >
              {' '}
              <Typography
                sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
              >
                + Add Test
              </Typography>
            </Button>
          </Box>
        </Box>
        {tests?.length == 0 ? (
          <TestsEmptyScreen title="Tests" section={'tests'} />
        ) : (
          <>
            <Table
              key={'Tests'}
              dataSource={tests}
              rowKey={'id'}
              columns={columns}
              loading={isLoading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />
            </Box>
          </>
        )}
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            {formData ? 'Edit Test' : 'Add Test'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <TestsForm data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  )
}

export default Tests
