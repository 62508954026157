import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import DividerHeading from 'components/DividerHeading'
import PageHeader from 'components/Mui/PageHeader'
import Table from 'components/Mui/TableComponent'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { http } from 'services/http'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from 'pages/MasterData/DrawerTemplate'
import AdminUserForm from './AdminUserForm'
import { DeleteButton, EditButton } from 'components/IconButtons'
import { useSnackbar } from 'notistack'
import AdminUsersEmptyScreen from './AdminUsersEmptyScreen'
import SearchIcon from '@mui/icons-material/Search'

const AdminUsersDetails = () => {
  const [searchParams] = useSearchParams()
  const [details, setDetails] = useState()
  const [count, setCount] = useState()
  const type = searchParams?.get('type')
  const [formData, setFormData] = useState([])
  const [open, setOpen] = React.useState(false)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState()
  const [searchLoading, setSearchLoading] = useState()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  useEffect(() => {
    handleSearch()
  }, [search])

  const buttonSX = {
    '&:hover': {
      color: 'white',
      backgroundColor: 'black',
    },
    marginTop: '-25px',
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    color: 'white',
    backgroundColor: 'black',
  }

  const { data, isLoading } = useQuery(
    ['adminUsersDetails'],
    () =>
      http.get(`/users?type=${type}`).then((res) => {
        console.log(res)
        return res?.data
      }),
    {
      onSuccess: (data) => {
        setCount(data?.count)
        setDetails(data?.adminUsers?.slice(0, 10))
      },
    },
  )
  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  async function getAdminUsers() {
    setSearchLoading(true)
    try {
      http.get(`/users?type=${type}&search=${search}`).then((res) => {
        console.log(res)
        setPage(1)
        setCount(res?.data?.count)
        setDetails(res?.data?.adminUsers?.slice(0, 10))
        setSearchLoading(false)
      })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  const handleDelete = (id) => {
    http
      .delete(`/users/${id}`)
      .then((res) => {
        queryClient.invalidateQueries('adminUsersDetails')
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message)
      })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },

    {
      title: 'Mail ID',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => (
        <Box display="flex" gap="10px">
          <EditButton
            onClick={() => {
              handleEditClick(row)
            }}
          />
          <DeleteButton onClick={() => handleDelete(row?.id)} />
        </Box>
      ),
    },
  ]

  async function handlePagination(e, v) {
    setPage(v)
    try {
      setLoading(true)
      const res = await http.get(
        `/users?type=${type}&limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&search=${search}`,
      )
      console.log(res)
      setDetails(res?.data?.adminUsers)
      setLoading(false)
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
      setLoading(false)
    }
  }
  useEffect(() => {
    getAdminUsers()
  }, [search])

  const handleSearch = (e) => {
    e?.preventDefault()
    getAdminUsers()
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Admin Users', to: '/admin-users' },
              {
                name: 'Admin User details',
                to: null,
              },
            ]}
          />
        }
      />
      <Box>
        <Box sx={{ marginBottom: '35px' }}>
          <Card title={type} />
        </Box>
        <DividerHeading title={`Users`} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '30px',
          }}
        >
          <Box>
            <form className="mb-4" onSubmit={handleSearch}>
              <TextField
                label="Search Users by name"
                sx={{ width: '494px', marginBottom: '1.5rem' }}
                hookForm={false}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
            </form>
          </Box>
          <Box>
            <Button
              sx={buttonSX}
              //    variant="contained"
              onClick={() => {
                setFormData(null)
                toggleDrawer(true)
              }}
            >
              + Add New User
            </Button>{' '}
          </Box>
        </Box>
        {details?.length != 0 ? (
          <>
            <Table
              key={'AdminUsers'}
              dataSource={details}
              rowKey={'id'}
              columns={columns}
              loading={isLoading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />{' '}
            </Box>
          </>
        ) : (
          <AdminUsersEmptyScreen type={type} />
        )}
        <Drawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <StyledDrawerHeader>
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              {formData ? 'Edit User' : 'Add New User'}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: 'poppins_semibold',
                color: 'white',
                opacity: '0.5',
                cursor: 'pointer',
              }}
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Typography>
          </StyledDrawerHeader>
          <StyledDrawerContent>
            <Box sx={{ width: '550px' }}>
              <AdminUserForm
                data={formData}
                toggleDrawer={toggleDrawer}
                type={type}
              />
            </Box>
          </StyledDrawerContent>
        </Drawer>
      </Box>
    </div>
  )
}

export default AdminUsersDetails
const Card = ({ title, img }) => {
  return (
    <Box
      sx={{
        borderRadius: '10px',
        width: '270px',
        boxShadow: '0px 3px 13px #2A32711A',
      }}
    >
      <Box
        sx={{ padding: '20px 18px', display: 'flex', alignItems: 'center' }}
        gap={'13px'}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_medium',
              opacity: '0.5',
            }}
          >
            Role Name
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              marginTop: '8px',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
