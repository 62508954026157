import React from 'react'
import './ImageModal.css'

const ImageModal = ({ setOpenModal, img }) => {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            style={{ color: '#B4B5B1' }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            X
          </button>
        </div>
        <img
          src={img}
          style={{
            width: '554px',
            height: '501px',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
        />
      </div>
    </div>
  )
}

export default ImageModal
