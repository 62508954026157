import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Link } from 'react-router-dom'

const PaperCard = ({
  width,
  count,
  height = '320px',
  img,
  link,
  linkColor,
  linkText,
  title,
}) => {
  return (
    <Box
      sx={{
        // width: '260px',
        height: '280px',
        borderRadius: '10px',
        marginRight: '29px',
        marginTop: '29px',
        boxShadow: '0px 3px 13px #2A32711A',
      }}
    >
      <Box sx={{ padding: '30px' }}>
        <Box sx={{ marginTop: '20px', marginBottom: '1rem' }}>
          <img src={img} style={{ width: '78px', height: '78px' }} />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: 'poppins_medium',
              opacity: '0.7',
              marginTop: '27px',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: '36px',
              fontFamily: 'poppins_semibold',
              marginTop: '10px',
            }}
          >
            {count}
          </Typography>
        </Box>
      </Box>
      {link && (
        <Link to={link} style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              // position: 'absolute',
              // bottom: '0px',
              // right: '0px',
              // left: '0px',
              display: 'flex',
              padding: '20px',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: linkColor,
              borderRadius: '0px 0px 10px 10px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              {linkText}
            </Typography>
            <ArrowRightAltIcon sx={{ color: 'white' }} />
          </Box>
        </Link>
      )}
    </Box>
  )
}

export default PaperCard
