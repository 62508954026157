import { Box, Button, Drawer, Typography } from '@mui/material'
import React, { useState } from 'react'
import CategoriesForm from './CategoriesForm'
import CRTteachersForm from './CRTteachersForm'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'

const CategoriesEmptyScreen = () => {
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }
  const buttonSX = {
    '&:hover': {
      borderColor: '#24272C',
      color: '#24272C',
      background: 'none',
    },
    marginTop: '28px',
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    borderColor: '#24272C',
    color: '#24272C',
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10rem',
        }}
      >
        <img src="/images/Group 20286.png" style={{ height: '300px' }} />
        <Box>
          <Typography
            sx={{
              fontSize: '22px',
              fontFamily: 'poppins_bold',
              marginTop: '23px',
              marginBottom: '10px',
            }}
          >
            <center>{`Categories`}</center>
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_medium',
              opacity: '0.85',
            }}
          >
            <center>{`There are no Categories.`}</center>
          </Typography>
          {/* <center>
            <Button
              sx={buttonSX}
              variant="outlined"
              onClick={() => {
                toggleDrawer(true)
              }}
            >
              + Add Category
            </Button>
          </center> */}
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_semibold',
              color: 'white',
            }}
          >
            {'Add Category'}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontFamily: 'poppins_semibold',
              color: 'white',
              opacity: '0.5',
              cursor: 'pointer',
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: '550px' }}>
            <CategoriesForm toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  )
}

export default CategoriesEmptyScreen
