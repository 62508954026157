import { Box, Grid, styled, Typography } from '@mui/material'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { http } from 'services/http'

const HospitalisedDetailsCard = ({ details }) => {
  const [status, setStatus] = useState('')
  useEffect(() => {
    details?.status && setStatus(details?.status)
  }, [details])

  const StatusBox = styled(Box)(() => {
    return {
      backgroundColor:
        details?.status === 'ADMITTED'
          ? '#FFFAEF'
          : details?.status === 'DISCHARGED' && '#E8F6EE',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '8px 8px',
    }
  })

  let desc =
    details?.description?.length > 500
      ? details?.description?.slice(0, 500) + '...'
      : details?.description

  const itemId = details?.id

  const fetchImages = async (id) => {
    const res = await http.get(`/hospitalized-students/images/${id}`)
    return res?.data
  }

  const { data: images, isLoading } = useQuery(
    ['hospitalizedImages', itemId],
    () => fetchImages(itemId),
    {
      enabled: !!itemId,
    },
  )
  return (
    <Box
      style={{ padding: '17px' }}
      overflow="auto"
      maxHeight="700px"
      className="scrollBox"
    >
      {' '}
      <Box
        // key={index}
        p={2}
        mb={1.5}
        border={'1px solid rgba(34, 34, 34, 0.08)'}
        borderRadius="5px"
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={6}>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Admitted Date
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {details?.admittedDate
                  ? moment(details?.admittedDate).format('DD-MM-YYYY')
                  : 'NA'}
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Student's Name
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {details?.studentName ?? 'NA'}
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Hospital Name
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {details?.hospitalName ?? 'NA'}
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Description
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {desc}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Box>
              <StatusBox status={details?.status}>
                <Typography
                  sx={{
                    color:
                      details?.status === 'ADMITTED'
                        ? '#FFCF64'
                        : details?.status === 'DISCHARGED' && '#1AAA55',
                  }}
                >
                  {details?.status}
                </Typography>
              </StatusBox>
              <Box
                pt={2.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '14px',
                    opacity: '0.5',
                  }}
                >
                  Class
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    fontSize: '14px',
                  }}
                >
                  {details?.studentClass ?? 'NA'}
                </Typography>
              </Box>
              <Box
                pt={2.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '14px',
                    opacity: '0.5',
                  }}
                >
                  Priority
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    fontSize: '14px',
                  }}
                >
                  {details?.priority}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2.5}>
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              fontSize: '14px',
              opacity: '0.5',
            }}
          >
            Uploaded Images
          </Typography>
          {images?.length == 0 ? (
            <SchoolAnalyticsEmptyScreen height={'190px'} />
          ) : (
            images?.map((item, index) => {
              return (
                <Box
                  p={2}
                  mt={1.25}
                  sx={{
                    border: '1px solid rgba(34, 34, 34, 0.08)',
                    borderRadius: '5px',
                  }}
                >
                  <img
                    style={{ width: '100%', borderRadius: '5px' }}
                    src={item}
                  />
                </Box>
              )
            })
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default HospitalisedDetailsCard
