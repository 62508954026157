import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const AdminUserForm = ({ data, toggleDrawer, type }) => {
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  const [districts, setDistricts] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [atdoPoints, setAtdoPoints] = useState([])
  const [allAtdoPoints, setAllAtdoPoints] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      fullName: data?.fullName,
      email: data?.email,
      mobileNumber: data?.mobileNumber,
      gender: data?.gender,
      districtId: data?.districtId,
      atdoPointId: data?.atdoPointId,
    },
  })

  const districtid = watch('districtId')
  const atdoPointID = watch('atdoPointId')

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  useEffect(() => {
    setDistrictId(
      allAtdoPoints?.filter((item) => item?.id == atdoPointID)[0]?.districtId,
    )
  }, [atdoPointID])

  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  useEffect(() => {
    http.get(`/districts`).then((res) => {
      console.log(res)
      setDistricts(res?.data?.districts)
    })
  }, [])

  useEffect(() => {
    http.get(`/atdo-point`).then((res) => {
      setAllAtdoPoints(res?.data?.atdoPoints)
    })
  }, [])

  useEffect(() => {
    http.get(`/atdo-point`).then((res) => {
      edit
        ? setAtdoPoints(res?.data?.atdoPoints)
        : setAtdoPoints(
            res?.data?.atdoPoints.filter((item) => {
              return item?.districtId == districtid
            }),
          )
    })
  }, [districtid])

  const onSubmit = (data) => {
    console.log(data)
    if (edit) {
      console.log(id)
      const body = { ...data, type: type, districtId: districtId }
      http
        .put(`/users/${id}`, body)
        .then((res) => {
          enqueueSnackbar('User details have been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('adminUsersDetails')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      const body = { ...data, type: type, districtId: districtId }

      http
        .post(`/users`, body)
        .then((res) => {
          enqueueSnackbar('User has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('adminUsersDetails')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }

  console.log(errors)

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(type == 'DD' ||
            type == 'AE' ||
            type == 'DE' ||
            type == 'MHO' ||
            type == 'DMHO' ||
            type == 'MGCC' ||
            type == 'DMGCC') && (
            <FormControl fullWidth sx={{ marginTop: '24px' }}>
              <InputLabel id="districtId">District Name *</InputLabel>
              <Select
                labelId="districtId"
                id="districtId"
                label="District Name *"
                {...register('districtId', { required: true })}
                defaultValue={data?.districtId}
                required={true}
              >
                {districts?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          )}

          {errors?.districtId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {type == 'ATDO' || type == 'Admin' ? (
            <FormControl fullWidth sx={{ marginTop: '24px' }}>
              <InputLabel id="atdoPointId">ATDO Point Name *</InputLabel>
              <Select
                labelId="atdoPointId"
                id="atdoPointId"
                label="ATDO Point Name *"
                {...register('atdoPointId', { required: true })}
                defaultValue={data?.atdoPointId}
                required={true}
              >
                {allAtdoPoints?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          ) : (
            <FormControl fullWidth sx={{ marginTop: '24px' }}>
              <InputLabel id="atdoPointId">ATDO Point Name *</InputLabel>
              <Select
                labelId="atdoPointId"
                id="atdoPointId"
                label="ATDO Point Name *"
                {...register('atdoPointId', { required: true })}
                defaultValue={data?.atdoPointId}
                required={true}
              >
                {atdoPoints?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          )}

          {errors?.atdoPointId && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('fullName', {
              required: true,
              pattern: { value: /^[A-Za-z,\- .]+$/ },
            })}
            label={'User Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.fullName?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.fullName?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('mobileNumber', {
              required: true,
              pattern: /^\d{10}$/,
            })}
            label={'Mobile Number'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.mobileNumber?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.mobileNumber?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Mobile Number
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('email', {
              required: true,
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            })}
            label={'Email ID'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.email?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.email?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Email ID
            </Typography>
          )}
          <TextField
            type={showPassword ? 'text' : 'password'}
            {...register('password', { required: true })}
            label={'Password'}
            required={true}
            sx={{ marginTop: '24px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors?.password && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {data ? 'Edit User' : 'Add User'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default AdminUserForm
