import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Drawer from '@mui/material/Drawer'
import React, { useEffect, useState } from 'react'
import Table from 'components/Mui/TableComponent'
import { useNavigate } from 'react-router-dom'
import { DeleteButton, EditButton } from 'components/IconButtons'
import { StyledDrawerContent, StyledDrawerHeader } from './DrawerTemplate'
import { Close, SettingsCellOutlined } from '@mui/icons-material'
import AssetCategoriesForm from './AssetCategoriesForm'
import { useQueryClient, useQuery } from 'react-query'
import { http } from 'services/http'
import moment from 'moment'
import AssetCategoriesEmptyScreen from './AssetCategoriesEmptyScreen'

const AssetCategories = () => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [assetCategories, setAssetCategories] = useState([
    { name: 'A', createdOn: '2023-01-17T11:53:01.000Z' },
    { name: 'B', createdOn: '2023-01-18T11:53:01.000Z' },
  ])
  const [formData, setFormData] = useState([])
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()
  const [modalOpen, setModalOpen] = useState(false)

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }
  const queryClient = useQueryClient()

  useEffect(() => {
    modalOpen == false && queryClient.invalidateQueries('assetCategories')
  }, [modalOpen])
  const { data, isLoading } = useQuery(
    ['assetCategories'],
    () =>
      http.get(`/assets-category`).then((res) => {
        console.log(res)
        setCount(res?.data?.count)

        return res?.data?.result
      }),
    {
      onSuccess: (data) => {
        setAssetCategories(data?.slice(0, 10))
      },
    },
  )

  useEffect(() => {
    ;(search?.length != 0 || search == '') && getAssetCategories()
  }, [search])

  async function getAssetCategories() {
    setSearchLoading(true)
    try {
      http.get(`/assets-category?search=${search}`).then((res) => {
        console.log(res)
        setPage(1)
        setCount(res?.data?.count)
        setAssetCategories(res?.data?.result?.slice(0, 10))
        setSearchLoading(false)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const handleDelete = (id) => {
    http
      .delete(`/assets-category/${id}`)
      .then((res) => {
        queryClient.invalidateQueries('assetCategories')
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message)
      })
  }

  const columns = [
    {
      title: 'Asset Category Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Added on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (colData, row) => (
        <Box sx={{ display: 'flex' }} gap={'18px'}>
          <EditButton
            onClick={() => {
              handleEditClick(row)
            }}
          />
          <DeleteButton onClick={() => handleDelete(row?.id)} />
        </Box>
      ),
    },
  ]

  if (isLoading || loading || !assetCategories) {
    return <CircularProgress />
  }

  //   const handleSearch = (e) => {
  //     e?.preventDefault()
  //     getDistricts()
  //   }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      const res = await http.get(
        `/assets-category?limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&search=${search}`,
      )
      console.log(res)
      setAssetCategories(res.data?.result)
      setLoading(false)
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
      setLoading(false)
    }
  }

  //   console.log(districts)
  return (
    <div>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <form className="mb-4" onSubmit={() => {}}>
            <TextField
              label="Search by Asset Category Name"
              sx={{ width: '494px', marginBottom: '1.5rem' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => {}}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <Box sx={{ display: 'flex' }} gap={'18px'}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: '10px',
                borderColor: 'black !important',
                background: 'transparent !important',
              }}
              onClick={() => {
                setFormData(null)
                toggleDrawer(true)
              }}
            >
              {' '}
              <Typography
                sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
              >
                + Add Asset Category
              </Typography>
            </Button>
          </Box>
        </Box>
        {assetCategories?.length == 0 ? (
          <AssetCategoriesEmptyScreen
            title="Asset Categories"
            section={'Asset categories'}
          />
        ) : (
          <>
            <Table
              key={'Asset Categories'}
              dataSource={assetCategories}
              rowKey={'id'}
              columns={columns}
              loading={isLoading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />
            </Box>
          </>
        )}

        <Drawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <StyledDrawerHeader>
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              {formData ? 'Edit Asset Category' : 'Add Asset Category'}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: 'poppins_semibold',
                color: 'white',
                opacity: '0.5',
                cursor: 'pointer',
              }}
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Typography>
          </StyledDrawerHeader>
          <StyledDrawerContent>
            <Box sx={{ width: '550px' }}>
              <AssetCategoriesForm
                data={formData}
                toggleDrawer={toggleDrawer}
              />
            </Box>
          </StyledDrawerContent>
        </Drawer>
      </Box>
    </div>
  )
}

export default AssetCategories
