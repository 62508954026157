import { Box, Grid, Typography } from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import PieChartCard from '../PieChart'

const TestDetails = ({ dataVal }) => {
  const data = dataVal?.grades?.map((item) => {
    return {
      name: `grade ${item.grade}`,
      value: item?.percentage,
    }
  })

  const COLORS = ['#FF3465', '#FFCF64', '#00D9A6', '#25A4CF']

  return (
    <Box>
      <DividerHeading title={dataVal?.test?.name} />
      <Box p={2}>
        {dataVal?.grades?.length > 0 ? (
          <Grid
            sx={{
              p: 2,
              borderRadius: '10px 10px 10px 10px',
              boxShadow: '0px 3px 13px #2A32711A',
            }}
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <PieChartCard
                data={data}
                COLORS={COLORS}
                name={dataVal?.subject?.name}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                {dataVal?.grades?.map((item, index) => (
                  <GradeCard
                    title={`grade ${item?.grade}`}
                    percentage={item?.percentage.toFixed(1)}
                    color={
                      index === 0
                        ? '#FF3465'
                        : index === 1
                        ? '#FFCF64'
                        : index === 2
                        ? '#00D9A6'
                        : '#25A4CF'
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box>No data</Box>
        )}
      </Box>
    </Box>
  )
}

export default TestDetails

const GradeCard = ({ color, percentage, title }) => {
  return (
    <Grid item xs={12} md={6}>
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderRadius: '10px 10px 10px 10px',
          boxShadow: '0px 3px 13px #2A32711A',
        }}
      >
        <Box p display="flex" alignItems="center">
          <Box
            sx={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: color,
            }}
          ></Box>
          <Typography ml={1} variant="h5">
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5">{percentage} %</Typography>
        </Box>
      </Box>
    </Grid>
  )
}
