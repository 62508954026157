import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import PageHeader from 'components/Mui/PageHeader'
import SearchIcon from '@mui/icons-material/Search'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useState } from 'react'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import Table from 'components/Mui/TableComponent'
import { ViewButton } from 'components/IconButtons'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import Loader from 'components/Loader'

function ViewStockUsageTable() {
  let { id } = useParams();
    const [date, setDate] = useState(new Date())
    const navigate = useNavigate()

    const { data, isLoading } = useQuery(["stockusage",date], () =>
    http.get(`/stock-usages/${id}`).then((res) => res.data)
);

    const columns = [
        {
          title: 'Stock Item Name',
          dataIndex: 'stockMaterial.itemName',
          key: 'stockMaterial.itemName',
        },
        {
          title: 'No. of Primary School Students',
          dataIndex: 'stockMaterial',
          key: 'stockMaterial',
          render: (_,row) => (
          <Typography>{data[0]?.noOfLowerClassStudents}</Typography>
          )
        },
        {
          title: 'No. of Secondary school Students',
          dataIndex: 'stockMaterial.id',
          key: 'stockMaterial.id',
          render: (_,row) => (
            <Typography>{data[0]?.noOfUpperClassStudents}</Typography>
            )
        },
        {
          title: 'Total Cost For Primary Students',
          dataIndex: 'lowerClassPrice',
          key: 'lowerClassPrice',
        },
        {
            title: 'Total Cost For Secondary Students',
            dataIndex: 'upperClassPrice',
            key: 'upperClassPrice',
          },
          {
            title: 'Total Quantity',
            dataIndex: 'changeQuantity',
            key: 'changeQuantity',
            render: (_,row) => (
              <Typography>{row.changeQuantity +"  " + row.stockMaterial.unitOfMeasure }</Typography>
            )
          },
          {
            title: 'Total Cost',
            dataIndex: 'price',
            key: 'price',
            render: (_,row) => (
              <Typography>{row.price + "/-"}</Typography>
            )
          }
      ]

      if(isLoading)return<Loader/>

  return (
    <div>
     <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'GCC Stock', to: '/gcc' },
              {
                name: 'Stock Usage',
                to: null,
              },
            ]}
          />
        }
      />
      <Box display="flex" justifyContent="flex-end" mb={2}>
       {/* <TextField
            label="Search by School Name"
            sx={{ width: '300px' }}
            // hookForm={false}
            InputProps={{
              endAdornment: (
                <IconButton >
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={""}
            onChange={({ target }) => {
              setSearch(target.value)
            }}
          /> */}
          {/* <Box display="flex" gap={2}> */}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '155px', height: '50px' }}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue)
                    }}
                    inputFormat="DD MMM, YYYY"
                    maxDate={new Date()}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider> */}
              <Button
          variant="contained"
          sx={{
            backgroundColor: 'black !important',
            height: '50px',
            width: '130px',
            borderRadius: '10px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'poppins_semibold',
              color: 'white',
              fontSize: '14px',
            }}
          >
            Export Report
          </Typography> 
        </Button>

          {/* </Box> */}

      </Box>

      {false ? (
        <center>
          <SchoolAnalyticsEmptyScreen />
        </center>
      ) : (
        <>
          <Table
            key={'schools'}
            dataSource={data[0]?.individualStockUsages}
            rowKey={'id'}
            columns={columns}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '} */}
          </Box>
        </>
      )}
       
      
    </div>
  )
}

export default ViewStockUsageTable