import { Box, Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

export default function PageHeader({
  title,
  subtitle,
  actions,
  noPadding = false,
}) {
  return (
    <Box
      sx={{
        minHeight: '40px',
        padding: noPadding ? 0 : '0 25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1rem 0',
      }}
    >
      <>
        {typeof title === 'string' ? (
          <Typography variant="h1">{title}</Typography>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <Typography>{subtitle}</Typography>
        ) : (
          <Box marginTop={'10px'}>{subtitle}</Box>
        )}
      </>
      <Stack direction="row" alignItems="center" gap="0.5rem">
        {actions}
      </Stack>
    </Box>
  )
}
