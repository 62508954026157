import { Box, IconButton, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import styles from './Styles.module.css'
import EastIcon from '@mui/icons-material/East'
import { Cell, Pie, PieChart } from 'recharts'

export const ContentCards = ({
  count,
  text,
  img,
  percentage,
  height = '65px',
  link,
  // width = img && '100%',
}) => {
  const attPercentage = +(percentage && percentage?.split('%')[0])
  const data = [
    { name: 'Group A', value: attPercentage },
    { name: 'Group B', value: 100 - attPercentage },
    // { name: 'Group C', value: 300 },
    // { name: 'Group D', value: 200 },
  ]
  const COLORS = ['#68C689', '#DBDBDB']
  return link ? (
    <Box
      sx={{
        padding: '63px 28px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: img ? 'center' : 'space-between',
        alignItems: 'center',
        boxShadow: '0px 3px 13px #2A32711A',
        margin: '29px 29px 0 0',
        // width: width,
        height: height,
      }}
    >
      <Link to={link} style={{ textDecoration: 'none', width: '100%' }}>
        {!img ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
              >
                {count}
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'poppins_medium',
                  opacity: '0.7',
                }}
              >
                {text}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton sx={{ color: 'black' }}>
                <EastIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '28px',
              // width: '100%',
              // flexWrap: 'wrap',
            }}
          >
            <PieChart
              width={200}
              height={100}
              style={{ fontSize: '24px', fontFamily: 'poppins_semibold' }}
            >
              <Pie
                data={data}
                cx={80}
                cy={80}
                startAngle={180}
                endAngle={0}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <text x={87} y={80} textAnchor="middle" dominantBaseline="middle">
                {percentage}
              </text>
            </PieChart>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: 'poppins_medium',
                marginLeft: '-28px',
              }}
            >
              {text}
            </Typography>
          </Box>
        )}
      </Link>
    </Box>
  ) : (
    <Box
      sx={{
        padding: '63px 28px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: img ? 'center' : 'space-between',
        alignItems: 'center',
        boxShadow: '0px 3px 13px #2A32711A',
        margin: '29px 29px 0 0',
        // width: width,
        height: height,
      }}
    >
      {!img ? (
        <Box>
          <Typography sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}>
            {count}
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: 'poppins_medium',
              opacity: '0.7',
            }}
          >
            {text}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '28px',
            // width: '100%',
            // flexWrap: 'wrap',
          }}
        >
          {/* <PieChart
            width={800}
            height={400}
            // style={{ fontFamily: 'poppins_bold', fontSize: '22px' }}
          >
            <Pie
              data={data}
              // cx={120}
              // cy={200}
              innerRadius={80}
              outerRadius={100}
              fill="#8884d8"
              dataKey="percentage"
            >
              <Cell fill={'#68C689'} />
            </Pie>
            <g>
              <foreignObject width={200} height={100}>
                <Box className={styles.metricsContainer}>
                  <img className={styles.metricsImage} src={img} />
                  <Typography className={styles.metrics}>
                    {percentage}
                  </Typography>
                </Box>
              </foreignObject>
            </g>
          </PieChart> */}
          <PieChart
            width={200}
            height={100}
            style={{ fontSize: '24px', fontFamily: 'poppins_semibold' }}
          >
            <Pie
              data={data}
              cx={80}
              cy={80}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <text x={87} y={80} textAnchor="middle" dominantBaseline="middle">
              {percentage}
            </text>
          </PieChart>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'poppins_medium',
              marginLeft: '-28px',
            }}
          >
            {text}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
