import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import AbsentCards from 'components/AbsentCards'
import { ContentCards } from 'components/ContentCards'
import React, { useEffect, useState } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { http } from 'services/http'

const DashboardAttendanceActivities = () => {
  const [date, districtId, atdoPointId, mandalId, schoolId, hitApi, setHitApi] =
    useOutletContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [activities, setActivities] = useState()

  const type = 'Attendance'
  console.log(date)
  useEffect(() => {
    setLoading(true)
    http
      .get(
        `/statistics/activity?date=${date}&type=${type}&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      .then((res) => {
        console.log(res)
        setActivities(res?.data)
        setLoading(false)
        setHitApi(false)
      })
  }, [date, hitApi])

  useEffect(() => {
    localStorage.setItem('districtId', districtId)
    localStorage.setItem('atdoPointId', atdoPointId)
    localStorage.setItem('date', date)
  }, [districtId, atdoPointId, date])

  const handleNavigation = (title, type) => {
    localStorage.setItem('heading', title)
    navigate(`/activity-dashboard/unmarked-schools?type=${type}`)
  }

  const studentCards = [
    {
      count: activities?.student?.studentsCount,
      text: 'Overall Students',
      link: '/activity-dashboard/overall-attendance?type=Student',
    },
    {
      count: activities?.student?.presentStudents,
      text: 'Students Present',
      link: '/activity-dashboard/present-attendance?type=Student',
    },
    {
      count: activities?.student?.absentStudents ?? 'NA',
      text: 'Students Absent',
      link: '/activity-dashboard/absent-attendance?type=Student',
    },

    {
      count: activities?.student?.sickStudents ?? 'NA',
      text: 'Sick Students',
      link: '/activity-dashboard/sick-attendance?type=Student',
    },
    {
      percentage: `${
        Number.isInteger(activities?.student?.percentage)
          ? activities?.student?.percentage
          : activities?.student?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Present Percentage',
    },
  ]

  const regTeacherCards = [
    {
      count: activities?.regular?.totalTeachers,
      text: 'Overall Regular Teachers',
      link: '/activity-dashboard/attendance-details?type=Regular',
    },
    {
      count: activities?.regular?.presentTeachers,
      text: 'Regular Teachers Present',
      link: '/activity-dashboard/present-attendance-details?type=Regular',
    },
    {
      absentCount: activities?.regular?.absentTeachers,
      leaves: activities?.regular?.teacherLeave,
      link: '/activity-dashboard/absent-attendance-details?type=Regular',
    },
    {
      percentage: `${
        Number.isInteger(activities?.regular?.percentage)
          ? activities?.regular?.percentage
          : activities?.regular?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Present Percentage',
    },
  ]

  const crtTeacherCards = [
    {
      count: activities?.crt?.totalTeachers,
      text: 'Overall CRT Teachers',
      link: '/activity-dashboard/attendance-details?type=CRT',
    },
    {
      count: activities?.crt?.presentTeachers,
      text: 'CRT Teachers Present',
      link: '/activity-dashboard/present-attendance-details?type=CRT',
    },
    {
      absentCount: activities?.crt?.absentTeachers,
      leaves: activities?.crt?.crtLeave,
      link: '/activity-dashboard/absent-attendance-details?type=CRT',
    },
    {
      percentage: `${
        Number.isInteger(activities?.crt?.percentage)
          ? activities?.crt?.percentage
          : activities?.crt?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Present Percentage',
    },
  ]

  const nightCards = [
    {
      count: activities?.night?.studentsCount,
      text: 'Overall Students',
      link: '/activity-dashboard/overall-attendance?type=Night',
    },
    {
      count: activities?.night?.presentStudents,
      text: 'Students Present',
      link: '/activity-dashboard/present-attendance?type=Night',
    },
    {
      count: activities?.night?.absentStudents ?? 'NA',
      text: 'Students Absent',
      link: '/activity-dashboard/absent-attendance?type=Night',
    },

    {
      count: activities?.night?.sickStudents ?? 'NA',
      text: 'Sick Students',
      link: '/activity-dashboard/sick-attendance?type=Night',
    },
    {
      percentage: `${
        Number.isInteger(activities?.night?.percentage)
          ? activities?.night?.percentage
          : activities?.night?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Present Percentage',
    },
  ]

  const staffCards = [
    {
      count: activities?.staff?.totalStaff,
      text: 'Overall Staff Members',
      link: '/activity-dashboard/attendance-details?type=Staff',
    },
    {
      count: activities?.staff?.presentStaff,
      text: 'Staff Members Present',
      link: '/activity-dashboard/present-attendance-details?type=Staff',
    },
    {
      absentCount: activities?.staff?.absentStaff,
      leaves: activities?.staff?.staffLeave,
      link: '/activity-dashboard/absent-attendance-details?type=Staff',
    },
    {
      percentage: `${
        Number.isInteger(activities?.staff?.percentage)
          ? activities?.staff?.percentage
          : activities?.staff?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Present Percentage',
    },
  ]
  if (loading) {
    return <CircularProgress />
  }
  console.log(regTeacherCards[2]?.leaves)
  return (
    <div>
      <Grid container>
        <Grid item xl={11.05} lg={11.69} md={11.69}>
          <Box
            sx={{
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '15px' }}>
              Student Attendance
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'black !important',
                height: '50px',
                // width: '130px',
                borderRadius: '10px',
                // right: '7.9%',
              }}
              onClick={() => handleNavigation('Student Attendance', 'Student')}
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                View Unmarked Schools
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginTop: '-25px' }}>
        {studentCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={item?.img ? 3.25 : 2}
              lg={item?.img ? 4 : 3}
              md={item?.img ? 4 : 3}
            >
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Grid container>
        <Grid item xl={11.05} lg={11.58} md={11.58}>
          <Box
            sx={{
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '15px' }}>
              Regular Teacher Attendance
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'black !important',
                height: '50px',
                // width: '130px',
                borderRadius: '10px',
                // right: '7.9%',
              }}
              onClick={() =>
                handleNavigation('Regular Teacher Attendance', 'Regular')
              }
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                View Unmarked Schools
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginTop: '-25px' }}>
        <Grid item xl={3} lg={3} md={3}>
          <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12}>
              <ContentCards
                count={regTeacherCards[0]?.count}
                text={regTeacherCards[0]?.text}
                height={'5px'}
                link={regTeacherCards[0]?.link}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sx={{ marginTop: '-13px' }}>
              <ContentCards
                count={regTeacherCards[1]?.count}
                text={regTeacherCards[1]?.text}
                height={'5px'}
                link={regTeacherCards[1]?.link}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={5} lg={5} md={5}>
          <AbsentCards
            absentCount={regTeacherCards[2]?.absentCount}
            leaves={regTeacherCards[2]?.leaves}
            link={regTeacherCards[2]?.link}
          />
        </Grid>
        <Grid item xl={3.32} lg={4} md={4} sx={{ marginLeft: '-10px' }}>
          <ContentCards
            text={regTeacherCards[3]?.text}
            img={regTeacherCards[3]?.img}
            percentage={regTeacherCards[3]?.percentage}
            height={'152px'}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xl={11.05} lg={11.58} md={11.58}>
          <Box
            sx={{
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '15px' }}>
              CRT Teacher Attendance{' '}
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'black !important',
                height: '50px',
                // width: '130px',
                borderRadius: '10px',
                // right: '7.9%',
              }}
              onClick={() => handleNavigation('CRT Teacher Attendance', 'CRT')}
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                View Unmarked Schools
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginTop: '-25px' }}>
        <Grid item xl={3} lg={3} md={3}>
          <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12}>
              <ContentCards
                count={crtTeacherCards[0]?.count}
                text={crtTeacherCards[0]?.text}
                height={'5px'}
                link={crtTeacherCards[0]?.link}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sx={{ marginTop: '-13px' }}>
              <ContentCards
                count={crtTeacherCards[1]?.count}
                text={crtTeacherCards[1]?.text}
                height={'5px'}
                link={crtTeacherCards[1]?.link}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={5} lg={5} md={5}>
          <AbsentCards
            absentCount={crtTeacherCards[2]?.absentCount}
            leaves={crtTeacherCards[2]?.leaves}
            link={crtTeacherCards[2]?.link}
          />
        </Grid>
        <Grid item xl={3.32} lg={4} md={4} sx={{ marginLeft: '-10px' }}>
          <ContentCards
            text={crtTeacherCards[3]?.text}
            img={crtTeacherCards[3]?.img}
            percentage={crtTeacherCards[3]?.percentage}
            height={'152px'}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xl={11.05} lg={11.69} md={11.69}>
          <Box
            sx={{
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '15px' }}>
              Night Closing Attendance
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'black !important',
                height: '50px',
                // width: '130px',
                borderRadius: '10px',
                // right: '7.9%',
              }}
              onClick={() =>
                handleNavigation('Night Closing Attendance', 'Night')
              }
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                View Unmarked Schools
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ marginTop: '-25px' }}>
        {nightCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={item?.img ? 3.25 : 2}
              lg={item?.img ? 4 : 3}
              md={item?.img ? 4 : 3}
            >
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>

      <Grid container>
        <Grid item xl={11.05} lg={11.58} md={11.58}>
          <Box
            sx={{
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '15px' }}>
              Staff Attendance{' '}
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'black !important',
                height: '50px',
                // width: '130px',
                borderRadius: '10px',
                // right: '7.9%',
              }}
              onClick={() => handleNavigation('Staff Attendance', 'Staff')}
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                View Unmarked Schools
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginTop: '-25px' }}>
        <Grid item xl={3} lg={3} md={3}>
          <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12}>
              <ContentCards
                count={staffCards[0]?.count}
                text={staffCards[0]?.text}
                height={'5px'}
                link={staffCards[0]?.link}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sx={{ marginTop: '-13px' }}>
              <ContentCards
                count={staffCards[1]?.count}
                text={staffCards[1]?.text}
                height={'5px'}
                link={staffCards[1]?.link}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={5} lg={5} md={5}>
          <AbsentCards
            absentCount={staffCards[2]?.absentCount}
            leaves={staffCards[2]?.leaves}
            link={staffCards[2]?.link}
            title={'Staff Members'}
          />
        </Grid>
        <Grid item xl={3.32} lg={4} md={4} sx={{ marginLeft: '-10px' }}>
          <ContentCards
            text={staffCards[3]?.text}
            img={staffCards[3]?.img}
            percentage={staffCards[3]?.percentage}
            height={'152px'}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default DashboardAttendanceActivities
