import { IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import EastIcon from '@mui/icons-material/East'
import { Link } from 'react-router-dom'

const AbsentCards = ({ absentCount, leaves, height = '98px', link, title }) => {
  return !link ? (
    <div
      style={{
        // width: '600px',
        height: 'fit-content',
        boxShadow: '0px 3px 13px #2A32711A',
        borderRadius: '10px',
        margin: '29px 39px 0 0',
        padding: '38px 29px',
      }}
    >
      <Box>
        <Box sx={{ paddingBottom: '30px', borderBottom: '1px solid #EDEDED' }}>
          <Typography sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}>
            {absentCount}
          </Typography>
          <Typography
            sx={{
              fontSize: '15px',
              fontFamily: 'poppins_medium',
              opacity: '0.7',
            }}
          >
            {title == 'Staff Members'
              ? 'Staff Members Absent'
              : 'Teachers Absent'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '30px',
          }}
        >
          {leaves?.length != 0 ? (
            leaves?.map((item, ind) => {
              return (
                <Box>
                  <Typography
                    sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
                  >
                    {item?.count ? item?.count : 'NA'}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontFamily: 'poppins_medium',
                      opacity: '0.7',
                    }}
                  >
                    {item?.leave_type ? item?.leave_type : 'NA'}
                  </Typography>
                </Box>
              )
            })
          ) : (
            <Box>
              <Typography
                sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
              >
                NA
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'poppins_medium',
                  opacity: '0.7',
                }}
              >
                NA
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  ) : (
    <div
      style={{
        // width: '600px',
        height: 'fit-content',
        boxShadow: '0px 3px 13px #2A32711A',
        borderRadius: '10px',
        margin: '29px 39px 0 0',
        padding: '38px 29px',
      }}
    >
      <Box>
        <Box sx={{ paddingBottom: '30px', borderBottom: '1px solid #EDEDED' }}>
          <Link to={link} style={{ textDecoration: 'none', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
                >
                  {absentCount}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontFamily: 'poppins_medium',
                    opacity: '0.7',
                  }}
                >
                  {title == 'Staff Members'
                    ? 'Staff Members Absent'
                    : 'Teachers Absent'}
                </Typography>
              </Box>
              <Box>
                <IconButton sx={{ color: 'black' }}>
                  <EastIcon />
                </IconButton>
              </Box>
            </Box>
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '30px',
          }}
        >
          {leaves?.length != 0 ? (
            leaves?.map((item, ind) => {
              return (
                <Box>
                  <Typography
                    sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
                  >
                    {item?.count ? item?.count : 'NA'}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontFamily: 'poppins_medium',
                      opacity: '0.7',
                    }}
                  >
                    {item?.leave_type ? item?.leave_type : 'NA'}
                  </Typography>
                </Box>
              )
            })
          ) : (
            <Box>
              <Typography
                sx={{ fontSize: '32px', fontFamily: 'poppins_semibold' }}
              >
                NA
              </Typography>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontFamily: 'poppins_medium',
                  opacity: '0.7',
                }}
              >
                NA
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default AbsentCards
