import { Box, Button, Drawer, Typography } from '@mui/material'
import React, { useState } from 'react'

const SchoolAnalyticsEmptyScreen = ({ height }) => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src="/images/Group 20286.png"
          style={{ height: height ? height : '300px' }}
        />
        <Box>
          <Typography
            sx={{
              fontSize: '22px',
              fontFamily: 'poppins_bold',
              marginTop: '23px',
              marginBottom: '10px',
            }}
          >
            <center>No records found</center>
          </Typography>
        </Box>
      </Box>
    </div>
  )
}

export default SchoolAnalyticsEmptyScreen
