import { Box, Button, Modal, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { http } from 'services/http'
import FileUpload from './FileUpload/FileUpload'
import './UploadModal.css'

export const UploadModal = ({ setOpen, img, title, section, type }) => {
  const [file, setFile] = useState()
  const removeFile = (filename) => {
    setFile(file.filter((file) => file.name !== filename))
  }
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: '10px',
  }

  const handleClose = () => setOpen(false)
  const handleSubmit = () => {
    const formData = new FormData()

    formData.append('file', file.selectedFile)
    http
      .post(`/${section}/upload?type=${type}`, formData)
      .then((res) => {
        enqueueSnackbar('Successfully uploaded', { variant: 'success' })

        setOpen(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, { variant: 'error' })
      })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ borderBottom: '1px solid #E5E5E4' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '28px 28px 15px 28px',
            }}
          >
            <Typography
              sx={{ fontSize: '16px', fontFamily: 'poppins_semibold' }}
            >
              {title}
            </Typography>
            <Typography
              onClick={() => handleClose()}
              id="modal-modal-description"
              sx={{
                fontSize: '14px',
                fontFamily: 'poppins_semibold',
                opacity: '0.5',
                cursor: 'pointer',
              }}
            >
              Cancel{' '}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: '28px' }}>
          <FileUpload
            onChange={(v) => setFile({ ...file, selectedFile: v })}
            name="sheet"
            accept=".xls,.xlsx"
          />
        </Box>
        <Box
          sx={{
            padding: '28px',
            paddingTop: '0px',
            paddingLeft: '0px',
            paddingRight: '0px',
            marginTop: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          gap={'18px'}
        >
          <Button
            sx={{
              backgroundColor: '#24272C',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#24272C',
              },
            }}
            onClick={() => handleSubmit()}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              Upload
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: '#F2F2F2',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
            }}
            onClick={() => handleClose()}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'black',
              }}
            >
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
