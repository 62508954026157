import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'

const DetailsBanner = ({ items }) => {
  return (
    <div>
      <Box
        sx={{
          padding: '30px',
          borderRadius: '10px',
          boxShadow: '0px 3px 13px #2A32711A',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {items?.map((item, index) => {
            return (
              <Box sx={{ width: 'fit-content' }}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontFamily: 'poppins_medium',
                    opacity: '0.5',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontFamily: 'poppins_semibold',
                    opacity: '1',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item?.value}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
    </div>
  )
}

export default DetailsBanner
