import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

function Loader(props) {
  const { minHeight = 400, color = 'primary.main' } = props
  return (
    <>
      <Box
        sx={{
          minHeight,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </>
  )
}

export default Loader
