import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { http } from 'services/http'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'

const TicketDetailsCard = ({ details }) => {
  const [status, setStatus] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // const detailsLength = Object.keys(details).length
  const queryClient = useQueryClient()
  console.log(details)

  useEffect(() => {
    details?.status && setStatus(details?.status)
  }, [details])

  const StatusBox = styled(Box)(() => {
    return {
      backgroundColor:
        details?.status === 'PENDING'
          ? '#FFFAEF'
          : details?.status === 'WORK IN PROGRESS'
          ? '#FEF2E6'
          : details?.status === 'ESCALATED'
          ? '#E9F6FA'
          : details?.status === 'ASSIGNED'
          ? '#0a11601a'
          : '#00d9a626',
      //   color: theme.colors.warning.main,
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '8px 8px',
    }
  })

  let desc =
    details?.description?.length > 500
      ? details?.description?.slice(0, 500) + '...'
      : details?.description

  const handleStatusChange = (e) => {
    e?.preventDefault()
    http
      .patch(`/tickets/${details?.id}`, {
        status: status,
        userId: details.userId,
      })
      .then((res) => {
        console.log(res)
        queryClient.invalidateQueries('ticketDetails')
        queryClient.invalidateQueries('timeline')
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, { variant: 'error' })
      })
  }

  const fetchImages = async (id) => {
    const res = await http.get(`/tickets/images/${id}?subType=create`)
    return res?.data
  }

  const { data: images, isLoading } = useQuery(
    ['ticketImages', details?.id],
    () => fetchImages(details?.id),
    {
      enabled: !!details?.id,
    },
  )

  const fetchCompletedImages = async (id) => {
    const res = await http.get(`/tickets/images/${id}?subType=complete`)
    return res?.data
  }

  const { data: completedImages, isLoading: completedLoading } = useQuery(
    ['ticketCompletedImages', details?.id],
    () => fetchCompletedImages(details?.id),
    {
      enabled: !!details?.id && details?.status === 'COMPLETED',
    },
  )

  console.log(images)
  console.log(completedImages)

  return (
    <Box
      style={{ padding: '17px' }}
      overflow="auto"
      maxHeight="700px"
      className="scrollBox"
    >
      {' '}
      <Box
        // key={index}
        p={2}
        mb={1.5}
        border={'1px solid rgba(34, 34, 34, 0.08)'}
        borderRadius="5px"
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs={6}>
            <Box>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Ticket ID
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {details?.ticketName ?? 'NA'}
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Date
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {moment(details?.createdAt).format('DD-MM-YYYY')}
              </Typography>
            </Box>
            <Box pt={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Category
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {details?.category?.name ?? 'NA'}
              </Typography>
            </Box>
            <Box pt={2.5} pb={2.5}>
              <Typography
                sx={{
                  fontFamily: 'poppins_medium',
                  fontSize: '14px',
                  opacity: '0.5',
                }}
              >
                Description
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  fontSize: '14px',
                }}
              >
                {desc}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Box>
              <StatusBox>
                <Typography
                  sx={{
                    color:
                      details?.status === 'PENDING'
                        ? '#FFCF64'
                        : details?.status === 'WORK IN PROGRESS'
                        ? '#F27F0C'
                        : details?.status === 'ESCALATED'
                        ? `#25A4CF`
                        : details?.status === 'ASSIGNED'
                        ? `#0A1160`
                        : '#00D9A6',
                  }}
                >
                  {details?.status}
                </Typography>
              </StatusBox>
              <Box
                pt={2.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '14px',
                    opacity: '0.5',
                  }}
                >
                  School Name
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    fontSize: '14px',
                  }}
                >
                  {details?.school?.name}
                </Typography>
              </Box>
              <Box
                pt={2.5}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '14px',
                    opacity: '0.5',
                  }}
                >
                  Priority
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    fontSize: '14px',
                  }}
                >
                  {details?.priority}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box pb={2.5}>
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              fontSize: '14px',
              opacity: '0.5',
            }}
          >
            Ticket Images
          </Typography>
          {images?.length == 0 ? (
            <SchoolAnalyticsEmptyScreen height={'190px'} />
          ) : (
            images?.map((item, index) => {
              return (
                <Box
                  p={2}
                  mt={1.25}
                  sx={{
                    border: '1px solid rgba(34, 34, 34, 0.08)',
                    borderRadius: '5px',
                  }}
                >
                  <img
                    style={{ width: '100%', borderRadius: '5px' }}
                    src={item}
                  />
                </Box>
              )
            })
          )}
        </Box>
        {details?.status === 'COMPLETED' && (
          <Box pb={2.5}>
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                fontSize: '14px',
                opacity: '0.5',
              }}
            >
              Ticket Closing Images
            </Typography>
            {completedImages?.length == 0 ? (
              <SchoolAnalyticsEmptyScreen height={'190px'} />
            ) : (
              completedImages?.map((item, index) => {
                return (
                  <Box
                    p={2}
                    mt={1.25}
                    sx={{
                      border: '1px solid rgba(34, 34, 34, 0.08)',
                      borderRadius: '5px',
                    }}
                  >
                    <img
                      style={{ width: '100%', borderRadius: '5px' }}
                      src={item}
                    />
                  </Box>
                )
              })
            )}
          </Box>
        )}
        <Box pb={2.5}>
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              fontSize: '14px',
              opacity: '0.5',
            }}
          >
            Actions
          </Typography>
          <Box pt={1}>
            <form
              onSubmit={(e) => {
                handleStatusChange(e)
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="districtId">Select Status</InputLabel>
                <Select
                  label={'Select Status'}
                  value={status}
                  onChange={(e) => setStatus(e?.target?.value)}
                >
                  <MenuItem value={'PENDING'}>{'Pending'}</MenuItem>
                  <MenuItem value={'WORK IN PROGRESS'}>
                    {'Work In Progress'}
                  </MenuItem>
                  <MenuItem value={'ESCALATED'}>{'Escalated'}</MenuItem>
                  <MenuItem value={'COMPLETED'}>{'Completed'}</MenuItem>
                </Select>
              </FormControl>
              <center>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: 'black',
                    '&: hover': { backgroundColor: 'black' },
                    marginTop: '10px',
                  }}
                >
                  Done
                </Button>
              </center>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TicketDetailsCard
