import { styled } from '@material-ui/styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../App.css'
import { menu } from './menu'

function LayoutDrawer() {
  const [selectedItem, setSelectedItem] = useState('')

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          width: 160,
          background: '#0A1160',
        },
      }}
      sx={{ zIndex: 0, width: 160 }}
    >
      <Toolbar />
      <List>
        {menu.map((item, index) => (
          <Box sx={{ marginTop: '19px' }}>
            <MenuItem
              item={item}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          </Box>
        ))}
      </List>
    </Drawer>
  )
}

export default LayoutDrawer

const MenuItem = ({ item, selectedItem, setSelectedItem }) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  if (item?.children?.length) {
    return (
      <>
        <ListItem
          button
          onClick={() => {
            if (selectedItem == item.title) {
              setSelectedItem('')
            } else {
              setSelectedItem(item.title)
            }
          }}
        >
          <img src={item.icon} />
          <ListItemText primary={item.title} />
          {selectedItem === item.title ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={selectedItem === item.title} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child, index) => (
              <MenuItem item={child} />
            ))}
          </List>
        </Collapse>
      </>
    )
  }
  return (
    <Box>
      <NavLink
        className={'sidebarNavLink'}
        button
        to={item.path}
        style={{
          backgroundColor: isHovering ? '#232C88' : '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textDecoration: 'none',
          padding: '12.5px 34px 6px 34px',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <center>
            {typeof item.icon == 'string' ? (
              <img
                src={item.icon}
                style={{
                  width: '29px',
                  height: '29px',
                }}
              />
            ) : (
              <IconButton style={{ width: '29px', height: '29px' }}>
                {item.icon}
              </IconButton>
            )}

            <ListItemText
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <center>
                <Typography
                  sx={{
                    color: 'white',
                    fontFamily: 'poppins_bold',
                    fontSize: '11.75px',
                  }}
                >
                  {' '}
                  {item.title}
                </Typography>
              </center>
            </ListItemText>
          </center>
        </Box>
      </NavLink>
    </Box>
  )
}

const Image = styled('img')(({ theme }) => ({}))
