import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import PaperCard from 'components/Mui/PaperCard/PaperCard'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { http } from 'services/http'
import VisibilityIcon from '@mui/icons-material/Visibility'
import moment from 'moment'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import Table from 'components/Mui/TableComponent'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ViewButton } from 'components/IconButtons'

const StaffAttendanceDashboard = () => {
  const userType = localStorage.getItem('user_type')
  const [districtId, setDistrictId] = useState('')
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [count, setCount] = useState()

  const [schools, setSchools] = useState()
  const [atdoPointId, setAtdoPointId] = useState('')
  const [schoolId, setSchoolId] = useState('')
  const [stats, setStats] = useState([])
  const [date, setDate] = useState(new Date())
  let fromDate = moment(new Date()).format('YYYY-MM-DD')
  let toDate = moment(new Date()).format('YYYY-MM-DD')
  const [tableData, setTableData] = useState()
  const type = 'Staff'
  const id = localStorage.getItem('schoolId')
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState()
  const [loading, setLoading] = useState()
  const [page, setPage] = useState(1)

  const [sampleBuffer, setSampleBuffer] = useState()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    fromDate = moment(new Date(date)).format('YYYY-MM-DD')
    toDate = moment(new Date(date)).format('YYYY-MM-DD')
    setLoading(true)
    http
      .get(
        `/statistics/staff?fromDate=${fromDate}&toDate=${toDate}&districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      .then((res) => {
        console.log(res)
        setTableData(res?.data?.data?.slice(0, 10))

        setCount(res?.data?.count)
        setStats(res?.data?.staff)
        setLoading(false)
      })
  }, [date])

  console.log(stats)

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      console.log(res)
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      console.log(res)
      setSchools(
        res?.data?.schools.filter((item) => {
          if (districtId && !atdoPointId) {
            return item?.districtId == districtId
          }

          if (districtId && atdoPointId) {
            return (
              item?.districtId == districtId && item?.atdoPointId == atdoPointId
            )
          }
        }),
      )
    })
  }, [districtId, atdoPointId])

  useEffect(() => {
    localStorage.setItem('staffAndTeacherDate', date)
  }, [date])

  const handleSubmit = (e) => {
    e?.preventDefault()
    const fromDate = moment(new Date()).startOf('month').format('YYYY-MM-DD')
    toDate = moment(new Date()).endOf('month').format('YYYY-MM-DD')
    setLoading(true)
    http
      .get(
        `/statistics/staff?districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}&fromDate=${fromDate}&toDate=${toDate}`,
      )
      .then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        setTableData(res?.data?.data?.slice(0, 10))
        setStats(res?.data?.staff)
        setLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }

  const iconSX = {
    '&:hover': {
      cursor: 'pointer',
      color: '#0A1160',
    },
    color: '#878787',
  }

  const columns = [
    {
      title: 'School Name',
      dataIndex: 'school.name',
      key: 'school.name',
    },
    {
      title: 'Name',
      dataIndex: 'staff.name',
      key: 'staff.name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'staff.mobileNumber',
      key: 'staff.mobileNumber',
      render: (_, rowData) => {
        return rowData?.staff?.mobileNumber
          ? rowData?.staff?.mobileNumber
          : 'NA'
      },
    },
    {
      title: 'Gender',
      dataIndex: 'staff.gender',
      key: 'staff.gender',
    },
    {
      title: 'Designation',
      dataIndex: 'staff.designation.name',
      key: 'staff.designation.name',
      render: (_, rowData) =>
        rowData?.staff?.designation?.name
          ? rowData?.staff?.designation?.name
          : 'NA',
    },
    {
      title: 'Attendance Status',
      dataIndex: 'status',
      key: 'status',
      render: (rowData) =>
        rowData ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Present
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Absent
          </Box>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => (
        <Box display="flex" gap="10px">
          <ViewButton
            onClick={() =>
              navigate(`/staff-attendance/details?id=${row?.staff?.id}`)
            }
          />
        </Box>
      ),
    },
  ]

  async function getStaff() {
    fromDate = moment(new Date(date)).format('YYYY-MM-DD')
    toDate = moment(new Date(date)).format('YYYY-MM-DD')
    setSearchLoading(true)
    try {
      http
        .get(
          `/statistics/staff?fromDate=${fromDate}&toDate=${toDate}&search=${search}&districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
        )
        .then((res) => {
          console.log(res)
          setPage(1)
          setCount(res?.data?.count)
          setTableData(res?.data?.data?.slice(0, 10))
          setSearchLoading(false)
        })
    } catch (err) {
      console.log(err)
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      fromDate = moment(new Date(date)).format('YYYY-MM-DD')
      toDate = moment(new Date(date)).format('YYYY-MM-DD')
      console.log('hi')
      const res = await http.get(
        `/statistics/staff?fromDate=${fromDate}&toDate=${toDate}&limit=10&offset=${
          v > 0 ? (v - 1) * 10 : v * 10
        }&districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}&name=${search}`,
      )
      console.log(res)
      setTableData(res?.data?.data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getStaff()
  }, [search])
  const handleSearch = (e) => {
    e?.preventDefault()
    getStaff()
  }

  if (loading) {
    return <CircularProgress />
  }

  // const handleExport = () => {
  //   if (sampleBuffer === undefined) {
  //     enqueueSnackbar('Sample Excel Sheet Not Generated', {
  //       variant: 'error',
  //     })
  //     return
  //   }
  //   const arr = new Uint8Array(sampleBuffer)
  //   const blob = new Blob([arr], {
  //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   })
  //   const file = window.URL.createObjectURL(blob)
  //   let link = document.createElement('a')
  //   link.href = file
  //   link.download = 'Source_Document_Sample_Sheet.xlsx'
  //   link.click()
  // }

  console.log(tableData)

  return (
    <div>
      <Box sx={{ marginTop: '1rem' }}>
        {(userType == 'Admin' || userType == 'DD') && (
          <form onSubmit={handleSubmit}>
            <Stack
              gap={3}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="districtId">Select District</InputLabel>
                <Select
                  labelId="districtId"
                  id="districtId"
                  label="Select District"
                  value={districtId}
                  onChange={(e) => {
                    setAtdoPointId('')
                    setDistrictId(e?.target?.value)
                  }}
                >
                  {districts?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
                <Select
                  labelId="atdoPointId"
                  id="atdoPointId"
                  label="Select ATDO Point"
                  value={atdoPointId}
                  onChange={(e) => setAtdoPointId(e?.target?.value)}
                >
                  {atdoPoints?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="schoolId">Select School</InputLabel>
                <Select
                  labelId="schoolId"
                  id="schoolId"
                  label="Select School"
                  value={schoolId}
                  onChange={(e) => setSchoolId(e?.target?.value)}
                >
                  {schools?.map((item) => {
                    return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: ' white !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                  border: '1px solid black',
                }}
                onClick={() => window.location.reload(true)}
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  Clear
                </Typography>
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                }}
                type="submit"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'white',
                    fontSize: '14px',
                  }}
                >
                  Apply
                </Typography>
              </Button>
            </Stack>
          </form>
        )}
      </Box>
      <Box sx={{ marginTop: '31px' }}>
        <DividerHeading title="Staff Metrics" />
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            spacing={1}
            sx={{
              marginTop: '-29px',
            }}
          >
            {stats?.length !== 0 || !stats ? (
              stats?.map((item) => {
                return (
                  <Grid item xl={2.2} lg={3} md={3}>
                    <PaperCard
                      // width="250px"

                      title={item?.name}
                      count={item?.count ?? 'NA'}
                      img={item?.image}
                    />
                  </Grid>
                )
              })
            ) : (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '30px',
                }}
              >
                <SchoolAnalyticsEmptyScreen />
              </Box>
            )}
          </Grid>
        )}
      </Box>
      <Box sx={{ marginTop: '40px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '18px',
          }}
        >
          <form className="mb-4" onSubmit={handleSearch}>
            <TextField
              label="Search by Name"
              sx={{ width: '494px' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <Box
            gap={'18px'}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ width: '190px', height: '50px' }}>
                <DatePicker
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue)
                  }}
                  inputFormat="DD MMM, YYYY"
                  maxDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                />{' '}
              </Box>
            </LocalizationProvider>{' '}
          </Box>
        </Box>
        {tableData?.length == 0 ? (
          <SchoolAnalyticsEmptyScreen />
        ) : (
          <>
            <Table
              // key={'staffAttendance'}
              dataSource={tableData}
              rowKey={'id'}
              columns={columns}
              loading={loading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />{' '}
            </Box>
          </>
        )}
      </Box>
    </div>
  )
}

export default StaffAttendanceDashboard
