import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Grid, Typography } from '@mui/material'
import { useQuery } from 'react-query'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import DividerHeading from 'components/DividerHeading/DividerHeading'
import Loader from 'components/Loader'
import PageHeader from 'components/Mui/PageHeader'
import { http } from 'services/http'

import { StyledTitle } from './styledComponents'
import VerticalBarChart from './VerticalBarChart'
import { useNavigate } from 'react-router-dom'

const GCCDashboard = () => {
  const navigate = useNavigate()
  const fetchData = async () => {
    const res = await http.get(`/stock-indents/dashboard`)
    return res?.data
  }

  const { data: statistics, isLoading } = useQuery(
    ['gccDashboardStatistics'],
    () => fetchData(),
  )

  const barData = [
    {
      status: 'Pending Indents',
      count: statistics?.totalNumberOfPendingIndents,
    },
    {
      status: 'Approved Indents',
      count: statistics?.totalNumberOfApprovedIndents,
    },
    {
      status: 'Delivered Indents',
      count: statistics?.totalNumberOfDeliveredIndents,
    },
    {
      status: 'Payment pending Indents',
      count: statistics?.totalNumberOfPaymentPendingIndents,
    },
    {
      status: 'Payment Completed Indents',
      count: statistics?.totalNumberOfPaymentCompletedIndents,
    },
  ]

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'GCC Stock', to: '/gcc' },
              {
                name: 'Dashboard',
                to: null,
              },
            ]}
          />
        }
      />
      <DividerHeading title={'Stock Analytics'} />
      <Grid container gap={3} ml={1} mt={2.8}>
        <Cards
          img={'/images/stockIndents.png'}
          title={'Number of Stock Indents'}
          count={statistics?.totalNumberOfStockIndents}
        />
        <Cards
          img={'/images/totalStockIndents.png'}
          title={'Total Stock Indents Amount'}
          count={
            statistics?.totalIndentsPrice
              ? '₹ ' + Math.round(statistics?.totalIndentsPrice * 1000) / 1000
              : 0
          }
        />{' '}
        <Cards
          img={'/images/stockIndentsAmount.png'}
          title={'Paid Stock Indents Amount'}
          count={
            statistics?.totalIndentsAmountPaid
              ? '₹ ' +
                Math.round(statistics?.totalIndentsAmountPaid * 1000) / 1000
              : 0
          }
        />{' '}
        <Cards
          img={'/images/stockIndentsAmount.png'}
          title={'Pending Stock Indents Amount'}
          count={
            statistics?.totalPendingAmount
              ? '₹ ' + Math.round(statistics?.totalPendingAmount * 1000) / 1000
              : 0
          }
        />
      </Grid>
      <DividerHeading title={'Stock Indent Analytics'} />
      <VerticalBarChart data={barData} loading={isLoading} />
      <DividerHeading title={'Stock Usage Analytics'} />
      <Grid item xs={12}>
        <Box
          sx={{
            marginTop: '30px',
            padding: '30px',
            borderRadius: '10px',
            backgroundColor: '#F6F6F6',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'28px'}>
              <img src={'/images/element-equal.png'} />
              <Box>
                <Typography
                  sx={{ fontFamily: 'poppins_semibold', fontSize: '22px' }}
                >
                  Overall Stock Usage Report
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'poppins_medium',
                    fontSize: '16px',
                    opacity: '0.7',
                  }}
                >
                  Tap on view more to see the detailed Stock Usage report of all
                  the schools
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                border: '1px solid #24272C',
                padding: '11px 19px',
                borderRadius: '10px',
              }}
              gap={'55px'}
              onClick={() => {
                navigate('overall-stock-usage')
              }}
            >
              <Typography
                sx={{ fontFamily: 'poppins_semibold', fontSize: '13px' }}
              >
                View more
              </Typography>
              <KeyboardArrowRightIcon />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid container gap={3} ml={1} mt={2.8}>
        <Cards
          img={'/images/stockUsageAnalytics.png'}
          title={'Total Amount used by schools'}
          count={
            statistics?.totalAmountUsedBySchools
              ? '₹ ' +
                Math.round(statistics?.totalAmountUsedBySchools * 1000) / 1000
              : 0
          }
        />
        <Cards
          img={'/images/stockUsageAnalytics.png'}
          title={'Total number of primary school students'}
          count={statistics?.totalNoOfPrimarySchoolStudents}
        />
        <Cards
          img={'/images/stockUsageAnalytics.png'}
          title={'Total number of secondary school students'}
          count={statistics?.totalNoOfSecondarySchoolStudents}
        />
        <Cards
          img={'/images/stockUsageAnalytics.png'}
          title={'Total Amount used for primary school students'}
          count={
            statistics?.totalAmountUsedForPrimarySchoolStudents
              ? '₹ ' +
                Math.round(
                  statistics?.totalAmountUsedForPrimarySchoolStudents * 100,
                ) /
                  100
              : 0
          }
        />
        <Cards
          img={'/images/stockUsageAnalytics.png'}
          title={'Total Amount used for secondary school students'}
          count={
            statistics?.totalAmountUsedForSecondarySchoolStudents
              ? '₹ ' +
                Math.round(
                  statistics?.totalAmountUsedForSecondarySchoolStudents * 100,
                ) /
                  100
              : 0
          }
        />
        <Cards
          img={'/images/stockUsageAnalytics.png'}
          title={'Total Amount used today'}
          count={
            statistics?.totalAmountUsedToday
              ? '₹ ' +
                Math.round(statistics?.totalAmountUsedToday * 1000) / 1000
              : 0
          }
        />
      </Grid>
    </div>
  )
}

export default GCCDashboard

const Cards = ({ img, title, count, loading }) => {
  return (
    <Grid
      item
      xl={3.5}
      lg={3.5}
      sm={4}
      sx={{
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0px 3px 13px #2A32711A;',
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <Box sx={{ marginTop: '10px' }}>
            <img src={img} style={{ width: '65px', height: '65px' }} />
          </Box>
          <StyledTitle mt={'30px'}>{title}</StyledTitle>
          <Typography
            sx={{
              fontFamily: 'poppins_semibold',
              fontSize: '38px',
              marginTop: '7px',
            }}
          >
            {count}
          </Typography>
        </Box>
      )}
    </Grid>
  )
}
