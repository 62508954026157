import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const TestSubjectsForm = ({ data, toggleDrawer }) => {
  const [subjects, setSubjects] = useState([])
  const [subjectIds, setSubjectIds] = useState([])

  useEffect(() => {
    http.get(`/subjects`).then((res) => {
      console.log(res)
      setSubjects(res?.data?.subjects)
    })
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' })

  const queryClient = useQueryClient()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const onSubmit = () => {
    console.log(subjectIds)
    const subjectsArray = data?.subjects?.map((item) => item?.id)
    const array = [...subjectsArray, ...subjectIds]
    const Arr = Array.from(new Set(array))
    const formData = {
      name: data?.id,
      subjectIds: Arr,
    }
    http
      .put(`/class/${data?.id}`, formData)
      .then((res) => {
        enqueueSnackbar('The subject has been created', {
          variant: 'success',
        })
        queryClient.invalidateQueries('testSubjects')
        toggleDrawer(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }
  console.log(data)

  const onTagsChange = (event, values) => {
    console.log(event)
    console.log(values)
    setSubjectIds(values?.map((item) => item?.id))
  }

  console.log(subjectIds)
  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={subjects}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            onChange={onTagsChange}
            renderInput={(params) => <TextField {...params} label="Subjects" />}
          />
          {/* <Controller
            control={control}
            name="subjectId"
            render={({ field: { onChange, value } }) => {
              console.log(value)
              return (
                <Autocomplete
                  multiple
                  id="country-select-demo"
                  sx={{ marginTop: '25px' }}
                  options={subjects}
                  // autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required={true}
                      // fullWidth
                      {...params}
                      label="Subject"
                      inputProps={{
                        ...params.inputProps,
                        // className: params?.classes?.input,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(_, data) => {
                    console.log(data)
                    onChange(data)
                  }}
                  // defaultValue={value}
                />
              )
            }}
          /> */}
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              disabled={subjectIds?.length == 0 ? true : false}
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                Add Subjects
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default TestSubjectsForm
