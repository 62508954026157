import { Box, Pagination, styled } from '@mui/material'

export const StyledTableStats = styled(Box)(({}) => ({}))

export const StyledTableFooter = styled(Box)(({ theme }) => ({
  paddingTop: '1rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    border: 'none',
    background: theme.palette.secondary.dark,
  },
  '& .MuiPaginationItem-previousNext': {
    border: theme.borders.grayLight,
    background: theme.palette.common.white,
  },
  '& .Mui-selected': {
    border: `2px solid ${theme.palette.common.yellow}`,
  },
  '& .MuiPaginationItem-ellipsis': {
    background: theme.palette.common.white,
  },
}))
