import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { ContentCards } from 'components/ContentCards'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { http } from 'services/http'

const DashboardFoodMenuActivities = () => {
  const [date, districtId, atdoPointId, mandalId, schoolId, hitApi, setHitApi] =
    useOutletContext()
  const [loading, setLoading] = useState(false)
  const [activities, setActivities] = useState()

  const type = 'FoodMenu'
  console.log(date)
  useEffect(() => {
    setLoading(true)
    http
      .get(
        `/statistics/activity?date=${date}&type=${type}&districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      .then((res) => {
        console.log(res)
        setActivities(res?.data)
        setLoading(false)
        setHitApi(false)
      })
  }, [date, hitApi])

  console.log(activities)

  const handleNavigation = (title) => {
    localStorage.setItem('heading', title)
  }

  useEffect(() => {
    localStorage.setItem('districtId', districtId)
    localStorage.setItem('atdoPointId', atdoPointId)
  }, [districtId, atdoPointId])

  const MilkCards = [
    {
      count: activities?.milk?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/foodMenuDetails?type=Milk',
    },
    {
      count: activities?.milk?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Milk',
    },
    {
      percentage: `${
        Number.isInteger(activities?.milk?.percentage)
          ? activities?.milk?.percentage
          : activities?.milk?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const BreakfastCards = [
    {
      count: activities?.breakfast?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/foodMenuDetails?type=Breakfast',
    },
    {
      count: activities?.breakfast?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Breakfast',
    },
    {
      percentage: `${
        Number.isInteger(activities?.breakfast?.percentage)
          ? activities?.breakfast?.percentage
          : activities?.breakfast?.percentage.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const LunchCards = [
    {
      count: activities?.lunch?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/foodMenuDetails?type=Lunch',
    },
    {
      count: activities?.lunch?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Lunch',
    },
    {
      percentage: `${
        Number.isInteger(activities?.lunch?.percentage)
          ? activities?.lunch?.percentage
          : activities?.lunch?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const SnacksCards = [
    {
      count: activities?.snacks?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/foodMenuDetails?type=Snacks',
    },
    {
      count: activities?.snacks?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Snacks',
    },
    {
      percentage: `${
        Number.isInteger(activities?.snacks?.percentage)
          ? activities?.snacks?.percentage
          : activities?.snacks?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const DinnerCards = [
    {
      count: activities?.dinner?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/foodMenuDetails?type=Dinner',
    },
    {
      count: activities?.dinner?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Dinner',
    },
    {
      percentage: `${
        Number.isInteger(activities?.dinner?.percentage)
          ? activities?.dinner?.percentage
          : activities?.dinner?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  const SpecialCards = [
    {
      count: activities?.special?.completed,
      text: 'Number of schools completed',
      link: '/activity-dashboard/foodMenuDetails?type=Special',
    },
    {
      count: activities?.special?.pending,
      text: 'Number of schools pending',
      link: '/activity-dashboard/unmarked-schools?type=Special',
    },
    {
      percentage: `${
        Number.isInteger(activities?.special?.percentage)
          ? activities?.special?.percentage
          : activities?.special?.percentage?.toFixed(1)
      }%`,
      img: '/images/Group 21049.png',
      text: 'Activity Conducted Percentage',
    },
  ]

  if (loading) {
    return <CircularProgress />
  }

  return (
    <div>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Breakfast Drink{' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {MilkCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Breakfast Drink')}
            >
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Breakfast{' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {BreakfastCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Breakfast')}
            >
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Lunch
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {LunchCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Lunch')}
            >
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Snacks{' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {SnacksCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Snacks')}
            >
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Dinner{' '}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {DinnerCards?.map((item, ind) => {
          return (
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              onClick={() => handleNavigation('Dinner')}
            >
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid>
      {/* <Box sx={{ marginTop: '31px' }}>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          Special Menu
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ marginTop: '-19px' }}>
        {SpecialCards?.map((item, ind) => {
          return (
            <Grid item xl={4} lg={4} md={4}>
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
                link={item?.link}
              />
            </Grid>
          )
        })}
      </Grid> */}
    </div>
  )
}

export default DashboardFoodMenuActivities
