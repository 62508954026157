import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Breadcrumbs from 'components/Breadcrumbs'
import { ContentCards } from 'components/ContentCards'
import DividerHeading from 'components/DividerHeading'
import DetailsBanner from 'components/Mui/DetailsBanner'
import SingleSelect from 'components/Mui/Form/SingleSelect'
import PageHeader from 'components/Mui/PageHeader'
import Table from 'components/Mui/TableComponent'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import dayjs from 'dayjs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { http } from 'services/http'

const CRTTeacherAttDetails = () => {
  const [attendanceDetails, setAttendanceDetails] = useState()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState()
  const DATE = localStorage.getItem('staffAndTeacherDate')
  const [month, setMonth] = useState(new Date(DATE ? DATE : ''))
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()
  let fromDate = moment(new Date(DATE ? DATE : ''))
    .startOf('month')
    .format('YYYY-MM-DD')
  let toDate = moment(new Date(DATE ? DATE : ''))
    .endOf('month')
    .format('YYYY-MM-DD')
  const [tableData, setTableData] = useState([])
  const type = 'CRT'
  const id = searchParams.get('id')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [sampleBuffer, setSampleBuffer] = useState()

  useEffect(() => {
    console.log(moment(new Date(month)).startOf('month').format('YYYY-MM-DD'))
    fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
    toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
    setLoading(true)
    http
      .get(
        `/statistics/teacher/${id}?type=${type}&fromDate=${fromDate}&toDate=${toDate}`,
      )
      .then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        setAttendanceDetails(res?.data)
        setTableData(res?.data?.data?.slice(0, 10))
        setLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
        setLoading(false)
      })
    // http
    //   .get(
    //     `/statistics/school/${id}/report?type=${type}&fromDate=${fromDate}&toDate=${toDate}`,
    //   )
    //   .then((res) => {
    //     console.log(res?.data?.data)
    //     setSampleBuffer(res?.data?.data)
    //   })
  }, [month])

  const cards = [
    { count: attendanceDetails?.workingDays ?? 'NA', text: 'Working days' },
    { count: attendanceDetails?.present ?? 'NA', text: 'Present days' },
    { count: attendanceDetails?.absent ?? 'NA', text: 'Absent days' },
    {
      percentage: attendanceDetails?.percentage
        ? `${
            Number.isInteger(attendanceDetails?.percentage)
              ? attendanceDetails?.percentage
              : attendanceDetails?.percentage?.toFixed(1)
          }%`
        : 'NA',
      img: '/images/Group 21049.png',
      text: 'Attendance Percentage',
    },
  ]

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Attendance Status',
      dataIndex: 'status',
      key: 'status',
      render: (rowData) =>
        rowData ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Present
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Absent
          </Box>
        ),
    },
    {
      title: 'Absent Type',
      dataIndex: 'absentType',
      key: 'absentType',
      render: (row) => {
        return row ? row : 'NA'
      },
    },
    {
      title: 'Updated on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
      render: (row) => {
        return row ? row : 'NA'
      },
    },
  ]

  async function handlePagination(e, v) {
    setPage(v)
    try {
      fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
      toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
      setLoading(true)
      http
        .get(
          `/statistics/teacher/${id}?type=${type}&fromDate=${fromDate}&toDate=${toDate}&limit=10&offset=${
            v > 0 ? (v - 1) * 10 : v * 10
          }`,
        )
        .then((res) => {
          console.log(res)
          setAttendanceDetails(res?.data)
          setTableData(res?.data?.data)
          setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  if (loading) {
    return <CircularProgress />
  }

  const handleExport = () => {
    if (sampleBuffer === undefined) {
      enqueueSnackbar('Sample Excel Sheet Not Generated', {
        variant: 'error',
      })
      return
    }
    const arr = new Uint8Array(sampleBuffer)
    const blob = new Blob([arr], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const file = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.href = file
    link.download = 'Source_Document_Sample_Sheet.xlsx'
    link.click()
  }
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              {
                name: 'CRT Teachers',
                to: '/crt-teachers',
              },
              { name: 'CRT Teachers Attendance', to: null },
            ]}
          />
        }
      />
      <Box sx={{ marginTop: '20px' }}>
        <DetailsBanner
          items={[
            { name: 'Teacher Name', value: attendanceDetails?.teacher?.name },
            {
              name: 'Mobile Number',
              value: attendanceDetails?.teacher?.mobileNumber,
            },
            { name: 'Gender', value: attendanceDetails?.teacher?.gender },
            {
              name: 'Subject',
              value: attendanceDetails?.teacher?.subject?.name,
            },
            {
              name: 'Designation',
              value: attendanceDetails?.teacher?.designation,
            },
            {
              name: 'Attendance Status',
              value: attendanceDetails?.status ? 'Present' : 'Absent',
            },
          ]}
        />
      </Box>
      <Stack
        gap={'18px'}
        direction="row"
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          marginTop: '28px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: '190px', height: '50px' }}>
            <DatePicker
              views={['year', 'month']}
              label="Select Month"
              value={month}
              minDate={dayjs('2022-01-01')}
              onChange={(newValue) => {
                setMonth(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </Box>
        </LocalizationProvider>

        {/* <Button
            variant="contained"
            sx={{
              backgroundColor: 'black !important',
              height: '50px',
              width: '190px',
              borderRadius: '10px',
            }}
            onClick={() => handleExport()}
          >
            <Typography
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'white',
                fontSize: '13px',
              }}
            >
              Export Data
            </Typography>
          </Button> */}
      </Stack>
      <Box sx={{ marginTop: '-40px' }}>
        <DividerHeading title={'CRT Teachers Attendance'} />
      </Box>
      <Grid
        container
        spacing={1}
        sx={{
          marginBottom: '29px',
          marginTop: '-29px',
        }}
      >
        {cards?.map((item, ind) => {
          return (
            <Grid item xl={3} lg={4} md={4}>
              <ContentCards
                count={item?.count}
                text={item?.text}
                img={item?.img}
                percentage={item?.percentage}
              />
            </Grid>
          )
        })}
      </Grid>
      {attendanceDetails?.count == 0 ? (
        <SchoolAnalyticsEmptyScreen />
      ) : (
        <>
          <Table
            key={'crtTeacherAttendanceDetails'}
            dataSource={tableData}
            rowKey={'id'}
            columns={columns}
            loading={loading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
    </div>
  )
}

export default CRTTeacherAttDetails
