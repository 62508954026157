import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DividerHeading from 'components/DividerHeading'
import dayjs from 'dayjs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { http } from 'services/http'
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  LabelList,
} from 'recharts'
import PieChart from '../AksharaJyothiDashboard/PieChart'
import Breadcrumbs from 'components/Breadcrumbs'
import PageHeader from 'components/Mui/PageHeader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { useQuery } from 'react-query'

const AksharaJyothiSchoolDashboard = () => {
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const userType = localStorage.getItem('user_type')
  const [month, setMonth] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [schools, setSchools] = useState()
  const [testAnalytics, setTestAnalytics] = useState()
  const [testAnalyticsData, setTestAnalyticsData] = useState()
  const [subjectAnalytics, setSubjectAnalytics] = useState()
  console.log(userType)
  let fromDate = moment(new Date()).startOf('month').format('YYYY-MM-DD')
  let toDate = moment(new Date()).endOf('month').format('YYYY-MM-DD')
  const [districtId, setDistrictId] = useState('')
  const [atdoPointId, setAtdoPointId] = useState('')
  const [classroomId1, setClassroomId1] = useState('')
  const [classroomId2, setClassroomId2] = useState('')
  const schoolId = localStorage.getItem('schoolId')

  const [classrooms, setClassrooms] = useState()
  const [subjectId, setSubjectId] = useState('')
  const [subjects, setSubjects] = useState()
  const [testId, setTestId] = useState('')
  const [testDate, setTestDate] = useState()
  const [tests, setTests] = useState()
  useEffect(() => {
    console.log(moment(new Date(month)).startOf('month').format('YYYY-MM-DD'))
    fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
    toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
    setLoading(true)
    http
      .get(
        `/akshara-jyothi/dashboard?districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}&fromDate=${fromDate}&toDate=${toDate}&classroomId=${classroomId1}&subjectId=${subjectId}`,
      )
      .then((res) => {
        console.log(res)
        setTestAnalytics(res?.data?.analytics)
        // setSubjectAnalytics(res?.data?.subjectAnalytics)
        setLoading(false)
      })
  }, [month])

  let i = 0

  const { data, isLoading } = useQuery(
    ['dashboardData'],
    () =>
      http
        .get(
          `/akshara-jyothi/dashboard?districtId=${districtId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}&fromDate=${fromDate}&toDate=${toDate}&subjectId=${subjectId}`,
        )
        .then((res) => {
          console.log(res)
          return res?.data
        }),
    {
      onSuccess: (data) => {
        console.log(data)
        setTestAnalytics(data?.analytics)
        const result = data?.subjectAnalytics
        result?.forEach((item) => {
          item?.grades?.forEach((item) => {
            item?.grade == 'A'
              ? ((item['color'] = '#FF3465'), (item['grade'] = 'Grade A'))
              : item?.grade == 'B'
              ? ((item['color'] = '#FFCF64'), (item['grade'] = 'Grade B'))
              : item?.grade == 'C'
              ? ((item['color'] = '#00D9A6'), (item['grade'] = 'Grade C'))
              : ((item['color'] = '#25A4CF'), (item['grade'] = 'Grade D'))
            return item
          })
        })
        setSubjectAnalytics(result)
      },
    },
  )

  console.log(testAnalytics)

  useEffect(() => {
    setTestAnalyticsData(
      testAnalytics?.map((item) => {
        return {
          date: item?.date,
          'Grade A': item?.grades?.filter((item) => item?.grade === 'A')[0]
            ?.noOfStudents,
          'Grade B': item?.grades?.filter((item) => item?.grade === 'B')[0]
            ?.noOfStudents,
          'Grade C': item?.grades?.filter((item) => item?.grade === 'C')[0]
            ?.noOfStudents,
          'Grade D': item?.grades?.filter((item) => item?.grade === 'D')[0]
            ?.noOfStudents,
        }
      }),
    )
  }, [testAnalytics])

  console.log(subjectAnalytics)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    http.get('/classrooms/group').then((res) => {
      console.log(res)
      setClassrooms(res?.data)
    })
  }, [])
  useEffect(() => {
    http.get('/subjects?showInExams=1').then((res) => {
      console.log(res)
      setSubjects(res?.data?.subjects)
    })
  }, [])

  useEffect(() => {
    http.get('/akshara-jyothi/tests').then((res) => {
      console.log(res)
      setTests(res?.data)
    })
  }, [])

  const handleApply = (e) => {
    e?.preventDefault()
    http
      .get(
        `/akshara-jyothi/dashboard?districtId=${districtId}&atdoPointId=${atdoPointId}&fromDate=${fromDate}&toDate=${toDate}&classroomId=${classroomId1}&subjectId=${subjectId}`,
      )
      .then((res) => {
        console.log(res)
        setTestAnalytics(res?.data?.analytics)
        // setSubjectAnalytics(res?.data?.subjectAnalytics)
        setLoading(false)
      })
  }

  const handleSubjectApply = (e) => {
    e?.preventDefault()
    http
      .get(
        `/akshara-jyothi/dashboard?districtId=${districtId}&atdoPointId=${atdoPointId}classroomId=${classroomId2}`,
      )
      .then((res) => {
        console.log(res)
        // setTestAnalytics(res?.data?.analytics)
        const result = res?.data?.subjectAnalytics
        result?.forEach((item) => {
          item?.grades?.forEach((item) => {
            item?.grade == 'A'
              ? ((item['color'] = '#FF3465'), (item['grade'] = 'Grade A'))
              : item?.grade == 'B'
              ? ((item['color'] = '#FFCF64'), (item['grade'] = 'Grade B'))
              : item?.grade == 'C'
              ? ((item['color'] = '#00D9A6'), (item['grade'] = 'Grade C'))
              : ((item['color'] = '#25A4CF'), (item['grade'] = 'Grade D'))
            return item
          })
        })
        setSubjectAnalytics(result)
        setLoading(false)
      })
  }

  const barNamesAndColors = [
    {
      label: 'Grade A',
      color: '#FF3465',
    },
    {
      label: 'Grade B',
      color: '#FFCF64',
    },
    {
      label: 'Grade C',
      color: '#00D9A6',
    },
    {
      label: 'Grade D',
      color: '#25A4CF',
    },
  ]

  const renderLegend = (props) => {
    const { payload } = props
    console.log(payload)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '7px',
        }}
      >
        {payload.map(
          (entry, index) =>
            entry.value !== 'line' && (
              <span
                style={{
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                key={`item-${index}`}
              >
                <div
                  style={{
                    display: 'inline-block',
                    width: '12px',
                    marginRight: '4px',
                    height: '12px',
                    borderRadius: '999px',
                    backgroundColor: entry.color,
                  }}
                ></div>
                <Typography sx={{ opacity: '0.6' }}>{entry.value}</Typography>
              </span>
            ),
        )}
      </div>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload)
      return (
        <div
          style={{
            padding: '7px',
            backgroundColor: 'white',
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0px 3px 13px #2A32711A',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ opacity: '1', fontSize: '14px' }}
          >
            {payload[0]?.name}{' '}
          </Typography>
          <Typography variant="h6" sx={{ opacity: '1', fontSize: '16px' }}>
            {payload[0]?.value}
          </Typography>
        </div>
      )
    }
  }

  if (loading) {
    return <CircularProgress />
  }
  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Schools', to: '/schools' },
              {
                name: 'Akshara Jyothi School wise Dashboard',
                to: null,
              },
            ]}
          />
        }
      />{' '}
      <Box sx={{ marginTop: '30px' }}>
        <DividerHeading title={'Test Analytics'} />
      </Box>
      <Box sx={{ border: '1px solid #E3E3E3', borderRadius: '10px' }}>
        <Box
          sx={{
            backgroundColor: '#F4F4F4',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            padding: '13px 18px',
            border: '1px solid #E3E3E3',
          }}
        >
          <form onSubmit={handleApply}>
            <Stack
              gap={3}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="classroomId1">Select Class Room</InputLabel>
                <Select
                  labelId="classroomId1"
                  id="classroomId1"
                  label="Select Class Room"
                  value={classroomId1}
                  onChange={(e) => setClassroomId1(e?.target?.value)}
                >
                  {classrooms?.map((item) => (
                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="atdoPointId">Select Subject</InputLabel>
                <Select
                  labelId="subjectId"
                  id="subjectId"
                  label="Select Subject"
                  value={subjectId}
                  onChange={(e) => setSubjectId(e?.target?.value)}
                >
                  {subjects?.map((item) => (
                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  ))}{' '}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: '190px', height: '50px' }}>
                  <DatePicker
                    views={['year', 'month']}
                    label="Select Month"
                    value={month}
                    minDate={dayjs('2022-01-01')}
                    onChange={(newValue) => {
                      setMonth(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </Box>
              </LocalizationProvider>

              <Button
                variant="contained"
                sx={{
                  borderColor: 'black !important',
                  backgroundColor: 'white !important',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                  border: '1px solid black',
                }}
                type="submit"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  Submit
                </Typography>
              </Button>
            </Stack>
          </form>
        </Box>
        <Box>
          {testAnalyticsData?.length != 0 ? (
            <ResponsiveContainer width="100%" height={550}>
              <BarChart
                data={testAnalyticsData}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
                // barCategoryGap={50}
                barGap={27}
              >
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  style={{
                    fontSize: '12px',
                    fontFamily: 'poppins_regular',
                  }}
                  axisLine={{ opacity: '0.2' }}
                />
                <YAxis
                  // tickCount={'11'}
                  tickLine={false}
                  style={{
                    fontSize: '12px',
                    fontFamily: 'poppins_regular',
                    // opacity: "0.7",
                  }}
                  axisLine={{ opacity: '0.2' }}
                />
                <Tooltip
                  shared={false}
                  content={<CustomTooltip />}
                  contentStyle={{
                    fontSize: '12px',
                    fontFamily: 'poppins_regular',
                  }}
                />{' '}
                <Legend content={renderLegend} iconType={'circle'} />
                {barNamesAndColors?.map((item) => (
                  <Bar
                    name={item?.label}
                    dataKey={item.label}
                    fill={item.color}
                    barSize={15}
                    // background={{ fill: "#eee" }}
                    radius={[10, 10, 0, 0]}
                    nameKey="date"
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Box sx={{ marginTop: '10px' }}>
              <SchoolAnalyticsEmptyScreen />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        <DividerHeading title={'Subject Wise Information'} />
      </Box>
      <Box sx={{ border: '1px solid #E3E3E3', borderRadius: '10px' }}>
        <Box
          sx={{
            backgroundColor: '#F4F4F4',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            padding: '13px 18px',
            border: '1px solid #E3E3E3',
          }}
        >
          <form onSubmit={handleSubjectApply}>
            <Stack
              gap={3}
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="classroomId2">Select Class Room</InputLabel>
                <Select
                  labelId="classroomId2"
                  id="classroomId2"
                  label="Select Class Room"
                  value={classroomId2}
                  onChange={(e) => setClassroomId2(e?.target?.value)}
                >
                  {classrooms?.map((item) => (
                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  ))}{' '}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '170px' }}>
                <InputLabel id="testId">Select Test Date</InputLabel>
                <Select
                  labelId="testId"
                  id="testId"
                  label="Select Test Date"
                  value={testId}
                  onChange={(e) => setTestId(e?.target?.value)}
                >
                  {tests?.map((item) => (
                    <MenuItem value={item?.id}>{item?.date}</MenuItem>
                  ))}{' '}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                sx={{
                  borderColor: 'black !important',
                  backgroundColor: 'white !important',
                  border: '1px solid black',
                  height: '50px',
                  width: '130px',
                  borderRadius: '10px',
                }}
                type="submit"
              >
                <Typography
                  sx={{
                    fontFamily: 'poppins_semibold',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  Submit
                </Typography>
              </Button>
            </Stack>
          </form>
        </Box>
        {subjectAnalytics?.length !== 0 ? (
          <Stack
            sx={{ padding: '0px 10px', overflow: 'hidden' }}
            divider={<Divider orientation="vertical" flexItem />}
            direction={'row'}
          >
            <Grid container>
              {subjectAnalytics?.map((item) => {
                i = i + 1
                return (
                  <>
                    <Grid item xs={3.9} sx={{ marginTop: '-20px' }}>
                      <PieChart data={item?.grades} subject={item?.subject} />
                    </Grid>
                    {i % 3 !== 0 && (
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                          height: '220px',
                          margin: 'auto 0px',
                          // marginLeft: '-63px',
                          // marginRight: '63px',
                        }}
                      />
                    )}
                  </>
                )
              })}
            </Grid>
          </Stack>
        ) : (
          <Box sx={{ marginTop: '10px' }}>
            <SchoolAnalyticsEmptyScreen />
          </Box>
        )}
      </Box>
    </div>
  )
}

export default AksharaJyothiSchoolDashboard
