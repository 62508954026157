import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'
import SearchIcon from '@mui/icons-material/Search'

const SchoolStaffForm = ({ data, toggleDrawer }) => {
  const [schools, setSchools] = useState([])
  const [designations, setDesignations] = useState([])
  const queryClient = useQueryClient()
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  const [search, setSearch] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      console.log(res)
      setSchools(res?.data?.schools)
    })
  }, [])

  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  useEffect(() => {
    http.get(`/staff/designations`).then((res) => {
      console.log(res)
      setDesignations(res?.data)
    })
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      schoolId: data?.school,
      name: data?.name,
      mobileNumber: data?.mobileNumber,
      gender: data?.gender,
      designation: data?.designation?.name,
    },
  })

  const onSubmit = (data) => {
    if (edit) {
      console.log(id)
      const formData = { ...data, schoolId: data?.schoolId?.id }
      console.log(data)
      http
        .put(`/staff/${id}`, formData)
        .then((res) => {
          enqueueSnackbar('Staff member details have been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('Employees')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      console.log(data)
      const formData = { ...data, schoolId: data?.schoolId?.id }
      console.log(formData)

      http
        .post(`/staff`, formData)
        .then((res) => {
          enqueueSnackbar('Staff member has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('Employees')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
  }

  const filteredData = schools?.filter((item) =>
    item?.name?.toLowerCase()?.includes(search?.toLowerCase()),
  )

  console.log(data)
  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="schoolId"
            render={({ field: { onChange, value } }) => {
              console.log(value)
              return (
                <Autocomplete
                  id="country-select-demo"
                  // sx={{ width: 300 }}
                  options={schools}
                  // autoHighlight
                  defaultValue={value}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required={true}
                      // fullWidth
                      {...params}
                      label="School"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(_, data) => {
                    onChange(data)
                  }}
                  // defaultValue={value}
                />
              )
            }}
          />
          <TextField
            type={'text'}
            {...register('name', {
              required: true,
              pattern: /^[A-Za-z.\- ()]+$/,
            })}
            label={'Staff Member Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.name?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.name?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}
          <TextField
            type={'text'}
            {...register('mobileNumber', {
              required: true,
              pattern: /^\d{10}$/,
            })}
            label={'Mobile Number'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.mobileNumber?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.mobileNumber?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Mobile Number
            </Typography>
          )}
          <FormControl component="fieldset" sx={{ marginTop: '24px' }}>
            <FormLabel component="legend">Gender *</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="gender"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {errors?.gender && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="designation">Designation *</InputLabel>
            <Select
              labelId="designation"
              id="designation"
              label="Designation *"
              {...register('designation', { required: true })}
              required={true}
              defaultValue={data?.designation?.name}
            >
              {designations?.map((item) => {
                return <MenuItem value={item?.name}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          {errors?.designation && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {data ? 'Edit School Staff' : 'Add School Staff'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default SchoolStaffForm
