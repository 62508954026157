import { Typography } from '@mui/material'

export default function FormLabel({ label, required, textColor }) {
  return (
    <>
      <Typography
        sx={{
          fontFamily: 'poppins_semibold',
          fontSize: '14px',
          color: textColor,
        }}
      >
        {label}
      </Typography>
      {required && (
        <Typography fontSize={12} component={'span'} color="error">
          {' *'}
        </Typography>
      )}
    </>
  )
}
