import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import DividerHeading from 'components/DividerHeading'
import SingleSelect from 'components/Mui/Form/SingleSelect'
import PaperCard from 'components/Mui/PaperCard/PaperCard'
import SmallCard from 'components/Mui/SmallCard/SmallCard'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { http } from 'services/http'

const Dashboard = () => {
  const [stats, setStats] = useState()
  const [districts, setDistricts] = useState()
  const [atdoPoints, setAtdoPoints] = useState()
  const [schools, setSchools] = useState()
  const userType = localStorage.getItem('user_type')
  console.log(userType)
  const [districtId, setDistrictId] = useState('')
  const [atdoPointId, setAtdoPointId] = useState('')
  const [mandalId, setMandalId] = useState('')
  const [schoolId, setSchoolId] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    http.get(`/statistics/master-data/district`).then((res) => {
      console.log(res)
      setDistricts(res?.data)
    })
  }, [])

  useEffect(() => {
    http.get(`/statistics/master-data/atdo`).then((res) => {
      setAtdoPoints(
        res?.data?.filter((item) => {
          return item?.districtId == districtId
        }),
      )
    })
  }, [districtId])

  useEffect(() => {
    http.get(`/schools`).then((res) => {
      console.log(res)
      setSchools(
        res?.data?.schools.filter((item) => {
          if (districtId && !atdoPointId) {
            return item?.districtId == districtId
          }

          if (districtId && atdoPointId) {
            return (
              item?.districtId == districtId && item?.atdoPointId == atdoPointId
            )
          }
        }),
      )
    })
  }, [districtId, atdoPointId])

  let regularCount = 0
  let crtCount = 0
  let headmasterCount = 0

  const { data, isLoading } = useQuery(
    ['dashboardStats'],
    () =>
      http.get(`/statistics/dashboard`).then((res) => {
        console.log(res)
        return res?.data
      }),
    {
      onSuccess: (data) => {
        setStats(data)
      },
    },
  )

  console.log(districts)

  if (stats) {
    for (let i = 0; i < stats?.regularTeacherSubject?.length; i++) {
      regularCount = regularCount + stats?.regularTeacherSubject[i]?.count
    }
    for (let i = 0; i < stats?.crtTeacherSubject?.length; i++) {
      crtCount = crtCount + stats?.crtTeacherSubject[i]?.count
    }
    for (let i = 0; i < stats?.headMaster?.length; i++) {
      headmasterCount = headmasterCount + stats?.headMaster[i]?.count
    }
  }

  const handleSubmit = (e) => {
    e?.preventDefault()
    http
      .get(
        `/statistics/dashboard?districtId=${districtId}&mandalId=${mandalId}&atdoPointId=${atdoPointId}&schoolId=${schoolId}`,
      )
      .then((res) => {
        console.log(res)
        setStats(res?.data)
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.message, {
          variant: 'error',
        })
      })
  }

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {(userType == 'Admin' || userType == 'DD') && (
        <form onSubmit={handleSubmit}>
          <Stack
            gap={3}
            direction="row"
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <FormControl sx={{ width: '170px' }}>
              <InputLabel id="districtId">Select District</InputLabel>
              <Select
                labelId="districtId"
                id="districtId"
                label="Select District"
                value={districtId}
                onChange={(e) => {
                  setAtdoPointId('')
                  setDistrictId(e?.target?.value)
                }}
              >
                {districts?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ width: '170px' }}>
              <InputLabel id="atdoPointId">Select ATDO Point</InputLabel>
              <Select
                labelId="atdoPointId"
                id="atdoPointId"
                label="Select ATDO Point"
                value={atdoPointId}
                onChange={(e) => setAtdoPointId(e?.target?.value)}
              >
                {atdoPoints?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>

            <FormControl sx={{ width: '170px' }}>
              <InputLabel id="schoolId">Select School</InputLabel>
              <Select
                labelId="schoolId"
                id="schoolId"
                label="Select School"
                value={schoolId}
                onChange={(e) => setSchoolId(e?.target?.value)}
              >
                {schools?.map((item) => {
                  return <MenuItem value={item?.id}>{item?.name}</MenuItem>
                })}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              sx={{
                backgroundColor: ' white !important',
                height: '50px',
                width: '130px',
                borderRadius: '10px',
                border: '1px solid black',
              }}
              onClick={() => window.location.reload(true)}
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                Clear
              </Typography>
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: 'black !important',
                height: '50px',
                width: '130px',
                borderRadius: '10px',
              }}
              type="submit"
            >
              <Typography
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                Apply
              </Typography>
            </Button>
          </Stack>
        </form>
      )}
      {/* ))} */}

      <Box sx={{ marginTop: '25px' }}>
        <DividerHeading title="Overall Metrics" />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            spacing={1}
            sx={{
              marginTop: '-29px',
              // width: '100%',
              // maxWidth: '1300px',
              // backgroundColor: 'red',
            }}
            // gap={'29px'}
          >
            <Grid item xl={2.2} lg={3} md={3}>
              <PaperCard
                title="Number of Schools"
                count={stats?.schools ?? 'NA'}
                img="/images/Group 20899.png"
              />
            </Grid>
            <Grid item xl={2.2} lg={3} md={3}>
              <PaperCard
                title="Number of Head Masters"
                count={headmasterCount ?? 'NA'}
                img="/images/Group 20898.png"
              />
            </Grid>
            <Grid item xl={2.2} lg={3} md={3}>
              <PaperCard
                title="Number of Students"
                count={stats?.students ?? 'NA'}
                img="/images/Group 20897.png"
              />
            </Grid>
            <Grid item xl={2.2} lg={3} md={3}>
              <PaperCard
                title="Number of Regular Teachers"
                count={
                  stats?.teachers?.find((item) => item?.type == 'Regular')
                    ?.count ?? 'NA'
                }
                img="/images/Group 20896.png"
              />
            </Grid>
            <Grid item xl={2.2} lg={3} md={3}>
              <PaperCard
                title="Number of CRT Teachers"
                count={
                  stats?.teachers?.find((item) => item?.type == 'CRT')?.count ??
                  'NA'
                }
                img="/images/Group 20895.png"
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box sx={{ marginTop: '31px' }}>
        <DividerHeading title="Gender Metrics" />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid
              container
              spacing={1}
              sx={{
                marginTop: '-29px',
              }}
            >
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="Students - Boys"
                  count={stats?.boyStudents ?? 'NA'}
                  img="/images/Group 21021.png"
                />
              </Grid>
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="Students - Girls"
                  count={stats?.girlStudents ?? 'NA'}
                  img="/images/Group 20989.png"
                />
              </Grid>
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="Headmasters - Male"
                  count={
                    stats?.headMaster?.find((item) => item?.gender == 'Male')
                      ?.count ?? 'NA'
                  }
                  img="/images/Group 21058.png"
                />
              </Grid>
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="Headmasters - Female"
                  count={
                    stats?.headMaster?.find((item) => item?.gender == 'Female')
                      ?.count ?? 'NA'
                  }
                  img="/images/Group 21059.png"
                />
              </Grid>
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="Regular Teachers - Male"
                  count={
                    stats?.teacher?.find(
                      (item) =>
                        item?.type == 'Regular' && item?.gender == 'Male',
                    )?.count ?? 'NA'
                  }
                  img="/images/Group 20995.png"
                />
              </Grid>
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="Regular Teachers - Female"
                  count={
                    stats?.teacher?.find(
                      (item) =>
                        item?.type == 'Regular' && item?.gender == 'Female',
                    )?.count ?? 'NA'
                  }
                  img="/images/Group 20992.png"
                />
              </Grid>
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="CRT Teachers - Male"
                  count={
                    stats?.teacher?.find(
                      (item) => item?.type == 'CRT' && item?.gender == 'Male',
                    )?.count ?? 'NA'
                  }
                  img="/images/Group 20993.png"
                />
              </Grid>
              <Grid item xl={2.2} lg={3} md={3}>
                <PaperCard
                  title="CRT Teachers - Female"
                  count={
                    stats?.teacher?.find(
                      (item) => item?.type == 'CRT' && item?.gender == 'Female',
                    )?.count ?? 'NA'
                  }
                  img="/images/Group 20994.png"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Box sx={{ marginTop: '31px' }}>
        <DividerHeading title="Subjects Metrics - Regular Teachers" />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={1}>
            <Grid item xl={2} lg={3} md={3} sx={{ marginTop: '-29px' }}>
              <PaperCard
                height="363px"
                title="Regular Teachers"
                count={regularCount ?? 'NA'}
                img="/images/Group 20997.png"
              />
            </Grid>
            <Grid
              item
              xl={10}
              lg={9}
              md={9}
              sx={{
                marginTop: '-17px',
              }}
            >
              <Grid container spacing={1}>
                {stats?.regularTeacherSubject?.map((item) => (
                  <Grid item xl={3} lg={4} md={4}>
                    <SmallCard
                      img={
                        item?.name == 'All Subjects'
                          ? '/images/vuesax-outline-3square.png'
                          : '/images/vuesax-outline-book.png'
                      }
                      title={item?.name}
                      count={item?.count ?? 'NA'}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid sx={{ marginTop: '29px' }}>
          <DividerHeading title="Subjects Metrics - CRT Teachers" />
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={1}>
              <Grid item xl={2} lg={3} md={3} sx={{ marginTop: '-29px' }}>
                <PaperCard
                  height="363px"
                  title="CRT Teachers"
                  count={crtCount ?? 'NA'}
                  img="/images/Group 20895.png"
                />
              </Grid>
              <Grid
                item
                xl={10}
                lg={9}
                md={9}
                sx={{
                  marginTop: '-17px',
                }}
              >
                <Grid container spacing={1}>
                  {stats?.crtTeacherSubject?.map((item) => (
                    <Grid item xl={3} lg={4} md={4}>
                      <SmallCard
                        img={
                          item?.name == 'All Subjects'
                            ? '/images/vuesax-outline-3square.png'
                            : '/images/vuesax-outline-book.png'
                        }
                        title={item?.name}
                        count={item?.count ?? 'NA'}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box sx={{ marginTop: '31px' }}>
        <DividerHeading title="Staff Metrics" />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            spacing={1}
            sx={{
              marginTop: '-29px',
            }}
          >
            {stats?.staff?.length !== 0 ? (
              stats?.staff?.map((item) => {
                return (
                  <Grid item xl={2.2} lg={3} md={3}>
                    <PaperCard
                      // width="250px"

                      title={item?.name}
                      count={item?.count ?? 'NA'}
                      img={item?.imageUrl}
                    />
                  </Grid>
                )
              })
            ) : (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '30px',
                }}
              >
                <SchoolAnalyticsEmptyScreen />
              </Box>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default Dashboard
