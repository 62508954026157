import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  DatePicker,
  LocalizationProvider,
  MonthPicker,
} from '@mui/x-date-pickers'
import Breadcrumbs from 'components/Breadcrumbs'
import DividerHeading from 'components/DividerHeading'
import DetailsBanner from 'components/Mui/DetailsBanner'
import SingleSelect from 'components/Mui/Form/SingleSelect'
import PageHeader from 'components/Mui/PageHeader'
import Table from 'components/Mui/TableComponent'
import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import moment from 'moment'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import { useSnackbar } from 'notistack'

const AttendanceDetails = () => {
  const [loading, setLoading] = useState()
  const selectedDate = localStorage.getItem('selectedDate')
  const [month, setMonth] = useState(new Date(selectedDate ? selectedDate : ''))
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()
  let fromDate = moment(new Date(selectedDate ? selectedDate : ''))
    .startOf('month')
    .format('YYYY-MM-DD')
  let toDate = moment(new Date(selectedDate ? selectedDate : ''))
    .endOf('month')
    .format('YYYY-MM-DD')
  const [attendanceDetails, setAttendanceDetails] = useState()
  const [tableData, setTableData] = useState()
  const type = 'Student'
  const id = localStorage.getItem('schoolId')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [sampleBuffer, setSampleBuffer] = useState()

  useEffect(() => {
    console.log(moment(new Date(month)).startOf('month').format('YYYY-MM-DD'))
    fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
    toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
    setLoading(true)
    http
      .get(
        `/statistics/school/${id}/analytics?type=${type}&fromDate=${fromDate}&toDate=${toDate}`,
      )
      .then((res) => {
        console.log(res)
        setCount(res?.data?.count)
        setAttendanceDetails(res?.data)
        setTableData(res?.data?.data?.slice(0, 10))
        setLoading(false)
      })

    // http
    //   .get(
    //     `/statistics/school/${id}/report?type=${type}&fromDate=${fromDate}&toDate=${toDate}`,
    //   )
    //   .then((res) => {
    //     console.log(res?.data?.data)
    //     setSampleBuffer(res?.data?.data)
    //   })
  }, [month])

  console.log(attendanceDetails)

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Number of students present',
      dataIndex: 'noOfPresentStudents',
      key: 'noOfPresentStudents',
    },
    {
      title: 'Number of sick students',
      dataIndex: 'noOfSickStudents',
      key: 'noOfSickStudents',
    },
    {
      title: 'Attendance percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (row) => {
        return (Number.isInteger(row) ? row + '%' : row?.toFixed(1)) + '%'
      },
    },
    {
      title: 'Updated on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
  ]

  async function handlePagination(e, v) {
    setPage(v)
    try {
      fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
      toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
      setLoading(true)
      http
        .get(
          `/statistics/school/${id}/analytics?type=${type}&fromDate=${fromDate}&toDate=${toDate}&limit=10&offset=${
            v > 0 ? (v - 1) * 10 : v * 10
          }`,
        )
        .then((res) => {
          console.log(res)
          setAttendanceDetails(res?.data)
          setTableData(res?.data?.data)
          setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  if (loading) {
    return <CircularProgress />
  }

  const handleExport = () => {
    if (sampleBuffer === undefined) {
      enqueueSnackbar('Sample Excel Sheet Not Generated', {
        variant: 'error',
      })
      return
    }
    const arr = new Uint8Array(sampleBuffer)
    const blob = new Blob([arr], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const file = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.href = file
    link.download = 'Source_Document_Sample_Sheet.xlsx'
    link.click()
  }

  return (
    <div>
      {' '}
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Schools', to: '/schools' },
              {
                name: 'School Analytics',
                to: '/schools/school-analytics/attendance-activities',
              },
              { name: 'Student Attendance', to: null },
            ]}
          />
        }
      />
      <Box sx={{ marginTop: '20px' }}>
        <DetailsBanner
          items={[
            {
              name: 'School ID',
              value: attendanceDetails?.schoolDetails?.schoolId,
            },
            {
              name: 'School Name',
              value: attendanceDetails?.schoolDetails?.name,
            },
            {
              name: 'ATDO Point',
              value: attendanceDetails?.schoolDetails?.atdoPoint,
            },
            {
              name: 'School Type',
              value: attendanceDetails?.schoolDetails?.schoolType,
            },
            { name: 'Mandal', value: attendanceDetails?.schoolDetails?.mandal },
            {
              name: 'District',
              value: attendanceDetails?.schoolDetails?.district,
            },
          ]}
        />
      </Box>
      <Stack
        gap={'18px'}
        direction="row"
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          marginTop: '28px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: '190px', height: '50px' }}>
            <DatePicker
              views={['year', 'month']}
              label="Select Month"
              value={month}
              minDate={dayjs('2022-01-01')}
              onChange={(newValue) => {
                setMonth(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </Box>
        </LocalizationProvider>

        {/* <Button
          variant="contained"
          sx={{
            backgroundColor: 'black !important',
            height: '50px',
            width: '190px',
            borderRadius: '10px',
          }}
          onClick={() => handleExport()}
        >
          <Typography
            sx={{
              fontFamily: 'poppins_semibold',
              color: 'white',
              fontSize: '13px',
            }}
          >
            Export Data
          </Typography>
        </Button> */}
      </Stack>
      <Box sx={{ marginTop: '-2rem' }}>
        <DividerHeading title={'Student Attendance'} />
      </Box>
      {attendanceDetails?.count == 0 ? (
        <SchoolAnalyticsEmptyScreen />
      ) : (
        <>
          <Table
            key={'attendance'}
            dataSource={tableData}
            rowKey={'id'}
            columns={columns}
            loading={loading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
    </div>
  )
}

export default AttendanceDetails
