import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import FormLabel from './FormLabel'

type MyTextFieldProps = MuiTextFieldProps & {
  control?: any
  label?: string
  name?: string
  value?: any
  hookForm?: boolean
  required?: boolean
  handleChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

export default function TextField({
  name,
  control,
  label,
  value,
  handleChange,
  required = false,
  hookForm = true,
  ...rest
}: MyTextFieldProps) {
  if (!hookForm)
    return (
      <MuiTextField
        label={
          <>
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: 'poppins_medium',
                opacity: '0.5',
              }}
            >
              {label}
            </Typography>
            {required && (
              <Typography fontSize={12} component={'span'} color="error">
                {' *'}
              </Typography>
            )}
          </>
        }
        value={value}
        name={name}
        onChange={handleChange}
        {...rest}
      />
    )
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MuiTextField
          {...rest}
          name={name}
          label={<FormLabel required={required} label={label} textColor={''} />}
          onChange={(e) => {
            onChange(e)
            if (handleChange) {
              handleChange(e)
            }
          }}
          value={value}
          error={error ? true : false}
          helperText={error ? error.message : null}
        />
      )}
    />
  )
}
