import { Box, Typography } from '@mui/material'
const EmptyScreen = () => {
  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10rem',
        }}
      >
        <img src="/images/Group 20286.png" style={{ height: '300px' }} />
        <Box>
          <Typography
            sx={{
              fontSize: '22px',
              fontFamily: 'poppins_bold',
              marginTop: '23px',
              marginBottom: '10px',
            }}
          >
            <center>{`Stock Materials`}</center>
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_medium',
              opacity: '0.85',
            }}
          >
            <center>{`There are no stock materials.`}</center>
          </Typography>
        </Box>
      </Box>
    </div>
  )
}

export default EmptyScreen
