import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const ATDOPointsForm = ({ data, toggleDrawer }) => {
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  const [districts, setDistricts] = useState()
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  const queryClient = useQueryClient()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    http.get(`/districts`).then((res) => {
      console.log(res)
      setDistricts(res?.data?.districts)
    })
  }, [])

  console.log(districts)
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: data?.name,
      districtId: data?.district?.id,
    },
  })

  const onSubmit = (data) => {
    console.log(data)
    if (edit) {
      console.log(id)
      http
        .put(`/atdo-point/${id}`, data)
        .then((res) => {
          enqueueSnackbar('The ATDO Point has been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('atdoPoints')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      http
        .post(`/atdo-point`, data)
        .then((res) => {
          enqueueSnackbar('The ATDO Point has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('atdoPoints')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type={'text'}
          {...register('name', {
            required: true,
            pattern: { value: /^[A-Za-z0-9 ]+$/, message: 'Invalid Name' },
          })}
          label={'ATDO Point'}
          required={true}
        />
        {errors?.name?.type == 'required' && (
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              color: 'red',
            }}
          >
            This field is required
          </Typography>
        )}
        {errors?.name?.type == 'pattern' && (
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              color: 'red',
            }}
          >
            Invalid Name
          </Typography>
        )}
        <FormControl fullWidth sx={{ marginTop: '24px' }}>
          <InputLabel id="demo-simple-select-label">District Name *</InputLabel>
          <Select
            {...register('districtId', {
              required: true,
            })}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="District Name *"
            required={true}
            defaultValue={data?.districtId}
          >
            {districts?.map((item) => {
              return <MenuItem value={item?.id}>{item?.name}</MenuItem>
            })}
          </Select>
        </FormControl>

        {errors?.districtId && (
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              color: 'red',
            }}
          >
            This field is required
          </Typography>
        )}

        <Box
          sx={{
            padding: '28px',
            paddingTop: '0px',
            paddingLeft: '0px',
            marginTop: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
          gap={'18px'}
        >
          <Button
            sx={{
              backgroundColor: '#24272C',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#24272C',
              },
            }}
            type="submit"
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              {data ? 'Edit ATDO Point' : 'Add ATDO Point'}
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: '#F2F2F2',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
            }}
            onClick={() => toggleDrawer(false)}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'poppins_semibold',
                color: 'black',
              }}
            >
              Cancel
            </Typography>
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default ATDOPointsForm
