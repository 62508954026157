import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { http } from 'services/http'

const Form = ({ data, toggleDrawer }) => {
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState()
  useEffect(() => {
    data && setEdit(true)
    data && setId(data?.id)
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      itemName: data?.itemName,
      unitOfMeasure: data?.unitOfMeasure,
      rate: data?.rate,
      itemType: data?.itemType,
      indentFrequency: data?.indentFrequency,
      usageType: data?.usageType,
      lowStockIndication: data?.lowStockIndication,
    },
  })

  const queryClient = useQueryClient()

  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (formData) => {
    if (edit) {
      // const data = {
      //   ...formData,
      //   rate: formData?.rate,
      //   minimumIndentQuantity: formData?.minimumIndentQuantity,
      // }
      http
        .patch(`/stock-materials/${id}`, formData)
        .then(() => {
          enqueueSnackbar('The material has been updated', {
            variant: 'success',
          })
          queryClient.invalidateQueries('stock-materials')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    } else {
      const data = {
        ...formData,
        rate: formData?.rate?.toString(),
        minimumIndentQuantity: formData?.minimumIndentQuantity?.toString(),
      }

      http
        .post(`/stock-materials`, data)
        .then(() => {
          enqueueSnackbar('The material has been created', {
            variant: 'success',
          })
          queryClient.invalidateQueries('stock-materials')
          toggleDrawer(false)
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: 'error',
          })
        })
    }
    console.log(data)
  }

  const unit = watch('unitOfMeasure')

  const categoryTypes = [
    { type: 'III-VII', name: 'lowerClassQuantityPerDay' },
    { type: 'VIII-X', name: 'upperClassQuantityPerDay' },
  ]

  return (
    <div>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={'text'}
            {...register('itemName', {
              required: true,
              pattern: { value: /^[A-Za-z,\-0-9 .]+$/ },
            })}
            label={'Name'}
            required={true}
            sx={{ marginTop: '24px' }}
          />
          {errors?.itemName?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.itemName?.type == 'pattern' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              Invalid Name
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="unitOfMeasure">Unit of Measure *</InputLabel>
            <Select
              labelId="unitOfMeasure"
              id="unitOfMeasure"
              label="Unit of Measure *"
              {...register('unitOfMeasure', { required: true })}
              defaultValue={data?.unitOfMeasure}
              required={true}
            >
              <MenuItem value={'kg'}>{'Kg'}</MenuItem>
              <MenuItem value={'litre'}>{'Litre'}</MenuItem>
              <MenuItem value={'ea'}>{'Each'}</MenuItem>
            </Select>
          </FormControl>
          {errors?.unitOfMeasure && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <Box
            m={0}
            p={0}
            sx={{ display: 'flex', alignItems: 'center' }}
            gap={'5px'}
          >
            <TextField
              type={'text'}
              {...register('rate', {
                required: true,
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              sx={{ marginTop: '25px', width: '300px' }}
              label={'Price'}
              required={true}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'5px'}>
              <Typography mt={'22px'}>per</Typography>
              <Typography
                sx={{
                  padding: '13.38px',
                  border: '1px solid #D9D9D9',
                  marginTop: '23px',
                  borderRadius: '9px',
                }}
              >
                {unit ?? 'Unit'}
              </Typography>
            </Box>
          </Box>
          {errors?.rate?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="type">Type *</InputLabel>
            <Select
              labelId="type"
              id="type"
              label="Type *"
              {...register('itemType', { required: true })}
              defaultValue={data?.itemType}
              required={true}
            >
              <MenuItem value={'perishable'}>{'Perishable'}</MenuItem>
              <MenuItem value={'non-perishable'}>{'Non-Perishable'}</MenuItem>
            </Select>
          </FormControl>
          {errors?.itemType && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="indentFrequency">Indent Frequency *</InputLabel>
            <Select
              labelId="indentFrequency"
              id="indentFrequency"
              label="Indent Frequency *"
              {...register('indentFrequency', { required: true })}
              defaultValue={data?.indentFrequency}
            >
              <MenuItem value={'weekly'}>{'Weekly'}</MenuItem>
              <MenuItem value={'monthly'}>{'Monthly'}</MenuItem>
              <MenuItem value={'quarterly'}>{'Quarterly'}</MenuItem>
            </Select>
          </FormControl>
          {errors?.indentFrequency && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="usageType">Select Usage Type *</InputLabel>
            <Select
              labelId="usageType"
              id="usageType"
              label="Select Usage Type *"
              {...register('usageType', { required: true })}
              defaultValue={data?.usageType}
              required={true}
            >
              <MenuItem value={'daily'}>{'Daily'}</MenuItem>
              <MenuItem value={'weekly'}>{'Weekly'}</MenuItem>
            </Select>
          </FormControl>
          {errors?.usageType && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          {categoryTypes?.map((item, index) => {
            const defaultValue =
              item?.type === 'III-VII'
                ? data?.lowerClassQuantityPerDay
                : data?.upperClassQuantityPerDay
            return (
              <Box
                m={0}
                p={0}
                sx={{ display: 'flex', alignItems: 'center' }}
                gap={'5px'}
              >
                <TextField
                  type={'text'}
                  {...register(item?.name, {
                    required: true,
                  })}
                  sx={{ marginTop: '25px', width: '300px' }}
                  label={`Quantity per student (${item?.type} Class) per day`}
                  defaultValue={defaultValue}
                  required={true}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'5px'}>
                  <Typography
                    sx={{
                      padding: '13.38px',
                      border: '1px solid #D9D9D9',
                      marginTop: '23px',
                      borderRadius: '9px',
                    }}
                  >
                    {unit ?? 'Unit'}
                  </Typography>
                </Box>
              </Box>
            )
          })}
          <Box
            m={0}
            p={0}
            sx={{ display: 'flex', alignItems: 'center' }}
            gap={'5px'}
          >
            <TextField
              type={'text'}
              {...register('lowStockIndication', {
                required: true,
              })}
              sx={{ marginTop: '25px', width: '300px' }}
              label={'Low Stock Limit'}
              required={true}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }} gap={'5px'}>
              <Typography
                sx={{
                  padding: '13.38px',
                  border: '1px solid #D9D9D9',
                  marginTop: '23px',
                  borderRadius: '9px',
                }}
              >
                {unit ?? 'Unit'}
              </Typography>
            </Box>
          </Box>
          {errors?.lowStockIndication?.type == 'required' && (
            <Typography
              sx={{
                fontFamily: 'poppins_medium',
                color: 'red',
              }}
            >
              This field is required
            </Typography>
          )}
          <Box
            sx={{
              padding: '28px',
              paddingTop: '0px',
              paddingLeft: '0px',
              marginTop: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
            gap={'18px'}
          >
            <Button
              sx={{
                backgroundColor: '#24272C',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#24272C',
                },
              }}
              type="submit"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {edit ? 'Edit Stock Material' : 'Create Stock Material'}
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: '#F2F2F2',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              onClick={() => toggleDrawer(false)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'poppins_semibold',
                  color: 'black',
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  )
}

export default Form
