import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Breadcrumbs from 'components/Breadcrumbs'
import { ContentCards } from 'components/ContentCards'
import DividerHeading from 'components/DividerHeading'
import DetailsBanner from 'components/Mui/DetailsBanner'
import SingleSelect from 'components/Mui/Form/SingleSelect'
import PageHeader from 'components/Mui/PageHeader'
import Table from 'components/Mui/TableComponent'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import dayjs from 'dayjs'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { http } from 'services/http'

const StaffAttDetails = () => {
  const [attendanceDetails, setAttendanceDetails] = useState()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState()
  const DATE = localStorage.getItem('staffAndTeacherDate')
  const [month, setMonth] = useState(new Date(DATE ? DATE : ''))
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()
  const [result, setResult] = useState()
  const [staffDetails, setStaffDetails] = useState()
  let fromDate = moment(new Date(DATE ? DATE : ''))
    .startOf('month')
    .format('YYYY-MM-DD')
  let toDate = moment(new Date(DATE ? DATE : ''))
    .endOf('month')
    .format('YYYY-MM-DD')
  const [tableData, setTableData] = useState([])
  const id = searchParams.get('id')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [sampleBuffer, setSampleBuffer] = useState()

  useEffect(() => {
    console.log(moment(new Date(month)).startOf('month').format('YYYY-MM-DD'))
    fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
    toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
    setLoading(true)
    http
      .get(`/statistics/${id}/staff?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        console.log(res)
        setTableData(res?.data?.data?.slice(0, 10))
        setResult(res?.data)
        setCount(res?.data?.count)
        setStaffDetails(res?.data?.staff)
        setLoading(false)
      })

    // http
    //   .get(
    //     `/statistics/staff/${id}/report?fromDate=${fromDate}&toDate=${toDate}`,
    //   )
    //   .then((res) => {
    //     console.log(res?.data?.data)
    //     setSampleBuffer(res?.data?.data)
    //   })
  }, [month])

  const cards = [
    { count: result?.workingDays ?? 'NA', text: 'Working days' },
    { count: result?.present ?? 'NA', text: 'Present days' },
    { count: result?.absent ?? 'NA', text: 'Absent days' },
    {
      percentage: result?.percentage
        ? `${
            Number.isInteger(result?.percentage)
              ? result?.percentage
              : result?.percentage?.toFixed(1)
          }%`
        : 'NA',
      img: '/images/Group 21049.png',
      text: 'Attendance Percentage',
    },
  ]

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Attendance Status',
      dataIndex: 'status',
      key: 'status',
      render: (rowData) =>
        rowData ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Present
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Absent
          </Box>
        ),
    },
    {
      title: 'Updated on',
      dataIndex: 'UpdatedAt',
      key: 'UpdatedAt',
      render: (row) => {
        console.log(row)
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
      render: (row) => {
        return row ? row : 'NA'
      },
    },
  ]

  const handleExport = () => {
    if (sampleBuffer === undefined) {
      enqueueSnackbar('Sample Excel Sheet Not Generated', {
        variant: 'error',
      })
      return
    }
    const arr = new Uint8Array(sampleBuffer)
    const blob = new Blob([arr], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const file = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.href = file
    link.download = 'Source_Document_Sample_Sheet.xlsx'
    link.click()
  }

  async function handlePagination(e, v) {
    setPage(v)
    try {
      fromDate = moment(new Date(month)).startOf('month').format('YYYY-MM-DD')
      toDate = moment(new Date(month)).endOf('month').format('YYYY-MM-DD')
      setLoading(true)
      http
        .get(
          `/statistics/${id}/staff?fromDate=${fromDate}&toDate=${toDate}&limit=10&offset=${
            v > 0 ? (v - 1) * 10 : v * 10
          }`,
        )
        .then((res) => {
          console.log(res)
          setTableData(res?.data?.data)
          setStaffDetails(res?.data?.staff)
          setLoading(false)
        })
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              {
                name: 'Staff Attendance',
                to: '/staff-attendance',
              },
              { name: 'Staff Attendance Details', to: null },
            ]}
          />
        }
      />
      <Box sx={{ marginTop: '20px' }}>
        <DetailsBanner
          items={[
            { name: 'Staff Member Name', value: staffDetails?.name },
            { name: 'Mobile Number', value: staffDetails?.mobileNumber },
            { name: 'Gender', value: staffDetails?.gender },
            { name: 'Designation', value: staffDetails?.designation?.name },
            {
              name: 'Attendance Status',
              value: result?.status ? 'Present' : 'Absent',
            },
          ]}
        />
      </Box>
      <Stack
        gap={'18px'}
        direction="row"
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          marginTop: '28px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: '190px', height: '50px' }}>
            <DatePicker
              views={['year', 'month']}
              label="Select Month"
              value={month}
              minDate={dayjs('2022-01-01')}
              onChange={(newValue) => {
                setMonth(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </Box>
        </LocalizationProvider>

        {/* <Button
        variant="contained"
        sx={{
          backgroundColor: 'black !important',
          height: '50px',
          width: '190px',
          borderRadius: '10px',
        }}
        onClick={() => handleExport()}
      >
        <Typography
          sx={{
            fontFamily: 'poppins_semibold',
            color: 'white',
            fontSize: '13px',
          }}
        >
          Export Data
        </Typography>
      </Button> */}
      </Stack>
      <Box sx={{ marginTop: '-2rem' }}>
        <DividerHeading title={'Staff Attendance'} />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '0.75fr 0.75fr 0.75fr 1fr',
          gridColumnGap: '28px',
          marginBottom: '29px',
        }}
      >
        {cards?.map((item, ind) => {
          return (
            <ContentCards
              count={item?.count}
              text={item?.text}
              img={item?.img}
              percentage={item?.percentage}
            />
          )
        })}
      </Box>
      {tableData?.length == 0 ? (
        <SchoolAnalyticsEmptyScreen />
      ) : (
        <>
          <Table
            key={'staffAttendanceDetails'}
            dataSource={tableData}
            rowKey={'id'}
            columns={columns}
            loading={loading}
          />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
            />{' '}
          </Box>
        </>
      )}
    </div>
  )
}

export default StaffAttDetails
