import { Box, Button, Typography } from '@mui/material'
import Loader from 'components/Loader'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import * as moment from 'moment'
import { useNavigate } from 'react-router-dom'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const VerticalBarChart = ({ date, data, loading }) => {
  const navigate = useNavigate()
  const barData = data?.map((item) => {
    return {
      issue: item?.issue,
      'Total Number of Health Emergencies': item?.count,
    }
  })
  console.log(data)
  const year = moment(new Date(date)).year()
  const CustomTick = (props) => {
    const { x, y, payload } = props

    return (
      <g>
        <foreignObject x={x - 215} y={y - 19} width={200} height={100}>
          <div
            style={{
              height: '41px',
              border: '1px solid #13131329',
              backgroundColor: '#FBFBFB',
              padding: '0px 12px',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                opacity: 1,
                fontFamily: 'poppins_medium',
                fontSize: '14px',
              }}
            >
              {payload?.value.length > 15
                ? payload?.value?.substring(0, 15) + '...'
                : payload?.value}
            </Typography>
          </div>
        </foreignObject>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(active)
    console.log(payload)
    console.log(label)

    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: '7px',
            backgroundColor: 'white',
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0px 3px 13px #2A32711A',
          }}
        >
          <Typography sx={{ opacity: '0.5' }}>{payload[0].name} </Typography>
          <Box display="flex">
            <Typography variant="h6">
              {payload[0]?.value?.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Box>
        </div>
      )
    }

    return null
  }

  const renderLegend = (props) => {
    console.log(props)
    const { payload } = props
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '7px',
        }}
      >
        {payload.map((entry, index) => (
          <span
            style={{ padding: '10px', display: 'flex', alignItems: 'center' }}
            key={`item-${index}`}
          >
            <div
              style={{
                display: 'inline-block',
                width: '12px',
                marginRight: '4px',
                height: '12px',
                borderRadius: '999px',
                backgroundColor: entry.color,
              }}
            ></div>
            <Typography>{entry.value}</Typography>
          </span>
        ))}
      </div>
    )
  }

  return (
    <div>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          border: '1px solid #EBEBEB',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#E9EBFF',
            border: '1px solid #0E0E0E1A',
            borderRadius: '10px 10px 0px 0px',
          }}
        >
          <Box p={'20px'}>
            <Typography
              sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}
            >{`Total Health Emergencies in ${year}`}</Typography>
          </Box>
        </Box>
        {loading ? (
          <Loader />
        ) : barData?.length == 0 ? (
          <Box sx={{ marginTop: '30px' }}>
            <SchoolAnalyticsEmptyScreen />
          </Box>
        ) : (
          <>
            <ResponsiveContainer width={'100%'} height={400}>
              <BarChart
                layout="vertical"
                data={barData?.slice(0, 3)}
                margin={{
                  top: 45,
                  right: 80,
                  left: 140,
                  bottom: 5,
                }}
              >
                <CartesianGrid
                  stroke="#f5f5f5"
                  vertical={false}
                  horizontal={true}
                />
                <XAxis
                  type="number"
                  tick
                  tickLine={false}
                  axisLine={false}
                  // tickCount={13}
                  style={{
                    fontSize: '12px',
                    fontFamily: 'poppins_regular',
                    overflowY: 'scroll',
                  }}
                />
                <YAxis
                  dataKey="issue"
                  type="category"
                  axisLine={false}
                  tickLine={false}
                  tick={<CustomTick />}
                  width={150}
                  style={{ overflow: 'scroll' }}
                />
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  shared={false}
                  content={<CustomTooltip />}
                />
                <Legend content={renderLegend} iconType={'circle'} />
                <Bar
                  dataKey="Total Number of Health Emergencies"
                  radius={[0, 10, 10, 0]}
                  barSize={12}
                  fill="#0A1160"
                />
              </BarChart>
            </ResponsiveContainer>
            <Box textAlign="end" sx={{ margin: '15px' }}>
              <Button
                variant="text"
                sx={{ color: '#0A1160' }}
                onClick={() => navigate('all-emergencies')}
              >
                View All
              </Button>
            </Box>
          </>
        )}
      </Box>
    </div>
  )
}

export default VerticalBarChart
