//@ts-nocheck
import { Box, Modal, Typography } from '@mui/material'

export const TextModal = ({ setOpen, row }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    padding: '20px',
  }

  const handleClose = () => setOpen(false)

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const priceType = row?.usageType === 'daily' ? 'day' : 'week'

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontFamily: 'poppins_semibold', fontSize: '16px' }}>
            {row?.itemName}
          </Typography>
          <Box
            p={'10px'}
            sx={{
              border: '1px solid #5FCF8A',
              borderRadius: '10px',
              backgroundColor: '#E7F8EE',
            }}
          >
            <Typography sx={{ color: '#5FCF8A', fontSize: '14px' }}>
              {capitalizeFirstLetter(row?.usageType) ?? 'NA'}
            </Typography>
          </Box>
        </Box>
        <Card
          category={row?.lowerClassQuantityPerDay}
          section={'III-VII'}
          unit={row?.unitOfMeasure}
          priceType={priceType}
          rate={row?.rate}
        />
        <Card
          category={row?.upperClassQuantityPerDay}
          section={'VIII-X'}
          unit={row?.unitOfMeasure}
          priceType={priceType}
          rate={row?.rate}
        />
      </Box>
    </Modal>
  )
}

const Card = ({ category, section, unit, priceType, rate }) => {
  return (
    <Box
      sx={{
        padding: '20px',
        border: '1px solid #E1E1E1',
        borderRadius: '5px',
        marginTop: '15px',
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: 'poppins_medium',
            fontSize: '14px',
            opacity: '0.6',
          }}
        >
          Quantity per student ({section} Class) per day
        </Typography>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          {Math.round(category * 1000) / 1000 + ' ' + unit}
        </Typography>
      </Box>
      {priceType === 'week' && (
        <Box mt={'10px'}>
          <Typography
            sx={{
              fontFamily: 'poppins_medium',
              fontSize: '14px',
              opacity: '0.6',
            }}
          >
            {`Price per student per week`}
          </Typography>
          <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
            {'₹ ' + Math.round(rate * category * 7 * 100) / 100}
          </Typography>
        </Box>
      )}
      <Box mt={'10px'}>
        <Typography
          sx={{
            fontFamily: 'poppins_medium',
            fontSize: '14px',
            opacity: '0.6',
          }}
        >
          {`Price per student per day`}
        </Typography>
        <Typography sx={{ fontFamily: 'poppins_medium', fontSize: '14px' }}>
          {'₹ ' + Math.round(rate * category * 100) / 100}
        </Typography>
      </Box>
    </Box>
  )
}
