import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  TextField,
  Typography,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { DeleteButton, EditButton } from 'components/IconButtons'
import Table from 'components/Mui/TableComponent'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { http } from 'services/http'
import { StyledDrawerContent, StyledDrawerHeader } from '../DrawerTemplate'
import MedicalIssuesEmptyScreen from './MedicalIssuesEmptyScreen'
import MedicalIssuesForm from './MedicalIssuesForm'

const MedicalIssues = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState()
  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [issues, setIssues] = useState([])
  const [open, setOpen] = React.useState(false)
  const [formData, setFormData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const queryClient = useQueryClient()

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  useEffect(() => {
    modalOpen == false && queryClient.invalidateQueries('medicalIssues')
  }, [modalOpen])

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const fetchIssues = async (search, page) => {
    const res = await http.get(
      `/medical-issues?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`,
    )
    setIssues(res?.data?.result)
    setCount(res?.data?.count)
  }

  const { data, isLoading } = useQuery(['medicalIssues', search, page], () =>
    fetchIssues(search, page),
  )

  const columns = [
    {
      title: 'Issue',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Added on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (colData, row) => (
        <Box display="flex" gap="10px">
          <EditButton
            onClick={() => {
              handleEditClick(row)
            }}
          />
          <DeleteButton onClick={() => handleDelete(row?.id)} />
        </Box>
      ),
    },
  ]

  const handleDelete = (id) => {
    http
      .delete(`/medical-issues/${id}`)
      .then((res) => {
        console.log(res)
        queryClient.invalidateQueries('medicalIssues')
      })
      .catch((err) => {
        console.log(err)
        enqueueSnackbar(err?.response?.data?.message)
      })
  }

  if (isLoading || loading || !issues) {
    return <CircularProgress />
  }

  function handlePagination(e, v) {
    setPage(v)
  }

  return (
    <div>
      <Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <form className="mb-4" onSubmit={() => {}}>
              <TextField
                label="Search by Issue Name"
                sx={{ width: '494px', marginBottom: '1.5rem' }}
                hookForm={false}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => {}}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
            </form>
            <Box sx={{ display: 'flex' }} gap={'18px'}>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: '10px',
                  borderColor: 'black !important',
                  background: 'transparent !important',
                }}
                onClick={() => {
                  setFormData(null)
                  toggleDrawer(true)
                }}
              >
                {' '}
                <Typography
                  sx={{ fontSize: '13px', fontFamily: 'poppins_semibold' }}
                >
                  + Add Issue
                </Typography>
              </Button>
            </Box>
          </Box>
          {issues?.length == 0 ? (
            <MedicalIssuesEmptyScreen
              title="Medical Issues"
              section={'medical issues'}
            />
          ) : (
            <>
              <Table
                key={'Medical Issues'}
                dataSource={issues}
                rowKey={'id'}
                columns={columns}
                loading={isLoading}
              />
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Pagination
                  count={count > 10 ? Math.ceil(count / 10) : 1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePagination}
                  page={page}
                />
              </Box>
            </>
          )}

          <Drawer
            anchor="right"
            open={open}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
          >
            <StyledDrawerHeader>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                }}
              >
                {formData ? 'Edit Medical Issue' : 'Add Medical Issue'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontFamily: 'poppins_semibold',
                  color: 'white',
                  opacity: '0.5',
                  cursor: 'pointer',
                }}
                onClick={() => toggleDrawer(false)}
              >
                Cancel
              </Typography>
            </StyledDrawerHeader>
            <StyledDrawerContent>
              <Box sx={{ width: '550px' }}>
                <MedicalIssuesForm
                  data={formData}
                  toggleDrawer={toggleDrawer}
                />
              </Box>
            </StyledDrawerContent>
          </Drawer>
        </Box>
      </Box>
    </div>
  )
}

export default MedicalIssues
