import { Box, Typography } from '@mui/material'
import React from 'react'

const AlternateScreen = () => {
  return (
    <div>
      {' '}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '30px',
        }}
      >
        <img src="/images/Group 20286.png" style={{ height: '300px' }} />
        <Box>
          <Typography
            sx={{
              fontSize: '22px',
              fontFamily: 'poppins_bold',
              marginTop: '23px',
              marginBottom: '10px',
            }}
          >
            <center>No records found</center>
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: 'poppins_bold',
              marginTop: '-7px',
              marginBottom: '10px',
            }}
          >
            <center>Please select a School</center>
          </Typography>
        </Box>
      </Box>
    </div>
  )
}

export default AlternateScreen
