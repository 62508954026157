import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Pagination,
  Typography,
} from '@mui/material'
import { EditButton } from 'components/IconButtons'
import Table from 'components/Mui/TableComponent'
import { useEffect, useState } from 'react'

import DividerHeading from 'components/DividerHeading'
import Loader from 'components/Loader'
import TextField from 'components/Mui/Form/TextField'
import SchoolAnalyticsEmptyScreen from 'components/SchoolAnalyticsEmptyScreen'
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from 'pages/MasterData/DrawerTemplate'
import { useQuery } from 'react-query'
import { http } from 'services/http'
import Form from './Form'
import moment from 'moment'

const SchoolStock = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [formData, setFormData] = useState([])
  const [open, setOpen] = useState(false)
  const schoolId = localStorage?.getItem('schoolID')

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen)
  }

  const handleEditClick = (row) => {
    setFormData(row)
    toggleDrawer(true)
  }

  const buttonSX = {
    '&:hover': {
      borderColor: '#24272C',
      color: '#24272C',
      background: 'none',
    },
    fontSize: '15px',
    fontFamily: 'poppins_semibold',
    borderRadius: '10px',
    borderColor: '#24272C',
    color: '#24272C',
  }

  const fetchStock = async (search, page) => {
    const res = await http.get(
      `/stocks/${schoolId}?search=${search}&limit=10&offset=${
        page > 0 ? (page - 1) * 10 : page * 10
      }`,
    )
    console.log(res)
    return res?.data
  }

  const { data, isLoading } = useQuery(
    ['schoolStock', search, page],
    () => fetchStock(search, page),
    {
      onError: () => {
        alert('Network error')
      },
    },
  )

  useEffect(() => {
    http.get(`/stocks/${schoolId}?search=${search}`).then((res) => {
      setCount(res?.data?.length)
    })
  }, [search])

  const tableData = data

  const columns = [
    {
      title: 'Item',
      dataIndex: 'stockMaterial.itemName',
      key: 'stockMaterial.itemName',
    },
    {
      title: 'Quantity',
      dataIndex: 'currentQuantity',
      key: 'currentQuantity',
      render: (value, row) => {
        return (
          (Math.round(value) * 1000) / 1000 +
          ' ' +
          row?.stockMaterial?.unitOfMeasure
        )
      },
    },
    {
      title: 'Low Stock Limit',
      render: (_, row) => {
        return (
          (Math.round(row?.lowStockIndication) * 1000) / 1000 +
          ' ' +
          row?.stockMaterial?.unitOfMeasure
        )
      },
    },

    {
      title: 'Last Updated Date',
      dataIndex: 'lastUpdatedDate',
      key: 'lastUpdatedDate',
      render: (_, colData) => {
        return moment(_).format('YYYY-MM-DD')
      },
    },
    {
      title: 'Type',
      dataIndex: 'stockMaterial.itemType',
      key: 'stockMaterial.itemType',
      render: (rowData) =>
        rowData == 'nonPerishable' ? (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Non-Perishable
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Perishable
          </Box>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, row) =>
        row?.currentQuantity <= row?.stockMaterial?.lowStockIndication ? (
          <Box
            sx={{
              backgroundColor: '#fe214f',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            Low Stock
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: '#70BF88',
              padding: '2px 10px',
              borderRadius: '10px',
              width: '122px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            In Stock
          </Box>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        console.log(row)
        return (
          <Box sx={{ display: 'flex' }} gap={'18px'}>
            <EditButton
              onClick={() => {
                handleEditClick(row)
              }}
            />
          </Box>
        )
      },
    },
  ]

  function handlePagination(e, v) {
    setPage(v)
  }
  return (
    <div>
      <Box sx={{ marginTop: '1rem' }}>
        <DividerHeading title={'School Stock'} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '18px',
          }}
        >
          <form className="mb-4">
            <TextField
              label="Search by Stock Item Name"
              sx={{ width: '494px' }}
              hookForm={false}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <Button
            sx={buttonSX}
            variant="outlined"
            onClick={() => {
              console.log('hi')
              setFormData(null)
              toggleDrawer(true)
            }}
          >
            + Add Stock Item
          </Button>
        </Box>
        {isLoading || !tableData ? (
          <Loader />
        ) : tableData?.length == 0 ? (
          <center>
            <SchoolAnalyticsEmptyScreen />
          </center>
        ) : (
          <>
            <Table
              key={'schoolStock'}
              dataSource={tableData}
              rowKey={'id'}
              columns={columns}
              loading={isLoading}
            />
            <Box
              sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pagination
                count={count > 10 ? Math.ceil(count / 10) : 1}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
                page={page}
              />{' '}
            </Box>
          </>
        )}
        <Drawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <StyledDrawerHeader>
            <Typography
              sx={{
                fontSize: '14px',
                fontFamily: 'poppins_semibold',
                color: 'white',
              }}
            >
              {formData ? 'Edit Stock Item' : 'Add Stock Item'}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: 'poppins_semibold',
                color: 'white',
                opacity: '0.5',
                cursor: 'pointer',
              }}
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Typography>
          </StyledDrawerHeader>
          <StyledDrawerContent>
            <Box sx={{ width: '550px' }}>
              <Form data={formData} toggleDrawer={toggleDrawer} />
            </Box>
          </StyledDrawerContent>
        </Drawer>
      </Box>
    </div>
  )
}

export default SchoolStock
